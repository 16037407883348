import React from "react";
import Icon from "../../components/icon/Icon";
import Button from "../../components/button/Button";
import { UncontrolledDropdown, CardTitle, DropdownMenu, DropdownItem, DropdownToggle } from "reactstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ActionCenterApplicant = () => {
  const [t, i18n] = useTranslation("global")
  return (
    <React.Fragment>
      {" "}
      <div className="card-inner-group">
        <div className="card-inner card-inner-md">
          <div className="card-title-group">
            <CardTitle>
              <h6 className="title">{t("main_actions")}</h6>
            </CardTitle>
            {/* <div className="card-tools mr-n1">
              <UncontrolledDropdown>
                <DropdownToggle tag="a" className="dropdown-toggle btn btn-icon btn-trigger">
                  <Icon name="more-h"></Icon>
                </DropdownToggle>
                <DropdownMenu right>
                  <ul className="link-list-opt no-bdr">
                    <li>
                      <DropdownItem
                        tag="a"
                        href="#dropdownitem"
                        onClick={(ev) => {
                          ev.preventDefault();
                        }}
                      >
                        <Icon name="setting"></Icon>
                        <span>Action Settings</span>
                      </DropdownItem>
                    </li>
                    <li>
                      <DropdownItem
                        tag="a"
                        href="#dropdownitem"
                        onClick={(ev) => {
                          ev.preventDefault();
                        }}
                      >
                        <Icon name="notify"></Icon>
                        <span>Push Notification</span>
                      </DropdownItem>
                    </li>
                  </ul>
                </DropdownMenu>
              </UncontrolledDropdown>
            </div> */}
          </div>
        </div>
        <Link to={`${process.env.PUBLIC_URL}/jobs_applicant/`}>
        <div className="card-inner">
          <div className="nk-wg-action">
            <div className="nk-wg-action-content">
              <Icon name="user-list-fill"></Icon>
              <div className="title">{t("recent_jobs")}</div>
              <p align="justify">
              {t("recent_jobs_description")}
              </p>
            </div>
            <Button className="btn-icon btn-trigger mr-n2">
              <Icon name="forward-ios"></Icon>
            </Button>
          </div>
        </div>
        </Link>
        <Link to={`${process.env.PUBLIC_URL}/my_jobs/`}>
        <div className="card-inner">
          <div className="nk-wg-action">
            <div className="nk-wg-action-content">
              <Icon name="user-check"></Icon>
              <div className="title">{t("my_jobs")}</div>
              <p align="justify">
              {t("my_jobs_description")}{" "}
              </p>
            </div>
            <Button className="btn-icon btn-trigger mr-n2">
              <Icon name="forward-ios"></Icon>
            </Button>
          </div>
        </div>
        </Link>
        <Link to={`${process.env.PUBLIC_URL}/companies-list-applicant/`}>
        <div className="card-inner">
          <div className="nk-wg-action">
            <div className="nk-wg-action-content">
              <Icon name="building"></Icon>
              <div className="title">{t("companies")}</div>
              <p align="justify">
              {t("companies_description")}
              </p>
            </div>
            <Button className="btn-icon btn-trigger mr-n2">
              <Icon name="forward-ios"></Icon>
            </Button>
          </div>
        </div>
        </Link>
        <Link to={`${process.env.PUBLIC_URL}/user-profile-regular/`}>
        <div className="card-inner">
          <div className="nk-wg-action">
            <div className="nk-wg-action-content">
              <Icon name="account-setting"></Icon>
              <div className="title">{t("update_profile")}</div>
              <p align="justify">
              {t("update_profile_description")}
              </p>
            </div>
            <Button className="btn-icon btn-trigger mr-n2">
              <Icon name="forward-ios"></Icon>
            </Button>
          </div>
        </div>
        </Link>
      </div>
    </React.Fragment>
  );
};
export default ActionCenterApplicant;

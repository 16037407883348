//Chart Data
export var analyticAuData = {
  labels: [
    "01 Jan",
    "02 Jan",
    "03 Jan",
    "04 Jan",
    "05 Jan",
    "06 Jan",
    "07 Jan",
    "08 Jan",
    "09 Jan",
    "10 Jan",
    "11 Jan",
    "12 Jan",
    "13 Jan",
    "14 Jan",
    "15 Jan",
    "16 Jan",
    "17 Jan",
    "18 Jan",
    "19 Jan",
    "20 Jan",
    "21 Jan",
    "22 Jan",
    "23 Jan",
    "24 Jan",
    "25 Jan",
    "26 Jan",
    "27 Jan",
    "28 Jan",
    "29 Jan",
    "30 Jan",
  ],
  dataUnit: "People",
  datasets: [
    {
      label: "Active Users Analytics",
      color: "#9d72ff",
      barPercentage: 0.7,
      categoryPercentage: 0.7,
      backgroundColor: "rgba(157, 114, 255, 0.75)",
      data: [
        1110, 1220, 1310, 980, 900, 770, 1060, 830, 690, 730, 790, 950, 1100, 800, 1250, 850, 950, 450, 900, 1000, 1200,
        1250, 900, 950, 1300, 1200, 1250, 650, 950, 750,
      ],
    },
  ],
};

export var analyticOvData = {
  labels: [
    "01 Jan",
    "02 Jan",
    "03 Jan",
    "04 Jan",
    "05 Jan",
    "06 Jan",
    "07 Jan",
    "08 Jan",
    "09 Jan",
    "10 Jan",
    "11 Jan",
    "12 Jan",
    "13 Jan",
    "14 Jan",
    "15 Jan",
    "16 Jan",
    "17 Jan",
    "18 Jan",
    "19 Jan",
    "20 Jan",
    "21 Jan",
    "22 Jan",
    "23 Jan",
    "24 Jan",
    "25 Jan",
    "26 Jan",
    "27 Jan",
    "28 Jan",
    "29 Jan",
    "30 Jan",
  ],
  dataUnit: "People",
  datasets: [
    {
      label: "Current Month",
      borderDash: [5],
      borderWidth: 2,
      fill: false,
      borderColor: "#9d72ff",
      backgroundColor: "transparent",
      pointBorderColor: "transparent",
      pointBackgroundColor: "transparent",
      pointHoverBackgroundColor: "#fff",
      pointHoverBorderColor: "#9d72ff",
      pointBorderWidth: 2,
      pointHoverRadius: 4,
      pointHoverBorderWidth: 2,
      pointRadius: 4,
      pointHitRadius: 4,
      data: [
        3910, 4420, 4110, 5180, 4400, 5170, 6460, 8830, 5290, 5430, 4690, 4350, 4600, 5200, 5650, 6850, 6950, 4150,
        4300, 6000, 6800, 2250, 6900, 7950, 6900, 4200, 6250, 7650, 8950, 9750,
      ],
    },
    {
      label: "Prev Month",
      color: "#9d72ff",
      borderWidth: 2,
      lineTension: 0,
      dash: 0,
      borderColor: "#9d72ff",
      backgroundColor: "rgba(157, 114, 255, 0.15)",
      borderCapStyle: "square",
      pointBorderColor: "transparent",
      pointBackgroundColor: "transparent",
      pointHoverBackgroundColor: "#fff",
      pointHoverBorderColor: "#9d72ff",
      pointBorderWidth: 2,
      pointHoverRadius: 4,
      pointHoverBorderWidth: 2,
      pointRadius: 4,
      pointHitRadius: 4,
      data: [
        4110, 4220, 4810, 5480, 4600, 5670, 6660, 4830, 5590, 5730, 4790, 4950, 5100, 5800, 5950, 5850, 5950, 4450,
        4900, 8000, 7200, 7250, 7900, 8950, 6300, 7200, 7250, 7650, 6950, 4750,
      ],
    },
  ],
};

export var analyticOvDataSet2 = {
  labels: ["01 Jan", "02 Jan", "03 Jan", "04 Jan", "05 Jan", "06 Jan", "07 Jan"],
  dataUnit: "People",
  datasets: [
    {
      label: "Current Month",
      borderDash: [5],
      borderWidth: 2,
      fill: false,
      borderColor: "#9d72ff",
      backgroundColor: "transparent",
      pointBorderColor: "transparent",
      pointBackgroundColor: "transparent",
      pointHoverBackgroundColor: "#fff",
      pointHoverBorderColor: "#9d72ff",
      pointBorderWidth: 2,
      pointHoverRadius: 4,
      pointHoverBorderWidth: 2,
      pointRadius: 4,
      pointHitRadius: 4,
      data: [3910, 4420, 4110, 5180, 4400, 5170, 6460],
    },
    {
      label: "Prev Month",
      color: "#798bff",
      borderWidth: 2,
      lineTension: 0,
      dash: 0,
      borderColor: "#9d72ff",
      backgroundColor: "rgba(121, 139, 255, 0.15)",
      borderCapStyle: "square",
      pointBorderColor: "transparent",
      pointBackgroundColor: "transparent",
      pointHoverBackgroundColor: "#fff",
      pointHoverBorderColor: "#9d72ff",
      pointBorderWidth: 2,
      pointHoverRadius: 4,
      pointHoverBorderWidth: 2,
      pointRadius: 4,
      pointHitRadius: 4,
      data: [4110, 4220, 4810, 5480, 4600, 5670, 6660],
    },
  ],
};

export var analyticOvDataSet3 = {
  labels: [
    "01 Jan",
    "02 Jan",
    "03 Jan",
    "04 Jan",
    "05 Jan",
    "06 Jan",
    "07 Jan",
    "08 Jan",
    "09 Jan",
    "10 Jan",
    "11 Jan",
    "12 Jan",
    "13 Jan",
    "14 Jan",
    "15 Jan",
    "16 Jan",
    "17 Jan",
    "18 Jan",
    "19 Jan",
    "20 Jan",
    "21 Jan",
    "22 Jan",
    "23 Jan",
    "24 Jan",
    "25 Jan",
    "26 Jan",
    "27 Jan",
    "28 Jan",
    "29 Jan",
    "30 Jan",
  ],
  dataUnit: "People",
  datasets: [
    {
      label: "Current Month",
      borderDash: [5],
      borderWidth: 2,
      fill: false,
      borderColor: "#9d72ff",
      backgroundColor: "transparent",
      pointBorderColor: "transparent",
      pointBackgroundColor: "transparent",
      pointHoverBackgroundColor: "#fff",
      pointHoverBorderColor: "#9d72ff",
      pointBorderWidth: 2,
      pointHoverRadius: 4,
      pointHoverBorderWidth: 2,
      pointRadius: 4,
      pointHitRadius: 4,
      data: [
        3910, 4420, 4110, 5180, 4400, 5170, 6460, 8830, 5290, 5430, 4690, 4350, 4600, 5200, 5650, 6850, 6950, 4150,
        4300, 6000, 6800, 2250, 6900, 7950, 6900, 4200, 6250, 7650, 8950, 9750,
      ],
    },
    {
      label: "Prev Month",
      color: "#798bff",
      borderWidth: 2,
      lineTension: 0,
      dash: 0,
      borderColor: "#9d72ff",
      backgroundColor: "rgba(121, 139, 255, 0.15)",
      borderCapStyle: "square",
      pointBorderColor: "transparent",
      pointBackgroundColor: "transparent",
      pointHoverBackgroundColor: "#fff",
      pointHoverBorderColor: "#9d72ff",
      pointBorderWidth: 2,
      pointHoverRadius: 4,
      pointHoverBorderWidth: 2,
      pointRadius: 4,
      pointHitRadius: 4,
      data: [
        4110, 4220, 4810, 5480, 4600, 5670, 6660, 4830, 5590, 5730, 4790, 4950, 5100, 5800, 5950, 5850, 5950, 4450,
        4900, 8000, 7200, 7250, 7900, 8950, 6300, 7200, 7250, 7650, 6950, 4750,
      ],
    },
  ],
};

export var OrganicSearchDataSet2 = {
  labels: [
    "01 Jan",
    "02 Jan",
    "03 Jan",
    "04 Jan",
    "05 Jan",
    "06 Jan",
    "07 Jan",
    "08 Jan",
    "09 Jan",
    "10 Jan",
    "11 Jan",
    "12 Jan",
  ],
  dataUnit: "People",
  datasets: [
    {
      label: "Organic Search",
      lineTension: 0,
      borderWidth: 2,
      color: "#798bff",
      backgroundColor: "rgba(121, 139, 255, 0.25)",
      borderColor: "#798bff",
      pointBorderColor: "transparent",
      pointBackgroundColor: "transparent",
      pointHoverBackgroundColor: "#fff",
      pointHoverBorderColor: "#9d72ff",
      pointBorderWidth: 2,
      pointHoverRadius: 4,
      pointHoverBorderWidth: 2,
      pointRadius: 4,
      pointHitRadius: 4,
      data: [100, 180, 65, 165, 65, 90, 100, 110, 90, 125, 100, 195],
    },
  ],
};

export var OrganicSearchData = {
  labels: [
    "01 Jan",
    "02 Jan",
    "03 Jan",
    "04 Jan",
    "05 Jan",
    "06 Jan",
    "07 Jan",
    "08 Jan",
    "09 Jan",
    "10 Jan",
    "11 Jan",
    "12 Jan",
  ],
  dataUnit: "People",
  datasets: [
    {
      label: "Organic Search",
      lineTension: 0,
      borderWidth: 2,
      color: "#798bff",
      backgroundColor: "rgba(121, 139, 255, 0.25)",
      borderColor: "#798bff",
      pointBorderColor: "transparent",
      pointBackgroundColor: "transparent",
      pointHoverBackgroundColor: "#fff",
      pointHoverBorderColor: "#9d72ff",
      pointBorderWidth: 2,
      pointHoverRadius: 4,
      pointHoverBorderWidth: 2,
      pointRadius: 4,
      pointHitRadius: 4,
      data: [110, 80, 125, 65, 95, 75, 90, 110, 80, 125, 70, 95],
    },
  ],
};

export var OrganicSearchDataSet3 = {
  labels: [
    "01 Jan",
    "02 Jan",
    "03 Jan",
    "04 Jan",
    "05 Jan",
    "06 Jan",
    "07 Jan",
    "08 Jan",
    "09 Jan",
    "10 Jan",
    "11 Jan",
    "12 Jan",
  ],
  dataUnit: "People",
  datasets: [
    {
      label: "Organic Search",
      lineTension: 0,
      borderWidth: 2,
      color: "#798bff",
      backgroundColor: "rgba(121, 139, 255, 0.25)",
      borderColor: "#798bff",
      pointRadius: "0",
      data: [65, 100, 150, 35, 105, 155, 50, 100, 180, 105, 170, 105],
    },
  ],
};

export var OrganicSearchDataSet4 = {
  labels: [
    "01 Jan",
    "02 Jan",
    "03 Jan",
    "04 Jan",
    "05 Jan",
    "06 Jan",
    "07 Jan",
    "08 Jan",
    "09 Jan",
    "10 Jan",
    "11 Jan",
    "12 Jan",
  ],
  dataUnit: "People",
  datasets: [
    {
      label: "Organic Search",
      lineTension: 0,
      borderWidth: 2,
      color: "#798bff",
      backgroundColor: "rgba(121, 139, 255, 0.25)",
      borderColor: "#798bff",
      pointRadius: "0",
      data: [110, 80, 125, 65, 95, 75, 90, 110, 80, 125, 70, 95],
    },
  ],
};

export var SocialMediaData = {
  labels: [
    "01 Jan",
    "02 Jan",
    "03 Jan",
    "04 Jan",
    "05 Jan",
    "06 Jan",
    "07 Jan",
    "08 Jan",
    "09 Jan",
    "10 Jan",
    "11 Jan",
    "12 Jan",
  ],
  dataUnit: "People",
  datasets: [
    {
      label: "Social Media",
      lineTension: 0,
      borderWidth: 2,
      color: "#b8acff",
      backgroundColor: "rgba(184, 172, 255, 0.25)",
      borderColor: "#b8acff",
      pointRadius: "0",
      data: [110, 80, 125, 65, 95, 75, 90, 110, 80, 125, 70, 95],
    },
  ],
};
export var SocialMediaDataSet2 = {
  labels: [
    "01 Jan",
    "02 Jan",
    "03 Jan",
    "04 Jan",
    "05 Jan",
    "06 Jan",
    "07 Jan",
    "08 Jan",
    "09 Jan",
    "10 Jan",
    "11 Jan",
    "12 Jan",
  ],
  dataUnit: "People",
  datasets: [
    {
      label: "Social Media",
      lineTension: 0,
      borderWidth: 2,
      color: "#b8acff",
      backgroundColor: "rgba(184, 172, 255, 0.25)",
      borderColor: "#b8acff",
      pointRadius: "0",
      data: [100, 180, 65, 165, 65, 90, 100, 110, 90, 125, 100, 195],
    },
  ],
};
export var SocialMediaDataSet3 = {
  labels: [
    "01 Jan",
    "02 Jan",
    "03 Jan",
    "04 Jan",
    "05 Jan",
    "06 Jan",
    "07 Jan",
    "08 Jan",
    "09 Jan",
    "10 Jan",
    "11 Jan",
    "12 Jan",
  ],
  dataUnit: "People",
  datasets: [
    {
      label: "Social Media",
      lineTension: 0,
      borderWidth: 2,
      color: "#b8acff",
      backgroundColor: "rgba(184, 172, 255, 0.25)",
      borderColor: "#b8acff",
      pointRadius: "0",
      data: [65, 100, 150, 35, 105, 155, 50, 100, 180, 105, 170, 105],
    },
  ],
};

export var SocialMediaDataSet4 = {
  labels: [
    "01 Jan",
    "02 Jan",
    "03 Jan",
    "04 Jan",
    "05 Jan",
    "06 Jan",
    "07 Jan",
    "08 Jan",
    "09 Jan",
    "10 Jan",
    "11 Jan",
    "12 Jan",
  ],
  dataUnit: "People",
  datasets: [
    {
      label: "Social Media",
      lineTension: 0,
      borderWidth: 2,
      color: "#b8acff",
      backgroundColor: "rgba(184, 172, 255, 0.25)",
      borderColor: "#b8acff",
      pointRadius: "0",
      data: [110, 80, 125, 65, 95, 75, 90, 110, 80, 125, 70, 95],
    },
  ],
};

export var ReferralsData = {
  labels: [
    "01 Jan",
    "02 Jan",
    "03 Jan",
    "04 Jan",
    "05 Jan",
    "06 Jan",
    "07 Jan",
    "08 Jan",
    "09 Jan",
    "10 Jan",
    "11 Jan",
    "12 Jan",
  ],
  dataUnit: "People",
  datasets: [
    {
      label: "Referrals",
      lineTension: 0,
      borderWidth: 2,
      color: "#ffa9ce",
      backgroundColor: "rgba(255, 169, 206, 0.25)",
      borderColor: "#ffa9ce",
      pointRadius: "0",
      data: [110, 80, 125, 65, 95, 75, 90, 110, 80, 125, 70, 95],
    },
  ],
};

export var ReferralsDataSet2 = {
  labels: [
    "01 Jan",
    "02 Jan",
    "03 Jan",
    "04 Jan",
    "05 Jan",
    "06 Jan",
    "07 Jan",
    "08 Jan",
    "09 Jan",
    "10 Jan",
    "11 Jan",
    "12 Jan",
  ],
  dataUnit: "People",
  datasets: [
    {
      label: "Referrals",
      lineTension: 0,
      borderWidth: 2,
      color: "#ffa9ce",
      backgroundColor: "rgba(255, 169, 206, 0.25)",
      borderColor: "#ffa9ce",
      pointRadius: "0",
      data: [100, 180, 65, 165, 65, 90, 100, 110, 90, 125, 100, 195],
    },
  ],
};

export var ReferralsDataSet3 = {
  labels: [
    "01 Jan",
    "02 Jan",
    "03 Jan",
    "04 Jan",
    "05 Jan",
    "06 Jan",
    "07 Jan",
    "08 Jan",
    "09 Jan",
    "10 Jan",
    "11 Jan",
    "12 Jan",
  ],
  dataUnit: "People",
  datasets: [
    {
      label: "Referrals",
      lineTension: 0,
      borderWidth: 2,
      color: "#ffa9ce",
      backgroundColor: "rgba(255, 169, 206, 0.25)",
      borderColor: "#ffa9ce",
      pointRadius: "0",
      data: [65, 100, 150, 35, 105, 155, 50, 100, 180, 105, 170, 105],
    },
  ],
};

export var ReferralsDataSet4 = {
  labels: [
    "01 Jan",
    "02 Jan",
    "03 Jan",
    "04 Jan",
    "05 Jan",
    "06 Jan",
    "07 Jan",
    "08 Jan",
    "09 Jan",
    "10 Jan",
    "11 Jan",
    "12 Jan",
  ],
  dataUnit: "People",
  datasets: [
    {
      label: "Referrals",
      lineTension: 0,
      borderWidth: 2,
      color: "#ffa9ce",
      backgroundColor: "rgba(255, 169, 206, 0.25)",
      borderColor: "#ffa9ce",
      pointRadius: "0",
      data: [110, 80, 125, 65, 95, 75, 90, 110, 80, 125, 70, 95],
    },
  ],
};

export var OthersData = {
  labels: [
    "01 Jan",
    "02 Jan",
    "03 Jan",
    "04 Jan",
    "05 Jan",
    "06 Jan",
    "07 Jan",
    "08 Jan",
    "09 Jan",
    "10 Jan",
    "11 Jan",
    "12 Jan",
  ],
  dataUnit: "People",
  datasets: [
    {
      label: "Others",
      lineTension: 0,
      borderWidth: 2,
      color: "#f9db7b",
      backgroundColor: "rgba(249, 219, 123, 0.25)",
      borderColor: "#f9db7b",
      pointRadius: "0",
      data: [110, 80, 125, 65, 95, 75, 90, 110, 80, 125, 70, 95],
    },
  ],
};
export var OthersDataSet2 = {
  labels: [
    "01 Jan",
    "02 Jan",
    "03 Jan",
    "04 Jan",
    "05 Jan",
    "06 Jan",
    "07 Jan",
    "08 Jan",
    "09 Jan",
    "10 Jan",
    "11 Jan",
    "12 Jan",
  ],
  dataUnit: "People",
  datasets: [
    {
      label: "Others",
      lineTension: 0,
      borderWidth: 2,
      color: "#f9db7b",
      backgroundColor: "rgba(249, 219, 123, 0.25)",
      borderColor: "#f9db7b",
      pointRadius: "0",
      data: [100, 180, 65, 165, 65, 90, 100, 110, 90, 125, 100, 195],
    },
  ],
};

export var OthersDataSet3 = {
  labels: [
    "01 Jan",
    "02 Jan",
    "03 Jan",
    "04 Jan",
    "05 Jan",
    "06 Jan",
    "07 Jan",
    "08 Jan",
    "09 Jan",
    "10 Jan",
    "11 Jan",
    "12 Jan",
  ],
  dataUnit: "People",
  datasets: [
    {
      label: "Others",
      lineTension: 0,
      borderWidth: 2,
      color: "#f9db7b",
      backgroundColor: "rgba(249, 219, 123, 0.25)",
      borderColor: "#f9db7b",
      pointRadius: "0",
      data: [65, 100, 150, 35, 105, 155, 50, 100, 180, 105, 170, 105],
    },
  ],
};

export var OthersDataSet4 = {
  labels: [
    "01 Jan",
    "02 Jan",
    "03 Jan",
    "04 Jan",
    "05 Jan",
    "06 Jan",
    "07 Jan",
    "08 Jan",
    "09 Jan",
    "10 Jan",
    "11 Jan",
    "12 Jan",
  ],
  dataUnit: "People",
  datasets: [
    {
      label: "Others",
      lineTension: 0,
      borderWidth: 2,
      color: "#f9db7b",
      backgroundColor: "rgba(249, 219, 123, 0.25)",
      borderColor: "#f9db7b",
      pointRadius: "0",
      data: [110, 80, 125, 65, 95, 75, 90, 110, 80, 125, 70, 95],
    },
  ],
};

export var BounceRateData = {
  labels: [
    "01 Jan",
    "02 Jan",
    "03 Jan",
    "04 Jan",
    "05 Jan",
    "06 Jan",
    "07 Jan",
    "08 Jan",
    "09 Jan",
    "10 Jan",
    "11 Jan",
    "12 Jan",
  ],
  dataUnit: "People",
  datasets: [
    {
      label: "Bounce Rate",
      lineTension: 0,
      borderWidth: 2,
      color: "#798bff",
      backgroundColor: "rgba(121, 139, 255, 0.25)",
      borderColor: "#798bff",
      pointBorderColor: "transparent",
      pointBackgroundColor: "transparent",
      pointHoverBackgroundColor: "#fff",
      pointHoverBorderColor: "#798bff",
      pointBorderWidth: 2,
      pointHoverRadius: 4,
      pointHoverBorderWidth: 2,
      pointRadius: 4,
      pointHitRadius: 4,
      data: [110, 80, 125, 65, 95, 75, 90, 110, 80, 125, 70, 95],
    },
  ],
};

export var PageviewsData = {
  labels: [
    "01 Jan",
    "02 Jan",
    "03 Jan",
    "04 Jan",
    "05 Jan",
    "06 Jan",
    "07 Jan",
    "08 Jan",
    "09 Jan",
    "10 Jan",
    "11 Jan",
    "12 Jan",
  ],
  dataUnit: "People",
  datasets: [
    {
      label: "Pageviews",
      lineTension: 0,
      borderWidth: 2,
      color: "#9a89ff",
      backgroundColor: "rgba(154, 137, 255, 0.25)",
      borderColor: "#9a89ff",
      pointBorderColor: "transparent",
      pointBackgroundColor: "transparent",
      pointHoverBackgroundColor: "#fff",
      pointHoverBorderColor: "#9a89ff",
      pointBorderWidth: 2,
      pointHoverRadius: 4,
      pointHoverBorderWidth: 2,
      pointRadius: 4,
      pointHitRadius: 4,
      data: [110, 80, 125, 65, 95, 75, 90, 110, 80, 125, 70, 95],
    },
  ],
};

export var NewUsersData = {
  labels: [
    "01 Jan",
    "02 Jan",
    "03 Jan",
    "04 Jan",
    "05 Jan",
    "06 Jan",
    "07 Jan",
    "08 Jan",
    "09 Jan",
    "10 Jan",
    "11 Jan",
    "12 Jan",
  ],
  dataUnit: "People",
  datasets: [
    {
      label: "New Users",
      lineTension: 0,
      borderWidth: 2,
      color: "#798bff",
      backgroundColor: "rgba(121, 139, 255, 0.25)",
      borderColor: "#798bff",
      pointBorderColor: "transparent",
      pointBackgroundColor: "transparent",
      pointHoverBackgroundColor: "#fff",
      pointHoverBorderColor: "#798bff",
      pointBorderWidth: 2,
      pointHoverRadius: 4,
      pointHoverBorderWidth: 2,
      pointRadius: 4,
      pointHitRadius: 4,
      data: [110, 80, 125, 65, 95, 75, 90, 110, 80, 125, 70, 95],
    },
  ],
};

export var TimeOnSiteData = {
  labels: [
    "01 Jan",
    "02 Jan",
    "03 Jan",
    "04 Jan",
    "05 Jan",
    "06 Jan",
    "07 Jan",
    "08 Jan",
    "09 Jan",
    "10 Jan",
    "11 Jan",
    "12 Jan",
  ],
  dataUnit: "Hours",
  datasets: [
    {
      label: "Time on Site",
      lineTension: 0,
      borderWidth: 2,
      color: "#9a89ff",
      backgroundColor: "rgba(154, 137, 255, 0.25)",
      borderColor: "#9a89ff",
      pointBorderColor: "transparent",
      pointBackgroundColor: "transparent",
      pointHoverBackgroundColor: "#fff",
      pointHoverBorderColor: "#9a89ff",
      pointBorderWidth: 2,
      pointHoverRadius: 4,
      pointHoverBorderWidth: 2,
      pointRadius: 4,
      pointHitRadius: 4,
      data: [110, 80, 125, 65, 95, 75, 90, 110, 80, 125, 70, 95],
    },
  ],
};

export var deviceStatusData = {
  labels: ["Desktop", "Mobile", "Tablet"],
  dataUnit: "People",
  legend: false,
  datasets: [
    {
      borderColor: "#fff",
      backgroundColor: ["#9cabff", "#b8acff", "#7de1f8"],
      data: [84.5, 14.2, 1.3],
    },
  ],
};

export var deviceStatusDataSet4 = {
  labels: ["Desktop", "Mobile", "Tablet"],
  dataUnit: "People",
  legend: false,
  datasets: [
    {
      borderColor: "#fff",
      backgroundColor: ["#9cabff", "#b8acff", "#7de1f8"],
      data: [84.5, 14.2, 1.3],
    },
  ],
};

export var deviceStatusDataSet2 = {
  labels: ["Desktop", "Mobile", "Tablet"],
  dataUnit: "People",
  legend: false,
  datasets: [
    {
      borderColor: "#fff",
      backgroundColor: ["#9cabff", "#b8acff", "#7de1f8"],
      data: [50.5, 32.2, 10.3],
    },
  ],
};

export var deviceStatusDataSet3 = {
  labels: ["Desktop", "Mobile", "Tablet"],
  dataUnit: "People",
  legend: false,
  datasets: [
    {
      borderColor: "#fff",
      backgroundColor: ["#9cabff", "#b8acff", "#7de1f8"],
      data: [70.5, 25.2, 4.3],
    },
  ],
};

export var TrafficChannelDoughnutData = {
  labels: ["Organic Search", "Social Media", "Referrals", "Others"],
  dataUnit: "People",
  legend: false,
  datasets: [
    {
      borderColor: "#fff",
      backgroundColor: ["#798bff", "#b8acff", "#ffa9ce", "#f9db7b"],
      data: [4705, 1509, 482, 1000],
    },
  ],
};

export var TrafficChannelDoughnutData2 = {
  labels: ["Organic Search", "Social Media", "Referrals", "Others"],
  dataUnit: "People",
  legend: false,
  datasets: [
    {
      borderColor: "#fff",
      backgroundColor: ["#798bff", "#b8acff", "#ffa9ce", "#f9db7b"],
      data: [3055, 259, 182, 438],
    },
  ],
};

export var TrafficChannelDoughnutData3 = {
  labels: ["Organic Search", "Social Media", "Referrals", "Others"],
  dataUnit: "People",
  legend: false,
  datasets: [
    {
      borderColor: "#fff",
      backgroundColor: ["#798bff", "#b8acff", "#ffa9ce", "#f9db7b"],
      data: [4505, 1059, 282, 800],
    },
  ],
};

export var TrafficChannelDoughnutData4 = {
  labels: ["Organic Search", "Social Media", "Referrals", "Others"],
  dataUnit: "People",
  legend: false,
  datasets: [
    {
      borderColor: "#fff",
      backgroundColor: ["#798bff", "#b8acff", "#ffa9ce", "#f9db7b"],
      data: [4705, 1509, 482, 1000],
    },
  ],
};

export var worldMap = {
  map: "world_en",
  data2: {
    CN: 100000,
    IN: 9900,
    SA: 86,
    EG: 70,
    SE: 0,
    FI: 0,
    FR: 0,
    US: 20,
  },
  data3: {
    AG: 10000,
    CD: 150000,
    BR: 100000,
    ET: 80000,
    EG: 70000,
    SA: 93330,
    RU: 58000,
  },
  data4: {
    GN: 10000,
    BH: 150000,
    AR: 100000,
    CA: 500000,
    EG: 70000,
    SA: 93330,
    AU: 58000,
  },
  data: {
    af: "16.63",
    al: "11.58",
    dz: "158.97",
    ao: "85.81",
    ag: "1.1",
    ar: "351.02",
    am: "8.83",
    au: "1219.72",
    at: "366.26",
    az: "52.17",
    bs: "7.54",
    bh: "21.73",
    bd: "105.4",
    bb: "3.96",
    by: "52.89",
    be: "461.33",
    bz: "1.43",
    bj: "6.49",
    bt: "1.4",
    bo: "19.18",
    ba: "16.2",
    bw: "12.5",
    br: "2023.53",
    bn: "11.96",
    bg: "44.84",
    bf: "8.67",
    bi: "1.47",
    kh: "11.36",
    cm: "21.88",
    ca: "1563.66",
    cv: "1.57",
    cf: "2.11",
    td: "7.59",
    cl: "199.18",
    cn: "5745.13",
    co: "283.11",
    km: "0.56",
    cd: "12.6",
    cg: "11.88",
    cr: "35.02",
    ci: "22.38",
    hr: "59.92",
    cy: "22.75",
    cz: "195.23",
    dk: "304.56",
    dj: "1.14",
    dm: "0.38",
    do: "50.87",
    ec: "61.49",
    eg: "216.83",
    sv: "21.8",
    gq: "14.55",
    er: "2.25",
    ee: "19.22",
    et: "30.94",
    fj: "3.15",
    fi: "231.98",
    fr: "2555.44",
    ga: "12.56",
    gm: "1.04",
    ge: "11.23",
    de: "3305.9",
    gh: "18.06",
    gr: "305.01",
    gd: "0.65",
    gt: "40.77",
    gn: "4.34",
    gw: "0.83",
    gy: "2.2",
    ht: "6.5",
    hn: "15.34",
    hk: "226.49",
    hu: "132.28",
    is: "12.77",
    in: "1430.02",
    id: "695.06",
    ir: "337.9",
    iq: "84.14",
    ie: "204.14",
    il: "201.25",
    it: "2036.69",
    jm: "13.74",
    jp: "5390.9",
    jo: "27.13",
    kz: "129.76",
    ke: "32.42",
    ki: "0.15",
    kr: "986.26",
    undefined: "5.73",
    kw: "117.32",
    kg: "4.44",
    la: "6.34",
    lv: "23.39",
    lb: "39.15",
    ls: "1.8",
    lr: "0.98",
    ly: "77.91",
    lt: "35.73",
    lu: "52.43",
    mk: "9.58",
    mg: "8.33",
    mw: "5.04",
    my: "218.95",
    mv: "1.43",
    ml: "9.08",
    mt: "7.8",
    mr: "3.49",
    mu: "9.43",
    mx: "1004.04",
    md: "5.36",
    mn: "5.81",
    me: "3.88",
    ma: "91.7",
    mz: "10.21",
    mm: "35.65",
    na: "11.45",
    np: "15.11",
    nl: "770.31",
    nz: "138",
    ni: "6.38",
    ne: "5.6",
    ng: "206.66",
    no: "413.51",
    om: "53.78",
    pk: "174.79",
    pa: "27.2",
    pg: "8.81",
    py: "17.17",
    pe: "153.55",
    ph: "189.06",
    pl: "438.88",
    pt: "223.7",
    qa: "126.52",
    ro: "158.39",
    ru: "1476.91",
    rw: "5.69",
    ws: "0.55",
    st: "0.19",
    sa: "434.44",
    sn: "12.66",
    rs: "38.92",
    sc: "0.92",
    sl: "1.9",
    sg: "217.38",
    sk: "86.26",
    si: "46.44",
    sb: "0.67",
    za: "354.41",
    es: "1374.78",
    lk: "48.24",
    kn: "0.56",
    lc: "1",
    vc: "0.58",
    sd: "65.93",
    sr: "3.3",
    sz: "3.17",
    se: "444.59",
    ch: "522.44",
    sy: "59.63",
    tw: "426.98",
    tj: "5.58",
    tz: "22.43",
    th: "312.61",
    tl: "0.62",
    tg: "3.07",
    to: "0.3",
    tt: "21.2",
    tn: "43.86",
    tr: "729.05",
    tm: 0,
    ug: "17.12",
    ua: "136.56",
    ae: "239.65",
    gb: "2258.57",
    us: "14624.18",
    uy: "40.71",
    uz: "37.72",
    vu: "0.72",
    ve: "285.21",
    vn: "101.99",
    ye: "30.02",
    zm: "15.69",
    zw: "5.57",
  },
};

// Json dummy data

export const browserUserData = [
  {
    id: 1,
    browser: "Google Chrome",
    users: "5001",
    userPercentage: "72.84",
    bounceRate: "22.62",
    theme: "primary",
  },
  {
    id: 2,
    browser: "Mozilla Firefox",
    users: "807",
    userPercentage: "7.93",
    bounceRate: "20.49",
    theme: "danger",
  },
  {
    id: 3,
    browser: "Safari Browser",
    users: "607",
    userPercentage: "7.93",
    bounceRate: "21.34",
    theme: "info",
  },
  {
    id: 4,
    browser: "UC Browser",
    users: "350",
    userPercentage: "2.46",
    bounceRate: "20.33",
    theme: "orange",
  },
  {
    id: 5,
    browser: "Edge / IE",
    users: "400",
    userPercentage: "1.14",
    bounceRate: "21.34",
    theme: "blue",
  },
  {
    id: 6,
    browser: "Other Browser",
    users: "800",
    userPercentage: "10.76",
    bounceRate: "20.49",
    theme: "purple",
  },
];

export const browserUserDataSet2 = [
  {
    id: 1,
    browser: "Google Chrome",
    users: "2000",
    userPercentage: "95.84",
    bounceRate: "30.62",
    theme: "primary",
  },
  {
    id: 2,
    browser: "Mozilla Firefox",
    users: "497",
    userPercentage: "5.93",
    bounceRate: "25.49",
    theme: "danger",
  },
  {
    id: 3,
    browser: "Safari Browser",
    users: "497",
    userPercentage: "3.93",
    bounceRate: "27.34",
    theme: "info",
  },
  {
    id: 4,
    browser: "UC Browser",
    users: "187",
    userPercentage: "1.46",
    bounceRate: "29.33",
    theme: "orange",
  },
  {
    id: 5,
    browser: "Edge / IE",
    users: "96",
    userPercentage: "0.14",
    bounceRate: "25.34",
    theme: "blue",
  },
  {
    id: 6,
    browser: "Other Browser",
    users: "1000",
    userPercentage: "15.76",
    bounceRate: "29.49",
    theme: "purple",
  },
];

export const browserUserDataSet3 = [
  {
    id: 1,
    browser: "Google Chrome",
    users: "3000",
    userPercentage: "50.84",
    bounceRate: "25.62",
    theme: "primary",
  },
  {
    id: 2,
    browser: "Mozilla Firefox",
    users: "1000",
    userPercentage: "17.93",
    bounceRate: "27.49",
    theme: "danger",
  },
  {
    id: 3,
    browser: "Safari Browser",
    users: "500",
    userPercentage: "15.93",
    bounceRate: "28.34",
    theme: "info",
  },
  {
    id: 4,
    browser: "UC Browser",
    users: "250",
    userPercentage: "1.46",
    bounceRate: "30.33",
    theme: "orange",
  },
  {
    id: 5,
    browser: "Edge / IE",
    users: "100",
    userPercentage: "5.14",
    bounceRate: "35.34",
    theme: "blue",
  },
  {
    id: 6,
    browser: "Other Browser",
    users: "680",
    userPercentage: "15.76",
    bounceRate: "40.49",
    theme: "purple",
  },
];

export const pagePerUserData = [
  {
    id: 1,
    link: "/",
    views: "6200",
  },
  {
    id: 2,
    link: "/subscription/index.html",
    views: "2094",
  },
  {
    id: 3,
    link: "/general/index.html",
    views: "1634",
  },
  {
    id: 4,
    link: "/crypto/index.html",
    views: "1497",
  },
  {
    id: 5,
    link: "/invest/index.html",
    views: "1349",
  },
  {
    id: 6,
    link: "/subscription/profile.html",
    views: "984",
  },
  {
    id: 7,
    link: "/general/index-crypto.html",
    views: "879",
  },
  {
    id: 8,
    link: "/apps/messages/index.html",
    views: "598",
  },
  {
    id: 9,
    link: "/general/index-crypto.html",
    views: "436",
  },
];

export const pagePerUserDataSet2 = [
  {
    id: 1,
    link: "/",
    views: "1009",
  },
  {
    id: 2,
    link: "/subscription/index.html",
    views: "5004",
  },
  {
    id: 3,
    link: "/general/index.html",
    views: "1644",
  },
  {
    id: 4,
    link: "/crypto/index.html",
    views: "2967",
  },
  {
    id: 5,
    link: "/invest/index.html",
    views: "1509",
  },
  {
    id: 6,
    link: "/subscription/profile.html",
    views: "1000",
  },
  {
    id: 7,
    link: "/general/index-crypto.html",
    views: "900",
  },
  {
    id: 8,
    link: "/apps/messages/index.html",
    views: "600",
  },
  {
    id: 9,
    link: "/general/index-crypto.html",
    views: "500",
  },
];
export const pagePerUserDataSet3 = [
  {
    id: 1,
    link: "/",
    views: "3829",
  },
  {
    id: 2,
    link: "/subscription/index.html",
    views: "1004",
  },
  {
    id: 3,
    link: "/general/index.html",
    views: "1604",
  },
  {
    id: 4,
    link: "/crypto/index.html",
    views: "3697",
  },
  {
    id: 5,
    link: "/invest/index.html",
    views: "1000",
  },
  {
    id: 6,
    link: "/subscription/profile.html",
    views: "984",
  },
  {
    id: 7,
    link: "/general/index-crypto.html",
    views: "900",
  },
  {
    id: 8,
    link: "/apps/messages/index.html",
    views: "800",
  },
  {
    id: 9,
    link: "/general/index-crypto.html",
    views: "469",
  },
];

export const trafficChannelData = [
  {
    id: 1,
    channel: "Organic Search",
    sessions: "400",
    prev: "420",
    change: "3.29",
    changeDifference: "up",
    chart: OrganicSearchData,
  },
  {
    id: 2,
    channel: "Social Media",
    sessions: "259",
    prev: "906",
    change: "10.8",
    changeDifference: "down",
    chart: SocialMediaData,
  },
  {
    id: 3,
    channel: "Referrals",
    sessions: "42",
    prev: "79",
    change: "4.3",
    changeDifference: "down",
    chart: ReferralsData,
  },
  {
    id: 4,
    channel: "Others",
    sessions: "108",
    prev: "90",
    change: "1.6",
    changeDifference: "up",
    chart: OthersData,
  },
];

export const trafficChannelDataSet2 = [
  {
    id: 1,
    channel: "Organic Search",
    sessions: "215",
    prev: "3129",
    change: "4.29",
    changeDifference: "up",
    chart: OrganicSearchDataSet2,
  },
  {
    id: 2,
    channel: "Social Media",
    sessions: "200",
    prev: "836",
    change: "10.8",
    changeDifference: "down",
    chart: SocialMediaDataSet2,
  },
  {
    id: 3,
    channel: "Referrals",
    sessions: "10",
    prev: "783",
    change: "41.3",
    changeDifference: "down",
    chart: ReferralsDataSet2,
  },
  {
    id: 4,
    channel: "Others",
    sessions: "26",
    prev: "87",
    change: "12.6",
    changeDifference: "up",
    chart: OthersDataSet2,
  },
];

export const trafficChannelDataSet3 = [
  {
    id: 1,
    channel: "Organic Search",
    sessions: "4305",
    prev: "4129",
    change: "4.29",
    changeDifference: "up",
    chart: OrganicSearchDataSet3,
  },
  {
    id: 2,
    channel: "Social Media",
    sessions: "859",
    prev: "936",
    change: "15.8",
    changeDifference: "down",
    chart: SocialMediaDataSet3,
  },
  {
    id: 3,
    channel: "Referrals",
    sessions: "482",
    prev: "793",
    change: "41.3",
    changeDifference: "down",
    chart: ReferralsDataSet3,
  },
  {
    id: 4,
    channel: "Others",
    sessions: "138",
    prev: "97",
    change: "12.6",
    changeDifference: "up",
    chart: OthersDataSet3,
  },
];

export const trafficChannelDataSet4 = [
  {
    id: 1,
    channel: "Organic Search",
    sessions: "400",
    prev: "420",
    change: "3.29",
    changeDifference: "up",
    chart: OrganicSearchDataSet4,
  },
  {
    id: 2,
    channel: "Social Media",
    sessions: "259",
    prev: "906",
    change: "10.8",
    changeDifference: "down",
    chart: SocialMediaDataSet4,
  },
  {
    id: 3,
    channel: "Referrals",
    sessions: "42",
    prev: "79",
    change: "4.3",
    changeDifference: "down",
    chart: ReferralsDataSet4,
  },
  {
    id: 4,
    channel: "Others",
    sessions: "108",
    prev: "90",
    change: "1.6",
    changeDifference: "up",
    chart: OthersDataSet4,
  },
];

import React, { useEffect, useState } from "react";
import Icon from "../../components/icon/Icon";
import { orderActivityData } from "../../components/partials/order-activity/OrderData";
import { CardTitle } from "reactstrap";
import { DataTableBody, DataTableHead, DataTableItem, DataTableRow } from "../../components/table/DataTable";
import { Link } from "react-router-dom";


import { useTranslation } from "react-i18next";
import UserProfile from "../../Login/UserProfile";
import Swal from "sweetalert2";
import axios from "axios";


const SkillsReport = (props) => {
  const [orderData, setOrderData] = useState(orderActivityData);
  const [orderActivity, setActivity] = useState("");
  useEffect(() => {
    let data;
    if (orderActivity === "Buy") {
      data = orderActivityData.filter((item) => item.desc.split(" ")[0] === "Buy");
    } else if (orderActivity === "Sell") {
      data = orderActivityData.filter((item) => item.desc.split(" ")[0] === "Sell");
    } else {
      data = orderActivityData;
    }
    setOrderData(data);
  }, [orderActivity]);

  const [t, i18n] = useTranslation("applSkillReport")
  const [ applicants, setapplicants ] = useState([]);
  const [ count, setcount ] = useState(0);

  const list = async () => {
    // setapplicants([])
    // setapplicants([
    //   {
    //     "activity" : {"id":"3", "name": "Perforador"},
    //     "cant" : 30
    //   },
    //   {
    //     "activity" : {"id":"1", "name": "soldador"},
    //     "cant" : 24
    //   }
    // ])
    axios
        .get(`${process.env.REACT_APP_BASE_URL}/api/applicant_activities_count`, {
          headers: {
            Authorization: `Bearer  ${UserProfile.getToken()}`,
          },
          params:{
            page: 1
        }}
      )
      .then((res) => {
        if (res.data.error ? res.data.error[0] == 'El Token a Expirado' : false) {props.history.push('/login'); return}
        setapplicants( res.data.data )
      })
      .catch((error) => console.log(error));
  };

  const counter = () => {
    axios
    .get(`${process.env.REACT_APP_BASE_URL}/api/applicants`, {
      headers: {
        Authorization: `Bearer  ${UserProfile.getToken()}`,
      }
    })
    .then((res) => {
        if (res.data.error ? res.data.error[0] == 'El Token a Expirado' : false) {props.history.push('/login'); return}
        setcount(res.data.data.total)
      })
      .catch((error) => console.log(error));
  }


  useEffect(() => {
    if (navigator.onLine) {
      UserProfile.setToken(localStorage.getItem('token'));
      list();
      counter()
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: t("msg1"),
        confirmButtonColor: "#071b30",
        confirmButtonText: t("ok"),
      });
    }
  }, [])

  return (
    <React.Fragment>
      <div className="card-inner">
        <div className="card-title-group">
          <CardTitle>
            <h6 className="title">
              <span className="mr-2">{t('ttl1')} - {t('ttl5')} {count} </span>
              {/* <Link to={`${process.env.PUBLIC_URL}/transaction-crypto`} className="link d-none d-sm-inline">
                See History
              </Link> */}
            </h6>
          </CardTitle>
          {/* <div className="card-tools">
            <ul className="card-tools-nav">
              <li className={orderActivity === "Buy" ? "active" : ""} onClick={() => setActivity("Buy")}>
                <a
                  href="#buy"
                  onClick={(ev) => {
                    ev.preventDefault();
                  }}
                >
                  <span>Buy</span>
                </a>
              </li>
              <li className={orderActivity === "Sell" ? "active" : ""} onClick={() => setActivity("Sell")}>
                <a
                  href="#sell"
                  onClick={(ev) => {
                    ev.preventDefault();
                  }}
                >
                  <span>Sell</span>
                </a>
              </li>
              <li className={orderActivity === "" ? "active" : ""} onClick={() => setActivity("")}>
                <a
                  href="#all"
                  onClick={(ev) => {
                    ev.preventDefault();
                  }}
                >
                  <span>All</span>
                </a>
              </li>
            </ul>
          </div> */}
        </div>
      </div>
      <DataTableBody className="border-top" bodyclass="nk-tb-orders">
        <DataTableHead>
          <DataTableRow className="nk-tb-orders-type">
            <span>{t('ttl2')}</span>
          </DataTableRow>
          <DataTableRow className="text-center">
            <span>{t('ttl3')}</span>
          </DataTableRow>
          {/* <DataTableRow size="sm">
            <span>Name</span>
          </DataTableRow> */}
          {/* <DataTableRow size="xl">
            <span>Location</span>
          </DataTableRow>
          <DataTableRow size="xxl">
            <span>Phone Number</span>
          </DataTableRow>
          <DataTableRow size="sm" className="text-right">
            <span>Options</span>
          </DataTableRow> */}
          {/* <DataTableRow className="text-right">
            <span>Amount</span>
          </DataTableRow> */}
        </DataTableHead>
        {applicants.map((applic) => {
          return (

            <DataTableItem key={applic.activity.id}>
              <DataTableRow className="nk-tb-orders-type">
              <span className="tb-lead">{applic.activity.name}</span>
              </DataTableRow>
              <DataTableRow className="text-center">
                <span className="tb-lead">{applic.count_applicants}</span>
              </DataTableRow>
              {/* <DataTableRow size="sm">
                <span className="tb-sub">{item.date}</span>
              </DataTableRow> */}
              {/* <DataTableRow size="xl">
                <span className="tb-sub">{item.time}</span>
              </DataTableRow>
              <DataTableRow size="xxl">
                <span className="tb-sub text-primary">{item.ref}</span>
              </DataTableRow>
              <DataTableRow size="sm" className="text-right">
                <span className="tb-sub tb-amount">
                  {item.usd} <span>USD</span>
                </span>
              </DataTableRow> */}
              {/* <DataTableRow className="text-right">
                <span className="tb-sub tb-amount ">
                  {item.amount} <span>BTC</span>
                </span>
              </DataTableRow> */}
            </DataTableItem>
          );
        })}
      </DataTableBody>
    </React.Fragment>
  );
};
export default SkillsReport;

import React from "react";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import { Card } from "reactstrap";
import { useTranslation } from "react-i18next";
import {
  Accordian,
  Block,
  BlockContent,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  PreviewCard,
} from "../../components/Component";

const Faq = ({ ...props }) => {
  const [t, i18n] = useTranslation("homeappl");
  return (
    <React.Fragment>
      <Head title="FAQ" />
      <Content>
        <div className="content-page wide-md m-auto">
          <BlockHead size="lg" wide="xs" className="mx-auto">
            <BlockHeadContent className="text-center">
              <div className="nk-block-head-sub">
                <span>FAQs</span>
              </div>
              <BlockTitle tag="h3" className="fw-normal">
                {t("text_faq")}
              </BlockTitle>
            </BlockHeadContent>
          </BlockHead>
          <Block>
            <Card className="card">
              <Accordian />
            </Card>
          </Block>
        </div>
      </Content>
    </React.Fragment>
  );
};

export default Faq;

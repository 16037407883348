import React,  { useState, useEffect } from "react";
import EnglishFlag from "../../images/flags/english.png";
import SpanishFlag from "../../images/flags/spanish.png";
import FrenchFlag from "../../images/flags/french.png";
import { LangDropdown } from "../../components/Component";
import { useTranslation } from "react-i18next";
import { Row, Col } from "../../components/Component";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import { Link } from "react-router-dom";

const AuthFooter = () => {

  const [t, i18n] = useTranslation("footer");


  return (
    <div className="nk-footer nk-auth-footer-full">
      <div className="container wide-lg">
        <Row className="g-3">
          <Col lg={6} className="order-lg-last">
            <ul className="nav nav-sm justify-content-center justify-content-lg-end">
              <li className="nav-item">
                <Link className="nav-link" target="_blank" to={`${process.env.PUBLIC_URL}/auths/terms`}>
                {t("lbl1")}
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" target="_blank" to={`${process.env.PUBLIC_URL}/auths/terms`}>
                {t("lbl2")}
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" target="_blank" to={`${process.env.PUBLIC_URL}/auths/faq`}>
                {t("lbl6")}
                </Link>
              </li>
              <li className="nav-item ">

              <UncontrolledDropdown direction="up">
                  <DropdownToggle
                    tag="a"
                    href="#toggle"
                    onClick={(ev) => ev.preventDefault()}
                    className="dropdown-toggle dropdown-indicator has-indicator nav-link"
                  >
                    <span>{t(localStorage.getItem('lngselect'))}</span>
                   <img
                          width="25"
                          height="17"
                          className="rounded-circle mx-3"
                          src={localStorage.getItem('lngiconselect')}
                        />
                  </DropdownToggle>
                  <LangDropdown />
                </UncontrolledDropdown>


              </li>
            </ul>
          </Col>
          <Col lg="6">
            <div className="nk-block-content text-center text-lg-left">
              <p className="text-soft">{t("lbl5")}</p>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};
export default AuthFooter;
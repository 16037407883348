import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";

import axios from "axios";
import UserProfile from "../../Login/UserProfile";

// import Select from "react-select";
// import { components } from "react-select";
import Head from "../../layout/head/Head";
import DatePicker from "react-datepicker";
import { Modal, ModalBody, FormGroup } from "reactstrap";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Row,
  Col,
  Button,
  RSelect,
} from "../../components/Component";

// import basuraimg from "../../img/Basura.png";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";


const UserProfileRegularPage = (props) => {
  const [t, i18n] = useTranslation("detailapplap");

  // Set Data from API
  const [ idApplicant, setidApplicant ] = useState(0);
  const [ first_nameRes, setfirst_nameRes ] = useState("");
  const [ country_nacionalityRes, setcountry_nacionalityRes ] = useState("");
  const [ identification_typeRes, setidentification_typeRes ] = useState("");
  const [ emailRes, setemailRes ] = useState("");
  const [ civil_statusRes, setcivil_statusRes ] = useState("");
  const [ pais, setpais ] = useState([]);
  const [ paisLocation, setpaisLocation ] = useState([]);
  const [ gender, setgender ] = useState([]);
  const [ civilStatus, setcivilStatus ] = useState([]);
  const [ identificationType, setidentificationType ] = useState([]);

  const [modalTab, setModalTab] = useState("1");
  // Initial Data from Database
  const [personaluserInfo, setPersonalUserInfo] = useState({});
  // console.log(formData, "formData");
  const [modal, setModal] = useState(false);

  // Update the name of the user in the menu
  useEffect(() => {
    setfirst_nameRes(personaluserInfo.first_name);
  }, [personaluserInfo, setfirst_nameRes]);

  const saveEditApplicant = (val) => {
    val.lng = localStorage.getItem('lngselect');

    // console.log("editando aplicante");
    axios
      .put(
        `${process.env.REACT_APP_BASE_URL}/api/applicants/${idApplicant}`,
        val,
        {
          headers: {
            Authorization: `Bearer  ${UserProfile.getToken()}`,
          },
        }
      )
      .then((res) => {
        if (res.data.data.code == "new") {
          registro(val)
          return
        }
        if (!res.data.status) {
          Swal.fire({
            icon: "error",
            title: "Oops..",
            confirmButtonColor: "#071b30",
            confirmButtonText: t("ok"),
          });
          return
        }
        Swal.fire({
          icon: "success",
          title: t("updated_data"),
          confirmButtonColor: "#071b30",
          confirmButtonText: t("ok"),
        });
      })
      .catch((error) => console.log(error));
  };

  const registro = (val) => {
    // console.log("tregistrando aplicante");
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/api/applicants`,
        val,
        {
          headers: {
            Authorization: `Bearer  ${UserProfile.getToken()}`,
          },
        }
      )
      .then((res) => {
        if (!res.data.status) {
          Swal.fire({
            icon: "error",
            title: t("fail_updated_data"),
            confirmButtonColor: "#071b30",
            confirmButtonText: t("ok"),
          });
          return
        }
        Swal.fire({
          icon: "success",
          title: "success",
          confirmButtonColor: "#071b30",
          confirmButtonText: t("ok"),
        });
       localStorage.setItem('idapplicant', res.data.data.id);
      })
      .catch((error) => console.log(error));
  };

  const getGenders = async () => {
    await axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/attrs/genders`, {
        headers: {
          Authorization: `Bearer  ${UserProfile.getToken()}`,
        },
      })
      .then((res) => {
        setgender(res.data.data.genders)
      });
  };

  const getCivilStatus = async () => {
    await axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/attrs/civil_status`, {
        headers: {
          Authorization: `Bearer  ${UserProfile.getToken()}`,
        },
      })
      .then((res) => {
        setcivilStatus(res.data.data.civil_status)
      });
  };

  const getIdentificationType = async () => {
    await axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/attrs/identification_type`, {
        headers: {
          Authorization: `Bearer  ${UserProfile.getToken()}`,
        },
      })
      .then((res) => {
        setidentificationType(res.data.data.identification_type)
      });
  };

  const getCountry = async () => {
    await axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/attrs/country`, {
        headers: {
          Authorization: `Bearer  ${UserProfile.getToken()}`,
        },
      })
      .then((res) => {
        setpais(res.data.data.country)
        setpaisLocation(res.data.data.country)
      });
  };

  // Get and set basic data applicant
  const getApplicant = async () => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/api/applicants/profile`,
        {
          headers: {
            Authorization: `Bearer  ${UserProfile.getToken()}`,
          },
        }
      )
      .then((res) => {
        if (res.data.error ? res.data.error[0] == 'El Token a Expirado' : false) {props.history.push('/login'); return}
        setidApplicant(res.data.data.id)
        setfirst_nameRes(res.data.data.first_name)
        setcountry_nacionalityRes(res.data.data.country_nacionality.name ?? '')
        setidentification_typeRes(res.data.data.identification_type ?? '')
        setemailRes(res.data.data.email)
        setcivil_statusRes(res.data.data.civil_status ?? '')

        setPersonalUserInfo({
          first_name: res.data.data.first_name ?? '',
          second_name: res.data.data.second_name ?? '',
          first_lastname: res.data.data.first_lastname ?? '',
          second_lastname: res.data.data.second_lastname ?? '',
          title: res.data.data.title ?? '',
          email: res.data.data.email ?? '',
          birthday: res.data.data.birthday ?? '',
          country_nacionality: res.data.data.country_nacionality?.id ?? '',
          civil_status: res.data.data.civil_status?.id ?? '',
          // civil_status_desc: res.data.data.civil_status.description ?? '',
          identification_type: res.data.data.identification_type?.id ?? '',
          // type_identification_desc: res.data.data.identification_type.description,
          identification: res.data.data.identification ?? '',
        });
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    if (navigator.onLine) {
      UserProfile.setToken(localStorage.getItem("token"));
      UserProfile.setId(localStorage.getItem("id"));
      getCountry();
      getIdentificationType();
      getCivilStatus();
      getGenders();

      /***************** */
      getApplicant();

    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: t("msg4"),
        confirmButtonColor: "#071b30",
        confirmButtonText: t("ok"),
      });
    }
  }, [])

  return (
    <React.Fragment>
      <Head title="Applicant Profile"></Head>
      <BlockHead size="lg">
        <BlockBetween>
          <BlockHeadContent>
            <BlockTitle tag="h4">{t("lbl1")}</BlockTitle>
            <BlockDes>
              <p>{t("basic_info")}</p>
            </BlockDes>
          </BlockHeadContent>
          <BlockHeadContent className="align-self-start d-lg-none">
            <Button
              className={`toggle btn btn-icon btn-trigger mt-n1 ${props.sm ? "active" : ""}`}
              onClick={() => props.updateSm(!props.sm)}
            >
              <Icon name="menu-alt-r"></Icon>
            </Button>
          </BlockHeadContent>
        </BlockBetween>
      </BlockHead>

      <Block>
        <div className="nk-data data-list">
          <div className="data-head">
            <h6 className="overline-title">{t("basic_info_title")}</h6>
          </div>
          {/* First name */}
          <div className="data-item" onClick={() => setModal(true)}>
            <div className="data-col">
              <span className="data-label">{t("lbl2")}</span>
              {/* <span className="data-value">{userInfo.name}</span> */}
              <span className="data-value">{personaluserInfo.first_name}</span>
            </div>
            <div className="data-col data-col-end">
              <span className="data-more">
                <Icon name="forward-ios"></Icon>
              </span>
            </div>
          </div>
          {/* Middle name */}
          <div className="data-item" onClick={() => setModal(true)}>
            <div className="data-col">
              <span className="data-label">{t("lbl3")}</span>
              <span className="data-value">{personaluserInfo.second_name}</span>
            </div>
            <div className="data-col data-col-end">
              <span className="data-more">
                <Icon name="forward-ios"></Icon>
              </span>
            </div>
          </div>
          {/* Last name */}
          <div className="data-item" onClick={() => setModal(true)}>
            <div className="data-col">
              <span className="data-label">{t("lbl4")}</span>
              <span className="data-value">{personaluserInfo.first_lastname}</span>
            </div>
            <div className="data-col data-col-end">
              <span className="data-more">
                <Icon name="forward-ios"></Icon>
              </span>
            </div>
          </div>
          {/* Second last name */}
          <div className="data-item" onClick={() => setModal(true)}>
            <div className="data-col">
              <span className="data-label">{t("lbl5")}</span>
              <span className="data-value">{personaluserInfo.second_lastname}</span>
            </div>
            <div className="data-col data-col-end">
              <span className="data-more">
                <Icon name="forward-ios"></Icon>
              </span>
            </div>
          </div>
          {/* Gender */}
          <div className="data-item" onClick={() => setModal(true)}>
            <div className="data-col">
              <span className="data-label">{t("gender")}</span>
              <span className="data-value">{personaluserInfo.gender}</span>
            </div>
            <div className="data-col data-col-end">
              <span className="data-more">
                <Icon name="forward-ios"></Icon>
              </span>
            </div>
          </div>
          {/* Professional title */}
          <div className="data-item" onClick={() => setModal(true)}>
            <div className="data-col">
              <span className="data-label">{t("lbl6")}</span>
              <span className="data-value">{personaluserInfo.title}</span>
            </div>
            <div className="data-col data-col-end">
              <span className="data-more">
                <Icon name="forward-ios"></Icon>
              </span>
            </div>
          </div>
          {/* Email */}
          <div className="data-item">
            <div className="data-col">
              <span className="data-label">{t("lbl16")}</span>
              <span className="data-value">{personaluserInfo.email}</span>
            </div>
            <div className="data-col data-col-end">
              <span className="data-more">
                <Icon name="forward-ios"></Icon>
              </span>
            </div>
          </div>
          {/* Date of birth */}
          <div className="data-item" onClick={() => setModal(true)}>
            <div className="data-col">
              <span className="data-label">{t("lbl24")}</span>
              <span className="data-value">{personaluserInfo.birthday}</span>
            </div>
            <div className="data-col data-col-end">
              <span className="data-more">
                <Icon name="forward-ios"></Icon>
              </span>
            </div>
          </div>
          {/* Country Nacionality */}
          <div className="data-item" onClick={() => setModal(true)}>
            <div className="data-col">
              <span className="data-label">{t("lbl14")}</span>
              <span className="data-value">
              {/* { personaluserInfo.country_nacionality } */}
              { country_nacionalityRes }
                </span>
            </div>
            <div className="data-col data-col-end">
              <span className="data-more">
                <Icon name="forward-ios"></Icon>
              </span>
            </div>
          </div>
        {/* End Div Basics   */}
        </div>
        {/* Div Civil Info */}
        <div className="nk-data data-list">
          <div className="data-head">
            <h6 className="overline-title">{t("civil_info_title")}</h6>
          </div>
          {/* identification_type */}
          <div className="data-item">
            <div className="data-col">
              <span className="data-label">{t("lbl9")}</span>
              <span className="data-value">
              {/* { personaluserInfo.identification_type } */}
              { i18n.language == 'en' ? identification_typeRes.description
                  : i18n.language == 'es' ? identification_typeRes.description_es : identification_typeRes?.description_fr }
               </span>
            </div>
            <div className="data-col data-col-end" onClick={() => setModal(true)} style={{cursor:"pointer"}}>
              <a
                className="link link-primary"
              >
                {t("change")}
              </a>
            </div>
          </div>
          <div className="data-item">
            <div className="data-col">
              <span className="data-label">{t("lbl10")}</span>
              <span className="data-value">{personaluserInfo.identification}</span>
            </div>
            <div className="data-col data-col-end" onClick={() => setModal(true)} style={{cursor:"pointer"}}>
              <a
                onClick={() => setModal(true)}
                className="link link-primary"
              >
                {t("change")}
              </a>
            </div>
          </div>
          {/* Civil Status */}
          <div className="data-item">
            <div className="data-col">
              <span className="data-label">{t("lbl17")}</span>
              <span className="data-value">
              {/* { personaluserInfo.civil_status } */}
              { i18n.language == 'en' ? civil_statusRes.description
                  : i18n.language == 'es' ? civil_statusRes.description_es : civil_statusRes?.description_fr }
                </span>
            </div>
            <div className="data-col data-col-end" onClick={() => setModal(true)} style={{cursor:"pointer"}}>
              <a
                className="link link-primary"
              >
                {t("change")}
              </a>
            </div>
          </div>
        </div>
      </Block>

      <Modal isOpen={modal} className="modal-dialog-centered" size="lg" toggle={() => setModal(false)}>
        <ModalBody>
          {/* Form Old Frontend */}
          {/* {console.log(identification_typeRes, "identification_typeRes")}
          {console.log(country_nacionalityRes, "Country Nacionality")} */}
        <Formik
                            initialValues={{
                              first_name: personaluserInfo.first_name,
                              second_name: personaluserInfo.second_name,
                              first_lastname: personaluserInfo.first_lastname,
                              second_lastname: personaluserInfo.second_lastname,
                              gender: personaluserInfo.gender,
                              title: personaluserInfo.title,
                              email: personaluserInfo.email,
                              birthday: personaluserInfo.birthday,
                              country_nacionality: personaluserInfo.country_nacionality,
                              // description: descriptionRes,
                              identification: personaluserInfo.identification,
                              identification_type: personaluserInfo.identification_type,
                              // idCountry: countryRes,
                              // idDepartament: departmentRes,
                              // idcity:cityRes,
                              // videoPresentation: video_presentationRes,
                              civil_status: personaluserInfo.civil_status,

                            }}
                            validate={(values) => {
                              const pattern = new RegExp(
                                "^[a-zA-ZñÑáéíóúÁÉÍÓÚÇ ]+$",
                                "i"
                              );

                              const errors = {};
                              if (!values.first_name)
                                errors.first_name = t("fr");
                              else if (!pattern.test(values.first_name))
                                errors.first_name = t("ot");
                              if (!values.first_lastname)
                                errors.first_lastname = t("fr");
                              else if (!pattern.test(values.first_lastname))
                                errors.first_lastname = t("ot");
                              // if (!values.title) errors.title = t("fr");
                              // else if (!pattern.test(values.title))
                              //   errors.title = t("ot");
                              // setlimitdescr(500 - (values.description != null ? values.description.length : 0) )

                              // if (!values.idCountry) {
                              //   errors.idCountry = t("fr");
                              // } else {
                              //   if (values.idCountry != estadoDeparment) {
                              //     getDeparment(values.idCountry, "1");
                              //     setestadoDeparment(values.idCountry)
                              //   }
                              // }

                              // if (!values.idDepartament) {
                              //   errors.idDepartament = t("fr");
                              // } else {
                              //   if (
                              //     values.idDepartament !=
                              //     estadoCity
                              //   ) {
                              //     getCities(values.idDepartament);
                              //     setestadoCity(values.idDepartament)
                              //   }
                              // }

                              return errors;
                            }}
                            validationSchema={Yup.object().shape({
                              // idTypeidentification:
                              //   Yup.string(),
                              // civilStatus: Yup.string(),
                              email: Yup.string()
                                .required(t("fr"))
                                .email(t("ei")),
                              birthday: Yup.string().required(t("fr")),
                            })}

                            onSubmit={(val, { resetForm }) => {
                                const data ={
                                  first_name: val.first_name ? val.first_name.trim() : "",
                                  second_name: val.second_name ? val.second_name.trim() : "",
                                  first_lastname: val.first_lastname ? val.first_lastname.trim() : "",
                                  second_lastname: val.second_lastname ? val.second_lastname.trim() : "",
                                  gender: val.gender,
                                  title: val.title ? val.title.trim() : "",
                                  identification_type: val.identification_type ,
                                  identification: val.identification ? val.identification.trim() : "" ,
                                  birthday : val.birthday,
                                  country_nacionality: val.country_nacionality,
                                  email: val.email ?  val.email.trim() : "",
                                  civil_status: val.civil_status,
                                }
                                saveEditApplicant(data)
                                setPersonalUserInfo(data);
                                setModal(false);
                            }}
                            enableReinitialize
                          >
                          { ({handleSubmit}) => (
                            <Form class="container" onSubmit={handleSubmit}>



          <a
            href="#dropdownitem"
            onClick={(ev) => {
              ev.preventDefault();
              setModal(false);
            }}
            className="close"
          >
            <Icon name="cross-sm"></Icon>
          </a>
          <div className="p-2">
            <h5 className="title">{t("update_profile")}</h5>
            <ul className="nk-nav nav nav-tabs">
              <li className="nav-item">
                <a
                  className={`nav-link ${modalTab === "1" && "active"}`}
                  onClick={(ev) => {
                    ev.preventDefault();
                    setModalTab("1");
                  }}
                  href="#personal"
                >
                  Personal
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={`nav-link ${modalTab === "2" && "active"}`}
                  onClick={(ev) => {
                    ev.preventDefault();
                    setModalTab("2");
                  }}
                  href="#address"
                >
                  {t("civil_info_title")}
                </a>
              </li>
            </ul>
            <div className="tab-content">
              <div className={`tab-pane ${modalTab === "1" ? "active" : ""}`} id="personal">
                <Row className="gy-4">
                  <Col md="3">
                    <FormGroup>
                    <label for="first_name" class="form-label">{t("lbl2")} *</label>
                                    <Field
                                      id="first_name"
                                      name="first_name"
                                      type="text"
                                      // onChange={(e) => onInputChange(e)}
                                      class="form-control imputText"
                                      placeholder={t("lbl2")}
                                    />

                                    <ErrorMessage
                                      name="first_name"
                                      component="div"
                                      className="invalid"
                                    />
                      {/* <label className="form-label" htmlFor="first-name">
                        First Name
                      </label>
                      <input
                        type="text"
                        id="first-name"
                        className="form-control"
                        name="firt_name"
                        onChange={(e) => onInputChange(e)}
                        defaultValue={formData.first_name}
                        placeholder="Enter First name"
                      /> */}
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <FormGroup>
                    <label for="second_name" class="form-label">{t("lbl3")}</label>
                                    <Field
                                      id="second_name"
                                      name="second_name"
                                      type="text"
                                      class="form-control imputText"
                                      placeholder={t("lbl3")}
                                    />

                                    <ErrorMessage
                                      name="second_name"
                                      component="div"
                                      className="invalid"
                                    />
                      {/* <label className="form-label" htmlFor="second-name">
                        Second Name
                      </label>
                      <input
                        type="text"
                        id="second-name"
                        className="form-control"
                        name="second_name"
                        onChange={(e) => onInputChange(e)}
                        defaultValue={formData.second_name}
                        placeholder="Enter Second name"
                      /> */}
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <FormGroup>
                    <label for="first_lastname" class="form-label">{t("lbl4")} *</label>
                                    <Field
                                      id="first_lastname"
                                      name="first_lastname"
                                      type="text"
                                      class="form-control imputText"
                                      placeholder={t("lbl4")}
                                    />

                                    <ErrorMessage
                                      name="first_lastname"
                                      component="div"
                                      className="invalid"
                                    />
                      {/* <label className="form-label" htmlFor="first_lastname">
                        First Last Name
                      </label>
                      <input
                        type="text"
                        id="first_lastname"
                        className="form-control"
                        name="first_lastname"
                        onChange={(e) => onInputChange(e)}
                        defaultValue={formData.first_lastname}
                        placeholder="First Lastname"
                      /> */}
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <FormGroup>
                    <label for="second_lastname" class="form-label">{t("lbl5")}</label>
                                    <Field
                                      id="second_lastname"
                                      name="second_lastname"
                                      type="text"
                                      class="form-control imputText"
                                      placeholder={t("lbl5")}
                                    />

                                    <ErrorMessage
                                      name="second_lastname"
                                      component="div"
                                      className="invalid"
                                    />
                      {/* <label className="form-label" htmlFor="second_lastname">
                        Second Last Name
                      </label>
                      <input
                        type="text"
                        id="second_lastname"
                        className="form-control"
                        name="second_lastname"
                        onChange={(e) => onInputChange(e)}
                        defaultValue={formData.second_lastname}
                        placeholder="Second Lastname"
                      /> */}
                    </FormGroup>
                  </Col>
                  <Col md="6">
                  <FormGroup>
                    <label for="gender" class="form-label">{t("gender")} *</label>
                                    <Field
                                      as="select"
                                      id="gender"
                                      name="gender"
                                      className="form-control"
                                      aria-label={t("gender")}
                                    >
                                      {gender.map((i) => {
                                        return (
                                          <option
                                            className="form-control"
                                            value={i.id}

                                          >
                                            {
                                                i18n.language == 'en' ?
                                                  i.name :
                                                i18n.language == 'es' ?
                                                  i.name_es :
                                                i18n.language == 'fr' ?
                                                  i.name_fr : ""
                                              }
                                              {/* {i.id == country_nacionalityRes ? "selected" : ""} */}

                                          </option>
                                        );
                                      })}
                                    </Field>

                                    <ErrorMessage
                                      name="gender"
                                      component="div"
                                      className="invalid"
                                    />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                    <label for="email" class="form-label">{t("lbl16")} *</label>
                                    <Field
                                      id="email"
                                      name="email"
                                      type="text"
                                      class="form-control imputText"
                                      placeholder={t("lbl16")}
                                    />

                                    <ErrorMessage
                                      name="email"
                                      component="div"
                                      className="invalid"
                                    />
                      {/* <label className="form-label" htmlFor="email">
                        Email
                      </label>
                      <input
                        type="email"
                        id="email"
                        className="form-control"
                        name="email"
                        onChange={(e) => onInputChange(e)}
                        defaultValue={formData.email}
                        placeholder="Email"
                      /> */}
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                    <label for="birthday" class="form-label">{t("lbl24")} *</label>
                                    <Field
                                      id="birthday"
                                      name="birthday"
                                      type="date"
                                      class="form-control imputText"
                                      placeholder={t("lbl24")}
                                    />

                                    <ErrorMessage
                                      name="birthday"
                                      component="div"
                                      className="invalid"
                                    />
                      {/* <label className="form-label" htmlFor="birthday">
                        Date of Birth
                      </label>
                      <DatePicker
                        selected={new Date(formData.birthday)}
                        className="form-control"
                        onChange={(date) => setFormData({ ...formData, birthday: getDateStructured(date) })}
                        maxDate={new Date()}
                      /> */}
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                    <label for="title" class="form-label">{t("lbl6")} *</label>
                                    <Field
                                      id="title"
                                      name="title"
                                      type="text"
                                      class="form-control imputText"
                                      placeholder={t("lbl6")}
                                    />

                                    <ErrorMessage
                                      name="title"
                                      component="div"
                                      className="invalid"
                                    />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                    <label for="country_nacionality" class="form-label">{t("lbl14")} *</label>
                                    <Field
                                      as="select"
                                      id="country_nacionality"
                                      name="country_nacionality"
                                      className="form-control"
                                      aria-label={t("lbl8")}
                                    >
                                      {/* <option
                                        className="form-control"
                                        value=""
                                        selected
                                      >
                                        {t("lbl14")}
                                      </option> */}

                                      {paisLocation.map((i) => {
                                        return (
                                          <option
                                            className="form-control"
                                            value={i.id}

                                          >
                                            {
                                                i18n.language == 'en' ?
                                                  i.name :
                                                i18n.language == 'es' ?
                                                  i.name_es :
                                                i18n.language == 'fr' ?
                                                  i.name_fr : ""
                                              }
                                              {/* {i.id == country_nacionalityRes ? "selected" : ""} */}

                                          </option>
                                        );
                                      })}
                                    </Field>

                                    <ErrorMessage
                                      name="country_nacionality"
                                      component="div"
                                      className="invalid"
                                    />
                      {/* <label className="form-label" htmlFor="address-county">
                      Country Nacionality
                      </label>
                      <RSelect
                        options={countryOptions}
                        placeholder="Select a country"
                        defaultValue={[
                          {
                            value: formData.country,
                            label: formData.country_name,
                          },
                        ]}
                        onChange={(e) => setFormData({ ...formData, country: e.value, country_name: e.label })}
                      /> */}
                    </FormGroup>
                  </Col>
                  {/* <Col size="12">
                    <div className="custom-control custom-switch">
                      <input type="checkbox" className="custom-control-input form-control" id="latest-sale" />
                      <label className="custom-control-label" htmlFor="latest-sale">
                        Use full name to display{" "}
                      </label>
                    </div>
                  </Col> */}
                  <Col size="12">
                    <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                      <li>
                        {/* <Button
                          color="primary"
                          size="lg"
                          onClick={(ev) => {
                            ev.preventDefault();
                            submitForm();
                          }}
                        > */}
                        <Button
                          color="primary"
                          size="lg"
                         type="submit"
                        >
                          {t("update_profile")}
                        </Button>
                      </li>
                      <li>
                        <a
                          href="#dropdownitem"
                          onClick={(ev) => {
                            ev.preventDefault();
                            setModal(false);
                          }}
                          className="link link-light"
                        >
                          {t("cancel")}
                        </a>
                      </li>
                    </ul>
                  </Col>
                </Row>
              </div>
              <div className={`tab-pane ${modalTab === "2" ? "active" : ""}`} id="address">
                <Row className="gy-4">
                <Col md="6">
                    <FormGroup>
                    <label for="identification_type" class="form-label">{t("lbl9")}</label>
                                    <Field
                                      as="select"
                                      id="identification_type"
                                      name="identification_type"
                                      className="form-control"
                                      aria-label={t("lbl8")}
                                    >
                                      {/* <option
                                        className="form-control"
                                        value=""
                                        selected
                                      >
                                        {t("lbl9")}
                                      </option> */}

                                      {identificationType.map((i) => {
                                        return (
                                          <option
                                            className="form-control"
                                            value={i.id}
                                          >
                                            {
                                                i18n.language == 'en' ?
                                                  i.description :
                                                i18n.language == 'es' ?
                                                  i.description_es :
                                                i18n.language == 'fr' ?
                                                  i.description_fr : ""
                                              }
                                              {/* { i.id == identification_typeRes ? "selected" : ""} */}
                                          </option>
                                        );
                                      })}
                                    </Field>

                                    <ErrorMessage
                                      name="identification_type"
                                      component="div"
                                      className="invalid"
                                    />
                      {/* <label className="form-label" htmlFor="address-county">
                        Identification Type
                      </label>
                      <RSelect
                        options={TypeIdentification}
                        placeholder="Select a Type Identification"
                        defaultValue={[
                          {
                            value: formData.type_identification,
                            label: formData.type_identification_desc,
                          },
                        ]}
                        onChange={(e) => setFormData({ ...formData, type_identification: e.value, type_identification_desc: e.label })}
                      /> */}
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                    <label for="identification" class="form-label">{t("lbl10")}</label>
                                    <Field
                                      id="identification"
                                      name="identification"
                                      type="text"
                                      class="form-control"
                                      placeholder={t("lbl10")}
                                    />

                                    <ErrorMessage
                                      name="identification"
                                      component="div"
                                      className="invalid"
                                    />
                      {/* <label className="form-label" htmlFor="identification">
                        Identification Number
                      </label>
                      <input
                        type="text"
                        id="identification"
                        name="identification"
                        onChange={(e) => onInputChange(e)}
                        defaultValue={formData.identification}
                        className="form-control"
                      /> */}
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                    <label for="civil_status" class="form-label">{t("lbl17")}</label>
                                    <Field
                                      as="select"
                                      id="civil_status"
                                      name="civil_status"
                                      className="form-control"
                                      aria-label={t("lbl8")}
                                    >

                                      {civilStatus.map((i) => {
                                        return (
                                          <option
                                            className="form-control"
                                            value={i.id}
                                          >
                                            {
                                                i18n.language == 'en' ?
                                                  i.description :
                                                i18n.language == 'es' ?
                                                  i.description_es :
                                                i18n.language == 'fr' ?
                                                  i.description_fr : ""
                                              }
                                          </option>
                                        );
                                      })}
                                    </Field>

                                    <ErrorMessage
                                      name="civil_status"
                                      component="div"
                                      className="invalid"
                                    />
                      {/* <label className="form-label" htmlFor="civil_status">
                       Marital Status
                      </label>
                      <RSelect
                        options={CivilStatus}
                        placeholder="Select a Marital Status"
                        defaultValue={[
                          {
                            value: formData.civil_status,
                            label: formData.civil_status_desc,
                          },
                        ]}
                        onChange={(e) => setFormData({ ...formData, civil_status: e.value, civil_status_desc: e.label })}
                      /> */}
                    </FormGroup>
                  </Col>

                  <Col size="12">
                    <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                      <li>
                        {/* <Button color="primary" size="lg" onClick={() => submitForm()}> */}
                        <Button color="primary" size="lg" type="submit">
                           {t("update_profile")}
                        </Button>
                      </li>
                      <li>
                        <a
                          href="#dropdownitem"
                          onClick={(ev) => {
                            ev.preventDefault();
                            setModal(false);
                          }}
                          className="link link-light"
                        >
                          {t("cancel")}
                        </a>
                      </li>
                    </ul>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
          </Form>
        )}
      </Formik>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};
export default UserProfileRegularPage;

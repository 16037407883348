import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import axios from "axios";
import UserProfile from "../../Login/UserProfile";
import Head from "../../layout/head/Head";
import { Modal, ModalBody, FormGroup } from "reactstrap";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Row,
  Col,
  Button,
  RSelect,
} from "../../components/Component";
import { countryOptions} from "../../pages/pre-built/user-manage/UserData";

import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

const UserProfileLocationPage = (props) => {
  const [t, i18n] = useTranslation("detailapplap");
  // Necessary to use the Update Profile
  const [ idApplicant, setidApplicant ] = useState(0);

  const [ pais, setpais ] = useState([]);
  const [ departamento, setdepartamento ] = useState([]);
  const [ city, setcity ] = useState([]);

  const [ email, setEmail ] = useState([]);

  const [ estadoDeparment, setestadoDeparment ] = useState("");
  const [ estadoCity, setestadoCity ] = useState([]);

  const [ paisLocation, setpaisLocation ] = useState([]);
  const [ countryRes, setcountryRes ] = useState("");
  const [ departmentRes, setdepartmentRes ] = useState("");
  const [ cityRes, setcityRes ] = useState("");

  const [modalTab, setModalTab] = useState("1");
  const [locationuserInfo, setLocationUserInfo] = useState({});
  const [modal, setModal] = useState(false);


  // const submitForm = () => {
  //   let submitData = {
  //     ...formData,
  //   };
  //   setUserInfo(submitData);
  //   setModal(false);
  // };
  const saveEditApplicant = (val) => {
    val.lng = localStorage.getItem('lngselect');
    val.email = email;
    // console.log("editando aplicante");
    axios
      .put(
        `${process.env.REACT_APP_BASE_URL}/api/applicants/${idApplicant}`,
        val,
        {
          headers: {
            Authorization: `Bearer  ${UserProfile.getToken()}`,
          },
        }
      )
      .then((res) => {
        if (res.data.data.code == "new") {
          registro(val)
          return
        }
        if (!res.data.status) {
          Swal.fire({
            icon: "error",
            title: "Oops..",
            confirmButtonColor: "#071b30",
            confirmButtonText: t("ok"),
          });
          return
        }
        Swal.fire({
          icon: "success",
          title: t("updated_data"),
          confirmButtonColor: "#071b30",
          confirmButtonText: t("ok"),
        });
      })
      .catch((error) => console.log(error));
  };

  const registro = (val) => {
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/api/applicants`,
        val,
        {
          headers: {
            Authorization: `Bearer  ${UserProfile.getToken()}`,
          },
        }
      )
      .then((res) => {
        if (!res.data.status) {
          Swal.fire({
            icon: "error",
            title: t("fail_updated_data"),
            confirmButtonColor: "#071b30",
            confirmButtonText: t("ok"),
          });
          return
        }
        Swal.fire({
          icon: "success",
          title: "success",
          confirmButtonColor: "#071b30",
          confirmButtonText: t("ok"),
        });
       localStorage.setItem('idapplicant', res.data.data.id);
      })
      .catch((error) => console.log(error));
  };

  const getCountry = async () => {
    await axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/attrs/country`, {
        headers: {
          Authorization: `Bearer  ${UserProfile.getToken()}`,
        },
      })
      .then((res) => {
        setpais(res.data.data.country)
      });
  };
  const getDeparment = async (val, opc) => {
    await axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/api/departments/by_country/${val}`,
        {
          headers: {
            Authorization: `Bearer  ${UserProfile.getToken()}`,
          },
        }
      )
      .then((res) => {
        if (opc === "1")
          setdepartamento(res.data.data.department)
          // else setdepartamentoNacionality(res.data.data.department)
      });
  };

  const getCities = async (val) => {
    await axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/api/cities/by_department/${val}`,
        {
          headers: {
            Authorization: `Bearer  ${UserProfile.getToken()}`,
          },
        }
      )
      .then((res) => {
        setcity(res.data.data.city)
      });
  };

  const getApplicantLocation = async () => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/api/applicants/profile`,
        {
          headers: {
            Authorization: `Bearer  ${UserProfile.getToken()}`,
          },
        }
      )
      .then((res) => {
        console.log(res, "res");
        if (res.data.error ? res.data.error[0] == 'El Token a Expirado' : false) {props.history.push('/auth-login'); return}
        setidApplicant(res.data.data.id)
        setEmail(res.data.data.email)
        setcountryRes(res.data.data.country.id)
        getDeparment(res.data.data.country.id,"1")
        setdepartmentRes(res.data.data.department ? res.data.data.department.id : "")
        getCities(res.data.data.department ? res.data.data.department.id : "")
        setcityRes(res.data.data.city ? res.data.data.city.id : "")
        console.log(countryRes, "countryRes");
        setLocationUserInfo({
          country: res.data.data.country ?? '',
          // country_name: res.data.data.country.name ?? '',
          department: res.data.data.department ?? '',
          // department_name: res.data.data.department.name ?? '',
          city: res.data.data.city ?? '',
          // city_name: res.data.data.city.name ?? '',
        });
        console.log(locationuserInfo, "locationuserInfo");

      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    // To know if the user had internet
    if (navigator.onLine) {
      UserProfile.setToken(localStorage.getItem("token"));
      UserProfile.setId(localStorage.getItem("id"));
      getCountry();
      getApplicantLocation();

    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "msg",
        confirmButtonColor: "#071b30",
        confirmButtonText: "ok",
      });
    }
  }, [])


  return (
    <React.Fragment>
      <Head title="Applicant Profile"></Head>
      <BlockHead size="lg">
        <BlockBetween>
          <BlockHeadContent>
            <BlockTitle tag="h4">{t("location_info")}</BlockTitle>
            <BlockDes>
              <p>{t("location_basic_info")}</p>
            </BlockDes>
          </BlockHeadContent>
          {/* <BlockHeadContent className="align-self-start d-lg-none">
            <Button
              className={`toggle btn btn-icon btn-trigger mt-n1 ${sm ? "active" : ""}`}
              onClick={() => updateSm(!sm)}
            >
              <Icon name="menu-alt-r"></Icon>
            </Button>
          </BlockHeadContent> */}
        </BlockBetween>
      </BlockHead>

      <Block>
        <div className="nk-data data-list">
          <div className="data-head">
            <h6 className="overline-title">{t("location_info")}</h6>
          </div>
          {/* country */}
          <div className="data-item" onClick={() => setModal(true)}>
            <div className="data-col">
            <span className="data-label">{t("lbl11")}</span>
              <span className="data-value">{locationuserInfo.country?.name}</span>
            </div>
            <div className="data-col data-col-end">
              <span className="data-more">
                <Icon name="forward-ios"></Icon>
              </span>
            </div>
          </div>
          {/* State */}
          <div className="data-item" onClick={() => setModal(true)}>
            <div className="data-col">
              <span className="data-label">{t("lbl12")}</span>
              <span className="data-value">{locationuserInfo.department?.name}</span>
            </div>
            <div className="data-col data-col-end">
              <span className="data-more">
                <Icon name="forward-ios"></Icon>
              </span>
            </div>
          </div>
          {/* City */}
          <div className="data-item" onClick={() => setModal(true)}>
            <div className="data-col">
              <span className="data-label">{t("lbl13")}</span>
              <span className="data-value">{locationuserInfo.city?.name}</span>
            </div>
            <div className="data-col data-col-end">
              <span className="data-more">
                <Icon name="forward-ios"></Icon>
              </span>
            </div>
          </div>
        {/* End Div Basics   */}
        </div>
      </Block>

      <Modal isOpen={modal} className="modal-dialog-centered" size="lg" toggle={() => setModal(false)}>
        <ModalBody>
          <a
            href="#dropdownitem"
            onClick={(ev) => {
              ev.preventDefault();
              setModal(false);
            }}
            className="close"
          >
            <Icon name="cross-sm"></Icon>
          </a>
          <div className="p-2">
            <h5 className="title">{t("update_profile")}</h5>
            <ul className="nk-nav nav nav-tabs">
              <li className="nav-item">
                <a
                  className={`nav-link ${modalTab === "1" && "active"}`}
                  onClick={(ev) => {
                    ev.preventDefault();
                    setModalTab("1");
                  }}
                  href="#personal"
                >
                  {t("location_info")}
                </a>
              </li>
            </ul>

            <Formik
                            initialValues={{
                              country: locationuserInfo.country?.id ?? '',
                              department: locationuserInfo.department?.id ?? '',
                              city:locationuserInfo.city?.id ?? '',

                            }}
                            validate={(values) => {

                              const errors = {};

                              if (!values.country) {
                                errors.country = t("fr");
                              } else {
                                if (values.country != estadoDeparment) {
                                  getDeparment(values.country, "1");
                                  setestadoDeparment(values.country)
                                }
                              }

                              if (!values.department) {
                                errors.department = t("fr");
                              } else {
                                if (
                                  values.department !=
                                  estadoCity
                                ) {
                                  getCities(values.department);
                                  setestadoCity(values.department)
                                }
                              }
                              return errors;
                            }}

                            onSubmit={(val, { resetForm }) => {
                              // resetForm();
                              // if(languageRes.length > 0 && educationRes.length>0 && experienceRes.length>0){

                                const data ={
                                  country:  val.country,
                                  department: val.department,
                                  city: val.city,
                                }
                                saveEditApplicant(data)
                                setLocationUserInfo(data);
                                setModal(false);
                            }}
                            enableReinitialize
                          >
                          { ({handleSubmit}) => (
                            <Form class="container" onSubmit={handleSubmit}>



            <div className="tab-content">
              <div className={`tab-pane ${modalTab === "1" ? "active" : ""}`} id="personal">
                <Row className="gy-4">
                <Col md="6">
                    <FormGroup>
                    <label for="country" class="form-label">{t("lbl11")} *</label>
                                        <Field
                                          as="select"
                                          id="country"
                                          name="country"
                                          className="form-control"

                                        >
                                          {pais.map((i) => {
                                            return (
                                              <option
                                                className="form-control"
                                                value={i.id}
                                              >
                                                {
                                                i18n.language == 'en' ?
                                                  i.name :
                                                  i18n.language == 'es' ?
                                                  i.name_es :
                                                  i18n.language == 'fr' ?
                                                  i.name_fr : ""
                                              }
                                              </option>
                                            );
                                          })}
                                        </Field>

                                        <ErrorMessage
                                          name="country"
                                          component="div"
                                          className="invalid"
                                        />

                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                    <label for="department" class="form-label">{t("lbl12")} *</label>
                                        <Field
                                          as="select"
                                          id="department"
                                          name="department"
                                          className="form-control"

                                        >

                                          {departamento.map((i) => {
                                            return (
                                              <option
                                                className="form-control"
                                                value={i.id}
                                              >
                                                {
                                                 i18n.language == 'en' ?
                                                  i.name :
                                                  i18n.language == 'es' ?
                                                  i.name_es :
                                                  i18n.language == 'fr' ?
                                                  i.name_fr : ""
                                              }
                                              </option>
                                            );
                                          })}
                                        </Field>

                                        <ErrorMessage
                                          name="department"
                                          component="div"
                                          className="invalid"
                                        />

                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                    <label for="city" class="form-label">{t("lbl13")} *</label>
                                        <Field
                                          as="select"
                                          id="city"
                                          name="city"
                                          className="form-control"
                                        >

                                          {city.map((i) => {
                                            return (
                                              <option
                                                className="form-control"
                                                value={i.id}
                                              >
                                              {
                                                 i18n.language == 'en' ?
                                                  i.name :
                                                  i18n.language == 'es' ?
                                                  i.name_es :
                                                  i18n.language == 'fr' ?
                                                  i.name_fr : ""
                                              }
                                              </option>
                                            );
                                          })}
                                        </Field>

                                        <ErrorMessage
                                          name="city"
                                          component="div"
                                          className="invalid"
                                        />
                    </FormGroup>
                  </Col>
                  <Col size="12">
                    <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                      <li>
                        <Button
                          color="primary"
                          size="lg"
                          type="submit"
                        >
                          {t("update_profile")}
                        </Button>
                      </li>
                      <li>
                        <a
                          href="#dropdownitem"
                          onClick={(ev) => {
                            ev.preventDefault();
                            setModal(false);
                          }}
                          className="link link-light"
                        >
                          Cancel
                        </a>
                      </li>
                    </ul>
                  </Col>
                </Row>
              </div>

            </div>

            </Form>
              )}
            </Formik>

          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};
export default UserProfileLocationPage;

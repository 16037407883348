import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import Head from "../../layout/head/Head";
import axios from "axios";
import UserProfile from "../../Login/UserProfile";
import RegisterXpCompany from "./RegisterXpCompany";
import RegisterPosition from "./RegisterPosition";
// import UserListCompact from "./UserListCompact";
import {
    DropdownMenu,
    DropdownToggle,
    FormGroup,
    UncontrolledDropdown,
    Modal,
    ModalBody,
    DropdownItem,
  } from "reactstrap";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  InputSwitch,
  Button,
  Row,
  Col,
  UserAvatar,
  PaginationComponent,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableRow,
  DataTableItem,
  RSelect,
  TooltipComponent,
} from "../../components/Component";
import { Link } from "react-router-dom";
// import { useForm } from "react-hook-form";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { UserContext } from "../../pages/pre-built/user-manage/UserContext";

const UserProfileExperiencePage = (props) => {

    const [t, i18n] = useTranslation("detailapplap")
    const [ experienceRes, setexperienceRes ] = useState([]);
    const [ idApplicant, setidApplicant ] = useState(0);
    // const { contextData } = useContext(UserContext);
    const [data, setData] = useState("");
    // const { errors, register, handleSubmit } = useForm();
    const [modalTab, setModalTab] = useState("1");

    const [sm, updateSm] = useState(false);
    const [tablesm, updateTableSm] = useState(false);
    const [onSearch, setonSearch] = useState(true);
    const [onSearchText, setSearchText] = useState("");
    const [modal, setModal] = useState({
      edit: false,
      add: false,
    });
    const [editId, setEditedId] = useState();
    const [formData, setFormData] = useState({
      name: "",
      email: "",
      balance: "",
      phone: "",
      status: "Active",
    });
    const [currentPage, setCurrentPage] = useState(1);
    const [itemPerPage, setItemPerPage] = useState(10);
    const [sort, setSortState] = useState("");



    const [ pais, setpais ] = useState([]);
    const [ paisLocation, setpaisLocation ] = useState([]);
    const [ departamento, setdepartamento ] = useState([]);
    const [ city, setcity ] = useState([]);
    const [ email, setEmail ] = useState([]);

    const [ departamentoNacionality, setdepartamentoNacionality ] = useState([]);

    const [ unidad, setunidad ] = useState([]);
    const [ positions, setpositions ] = useState([]);

    const [ companyWork, setcompanyWork ] = useState([]);

    const [ actividades, setactividades ] = useState([]);
    const [ optionSelected, setoptionSelected ] = useState(null);

    // Sorting data
  const sortFunc = (params) => {
    let defaultData = data;
    if (params === "asc") {
      let sortedData = defaultData.sort((a, b) => a.name.localeCompare(b.name));
      setData([...sortedData]);
    } else if (params === "dsc") {
      let sortedData = defaultData.sort((a, b) => b.name.localeCompare(a.name));
      setData([...sortedData]);
    }
  };

  // Changing state value when searching name
  useEffect(() => {
    if (onSearchText !== "") {
      // console.log(data, "data");
      const filteredObject = experienceRes.filter((item) => {
        return (
          item.name.toLowerCase().includes(onSearchText.toLowerCase()) ||
          item.email.toLowerCase().includes(onSearchText.toLowerCase())
        );
      });
      // console.log(filteredObject, "filteredObject");
      setData([...filteredObject]);
    } else {
      setData([...data]);
    }
  }, [onSearchText, setData]);

  // onChange function for searching name
  const onFilterChange = (e) => {
    setSearchText(e.target.value);
  };

    // function to reset the form
    const resetForm = () => {
      setFormData({
        name: "",
        email: "",
        type:1,
        password: "",
        status: 1,
      });
    };

    // function to close the form modal
    const onFormCancel = () => {
      setModal({ edit: false, add: false });
      resetForm();
    };

      // function to toggle the search option
  const toggle = () => setonSearch(!onSearch);

    // Get current list, pagination
  const indexOfLastItem = currentPage * itemPerPage;
  const indexOfFirstItem = indexOfLastItem - itemPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  // Change Page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const addNewXpCompany = (data) => {
    setcompanyWork([...companyWork, data])
  }

  const addNewPosition = (data) => {
    setpositions([...positions, data])
  }

  const addNewSkill = (data) => {
    setactividades([...actividades, data])
    // renderList()
    document.getElementById("clocedModal").click()
  }

  const getCompanyWork = async () => {
    await axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/attrs/company_experience`, {
        headers: {
          Authorization: `Bearer  ${UserProfile.getToken()}`,
        },
      })
      .then((res) => {
        setcompanyWork(res.data.data.company_experience)
      });
  };

  const getPositions = async () => {
    await axios
      // .get(`${process.env.REACT_APP_BASE_URL}/api/attrs/positions`, {
      .post(`${process.env.REACT_APP_BASE_URL}/api/attrs/filterpositions/2`, [], {
        headers: {
          Authorization: `Bearer  ${UserProfile.getToken()}`,
        },
      })
      .then((res) => {
        console.log(res)
        setpositions(res.data.data)
      });
  };

  const getInitMeasure = async () => {
    await axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/attrs/unit_measurement`, {
        headers: {
          Authorization: `Bearer  ${UserProfile.getToken()}`,
        },
      })
      .then((res) => {
        setunidad(res.data.data.unit_measurement)
      });
  };

  const addExperience = (val) => {
    console.log(val, "val")
    var comp = companyWork.find( item => item.id == val.company )
    var posi = positions.find( item => item.id == val.position )
    var uni = unidad.find( item => item.id == val.unit_measurement )
    const temp = {
      "functions": val.functions,
      "goals": val.goals,
      "initial_year": val.initial_year,
      "company": comp,
      "position": posi,
      "duration_time": val.duration_time,
      "unit_measurement": uni
    }
    console.log(temp, "temp")
    setexperienceRes(experienceRes => [...experienceRes, temp])
    console.log(experienceRes, "experienceRes");
    val.lng = localStorage.getItem('lngselect');
    val.email = email;
    val.experiences = experienceRes;

    // console.log("editando aplicante");
    axios
      .put(
        `${process.env.REACT_APP_BASE_URL}/api/applicants/${idApplicant}`,
        val,
        {
          headers: {
            Authorization: `Bearer  ${UserProfile.getToken()}`,
          },
        }
      )
      .then((res) => {
        if (res.data.data.code == "new") {
          registro(val)
          return
        }
        if (!res.data.status) {
          Swal.fire({
            icon: "error",
            title: "Oops..",
            confirmButtonColor: "#071b30",
            confirmButtonText: t("ok"),
          });
          return
        }
        Swal.fire({
          icon: "success",
          title: t("updated_data"),
          confirmButtonColor: "#071b30",
          confirmButtonText: t("ok"),
        });
      })
      .catch((error) => console.log(error));
  };

  const deleteExperience = (val) => {
    setexperienceRes(experienceRes.filter((xp, itm) => itm !== val))
  };

  // const saveEditApplicant = (val) => {
  //   val.lng = localStorage.getItem('lngselect');

  //   // console.log("editando aplicante");
  //   axios
  //     .put(
  //       `${process.env.REACT_APP_BASE_URL}/api/applicants/${idApplicant}`,
  //       val,
  //       {
  //         headers: {
  //           Authorization: `Bearer  ${UserProfile.getToken()}`,
  //         },
  //       }
  //     )
  //     .then((res) => {
  //       if (res.data.data.code == "new") {
  //         registro(val)
  //         return
  //       }
  //       if (!res.data.status) {
  //         Swal.fire({
  //           icon: "error",
  //           title: "Oops..",
  //           confirmButtonColor: "#071b30",
  //           confirmButtonText: t("ok"),
  //         });
  //         return
  //       }
  //       Swal.fire({
  //         icon: "success",
  //         title: t("updated_data"),
  //         confirmButtonColor: "#071b30",
  //         confirmButtonText: t("ok"),
  //       });
  //     })
  //     .catch((error) => console.log(error));
  // };

  const registro = (val) => {
    // console.log("tregistrando aplicante");
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/api/applicants`,
        val,
        {
          headers: {
            Authorization: `Bearer  ${UserProfile.getToken()}`,
          },
        }
      )
      .then((res) => {
        if (!res.data.status) {
          Swal.fire({
            icon: "error",
            title: t("fail_updated_data"),
            confirmButtonColor: "#071b30",
            confirmButtonText: t("ok"),
          });
          return
        }
        Swal.fire({
          icon: "success",
          title: "success",
          confirmButtonColor: "#071b30",
          confirmButtonText: t("ok"),
        });
       localStorage.setItem('idapplicant', res.data.data.id);
      })
      .catch((error) => console.log(error));
  };

  // const closeExperience = () => {
  //   document.getElementById("clocedModalExperience").click();
  // };


const getApplicant = async () => {
    var name = "";
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/api/applicants/profile`,
        {
          headers: {
            Authorization: `Bearer  ${UserProfile.getToken()}`,
          },
        }
      )
      .then((res) => {
        if (res.data.error ? res.data.error[0] == 'El Token a Expirado' : false) {props.history.push('/login'); return}
        setidApplicant(res.data.data.id)
        // setfirst_nameRes(res.data.data.first_name)
        // setmiddle_nameRes(res.data.data.second_name)
        // setsecond_lasnameRes(res.data.data.second_lastname)
        // setfirst_lastnameRes(res.data.data.first_lastname)
        // settitleRes(res.data.data.title)
        // setdescriptionRes(res.data.data.description)
        // setidentification_typeRes(res.data.data.identification_type ? res.data.data.identification_type.id : "")
        // setidentificationRes(res.data.data.identification)
        // setimg_perfilRes(res.data.data.img_perfil)

        // setcountryRes(res.data.data.country.id)
        // getDeparment(res.data.data.country.id,"1")

        // setdepartmentRes(res.data.data.department ? res.data.data.department.id : "")
        // getCities(res.data.data.department ? res.data.data.department.id : "")
        // setcityRes(res.data.data.city ? res.data.data.city.id : "")

        // setcountry_nacionalityRes(res.data.data.country_nacionality.id)
        // getDeparment(res.data.data.country_nacionality.id,"2")
        // setdepartment_nacionalityRes(res.data.data.department_nacionality ? res.data.data.department_nacionality.id : "")

        setEmail(res.data.data.email)
        // setcivil_statusRes(res.data.data.civil_status.id)

        // //falta img
        // setvideo_presentationRes(res.data.data.video_presentation)
        // setstatusRes(res.data.data.status.id)

        // setbirthdayRes(res.data.data.birthday)

        // multiples
        // setlanguageRes(res.data.data.languages_applicant)
        // seteducationRes(res.data.data.studies)
        setexperienceRes(res.data.data.experiences)
        setData(res.data.data.experiences)
        console.log(data, "data");
        // setsocialNetworkRes(res.data.data.applicant_social_networks)

        // setactividadesres(res.data.data.applicant_activities)
        let opactsel = []
        for (const acti of res.data.data.applicant_activities) {
          opactsel.push({"value":acti.activity.id, "label":acti.activity.name})
        }
        setoptionSelected(opactsel)

      })
      .catch((error) => console.log(error));
  };



  const getDeparment = async (val, opc) => {
    await axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/api/departments/by_country/${val}`,
        {
          headers: {
            Authorization: `Bearer  ${UserProfile.getToken()}`,
          },
        }
      )
      .then((res) => {
        if (opc === "1")
          setdepartamento(res.data.data.department)
          else setdepartamentoNacionality(res.data.data.department)
      });
  };



  const getCities = async (val) => {
    await axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/api/cities/by_department/${val}`,
        {
          headers: {
            Authorization: `Bearer  ${UserProfile.getToken()}`,
          },
        }
      )
      .then((res) => {
        setcity(res.data.data.city)
      });
  };


  const getCountry = async () => {
    await axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/attrs/country`, {
        headers: {
          Authorization: `Bearer  ${UserProfile.getToken()}`,
        },
      })
      .then((res) => {
        setpais(res.data.data.country)
        setpaisLocation(res.data.data.country)
      });
  };

  const getCargo = async () => {
    await axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/attrs/positions`, {
        headers: {
          Authorization: `Bearer  ${UserProfile.getToken()}`,
        },
      })
      .then((res) => {
        setpositions(res.data.data.positions)
      });
  };

  // const getCompanyWork = async () => {
  //   await axios
  //     .get(`${process.env.REACT_APP_BASE_URL}/api/attrs/company_experience`, {
  //       headers: {
  //         Authorization: `Bearer  ${UserProfile.getToken()}`,
  //       },
  //     })
  //     .then((res) => {
  //       setcompanyWork(res.data.data.company_experience)
  //     });
  // };

  // function that loads the want to editted data
  const onEditClick = (id) => {
    console.log('clic')

    setModal({ edit: true }, { add: false });
    setEditedId(id);


    // companyWork.forEach((item) => {
    //   if (item.id === id) {
    //     console.log('si')
    //     setFormData({
    //       name: item.name,
    //       email: item.email,
    //       status: item.status,
    //       type: item.type,
    //       password: item.password,
    //     });
    //     setModal({ edit: true }, { add: false });
    //     setEditedId(id);
    //   }
    // });

  };

  useEffect(() => {
    if (navigator.onLine) {
      UserProfile.setToken(localStorage.getItem("token"));
      UserProfile.setId(localStorage.getItem("id"));
      // setData(props.experienceRes);
      // setexperienceRes(props.experienceRes);
      // console.log(experienceRes,'data');
      getCountry();
      // getIdentificationType();
      // getCivilStatus();
      // getLevelStudy();
      // getSocialNetwork();
      // getLanguage();
      // getLevelLanguage();
      // getEducationInstitution();
      // getActividad();
      getPositions();
      getCompanyWork();
      getInitMeasure();
      getCargo();

      /***************** */
      getApplicant();

    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "msg",
        confirmButtonColor: "#071b30",
        confirmButtonText: "ok",
      });
    }
  }, [])

  return (
    <React.Fragment>
      <Head title="Experience List - Profile"></Head>

      <BlockHead size="lg">
        <BlockBetween>
          <BlockHeadContent>
            {/* Work Experience */}
            <BlockTitle tag="h4">{t("work_experience_title")}</BlockTitle>
            <BlockDes>
              <p>{t("work_experience_basic_info")}</p>
            </BlockDes>
          </BlockHeadContent>
          <BlockHeadContent>
            {/* <Button
              className={`toggle btn btn-icon btn-trigger mt-n1 ${sm ? "active" : ""}`}
              onClick={() => updateSm(!sm)}
            >
              <Icon name="menu-alt-r"></Icon>
            </Button> */}

            <div className="toggle-wrap nk-block-tools-toggle">
                <Button
                  className={`btn-icon btn-trigger toggle-expand mr-n1 ${sm ? "active" : ""}`}
                  onClick={() => updateSm(!sm)}
                >
                  <Icon name="menu-alt-r"></Icon>
                </Button>
                <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                  <ul className="nk-block-tools g-3">
                    <li className="nk-block-tools-opt">
                      <Button color="primary" className="btn-icon" onClick={() => setModal({ add: true })}>
                        <Icon name="plus"></Icon> {t("ttl3")}
                      </Button>
                    </li>
                  </ul>
                </div>
              </div>
          </BlockHeadContent>
        </BlockBetween>
      </BlockHead>

      <Block>
      {/* <UserListCompact /> */}

      <DataTable className="card-stretch">
            <div className="card-inner position-relative card-tools-toggle">
              <div className="card-title-group">
              <div className="card-title">
                  <h5 className="title">{t("your_work_experience")}</h5>
                </div>
              <div className="card-tools">
                  <div className="form-inline flex-nowrap gx-3">
                    {/* <div className="form-wrap">
                      <RSelect
                        options={bulkActionOptions}
                        className="w-130px"
                        placeholder="Bulk Action"
                        onChange={(e) => onActionText(e)}
                      />
                    </div>
                    <div className="btn-wrap">
                      <span className="d-none d-md-block">
                        <Button
                          disabled={actionText !== "" ? false : true}
                          color="light"
                          outline
                          className="btn-dim"
                          onClick={(e) => onActionClick(e)}
                        >
                          Apply
                        </Button>
                      </span>
                      <span className="d-md-none">
                        <Button
                          color="light"
                          outline
                          disabled={actionText !== "" ? false : true}
                          className="btn-dim  btn-icon"
                          onClick={(e) => onActionClick(e)}
                        >
                          <Icon name="arrow-right"></Icon>
                        </Button>
                      </span>
                    </div> */}
                  </div>
                </div>
                <div className="card-tools mr-n1">
                  <ul className="btn-toolbar gx-1">
                    <li>
                      <a
                        href="#search"
                        onClick={(ev) => {
                          ev.preventDefault();
                          toggle();
                        }}
                        className="btn btn-icon search-toggle toggle-search"
                      >
                        <Icon name="search"></Icon>
                      </a>
                    </li>
                    <li className="btn-toolbar-sep"></li>
                    <li>
                      <div className="toggle-wrap">
                        <Button
                          className={`btn-icon btn-trigger toggle ${tablesm ? "active" : ""}`}
                          onClick={() => updateTableSm(true)}
                        >
                          <Icon name="menu-right"></Icon>
                        </Button>
                        <div className={`toggle-content ${tablesm ? "content-active" : ""}`}>
                          <ul className="btn-toolbar gx-1">
                            <li className="toggle-close">
                              <Button className="btn-icon btn-trigger toggle" onClick={() => updateTableSm(false)}>
                                <Icon name="arrow-left"></Icon>
                              </Button>
                            </li>
                            <li>
                              <UncontrolledDropdown>
                                <DropdownToggle tag="a" className="btn btn-trigger btn-icon dropdown-toggle">
                                  <div className="dot dot-primary"></div>
                                  <Icon name="filter-alt"></Icon>
                                </DropdownToggle>
                                <DropdownMenu
                                  right
                                  className="filter-wg dropdown-menu-xl"
                                  style={{ overflow: "visible" }}
                                >
                                  <div className="dropdown-head">
                                    <span className="sub-title dropdown-title">Filter Users</span>
                                    <div className="dropdown">
                                      <DropdownItem
                                        href="#more"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                        }}
                                        className="btn btn-sm btn-icon"
                                      >
                                        <Icon name="more-h"></Icon>
                                      </DropdownItem>
                                    </div>
                                  </div>
                                  <div className="dropdown-body dropdown-body-rg">
                                    <Row className="gx-6 gy-3">
                                      {/* <Col size="6">
                                        <div className="custom-control custom-control-sm custom-checkbox">
                                          <input
                                            type="checkbox"
                                            className="custom-control-input form-control"
                                            id="hasBalance"
                                          />
                                          <label className="custom-control-label" htmlFor="hasBalance">
                                            {" "}
                                            Have Balance
                                          </label>
                                        </div>
                                      </Col> */}
                                      {/* <Col size="6">
                                        <div className="custom-control custom-control-sm custom-checkbox">
                                          <input
                                            type="checkbox"
                                            className="custom-control-input form-control"
                                            id="hasKYC"
                                          />
                                          <label className="custom-control-label" htmlFor="hasKYC">
                                            {" "}
                                            KYC Verified
                                          </label>
                                        </div>
                                      </Col> */}
                                      {/* <Col size="6">
                                        <FormGroup>
                                          <label className="overline-title overline-title-alt">Role</label>
                                          <RSelect options={filterRole} placeholder="Any Role" />
                                        </FormGroup>
                                      </Col>
                                      <Col size="6">
                                        <FormGroup>
                                          <label className="overline-title overline-title-alt">Status</label>
                                          <RSelect options={filterStatus} placeholder="Any Status" />
                                        </FormGroup>
                                      </Col> */}
                                      <Col size="12">
                                        <FormGroup className="form-group">
                                          <Button color="secondary">Filter</Button>
                                        </FormGroup>
                                      </Col>
                                    </Row>
                                  </div>
                                  <div className="dropdown-foot between">
                                    <a
                                      href="#reset"
                                      onClick={(ev) => {
                                        ev.preventDefault();
                                      }}
                                      className="clickable"
                                    >
                                      Reset Filter
                                    </a>
                                    <a
                                      href="#save"
                                      onClick={(ev) => {
                                        ev.preventDefault();
                                      }}
                                    >
                                      Save Filter
                                    </a>
                                  </div>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </li>
                            <li>
                              <UncontrolledDropdown>
                                <DropdownToggle tag="a" className="btn btn-trigger btn-icon dropdown-toggle">
                                  <Icon name="setting"></Icon>
                                </DropdownToggle>
                                <DropdownMenu right className="dropdown-menu-xs">
                                  <ul className="link-check">
                                    <li>
                                      <span>Show</span>
                                    </li>
                                    <li className={itemPerPage === 10 ? "active" : ""}>
                                      <DropdownItem
                                        tag="a"
                                        href="#dropdownitem"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                          setItemPerPage(10);
                                        }}
                                      >
                                        10
                                      </DropdownItem>
                                    </li>
                                    <li className={itemPerPage === 15 ? "active" : ""}>
                                      <DropdownItem
                                        tag="a"
                                        href="#dropdownitem"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                          setItemPerPage(15);
                                        }}
                                      >
                                        15
                                      </DropdownItem>
                                    </li>
                                  </ul>
                                  <ul className="link-check">
                                    <li>
                                      <span>Order</span>
                                    </li>
                                    <li className={sort === "dsc" ? "active" : ""}>
                                      <DropdownItem
                                        tag="a"
                                        href="#dropdownitem"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                          setSortState("dsc");
                                          sortFunc("dsc");
                                        }}
                                      >
                                        DESC
                                      </DropdownItem>
                                    </li>
                                    <li className={sort === "asc" ? "active" : ""}>
                                      <DropdownItem
                                        tag="a"
                                        href="#dropdownitem"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                          setSortState("asc");
                                          sortFunc("asc");
                                        }}
                                      >
                                        ASC
                                      </DropdownItem>
                                    </li>
                                  </ul>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className={`card-search search-wrap ${!onSearch && "active"}`}>
                <div className="card-body">
                  <div className="search-content">
                    <Button
                      className="search-back btn-icon toggle-search active"
                      onClick={() => {
                        setSearchText("");
                        toggle();
                      }}
                    >
                      <Icon name="arrow-left"></Icon>
                    </Button>
                    <input
                      type="text"
                      className="border-transparent form-focus-none form-control"
                      placeholder="Search by user or email"
                      value={onSearchText}
                      onChange={(e) => onFilterChange(e)}
                    />
                    <Button className="search-submit btn-icon">
                      <Icon name="search"></Icon>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <DataTableBody compact>
              <DataTableHead>
                <DataTableRow>
                  <span className="sub-text">Position</span>
                </DataTableRow>
                <DataTableRow size="md">
                  <span className="sub-text">{t("lbl41")}</span>
                </DataTableRow>
                <DataTableRow size="sm">
                  <span className="sub-text">{t("unit_measure")}</span>
                </DataTableRow>
                <DataTableRow size="md">
                  <span className="sub-text">{t("company")}</span>
                </DataTableRow>
                <DataTableRow size="md">
                  <span className="sub-text">{t("lbl47")}</span>
                </DataTableRow>
                {/* <DataTableRow size="md">
                  <span className="sub-text">Functions</span>
                </DataTableRow>
                <DataTableRow size="md">
                  <span className="sub-text">Goal</span>
                </DataTableRow> */}
                <DataTableRow size="md">
                  <span className="sub-text">{t("options")}</span>
                </DataTableRow>
              </DataTableHead>
              {/*Head*/}
              {currentItems.length > 0
                ? currentItems.map((item) => {
                    return (
                      <DataTableItem key={item.id}>
                       <DataTableRow>
                       { i18n.language == 'en' ? item.position?.name
                  : i18n.language == 'es' ? item.position?.name_es : item.position?.name_fr }
                        </DataTableRow>
                        <DataTableRow>
                        {item.duration_time}
                        </DataTableRow>
                        <DataTableRow>
                        { i18n.language == 'en' ? item.unit_measurement?.name
                  : i18n.language == 'es' ? item.unit_measurement?.name_es : item.unit_measurement?.name_fr }
                        </DataTableRow>
                        <DataTableRow>
                        {item.company.name}
                        </DataTableRow>
                        <DataTableRow>
                        {item.initial_year}
                        </DataTableRow>
                        {/* <DataTableRow>
                        {item.functions}
                        </DataTableRow>
                        <DataTableRow>
                        {item.goal}
                        </DataTableRow> */}
                        <DataTableRow className="nk-tb-col-tools">
                          <ul className="nk-tb-actions gx-1">
                            <li className="nk-tb-action-hidden" onClick={() => onEditClick(item.id)}>
                              <TooltipComponent
                                tag="a"
                                containerClassName="btn btn-trigger btn-icon"
                                id={"edit" + item.id}
                                icon="edit-alt-fill"
                                direction="top"
                                text="Editar"
                              />
                            </li>
                            <li>
                              <UncontrolledDropdown>
                                <DropdownToggle tag="a" className="dropdown-toggle btn btn-icon btn-trigger">
                                  <Icon name="more-h"></Icon>
                                </DropdownToggle>
                                <DropdownMenu right>
                                  <ul className="link-list-opt no-bdr">
                                    <li onClick={() => onEditClick(item.id)}>
                                      <DropdownItem
                                        tag="a"
                                        href="#edit"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                        }}
                                      >
                                        <Icon name="edit"></Icon>
                                        <span>{t("edit")}</span>
                                      </DropdownItem>
                                    </li>
                                  </ul>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </li>
                          </ul>
                        </DataTableRow>
                      </DataTableItem>
                    );
                  })
                : null}
            </DataTableBody>
            <div className="card-inner">
              {currentItems.length > 0 ? (
                <PaginationComponent
                  itemPerPage={itemPerPage}
                  totalItems={data.length}
                  paginate={paginate}
                  currentPage={currentPage}
                />
              ) : (
                <div className="text-center">
                  <span className="text-silent">No data found</span>
                </div>
              )}
            </div>
          </DataTable>
      </Block>

      {/* Modal to Add Experience */}

      <Modal isOpen={modal.add} toggle={() => setModal({ add: false })} className="modal-dialog-centered" size="lg">
          <ModalBody>
            <a
              href="#cancel"
              onClick={(ev) => {
                ev.preventDefault();
                onFormCancel();
              }}
              className="close"
            >
              <Icon name="cross-sm"></Icon>
            </a>
            <div className="p-2">
              <h5 className="title"> {t("lbl44")}</h5>
            <ul className="nk-nav nav nav-tabs">
              <li className="nav-item">
                <a
                  className={`nav-link ${modalTab === "1" && "active"}`}
                  onClick={(ev) => {
                    ev.preventDefault();
                    setModalTab("1");
                  }}
                  href="#actualxpcomp"
                >
                  {t("ttl8")}
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={`nav-link ${modalTab === "2" && "active"}`}
                  onClick={(ev) => {
                    ev.preventDefault();
                    setModalTab("2");
                  }}
                  href="#newxpComp"
                >
                {t("ttl9")}
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={`nav-link ${modalTab === "3" && "active"}`}
                  onClick={(ev) => {
                    ev.preventDefault();
                    setModalTab("3");
                  }}
                  href="#newPosition"
                >
                {t("ttl10")}
                </a>
              </li>
            </ul>

              <div className="tab-content">
              <div className={`tab-pane ${modalTab === "1" ? "active" : ""}`} id="actualxpcomp">

              <Formik
                    initialValues={{
                      position: "",
                      company: "",
                      duration_time: "",
                      unit_measurement: "",
                      initial_year: "",
                      functions: "",
                      goals: ""
                    }}

                    validationSchema={Yup.object().shape({
                      position:
                        Yup.string().required(t("fr")),
                      company:
                        Yup.string().required(t("fr")),

                      duration_time: Yup.number()
                        .typeError(t("onum"))
                        .required(t("fr")),

                      unit_measurement: Yup.string().required(t("fr")),

                      initial_year: Yup.string().required(t("fr")),
                      functions: Yup.string().required(t("fr")),
                      goals: Yup.string().required(t("fr")),

                    })}

                    validate = {(values) => {
                      const errors = {};
                      if (values.duration_time < 1) errors.duration_time = t("fr");
                      return errors;
                    }}

                    onSubmit={(val, { resetForm }) => {
                      // const data ={
                      //   country:  val.country,
                      //   department: val.department,
                      //   city: val.city,
                      // }

                      addExperience(val);
                      // saveEditApplicant();
                      resetForm();
                      onFormCancel();
                    }}
                    render={({ errors, status, touched }) => (

                      <Form className="row gy-4" >

                  <Col md="6">
                    <FormGroup>
                    <label className="form-label">{t("lbl45")}</label>
                    <Field
                                as="select"
                                id="position"
                                name="position"
                                className="form-control"

                              >
                                <option
                                  className="form-control"
                                  value=""
                                  selected
                                >
                                  {t("lbl45")}
                                </option>

                                {positions.map((i) => {
                                  return (
                                    <option
                                      className="form-control"
                                      value={i.id}
                                    >
                                    {
                                      i18n.language == 'en' ?
                                        i.name :
                                      i18n.language == 'es' ?
                                        i.name_es :
                                      i18n.language == 'fr' ?
                                        i.name_fr : ""
                                    }
                                    </option>
                                  );
                                })}
                              </Field>

                              <ErrorMessage
                                name="position"
                                component="div"
                                className="invalid"
                              />

                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <label className="form-label">{t("lbl46")}</label>
                       <Field
                                as="select"
                                id="company"
                                name="company"
                                className="form-control"

                              >

                                {companyWork.map((i) => {
                                  return (
                                    <option
                                      className="form-control"
                                      value={i.id}
                                    >
                                      {i.name}
                                    </option>
                                  );
                                })}
                              </Field>

                              <ErrorMessage
                                name="company"
                                component="div"
                                className="invalid"
                              />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <label className="form-label">{t("lbl41")} </label>
                      <Field
                                id="duration_time"
                                name="duration_time"
                                type="number"
                                class="form-control"
                                placeholder={t("lbl41")}
                              />

                              <ErrorMessage
                                name="duration_time"
                                component="div"
                                className="invalid"
                              />
                    </FormGroup>
                  </Col>
                  <Col size="6">
                    <FormGroup>
                      <label className="form-label">{t("lbl42")}</label>
                      <Field
                                as="select"
                                id="unit_measurement"
                                name="unit_measurement"
                                className="form-control"

                              >
                                <option
                                  className="form-control"
                                  value=""
                                  selected
                                >
                                  {t("lbl42")}
                                </option>

                                {unidad.map((i) => {
                                  return (
                                    <option
                                      className="form-control"
                                      value={i.id}
                                    >
                                    {
                                      i18n.language == 'en' ?
                                        i.name :
                                      i18n.language == 'es' ?
                                        i.name_es :
                                      i18n.language == 'fr' ?
                                        i.name_fr : ""
                                    }
                                    </option>
                                  );
                                })}
                              </Field>

                              <ErrorMessage
                                name="unit_measurement"
                                component="div"
                                className="invalid"
                              />
                    </FormGroup>
                  </Col>

                  <Col md="6">
                    <FormGroup>
                      <label className="form-label"> {" "}
                                {t("lbl47")} &nbsp;</label>
                      <Field
                                id="initial_year"
                                name="initial_year"
                                type="date"
                                class="form-control"
                                placeholder={t("lbl47")}
                              />

                              <ErrorMessage
                                name="initial_year"
                                component="div"
                                className="invalid"
                              />
                    </FormGroup>
                  </Col>
                  <Col md="12">
                    <FormGroup>
                      <label className="form-label">{t("lbl48")}</label>
                      <Field
                                id="functions"
                                name="functions"
                                type="textarea"
                                class="form-control"
                                component="textarea"
                                aria-describedby="emailHelp"
                                placeholder={t("lbl48")}
                                rows="3"
                              />

                              <ErrorMessage
                                name="functions"
                                component="div"
                                className="invalid"
                              />
                    </FormGroup>
                  </Col>
                  <Col md="12">
                  <FormGroup>
                    <label className="form-label">{t("lbl49")}</label>
                    <Field
                                id="goals"
                                name="goals"
                                type="textarea"
                                class="form-control"
                                component="textarea"
                                aria-describedby="emailHelp"
                                placeholder={t("lbl49")}
                                rows="3"
                              />

                              <ErrorMessage
                                name="goals"
                                component="div"
                                className="msgerror mx-2"
                              />
                  </FormGroup>
                </Col>
                   <Col size="12">
                    <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                      <li>
                        <Button color="primary" size="md" type="submit">
                        {t("lbl33")}
                        </Button>
                      </li>
                      <li>
                        <a
                          href="#cancel"
                          onClick={(ev) => {
                            ev.preventDefault();
                            onFormCancel();
                          }}
                          className="link link-light"
                        >
                          {t("cancel")}
                        </a>
                      </li>
                    </ul>
                  </Col>

                </Form>
                  )}
                  />
              </div>

              <div className={`tab-pane ${modalTab === "2" ? "active" : ""}`} id="newxpComp">
              <RegisterXpCompany addNewXpCompany={addNewXpCompany}/>

              </div>
              <div className={`tab-pane ${modalTab === "3" ? "active" : ""}`} id="newPosition">
              <RegisterPosition addNewPosition={addNewPosition}/>
              </div>
            </div>
            </div>
          </ModalBody>
        </Modal>

       {/* Modal to Update Experience */}

       <Modal isOpen={modal.edit} toggle={() => setModal({ edit: false })} className="modal-dialog-centered" size="lg">
          <ModalBody>
            <a
              href="#cancel"
              onClick={(ev) => {
                ev.preventDefault();
                onFormCancel();
              }}
              className="close"
            >
              <Icon name="cross-sm"></Icon>
            </a>
            <div className="p-2">
              <h5 className="title"> {t("lbl44")}</h5>
              <div className="mt-4">

              <Formik
                    initialValues={{
                      position: "",
                      company: "",
                      duration_time: "",
                      unit_measurement: "",
                      initial_year: "",
                      functions: "",
                      goals: ""
                    }}

                    validationSchema={Yup.object().shape({
                      position:
                        Yup.string().required(t("fr")),
                      company:
                        Yup.string().required(t("fr")),

                      duration_time: Yup.number()
                        .typeError(t("onum"))
                        .required(t("fr")),

                      unit_measurement: Yup.string().required(t("fr")),

                      initial_year: Yup.string().required(t("fr")),
                      functions: Yup.string().required(t("fr")),
                      goals: Yup.string().required(t("fr")),

                    })}

                    validate = {(values) => {
                      const errors = {};
                      if (values.duration_time < 1) errors.duration_time = t("fr");
                      return errors;
                    }}

                    onSubmit={(val, { resetForm }) => {
                      addExperience(val);
                      resetForm();
                      onFormCancel();
                    }}
                    render={({ errors, status, touched }) => (

                      <Form className="row gy-4" >

                  <Col md="6">
                    <FormGroup>
                    <label className="form-label">{t("lbl45")}</label>
                    <Field
                                as="select"
                                id="position"
                                name="position"
                                className="form-control"

                              >
                                <option
                                  className="form-control"
                                  value=""
                                  selected
                                >
                                  {t("lbl45")}
                                </option>

                                {positions.map((i) => {
                                  return (
                                    <option
                                      className="form-control"
                                      value={i.id}
                                    >
                                    {
                                      i18n.language == 'en' ?
                                        i.name :
                                      i18n.language == 'es' ?
                                        i.name_es :
                                      i18n.language == 'fr' ?
                                        i.name_fr : ""
                                    }
                                    </option>
                                  );
                                })}
                              </Field>

                              <ErrorMessage
                                name="position"
                                component="div"
                                className="invalid"
                              />

                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <label className="form-label">{t("lbl41")} </label>
                      <Field
                                id="duration_time"
                                name="duration_time"
                                type="number"
                                class="form-control"
                                placeholder={t("lbl41")}
                              />

                              <ErrorMessage
                                name="duration_time"
                                component="div"
                                className="invalid"
                              />
                    </FormGroup>
                  </Col>
                  <Col size="6">
                    <FormGroup>
                      <label className="form-label">{t("lbl42")}</label>
                      <Field
                                as="select"
                                id="unit_measurement"
                                name="unit_measurement"
                                className="form-control"

                              >
                                <option
                                  className="form-control"
                                  value=""
                                  selected
                                >
                                  {t("lbl42")}
                                </option>

                                {unidad.map((i) => {
                                  return (
                                    <option
                                      className="form-control"
                                      value={i.id}
                                    >
                                    {
                                      i18n.language == 'en' ?
                                        i.name :
                                      i18n.language == 'es' ?
                                        i.name_es :
                                      i18n.language == 'fr' ?
                                        i.name_fr : ""
                                    }
                                    </option>
                                  );
                                })}
                              </Field>

                              <ErrorMessage
                                name="unit_measurement"
                                component="div"
                                className="invalid"
                              />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <label className="form-label">{t("lbl46")}</label>
                       <Field
                                as="select"
                                id="company"
                                name="company"
                                className="form-control"

                              >

                                {companyWork.map((i) => {
                                  return (
                                    <option
                                      className="form-control"
                                      value={i.id}
                                    >
                                      {i.name}
                                    </option>
                                  );
                                })}
                              </Field>

                              <ErrorMessage
                                name="company"
                                component="div"
                                className="invalid"
                              />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <label className="form-label"> {" "}
                                {t("lbl47")} &nbsp;</label>
                      <Field
                                id="initial_year"
                                name="initial_year"
                                type="date"
                                class="form-control"
                                placeholder={t("lbl47")}
                              />

                              <ErrorMessage
                                name="initial_year"
                                component="div"
                                className="invalid"
                              />
                    </FormGroup>
                  </Col>
                  <Col md="12">
                    <FormGroup>
                      <label className="form-label">{t("lbl48")}</label>
                      <Field
                                id="functions"
                                name="functions"
                                type="textarea"
                                class="form-control"
                                component="textarea"
                                aria-describedby="emailHelp"
                                placeholder={t("lbl48")}
                                rows="3"
                              />

                              <ErrorMessage
                                name="functions"
                                component="div"
                                className="invalid"
                              />
                    </FormGroup>
                  </Col>
                  <Col md="12">
                  <FormGroup>
                    <label className="form-label">{t("lbl49")}</label>
                    <Field
                                id="goals"
                                name="goals"
                                type="textarea"
                                class="form-control"
                                component="textarea"
                                aria-describedby="emailHelp"
                                placeholder={t("lbl49")}
                                rows="3"
                              />

                              <ErrorMessage
                                name="goals"
                                component="div"
                                className="msgerror mx-2"
                              />
                  </FormGroup>
                </Col>
                   <Col size="12">
                    <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                      <li>
                        <Button color="primary" size="md" type="submit">
                        {t("lbl33")}
                        </Button>
                      </li>
                      <li>
                        <a
                          href="#cancel"
                          onClick={(ev) => {
                            ev.preventDefault();
                            onFormCancel();
                          }}
                          className="link link-light"
                        >
                          {t("cancel")}
                        </a>
                      </li>
                    </ul>
                  </Col>

                </Form>
                  )}
                  />
              </div>
            </div>
          </ModalBody>
        </Modal>

    </React.Fragment>

  );
};
export default UserProfileExperiencePage;

import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Logo from "../../images/logo_hrh.png";
import LogoDark from "../../images/logo_hrh.png";
import { Block, BlockContent, BlockDes, BlockHead, BlockTitle, Button, PreviewCard } from "../../components/Component";
import PageContainer from "../../layout/page-container/PageContainer";
import Head from "../../layout/head/Head";
import { Link } from "react-router-dom";
import AuthFooter from "./AuthFooter";
// import geojobscanada from "../img/formhrh.png";

import ChangePasswordform from "./ChangePasswordform"

const ChangePassword = (props) => {

  const [t, i18n] = useTranslation("login")


  return (
    <>
    <React.Fragment>
      <Head title="Forgot-Password" />
      <PageContainer>
        <Block className="nk-block-middle nk-auth-body  wide-xs">
          <div className="brand-logo pb-4 text-center">
            <Link to={process.env.PUBLIC_URL + "/"} className="logo-link">
              <img className="logo-light logo-img logo-img-lg" src={Logo} alt="logo" />
              <img className="logo-dark logo-img logo-img-lg" src={LogoDark} alt="logo-dark" />
            </Link>
          </div>
          <PreviewCard className="card-bordered" bodyClass="card-inner-lg">
            <BlockHead>
              <BlockContent>
                <BlockTitle tag="h5"> {t("set_new_password")}</BlockTitle>
                <BlockDes>
                  <p>{t("set_new_password_msg")}</p>
                </BlockDes>
              </BlockContent>
            </BlockHead>
            <section class="">

                  <ChangePasswordform history={props.history} />

                <div class="divider d-flex align-items-center my-2">
                  <div
                    class="tamanoLetra textbold  color-fuente-negro"
                    onClick={()=>props.history.push('/auth-login')}
                  >
                    {t("lbl14")}
                  </div>
                 </div>
      </section>
            </PreviewCard>
        </Block>
        <AuthFooter />
      </PageContainer>
    </React.Fragment>

    </>
  );
}

export default ChangePassword;

import React  from "react";
import { useTranslation } from "react-i18next";
import UserProfileSocial from "./UserProfileSocial";
// import UserProfileHardSkills from "./UserProfileHardSkills";

const UserProfileDocsPage = (props) => {
  const [t, i18n] = useTranslation("detailapplap");
  return (
    <div>
    <UserProfileSocial />
    {/* <UserProfileHardSkills /> */}
    </div>

  );
};
export default UserProfileDocsPage;

var menu = [];
    menu = [
      // { heading: "home" },
      {
        icon: "cc-alt2",
        text: "menu_home",
        link: "/admin",
      },
      // { heading: "appl" },
      {
        icon: "users",
        text: "menu_list_candidates",
        link: "/candidates-list-compact",
      },
      // { heading: "companies" },
      {
        icon: "building",
        text: "menu_list_companies",
        link: "/companies-list-compact",
      },
      // { heading: "joffers" },
      {
        icon: "card-view",
        text: "menu_jobs",
        link: "/jobs_admin",
      },
      { heading: "sales" },
      {
        icon: "coins",
        text: "menu_sales_applicants",
        link: "/sales_applicants",
      },
      {
        icon: "view-col",
        text: "menu_sales_services",
        link: "/sales_services",
      },
      { heading: "config" },
      {
        icon: "opt-dot-alt",
        text: "menu_config_data",
        link: "/config_data_admin",
      },
      {
        icon: "grid-alt",
        text: "menu_config_services",
        link: "/config_services_admin",
      },
      {
        icon: "user-list",
        text: "menu_config_list_users",
        link: "/user-list-compact",
      },
    ];

    export default menu;
import React from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import DarkImage from "../../../images/logo2x.png";
import Facebook from "../../../images/socials/facebook.png";
import Twitter from "../../../images/socials/twitter.png";
import Medium from "../../../images/socials/medium.png";
import Youtube from "../../../images/socials/youtube.png";
import KYCSub from "../../../images/email/kyc-progress.png";
import KYCPen from "../../../images/email/kyc-pending.png";
import KYCApp from "../../../images/email/kyc-success.png";
import AppliedJob from "../../../images/email/job-icon-green.png";
import ChooseCandidate from "../../../images/email/current-jobs-icon.png";
import Invoice from "../../../images/email/invoice_icon.png";
import SlideA from "../../../images/slides/slide-a.jpg";
import SlideB from "../../../images/slides/slide-b.jpg";
import SlideC from "../../../images/slides/slide-c.jpg";
import {
  Block,
  BlockContent,
  BlockDes,
  BlockHead,
  BlockTitle,
  EmailBody,
  EmailBodyContent,
  EmailFooter,
  EmailHeader,
  EmailWrapper,
  PreviewCard,
} from "../../../components/Component";

const EmailTemplate = () => {
  return (
    <React.Fragment>
      <Head title="Email Templates"></Head>
      <Content page="component">
        <BlockHead>
          <BlockContent>
            <BlockTitle tag="h2" className="fw-normal">
              Email Templates
            </BlockTitle>
            <BlockDes>
              <p className="lead">
              </p>
            </BlockDes>
          </BlockContent>
        </BlockHead>

        <Block>
          <PreviewCard>
            <h6 className="title text-soft mb-4 overline-title">Basis Style - Simple Email Template</h6>
            <EmailWrapper>
              <EmailHeader>
                <a
                  href="#logo"
                  onClick={(ev) => {
                    ev.preventDefault();
                  }}
                >
                  <img className="email-logo" src={DarkImage} alt="logo" />
                </a>
                <p className="email-title">Quality | Entrepreneurship | Efficiency | Security</p>
              </EmailHeader>
              <EmailBody>
                <EmailBodyContent className="p-3 p-sm-5">
                  <p>
                    <strong>Hello User</strong>,
                  </p>
                  <p align="justify">
                    Let's face it, sometimes you have a simple message that doesn’t need much design—but still needs
                    flexibility and reliability. Select a basic email template. Write your message. Then send with
                    confidence.
                  </p>
                  <p align="justify">
                    Its clean, minimal and pre-designed email template that is suitable for multiple purposes email
                    template.
                  </p>
                  <p align="justify">
                    Hope you'll enjoy the experience, we're here if you have any questions, drop us a line at
                    info@hrhworkforce.com anytime.{" "}
                  </p>
                  <p className="mt-4">
                    ---- <br /> Regards
                    <br />
                   Director of HRH Workforce
                  </p>
                </EmailBodyContent>
              </EmailBody>
              <EmailFooter>
                <p className="email-copyright-text">
                  Copyright 2022 - <a href="https://www.hrhworkforce.com/">HRH Workforce</a> All rights reserved.

                </p>
                <ul className="email-social">
                  <li>
                    <a
                      href="#socials"
                      onClick={(ev) => {
                        ev.preventDefault();
                      }}
                    >
                      <img src={Facebook} alt="" />
                    </a>
                  </li>
                  <li>
                    <a
                      href="#socials"
                      onClick={(ev) => {
                        ev.preventDefault();
                      }}
                    >
                      <img src={Twitter} alt="" />
                    </a>
                  </li>
                </ul>
              </EmailFooter>
            </EmailWrapper>
          </PreviewCard>
        </Block>

        <Block>
          <PreviewCard>
            <h6 className="title text-soft mb-4 overline-title">Notification - Buy of services or data applicants</h6>
            <EmailWrapper>
              <EmailHeader>
                <a
                  href="#logo"
                  onClick={(ev) => {
                    ev.preventDefault();
                  }}
                >
                  <img className="email-logo" src={DarkImage} alt="logo" />
                </a>
                <p className="email-title">Quality | Entrepreneurship | Efficiency | Security</p>
              </EmailHeader>

              <EmailBody centered>
              <EmailBodyContent className="px-3 px-sm-5 pt-3 pt-sm-5 pb-4">
                  <img className="w-100px" src={Invoice} alt="Invoice" />
                </EmailBodyContent>
                <EmailBodyContent className="p-3 p-sm-5">
                  <h5 className="email-heading email-heading-s1 mb-4">
                    Hi Sarah
                    <br />
                    Purchase and get lifetime update!
                  </h5>
                  <p className="fs-16px text-base">
                    Say hello to version 1.1. Introducing email template with massaging application concept and as
                    always modern, clean and easier to use.
                  </p>
                  <p>
                    Hope you'll enjoy the experience, we're here if you have any questions, drop us a line at
                    info@hrhworkforce anytime.{" "}
                  </p>
                </EmailBodyContent>
              </EmailBody>
              <EmailFooter>
                <p className="email-copyright-text">
                  Copyright 2022 - <a href="https://www.hrhworkforce.com/">HRH Workforce</a> All rights reserved.

                </p>
                <ul className="email-social">
                  <li>
                    <a
                      href="#socials"
                      onClick={(ev) => {
                        ev.preventDefault();
                      }}
                    >
                      <img src={Facebook} alt="" />
                    </a>
                  </li>
                  <li>
                    <a
                      href="#socials"
                      onClick={(ev) => {
                        ev.preventDefault();
                      }}
                    >
                      <img src={Twitter} alt="" />
                    </a>
                  </li>
                </ul>
              </EmailFooter>
            </EmailWrapper>
          </PreviewCard>
        </Block>

        <Block>
          <PreviewCard>
            <h6 className="title text-soft mb-4 overline-title">Notification - Confirmation Email Template</h6>
            <EmailWrapper>
              <EmailHeader>
                <a
                  href="#logo"
                  onClick={(ev) => {
                    ev.preventDefault();
                  }}
                >
                  <img className="email-logo" src={DarkImage} alt="logo" />
                </a>
                <p className="email-title">Quality | Entrepreneurship | Efficiency | Security</p>
              </EmailHeader>
              <EmailBody>
                <EmailBodyContent className="px-3 px-sm-5 pt-3 pt-sm-5 pb-3">
                  <h2 className="email-heading">Confirm Your E-Mail Address</h2>
                </EmailBodyContent>
                <EmailBodyContent className="px-3 px-sm-5 pb-2">
                  <p>Hi Sarah,</p>
                  <p>
                    Welcome! <br /> You are receiving this email because you have registered on our site.
                  </p>
                  <p>Click the link below to active your HRH Workforce account.</p>
                  <p className="mb-4">This link will expire in 15 minutes and can only be used once.</p>
                  <a
                    href="#verify"
                    onClick={(ev) => {
                      ev.preventDefault();
                    }}
                    className="email-btn"
                  >
                    Verify Email
                  </a>
                </EmailBodyContent>
                <EmailBodyContent className="px-3 px-sm-5 pt-4">
                  <h6 className="email-heading-s2">or</h6>
                  <p>If the button above does not work, paste this link into your web browser:</p>
                  <a
                    href="#link"
                    onClick={(ev) => {
                      ev.preventDefault();
                    }}
                    className="link-block"
                  >
                    https://hrhworkforce.com/account?login_token=dacb711d08a0ee7bda83ce1660918c31e8b43c30
                  </a>
                </EmailBodyContent>
                <EmailBodyContent className="px-3 px-sm-5 pt-4 pb-3 pb-sm-5">
                  <p>If you did not make this request, please contact us or ignore this message.</p>
                  <p className="email-note">
                    This is an automatically generated email please do not reply to this email. If you face any issues,
                    please contact us at info@hrhworkforce.com
                  </p>
                </EmailBodyContent>
              </EmailBody>
              <EmailFooter>
                <p className="email-copyright-text">
                  Copyright 2022 - <a href="https://www.hrhworkforce.com/">HRH Workforce</a>. All rights reserved.

                </p>
                <ul className="email-social">
                  <li>
                    <a
                      href="#socials"
                      onClick={(ev) => {
                        ev.preventDefault();
                      }}
                    >
                      <img src={Facebook} alt="" />
                    </a>
                  </li>
                  <li>
                    <a
                      href="#socials"
                      onClick={(ev) => {
                        ev.preventDefault();
                      }}
                    >
                      <img src={Twitter} alt="" />
                    </a>
                  </li>
                </ul>
              </EmailFooter>
            </EmailWrapper>
          </PreviewCard>
        </Block>

        <Block>
          <PreviewCard>
            <h6 className="title text-soft mb-4 overline-title">Notification - Welcome Email Template</h6>
            <EmailWrapper>
              <EmailHeader>
                <a
                  href="#logo"
                  onClick={(ev) => {
                    ev.preventDefault();
                  }}
                >
                  <img className="email-logo" src={DarkImage} alt="logo" />
                </a>
                <p className="email-title">Quality | Entrepreneurship | Efficiency | Security</p>
              </EmailHeader>
              <EmailBody>
                <EmailBodyContent className="p-3 p-sm-5">
                  <p>
                    <strong>Hi Sarah,</strong>
                  </p>
                  <p>We are pleased to have you as a member of HRH Workforce Family.</p>
                  <p>
                    Your account is now verified and you can search and apply to many jobs. Also you can submit your
                    documents for the profile of your account.
                  </p>
                  <p>
                    Hope you'll enjoy the experience, we're here if you have any questions, drop us a line at{" "}
                    <a href="mailto:info@hrhworkforcee.com">info@hrhworkforce.com</a> anytime.{" "}
                  </p>
                </EmailBodyContent>
              </EmailBody>
              <EmailFooter>
                <p className="email-copyright-text">
                Copyright 2022 - <a href="https://www.hrhworkforce.com/">HRH Workforce</a> All rights reserved.

                </p>
                <ul className="email-social">
                  <li>
                    <a
                      href="#socials"
                      onClick={(ev) => {
                        ev.preventDefault();
                      }}
                    >
                      <img src={Facebook} alt="" />
                    </a>
                  </li>
                  <li>
                    <a
                      href="#socials"
                      onClick={(ev) => {
                        ev.preventDefault();
                      }}
                    >
                      <img src={Twitter} alt="" />
                    </a>
                  </li>
                </ul>
              </EmailFooter>
            </EmailWrapper>
          </PreviewCard>
        </Block>

        <Block>
          <PreviewCard>
            <h6 className="title text-soft mb-4 overline-title">Notification - Password Reset Template</h6>
            <EmailWrapper>
              <EmailHeader>
                <a
                  href="#logo"
                  onClick={(ev) => {
                    ev.preventDefault();
                  }}
                >
                  <img className="email-logo" src={DarkImage} alt="logo" />
                </a>
                <p className="email-title">Quality | Entrepreneurship | Efficiency | Security</p>
              </EmailHeader>
              <EmailBody centered>
                <EmailBodyContent className="px-3 px-sm-5 pt-3 pt-sm-5 pb-3">
                  <h2 className="email-heading">Reset Password</h2>
                </EmailBodyContent>
                <EmailBodyContent className="px-3 px-sm-5 pb-2">
                  <p>Hi Sarah,</p>
                  <p>Click On The link blow to reset your password.</p>
                  <a
                    href="#resetpassword"
                    onClick={(ev) => {
                      ev.preventDefault();
                    }}
                    className="email-btn"
                  >
                    RESET PASSWORD
                  </a>
                </EmailBodyContent>
                <EmailBodyContent className="px-3 px-sm-5 pt-4 pb-3 pb-sm-5">
                  <p>If you did not make this request, please contact us or ignore this message.</p>
                  <p className="email-note">
                    This is an automatically generated email please do not reply to this email. If you face any issues,
                    please contact us at info@hrhworkforce.com
                  </p>
                </EmailBodyContent>
              </EmailBody>
              <EmailFooter>
                <p className="email-copyright-text">
                  Copyright 2022 - <a href="https://www.hrhworkforce.com/">HRH Workforce</a> All rights reserved.

                </p>
                <ul className="email-social">
                  <li>
                    <a
                      href="#socials"
                      onClick={(ev) => {
                        ev.preventDefault();
                      }}
                    >
                      <img src={Facebook} alt="" />
                    </a>
                  </li>
                  <li>
                    <a
                      href="#socials"
                      onClick={(ev) => {
                        ev.preventDefault();
                      }}
                    >
                      <img src={Twitter} alt="" />
                    </a>
                  </li>
                </ul>
              </EmailFooter>
            </EmailWrapper>
          </PreviewCard>
        </Block>

        <Block>
          <PreviewCard>
            <h6 className="title text-soft mb-4 overline-title">Notification - Password Changed Template</h6>
            <EmailWrapper>
              <EmailHeader>
                <a
                  href="#logo"
                  onClick={(ev) => {
                    ev.preventDefault();
                  }}
                >
                  <img className="email-logo" src={DarkImage} alt="logo" />
                </a>
                <p className="email-title">Quality | Entrepreneurship | Efficiency | Security</p>
              </EmailHeader>
              <EmailBody centered>
                <EmailBodyContent className="px-3 px-sm-5 pt-3 pt-sm-5 pb-3">
                  <h2 className="email-heading text-success">Password Successfully Changed</h2>
                </EmailBodyContent>
                <EmailBodyContent className="px-3 px-sm-5">
                  <p>Hi Sarah,</p>
                  <p>You have successfully reseted your password. Thanks for being with us.</p>
                </EmailBodyContent>
                <EmailBodyContent className="px-3 px-sm-5 pt-4 pb-3 pb-sm-5">
                  <p className="email-note">
                    This is an automatically generated email please do not reply to this email. If you face any issues,
                    please contact us at info@hrhworkforce.com
                  </p>
                </EmailBodyContent>
              </EmailBody>
              <EmailFooter>
                <p className="email-copyright-text">
                  Copyright 2022 - <a href="https://www.hrhworkforce.com/">HRH Workforce</a> All rights reserved.

                </p>
                <ul className="email-social">
                  <li>
                    <a
                      href="#socials"
                      onClick={(ev) => {
                        ev.preventDefault();
                      }}
                    >
                      <img src={Facebook} alt="" />
                    </a>
                  </li>
                  <li>
                    <a
                      href="#socials"
                      onClick={(ev) => {
                        ev.preventDefault();
                      }}
                    >
                      <img src={Twitter} alt="" />
                    </a>
                  </li>
                </ul>
              </EmailFooter>
            </EmailWrapper>
          </PreviewCard>
        </Block>

        <Block>
          <PreviewCard>
            <h6 className="title text-soft mb-4 overline-title">Notification - Profile Updated Pending </h6>
            <EmailWrapper>
              <EmailHeader>
                <a
                  href="#logo"
                  onClick={(ev) => {
                    ev.preventDefault();
                  }}
                >
                  <img className="email-logo" src={DarkImage} alt="logo" />
                </a>
                <p className="email-title">Quality | Entrepreneurship | Efficiency | Security</p>
              </EmailHeader>
              <EmailBody centered>
                <EmailBodyContent className="px-3 px-sm-5 pt-3 pt-sm-5 pb-4">
                  <img className="w-100px" src={KYCSub} alt="In Process" />
                </EmailBodyContent>
                <EmailBodyContent className="px-3 px-sm-5 pb-3 pb-sm-5">
                  <h5 className="text-primary mb-3">Your profile update is under verification process.</h5>
                  <p>
                  We have received your profile update and we are working on validating it. Once our team has validated the profile, you will be notified by email and you will be able to apply to the different job offers.
                  </p>
                </EmailBodyContent>
              </EmailBody>
              <EmailFooter>
                <p className="email-copyright-text">
                  Copyright 2022 - <a href="https://www.hrhworkforce.com/">HRH Workforce</a> All rights reserved.

                </p>
                <ul className="email-social">
                  <li>
                    <a
                      href="#socials"
                      onClick={(ev) => {
                        ev.preventDefault();
                      }}
                    >
                      <img src={Facebook} alt="" />
                    </a>
                  </li>
                  <li>
                    <a
                      href="#socials"
                      onClick={(ev) => {
                        ev.preventDefault();
                      }}
                    >
                      <img src={Twitter} alt="" />
                    </a>
                  </li>
                </ul>
              </EmailFooter>
            </EmailWrapper>
          </PreviewCard>
        </Block>

        <Block>
          <PreviewCard>
            <h6 className="title text-soft mb-4 overline-title">Notification - Profile Updated Rejected</h6>
            <EmailWrapper>
              <EmailHeader>
                <a
                  href="#logo"
                  onClick={(ev) => {
                    ev.preventDefault();
                  }}
                >
                  <img className="email-logo" src={DarkImage} alt="logo" />
                </a>
                <p className="email-title">Quality | Entrepreneurship | Efficiency | Security</p>
              </EmailHeader>
              <EmailBody centered>
                <EmailBodyContent className="px-3 px-sm-5 pt-3 pt-sm-5 pb-4">
                  <img className="w-100px" src={KYCPen} alt="Pending" />
                </EmailBodyContent>
                <EmailBodyContent className="px-3 px-sm-5">
                  <p>Hi Sarah,</p>
                  {/* <p>You have successfully reseted your password. Thanks for being with us.</p> */}
                </EmailBodyContent>
                <EmailBodyContent className="px-3 px-sm-5 pb-3 pb-sm-5">
                  <h5 className="text-warning mb-3">Your profile update was not approved.</h5>
                  <p>
                  In our verification process, we found that you wrote some information restricted. It would great if you resubmit the form.
                   If face problem in submission please contact us with our best ever excelent support team info@hrhworkforce.com.
                  </p>
                </EmailBodyContent>
              </EmailBody>
              <EmailFooter>
                <p className="email-copyright-text">
                  Copyright 2022 - <a href="https://www.hrhworkforce.com/">HRH Workforce</a> All rights reserved.

                </p>
                <ul className="email-social">
                  <li>
                    <a
                      href="#socials"
                      onClick={(ev) => {
                        ev.preventDefault();
                      }}
                    >
                      <img src={Facebook} alt="" />
                    </a>
                  </li>
                  <li>
                    <a
                      href="#socials"
                      onClick={(ev) => {
                        ev.preventDefault();
                      }}
                    >
                      <img src={Twitter} alt="" />
                    </a>
                  </li>
                </ul>
              </EmailFooter>
            </EmailWrapper>
          </PreviewCard>
        </Block>

        <Block>
          <PreviewCard>
            <h6 className="title text-soft mb-4 overline-title">Notification - Profile Updated Approved </h6>
            <EmailWrapper>
              <EmailHeader>
                <a
                  href="#logo"
                  onClick={(ev) => {
                    ev.preventDefault();
                  }}
                >
                  <img className="email-logo" src={DarkImage} alt="logo" />
                </a>
                <p className="email-title">Quality | Entrepreneurship | Efficiency | Security</p>
              </EmailHeader>
              <EmailBody centered>
                <EmailBodyContent className="px-3 px-sm-5 pt-3 pt-sm-5 pb-4">
                  <img className="w-100px" src={KYCApp} alt="Verified" />
                </EmailBodyContent>
                <EmailBodyContent className="px-3 px-sm-5">
                  <p>Hi Sarah,</p>
                  <p>You have successfully update your profile. Thanks for being with us.</p>
                </EmailBodyContent>
                <EmailBodyContent className="px-3 px-sm-5 pb-3 pb-sm-5">
                  <h5 className="text-success mb-3">Your Profile is Approved.</h5>
                  <p>One of our team validated your profile information. You are now be able to apply to the different job offers.</p>
                </EmailBodyContent>
              </EmailBody>
              <EmailFooter>
                <p className="email-copyright-text">
                  Copyright 2022 - <a href="https://www.hrhworkforce.com/">HRH Workforce</a> All rights reserved.

                </p>
                <ul className="email-social">
                  <li>
                    <a
                      href="#socials"
                      onClick={(ev) => {
                        ev.preventDefault();
                      }}
                    >
                      <img src={Facebook} alt="socials" />
                    </a>
                  </li>
                  <li>
                    <a
                      href="#socials"
                      onClick={(ev) => {
                        ev.preventDefault();
                      }}
                    >
                      <img src={Twitter} alt="socials" />
                    </a>
                  </li>

                </ul>
              </EmailFooter>
            </EmailWrapper>
          </PreviewCard>
        </Block>

        <Block>
          <PreviewCard>
            <h6 className="title text-soft mb-4 overline-title">Notification - Applied Job</h6>
            <EmailWrapper>
              <EmailHeader>
                <a
                  href="#logo"
                  onClick={(ev) => {
                    ev.preventDefault();
                  }}
                >
                  <img className="email-logo" src={DarkImage} alt="logo" />
                </a>
                <p className="email-title">Quality | Entrepreneurship | Efficiency | Security</p>
              </EmailHeader>
              <EmailBody centered>
                <EmailBodyContent className="px-3 px-sm-5 pt-3 pt-sm-5 pb-4">
                  <img className="w-100px" src={AppliedJob} alt="Verified" />
                </EmailBodyContent>
                <EmailBodyContent className="px-3 px-sm-5">
                  <p>Hi Sarah,</p>
                  <p>You have successfully applied to a job. Thanks for being with us.</p>
                </EmailBodyContent>
                <EmailBodyContent className="px-3 px-sm-5 pb-3 pb-sm-5">
                  <h5 className="text-success mb-3">Title of the Job - Company</h5>
                  <p>The company will choose the profiles that best fit the company's requirements and will contact you.</p>
                </EmailBodyContent>
              </EmailBody>
              <EmailFooter>
                <p className="email-copyright-text">
                  Copyright 2022 - <a href="https://www.hrhworkforce.com/">HRH Workforce</a> All rights reserved.

                </p>
                <ul className="email-social">
                  <li>
                    <a
                      href="#socials"
                      onClick={(ev) => {
                        ev.preventDefault();
                      }}
                    >
                      <img src={Facebook} alt="socials" />
                    </a>
                  </li>
                  <li>
                    <a
                      href="#socials"
                      onClick={(ev) => {
                        ev.preventDefault();
                      }}
                    >
                      <img src={Twitter} alt="socials" />
                    </a>
                  </li>

                </ul>
              </EmailFooter>
            </EmailWrapper>
          </PreviewCard>
        </Block>

        <Block>
          <PreviewCard>
            <h6 className="title text-soft mb-4 overline-title">Notification - Interested of company in your profile</h6>
            <EmailWrapper>
              <EmailHeader>
                <a
                  href="#logo"
                  onClick={(ev) => {
                    ev.preventDefault();
                  }}
                >
                  <img className="email-logo" src={DarkImage} alt="logo" />
                </a>
                <p className="email-title">Quality | Entrepreneurship | Efficiency | Security</p>
              </EmailHeader>
              <EmailBody centered>
                <EmailBodyContent className="px-3 px-sm-5 pt-3 pt-sm-5 pb-4">
                  <img className="w-100px" src={ChooseCandidate} alt="Choose" />
                </EmailBodyContent>
                <EmailBodyContent className="px-3 px-sm-5">
                  <p>Hi Sarah,</p>
                  <p>Respect to the offer job "Title Job" that you applied on "Date Applied".</p>
                </EmailBodyContent>
                <EmailBodyContent className="px-3 px-sm-5 pb-3 pb-sm-5">
                  <h5 className="text-success mb-3">CompanyName is interested in you</h5>
                  <p>The company already has access to all your data and documents attached to the profile. They will contact you..</p>
                </EmailBodyContent>
              </EmailBody>
              <EmailFooter>
                <p className="email-copyright-text">
                  Copyright 2022 - <a href="https://www.hrhworkforce.com/">HRH Workforce</a> All rights reserved.

                </p>
                <ul className="email-social">
                  <li>
                    <a
                      href="#socials"
                      onClick={(ev) => {
                        ev.preventDefault();
                      }}
                    >
                      <img src={Facebook} alt="socials" />
                    </a>
                  </li>
                  <li>
                    <a
                      href="#socials"
                      onClick={(ev) => {
                        ev.preventDefault();
                      }}
                    >
                      <img src={Twitter} alt="socials" />
                    </a>
                  </li>

                </ul>
              </EmailFooter>
            </EmailWrapper>
          </PreviewCard>
        </Block>

        <Block>
          <PreviewCard>
            <h6 className="title text-soft mb-4 overline-title">Simple News - Basic Newsletter Template</h6>
            <EmailWrapper>
              <EmailHeader>
                <a
                  href="#logo"
                  onClick={(ev) => {
                    ev.preventDefault();
                  }}
                >
                  <img className="email-logo" src={DarkImage} alt="logo" />
                </a>
                <p className="email-title">Quality | Entrepreneurship | Efficiency | Security</p>
              </EmailHeader>
              <EmailBody>
                <EmailBodyContent className="text-center px-3 px-sm-5 pt-3 pt-sm-5 pb-4">
                  <img src={SlideA} alt="slide" />
                </EmailBodyContent>
                <EmailBodyContent className="text-center px-3 px-sm-5 pb-5">
                  <h2 className="email-heading email-heading-sm">How to request a payment.</h2>
                  <p className="mgb-1-5x">
                    Lorem Ipsum decided to leave for the far World of Grammar. The Big Oxmox advised her not to do so,
                    because there were thousands of bad Comma
                  </p>
                  <a
                    href="#readmore"
                    onClick={(ev) => {
                      ev.preventDefault();
                    }}
                    className="email-btn email-btn-sm"
                  >
                    Read More
                  </a>
                </EmailBodyContent>
                <EmailBodyContent className="text-center px-3 px-sm-5 pb-4">
                  <img src={SlideB} alt="slide" />
                </EmailBodyContent>
                <EmailBodyContent className="text-center px-3 px-sm-5 pb-5">
                  <h2 className="email-heading email-heading-sm">How to request a payment.</h2>
                  <p>Lorem Ipsum decided to leave for the far World of Grammar.</p>
                  <p>
                    Wild Question Marks and devious Semikoli, but the Little Blind Text didn’t listen. She packed her
                    seven versalia, put her initial into the belt and made herself on the way
                  </p>
                  <p>
                    Lorem Ipsum decided to leave for the far World of Grammar. The Big Oxmox advised her not to do so,
                    because there were thousands of bad Commans.
                  </p>
                </EmailBodyContent>
                <EmailBodyContent className="text-center px-3 px-sm-5 pb-4">
                  <img src={SlideC} alt="slide" />
                </EmailBodyContent>
                <EmailBodyContent className="px-3 px-sm-5 pb-5">
                  <h2 className="email-heading email-heading-sm">Our New Update release.</h2>
                  <table className="w-100">
                    <tbody>
                      <tr>
                        <td className="w-100">
                          <ul className="email-ul email-ul-col2">
                            <li>Decenterlized</li>
                            <li>Faster Access</li>
                            <li>New Encryption</li>
                            <li>New App</li>
                            <li>Smart Payment</li>
                            <li>Extremely light</li>
                            <li>Strong Network</li>
                            <li>Updated UI</li>
                          </ul>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <a
                    href="#readmore"
                    onClick={(ev) => {
                      ev.preventDefault();
                    }}
                    className="email-btn email-btn-sm mt-2"
                  >
                    Read More
                  </a>
                </EmailBodyContent>
              </EmailBody>
              <EmailFooter>
                <p className="email-copyright-text">
                  Copyright 2022 - <a href="https://www.hrhworkforce.com/">HRH Workforce</a> All rights reserved.

                </p>
                <ul className="email-social">
                  <li>
                    <a
                      href="#socials"
                      onClick={(ev) => {
                        ev.preventDefault();
                      }}
                    >
                      <img src={Facebook} alt="socials" />
                    </a>
                  </li>
                  <li>
                    <a
                      href="#socials"
                      onClick={(ev) => {
                        ev.preventDefault();
                      }}
                    >
                      <img src={Twitter} alt="socials" />
                    </a>
                  </li>

                </ul>
              </EmailFooter>
            </EmailWrapper>
          </PreviewCard>
        </Block>
      </Content>
    </React.Fragment>
  );
};
export default EmailTemplate;

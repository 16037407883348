import React, { useEffect, useState } from "react";
import Icon from "../../components/icon/Icon";
import { orderActivityData } from "../../components/partials/order-activity/OrderData";
import { CardTitle } from "reactstrap";
import { DataTableBody, DataTableHead, DataTableItem, DataTableRow } from "../../components/table/DataTable";
import { Link } from "react-router-dom";


import { useTranslation } from "react-i18next";
import UserProfile from "../../Login/UserProfile";
import Swal from "sweetalert2";
import axios from "axios";


const RecentJobs = (props) => {

  const [orderData, setOrderData] = useState(orderActivityData);
  const [orderActivity, setActivity] = useState("");
  useEffect(() => {
    let data;
    if (orderActivity === "Buy") {
      data = orderActivityData.filter((item) => item.desc.split(" ")[0] === "Buy");
    } else if (orderActivity === "Sell") {
      data = orderActivityData.filter((item) => item.desc.split(" ")[0] === "Sell");
    } else {
      data = orderActivityData;
    }
    setOrderData(data);
  }, [orderActivity]);

  const [t, i18n] = useTranslation("homeappl","demandReport");
  // Get the language selected
  let lng = i18n.languages[0];


  const [ offers, setoffers ] = useState([]);
  const [ companies, setcompanies ] = useState([]);

  const [ oferts, setoferts ] = useState([]);
  const [ titleoffer, settitleoffer ] = useState([]);

  const list = () => {
    const search = null;
    // let newsearch = {...search, page: selectedPage}
      axios
        .post(`${process.env.REACT_APP_BASE_URL}/api/job_offers/search`,search, {
          headers: {
              Authorization: `Bearer  ${localStorage.getItem('token')}`,
            },
            // params: newsearch
        })
        .then((res) => {
            setoferts(res.data.data.job_offers)
            // setpageCount(res.data.data.pages)
        })
        .catch((error) => console.log(error));
  }

// const list = async () => {
//   setoffers([])
//   axios
//         .get(`${process.env.REACT_APP_BASE_URL}/api/xxx`, {
//           headers: {
//             Authorization: `Bearer  ${UserProfile.getToken()}`,
//           },
//           params:{
//             page: 1
//         }}
//       )
//       .then((res) => {
//         console.log(res);
//     if (res.data.error ? res.data.error[0] == 'El Token a Expirado' : false) {props.history.push('/login'); return}
//         setcompanies( res.data.data.companies )
//       })
//       .catch((error) => console.log(error));
//     };

    useEffect(() => {
        if (navigator.onLine) {
          UserProfile.setToken(localStorage.getItem('token'));
          list();
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: t("msg1"),
            confirmButtonColor: "#071b30",
            confirmButtonText: t("ok"),
          });
        }
      }, [])

  return (
    <React.Fragment>
      <div className="card-inner">
        <div className="card-title-group">
          <CardTitle>
            <h6 className="title">
              <span className="mr-2">Ofertas de Trabajo Recientes</span>{" "}
              { props.role == 'applicant' ? <Link to="/jobs_applicant" className="link d-none d-sm-inline"> See All</Link> :
                  <Link to="/admin/job-offers" className="link d-none d-sm-inline"> See All</Link>
               }
            </h6>
          </CardTitle>
        </div>
      </div>
      <DataTableBody className="border-top" bodyclass="nk-tb-orders">
        <DataTableHead>
          <DataTableRow className="nk-tb-orders-type">
            <span> Titulo</span>
          </DataTableRow>
          <DataTableRow>
            <span>Description</span>
          </DataTableRow>
          {/* <DataTableRow size="sm">
            <span>Company</span>
          </DataTableRow> */}
          <DataTableRow size="sm">
            <span>Position</span>
          </DataTableRow>
          <DataTableRow size="sm">
            <span>Salary</span>
          </DataTableRow>
          <DataTableRow size="sm">
            <span>Country</span>
          </DataTableRow>
          <DataTableRow size="sm">
            <span>Vacancy</span>
          </DataTableRow>
          <DataTableRow size="sm">
            <span>End Offer</span>
          </DataTableRow>

          {/* <DataTableRow size="xl">
            <span>Location</span>
          </DataTableRow>
          <DataTableRow size="xxl">
            <span>Phone Number</span>
          </DataTableRow>
          <DataTableRow size="sm" className="text-right">
            <span>Options</span>
          </DataTableRow> */}
          {/* <DataTableRow className="text-right">
            <span>Amount</span>
          </DataTableRow> */}
        </DataTableHead>
        {oferts.map((item) => {
          return (
            <DataTableItem key={item.id}>
               <DataTableRow>
                <span className="tb-lead">{item.title}</span>
              </DataTableRow>
              <DataTableRow>
                <span className="tb-lead">{ item.description.substr(0, 50)}</span>
              </DataTableRow>
              {/* <DataTableRow>
                <span className="tb-lead">{item.company}</span>
              </DataTableRow> */}
              <DataTableRow>
                <span className="tb-lead">
                {
                                         lng == 'en' ?
                                            item.positions :
                                          lng == 'es' ?
                                            item.positions_es :
                                          lng == 'fr' ?
                                            item.positions_fr : ""
                                        }
                </span>
              </DataTableRow>
              <DataTableRow>
                <span className="tb-lead">{item.salary}</span>
              </DataTableRow>
              <DataTableRow size="sm">
                <span className="tb-sub">
                {
                                          lng == 'en' ?
                                            item.country :
                                          lng == 'es' ?
                                            item.country_es :
                                          lng == 'fr' ?
                                            item.country_fr : ""
                }
                </span>
              </DataTableRow>
              <DataTableRow>
                <span className="tb-lead">{item.vacancy_numbers}</span>
              </DataTableRow>
              <DataTableRow>
                <span className="tb-lead">  {item.end_offer}</span>
              </DataTableRow>
              {/* <DataTableRow size="xl">
                <span className="tb-sub">{item.time}</span>
              </DataTableRow>
              <DataTableRow size="xxl">
                <span className="tb-sub text-primary">{item.ref}</span>
              </DataTableRow>
              <DataTableRow size="sm" className="text-right">
                <span className="tb-sub tb-amount">
                  {item.usd} <span>USD</span>
                </span>
              </DataTableRow> */}
              {/* <DataTableRow className="text-right">
                <span className="tb-sub tb-amount ">
                  {item.amount} <span>BTC</span>
                </span>
              </DataTableRow> */}
            </DataTableItem>
          );
        })}
      </DataTableBody>
    </React.Fragment>
  );
};
export default RecentJobs;

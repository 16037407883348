import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import Head from "../../layout/head/Head";
import axios from "axios";
import Select from "react-select";
import { components } from "react-select";
import UserProfile from "../../Login/UserProfile";
import RegisterEducation from "./RegisterEducation";
import RegisterLevelEducation from "./RegisterLevelEducation";
import {
    DropdownMenu,
    DropdownToggle,
    FormGroup,
    UncontrolledDropdown,
    Modal,
    ModalBody,
    DropdownItem,
  } from "reactstrap";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Button,
  Row,
  Col,
  PaginationComponent,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableRow,
  DataTableItem,
  RSelect,
  TooltipComponent,
} from "../../components/Component";
// import { useForm } from "react-hook-form";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

const Option = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <input
            class="form-check-input"
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />{" "}
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };

const AddSkills = (props) => {

    const [t, i18n] = useTranslation("detailapplap")
    const [ actividades, setactividades ] = useState([]);
    const [ optionSelected, setoptionSelected ] = useState(null);
    const [modalTab, setModalTab] = useState("1");
    const [ idApplicant, setidApplicant ] = useState(0);


  const handleChange = (selected) => {
    setoptionSelected(selected)
  };

    const renderList = () => {
        return actividades.map((data) => ({
          label:  i18n.language == 'en' ?
                    data.name :
                    i18n.language == 'es' ?
                    data.name_es :
                    i18n.language == 'fr' ?
                    data.name_fr : "",
          value: data.id,
        }));
      }

      const renderListActividad = () => {
        var list = optionSelected == null ? [] : optionSelected
        list.map((data) => ({
          activity: data.value,
        }));
        return list
      }


    const addNewSkill = (data) => {
        setactividades([...actividades, data])
        renderList()
        document.getElementById("clocedModal").click()
      }

      const getActividad = async () => {
        await axios
          .get(`${process.env.REACT_APP_BASE_URL}/api/attrs/activities`, {
            headers: {
              Authorization: `Bearer  ${UserProfile.getToken()}`,
            },
          })
          .then((res) => {
            setactividades(res.data.data.activities)
          });
      };

  useEffect(() => {
    if (navigator.onLine) {
      UserProfile.setToken(localStorage.getItem("token"));
      UserProfile.setId(localStorage.getItem("id"));
      getActividad();

    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "msg",
        confirmButtonColor: "#071b30",
        confirmButtonText: "ok",
      });
    }
  }, [])
  return (
    <>
    <div class="col-md-6 col-sm-12 col-lg-6" >
            <label for="applicantActivites" class="form-label">{t("lbl56")}</label>
            {
                actividades ?
                <Select
                    id="applicantActivites"
                    name="applicantActivites"
                    placeholder={t("lbl56")}
                    options={renderList()}
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    components={{
                    Option,
                    }}
                    onChange={handleChange}
                    allowSelectAll={true}
                    value={optionSelected}
                />
                : ""
            }
            {/* <ErrorMessage
                name="applicantActivites"
                component="div"
                className="invalid"
            /> */}
    </div>
    </>

    );
};
export default AddSkills;
import React, { useState, useEffect } from "react";
import EnglishFlag from "../../../../images/flags/english-sq.png";
import SpanishFlag from "../../../../images/flags/spanish-sq.png";
import FrenchFlag from "../../../../images/flags/french-sq.png";
import { DropdownToggle, UncontrolledDropdown } from "reactstrap";
import { LangDropdown, UserAvatar } from "../../../../components/Component";
import { useTranslation } from "react-i18next";

const LanguageHead = () => {
  const [t, i18n] = useTranslation("footer");
  const languages = {
    1 : {
      lang: "en",
      icon: EnglishFlag
    },
    2 : {
      lang: "es",
      icon: SpanishFlag
    },
    3 : {
      lang: "fr",
      icon: FrenchFlag
    }
  }

   // To update the language
   const changeLanguage = async (opc) => {
    let ln = languages[opc]
    localStorage.setItem('lngselect', ln.lang)
    localStorage.setItem('lngiconselect', ln.icon)
    i18n.changeLanguage(localStorage.getItem('lngselect'))
  }

  // To define the language selected
  useEffect(()=>{
    changeLanguage(1)
  },[])

  return (
    <UncontrolledDropdown>
      <DropdownToggle
        tag="a"
        className="dropdown-toggle nk-quick-nav-icon"
      >
        <UserAvatar className="border border-light sm">
        <img  src={localStorage.getItem('lngiconselect')} alt="" />
        </UserAvatar>
      </DropdownToggle>
      <LangDropdown className="dropdown-menu-s1" />
    </UncontrolledDropdown>
  );
};

export default LanguageHead;

import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import { findUpper } from "../../../utils/Utils";
import { userData, FilterCountry,FilterCountryb, filterStatus } from "./UserData";
import {
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  UncontrolledDropdown,
  Modal,
  ModalBody,
  DropdownItem,
} from "reactstrap";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Row,
  Col,
  UserAvatar,
  PaginationComponent,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableRow,
  DataTableItem,
  Button,
  RSelect,
  TooltipComponent,
} from "../../../components/Component";
import axios from "axios";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import UserProfile from "../../../Login/UserProfile";
import sendimg from "../../../img/send.png";
import userimg from "../../../img/user.png";
import userchat from "../../../img/userchat.png";


const CompaniesListCompact = (props) => {

  // Translations
  const [t, i18n] = useTranslation(['companiesadm','global']);
  // Variables to use in the component
  const [ companies, setcompanies ] = useState([]);
  const [ comentarios, setcomentarios ] = useState([]);
  const [ idEmpresa, setidEmpresa ] = useState("");
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    balance: "",
    phone: "",
    status: "Active",
  });
  // Modals
  const [modal, setModal] = useState({
    edit: false,
    add: false,
  });
  const [modalDetail, setModalDetail] = useState(false);
  const [detail, setDetail] = useState({});

  // Filters to Apply
  const [ filcountry, setfilcountry ] = useState("");
  const [ filstatus, setfilstatus ] = useState("");

  const [sm, updateSm] = useState(false);
  const [tablesm, updateTableSm] = useState(false);
  const [onSearch, setonSearch] = useState(true);
  const [onSearchText, setSearchText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(10);
  const [sort, setSortState] = useState("");

// ------- Functions to Order, filter and search -------

  // Sorting data by name
  const sortFunc = (params) => {
    let defaultData = data;
    if (params === "asc") {
      let sortedData = defaultData.sort((a, b) => a.name.localeCompare(b.name));
      setData([...sortedData]);
    } else if (params === "dsc") {
      let sortedData = defaultData.sort((a, b) => b.name.localeCompare(a.name));
      setData([...sortedData]);
    }
  };

  // Changing state value when searching name
  useEffect(() => {
    if (onSearchText !== "") {
      const filteredObject = companies.filter((item) => {
        return (
          item.name.toLowerCase().includes(onSearchText.toLowerCase()) ||
          item.nit.includes(onSearchText.toLowerCase())
        );
      });
      setData([...filteredObject]);
    } else {
      setData([...companies]);
    }
  }, [onSearchText, setData]);

  // onChange function for searching name
  const onFilterChange = (e) => {
    setSearchText(e.target.value);
  };

  //  filter by country and status
  useEffect(() => {
    if (filcountry !== "" && filstatus === "") {
      const filteredObject = companies.filter((item) => {
        return (
          item.country.id == filcountry.value
        );
      });
      setData([...filteredObject]);
    } else if(filcountry != "" && filstatus != "") {
      const filteredObject = companies.filter((item) => {
        return (
          item.country.id == filcountry.value && item.status.id == filstatus.value
        );
      });
      setData([...filteredObject]);
    }
    else if(filcountry == "" && filstatus != "") {
      const filteredObject = companies.filter((item) => {
        return (
          item.status.id == filstatus.value
        );
      });
      setData([...filteredObject]);
    }
    else {
      setData([...data]);
    }
  }, [filcountry, filstatus, setData]);

  // function to reset the form of filter
const resetFilter = () => {
  setfilstatus("");
  setfilcountry("");
  setData([...companies]);
};
// ------- End Functions to Order, filter and search -------

// Functions to Manage forms

  // function to reset the form
  const resetForm = () => {
    setFormData({
      name: "",
      nit: "",
      status: "Active",
    });
  };

  // function to close the form modal
  const onFormCancel = () => {
    setModal({ edit: false, add: false });
    resetForm();
  };

    // submit function to update a new item
  // const onEditSubmit = (submitData) => {
  //   const { name, email, phone } = submitData;
  //   let submittedData;
  //   let newitems = data;
  //   newitems.forEach((item) => {
  //     if (item.id === editId) {
  //       submittedData = {
  //         id: item.id,
  //         avatarBg: item.avatarBg,
  //         name: name,
  //         image: item.image,
  //         role: item.role,
  //         email: email,
  //         balance: formData.balance,
  //         phone: "+" + phone,
  //         emailStatus: item.emailStatus,
  //         kycStatus: item.kycStatus,
  //         lastLogin: item.lastLogin,
  //         status: formData.status,
  //         country: item.country,
  //       };
  //     }
  //   });
  //   let index = newitems.findIndex((item) => item.id === editId);
  //   newitems[index] = submittedData;
  //   setModal({ edit: false });
  // };

  // function that loads the want to editted data
  // const onEditClick = (id) => {
  //   data.forEach((item) => {
  //     if (item.id === id) {
  //       setFormData({
  //         name: item.name,
  //         email: item.email,
  //         status: item.status,
  //         role: item.role,
  //         password: item.password,
  //       });
  //       setModal({ edit: true }, { add: false });
  //       setEditedId(id);
  //     }
  //   });
  // };

  // function to load detail data
  // Muestra popup con detalles de la empresa (Danny)
  const loadDetail = (id) => {
    let index = data.findIndex((item) => item.id === id);
    setDetail(data[index]);
  };

  // function to toggle the search option
  const toggle = () => setonSearch(!onSearch);

  // function to toggle details modal
  const toggleModalDetail = () => setModalDetail(!modalDetail);

  const { errors, register, handleSubmit } = useForm();

  // Get current list, pagination
  const indexOfLastItem = currentPage * itemPerPage;
  const indexOfFirstItem = indexOfLastItem - itemPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  // Change Page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const listChat = async () => {
    setcomentarios([])
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/api/commentcompany/${idEmpresa}`,
        {
          headers: {
            Authorization: `Bearer  ${UserProfile.getToken()}`,
          },
        }
      )
      .then((res) => {
        setcomentarios(res.data.data.comment_company)
      });
  }

  const send = async () => {
    if (document.getElementById("mensaje").value != "") {
      var data = {
        comment: document.getElementById("mensaje").value,
        company: idEmpresa,
      };
      axios
        .post(`${process.env.REACT_APP_BASE_URL}/api/comment_company`, data, {
          headers: {
            Authorization: `Bearer  ${UserProfile.getToken()}`,
          },
        })
        .then((res) => {
          if (res.data.status === true) {
            listChat();
          }
        })
        .catch((error) => console.log(error));
        document.getElementById("mensaje").value=""
    }
  }

  const downloaddocgettoken = () => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/api/companies/download/document?company=${idEmpresa}`,{
          headers: {
            Authorization: `Bearer  ${UserProfile.getToken()}`,
          },
        }
      )
      .then((res) => {
        if (!res.data.status) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: t("msg2"),
            confirmButtonColor: "#071b30",
            confirmButtonText: t("ok"),
          });
          return
        }
        downloaddoc(res.data.data.token_download)
      });
  }

  const downloaddoc = (token) => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/api/companies/download/document/${token}?company=${idEmpresa}`,{
          headers: {
            Authorization: `Bearer  ${UserProfile.getToken()}`,
          },
        }
      )
      .then((res) => {
        const fileName = res.data.data.name;
        const linkSource = `data:application/pdf;base64,${res.data.data.pdf}`;
        const downloadLink = document.createElement("a");
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      });
  }

  //List of Companies (Danny)
    const list = async (search) => {
    setcompanies([])
    // setloading(1)
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/api/companies`,
        {
          headers: {
            Authorization: `Bearer  ${UserProfile.getToken()}`,
          },

        }
      )
      .then((res) => {
        if (res.data.error ? res.data.error[0] == 'El Token a Expirado' : false) {props.history.push('/login'); return}
        setcompanies(res.data.data.companies )
        setData(res.data.data.companies )
        setTotal(res.data.data.total )

        // setloading(0)
      })
      .catch((error) => console.log(error));
  };

  // Activate/Desactivate Company (Danny)
  const handleSwitchChange = async (value,estado) => {
    var values = []
    if (estado == 1) {
      const valuesupdate = {
        status: 2
      };
      axios
        .put(
          `${process.env.REACT_APP_BASE_URL}/api/companies/status/${value.id}`,valuesupdate ,{
            headers: {
              Authorization: `Bearer  ${UserProfile.getToken()}`,
            },
          }
        )
        .then((res) => {
          if (res.data.status == true) {
            Swal.fire({
              icon: "success",
              title: t("user_updated", {ns: 'global'}),
              text: t("user_inactivated", {ns: 'global'}),
              confirmButtonColor: "#071b30",
              confirmButtonText: t("ok"),
            });
            list();
          }
          else{
            console.log(res)
          }
        })
        .catch((error) => console.log(error));
    }
    else {
      const valuesupdate = {
        // name: value.name,
        // email: value.email,
        // type: value.type,
        status: 1
      };
      axios
        .put(
          `${process.env.REACT_APP_BASE_URL}/api/companies/status/${value.id}`,
          valuesupdate,
          {
            headers: {
              Authorization: `Bearer  ${UserProfile.getToken()}`,
            }
          }
        )
        .then((res) => {
          if (res.data.status == true) {
            Swal.fire({
              icon: "success",
              title: t("user_updated", {ns: 'global'}),
              text: t("user_activated", {ns: 'global'}),
              confirmButtonColor: "#071b30",
              confirmButtonText: t("ok"),
            });
            list();
          }else{
            console.log(res)
          }
        })
        .catch((error) => console.log(error));
    }
  };

  useEffect(() => {
    if (navigator.onLine) {
      UserProfile.setToken(localStorage.getItem('token'));
      list();
      // getCountry();
      // getActivity()
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: t("msg1"),
        confirmButtonColor: "#071b30",
        confirmButtonText: t("ok"),
      });
    }
  }, [])

  return (
    <React.Fragment>
      <Head title="Companies List"></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h3" page>
              {t("ttl5")}
              </BlockTitle>
              <BlockDes className="text-soft">
                <p>{t("lbl27")} {total} {t("lbl28")}.</p>
              </BlockDes>
            </BlockHeadContent>
            {/* <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <Button
                  className={`btn-icon btn-trigger toggle-expand mr-n1 ${sm ? "active" : ""}`}
                  onClick={() => updateSm(!sm)}
                >
                  <Icon name="menu-alt-r"></Icon>
                </Button>

              </div>
            </BlockHeadContent> */}
          </BlockBetween>
        </BlockHead>

        <Block>
          <DataTable className="card-stretch">
    {/* Filters, search, order */}
          <div className="card-inner position-relative card-tools-toggle">
              <div className="card-title-group">
              <div className="card-title">
                  <h5 className="title">{t("lbl29")}</h5>
                </div>
                <div className="card-tools">
                  <div className="form-inline flex-nowrap gx-3">

                  </div>
                </div>
                <div className="card-tools mr-n1">
                  <ul className="btn-toolbar gx-1">
                    <li>
                      <a
                        href="#search"
                        onClick={(ev) => {
                          ev.preventDefault();
                          toggle();
                        }}
                        className="btn btn-icon search-toggle toggle-search"
                      >
                        <Icon name="search"></Icon>
                      </a>
                    </li>
                    <li className="btn-toolbar-sep"></li>
                    <li>
                      <div className="toggle-wrap">
                        <Button
                          className={`btn-icon btn-trigger toggle ${tablesm ? "active" : ""}`}
                          onClick={() => updateTableSm(true)}
                        >
                          <Icon name="menu-right"></Icon>
                        </Button>
                        <div className={`toggle-content ${tablesm ? "content-active" : ""}`}>
                          <ul className="btn-toolbar gx-1">
                            <li className="toggle-close">
                              <Button className="btn-icon btn-trigger toggle" onClick={() => updateTableSm(false)}>
                                <Icon name="arrow-left"></Icon>
                              </Button>
                            </li>
                            <li>
                              <UncontrolledDropdown>
                                <DropdownToggle tag="a" className="btn btn-trigger btn-icon dropdown-toggle">
                                  <div className="dot dot-primary"></div>
                                  <Icon name="filter-alt"></Icon>
                                </DropdownToggle>
                                <DropdownMenu
                                  right
                                  className="filter-wg dropdown-menu-xl"
                                  style={{ overflow: "visible" }}
                                >
                                  <div className="dropdown-head">
                                    <span className="sub-title dropdown-title">  {t("search", {ns: 'global'})}</span>
                                    <div className="dropdown">
                                      <DropdownItem
                                        href="#more"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                        }}
                                        className="btn btn-sm btn-icon"
                                      >
                                        <Icon name="more-h"></Icon>
                                      </DropdownItem>
                                    </div>
                                  </div>
                                  <div className="dropdown-body dropdown-body-rg">
                                    <Row className="gx-6 gy-3">

                                      <Col size="6">
                                        <FormGroup>
                                          <label className="overline-title overline-title-alt">  {t("country", {ns: 'global'})}</label>
                                          <RSelect options={FilterCountry} placeholder={t("select_country", {ns: 'global'})} value={filcountry} onChange={(e) =>
                            setfilcountry(e)
                          } />
                                        </FormGroup>
                                      </Col>
                                      <Col size="6">
                                        <FormGroup>
                                          <label className="overline-title overline-title-alt">  {t("state", {ns: 'global'})}</label>
                                          <RSelect options={filterStatus} placeholder={t("select_state", {ns: 'global'})}  value={filstatus}  onChange={(e) =>
                            setfilstatus(e)
                          }/>
                                        </FormGroup>
                                      </Col>

                                    </Row>
                                  </div>
                                  <div className="dropdown-foot between">
                                    <a
                                      href="#reset"
                                      onClick={(ev) => {
                                        ev.preventDefault();
                                        resetFilter();
                                      }}
                                      className="clickable"
                                    >
                                     {t("reset_filters", {ns: 'global'})}
                                    </a>

                                  </div>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </li>
                            <li>
                              <UncontrolledDropdown>
                                <DropdownToggle tag="a" className="btn btn-trigger btn-icon dropdown-toggle">
                                  <Icon name="setting"></Icon>
                                </DropdownToggle>
                                <DropdownMenu right className="dropdown-menu-xs">
                                  <ul className="link-check">
                                    <li>
                                      <span>{t("show", {ns: 'global'})}</span>
                                    </li>
                                    <li className={itemPerPage === 10 ? "active" : ""}>
                                      <DropdownItem
                                        tag="a"
                                        href="#dropdownitem"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                          setItemPerPage(10);
                                        }}
                                      >
                                        10
                                      </DropdownItem>
                                    </li>
                                    <li className={itemPerPage === 15 ? "active" : ""}>
                                      <DropdownItem
                                        tag="a"
                                        href="#dropdownitem"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                          setItemPerPage(15);
                                        }}
                                      >
                                        15
                                      </DropdownItem>
                                    </li>
                                  </ul>
                                  <ul className="link-check">
                                    <li>
                                      <span>{t("order", {ns: 'global'})}</span>
                                    </li>
                                    <li className={sort === "dsc" ? "active" : ""}>
                                      <DropdownItem
                                        tag="a"
                                        href="#dropdownitem"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                          setSortState("dsc");
                                          sortFunc("dsc");
                                        }}
                                      >
                                        DESC
                                      </DropdownItem>
                                    </li>
                                    <li className={sort === "asc" ? "active" : ""}>
                                      <DropdownItem
                                        tag="a"
                                        href="#dropdownitem"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                          setSortState("asc");
                                          sortFunc("asc");
                                        }}
                                      >
                                        ASC
                                      </DropdownItem>
                                    </li>
                                  </ul>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className={`card-search search-wrap ${!onSearch && "active"}`}>
                <div className="card-body">
                  <div className="search-content">
                    <Button
                      className="search-back btn-icon toggle-search active"
                      onClick={() => {
                        setSearchText("");
                        toggle();
                      }}
                    >
                      <Icon name="arrow-left"></Icon>
                    </Button>
                    <input
                      type="text"
                      className="border-transparent form-focus-none form-control"
                      placeholder={t("search_by_keyword_company", {ns: 'global'})}
                      value={onSearchText}
                      onChange={(e) => onFilterChange(e)}
                    />
                    <Button className="search-submit btn-icon">
                      <Icon name="search"></Icon>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <DataTableBody compact>
              <DataTableHead>
                <DataTableRow className="nk-tb-col-check">
                <span className="sub-text">#</span>
                  {/* <div className="custom-control custom-control-sm custom-checkbox notext">
                    <input
                      type="checkbox"
                      className="custom-control-input form-control"
                      onChange={(e) => selectorCheck(e)}
                      id="uid"
                    />
                    <label className="custom-control-label" htmlFor="uid"></label>
                  </div> */}
                </DataTableRow>
                <DataTableRow>
                  <span className="sub-text">{t("tbl1")}</span>
                </DataTableRow>
                <DataTableRow size="md">
                  <span className="sub-text">{t("tbl2")}</span>
                </DataTableRow>
                <DataTableRow size="sm">
                  <span className="sub-text">{t("tbl3")}</span>
                </DataTableRow>

                <DataTableRow size="lg">
                  <span className="sub-text">{t("tbl8")}</span>
                </DataTableRow>
                <DataTableRow size="xxl">
                  <span className="sub-text">{t("tbl11")}</span>
                </DataTableRow>
                <DataTableRow size="xxl">
                  <span className="sub-text">{t("tbl12")}</span>
                </DataTableRow>
                <DataTableRow>
                <span className="sub-text">{t("tbl4")}</span>
                </DataTableRow>
                <DataTableRow className="nk-tb-col-tools text-right">

                </DataTableRow>

              </DataTableHead>
              {/*Head*/}
              {currentItems.length > 0
                ? currentItems.map((item) => {
                    return (
                      <DataTableItem key={item.id}>
                        <DataTableRow className="nk-tb-col-check">
                        <Link to={`${process.env.PUBLIC_URL}/user-details-regular/${item.id}`}>
                        <span className="tb-lead">{item.id}</span>
                        </Link>

                        </DataTableRow>
                        <DataTableRow>
                          <Link to={`${process.env.PUBLIC_URL}/user-details-regular/${item.id}`}>
                            <div className="user-card">
                              <UserAvatar
                                theme={item.avatarBg}
                                className="xs"
                                text={findUpper(item.name)}
                                image={item.logo}
                              ></UserAvatar>
                              <div className="user-info">
                                <span className="tb-lead">{item.name}</span>
                                <span className="tb-lead">{item.legal_representative.first_name} {item.legal_representative.last_name}</span>
                              </div>
                            </div>
                          </Link>
                        </DataTableRow>
                        <DataTableRow size="md">
                          <span className="tb-lead-sub">{item.description}</span>
                          {/* <span className="tb-sub">{item.role}</span> */}
                        </DataTableRow>
                        <DataTableRow size="sm">
                          <span>{item.phone_number}</span>
                        </DataTableRow>
                        <DataTableRow size="md">
                          <span>{item.country.name}</span>
                        </DataTableRow>
                        <DataTableRow size="xxl">
                          0{/* <span>{item.country}</span> */}
                        </DataTableRow>

                        <DataTableRow size="xxl">
                        <span>{format(new Date(item.created_at), "dd MMMM Y k:m:s")}</span>

                        </DataTableRow>
                        <DataTableRow>
                          <span
                            className={`tb-status text-${
                              item.status.description === "Active" ? "success" : item.status.description === "Pending" ? "warning" : "danger"
                            }`}
                          >
                            {item.status.description}
                          </span>
                        </DataTableRow>
                        <DataTableRow className="nk-tb-col-tools">
                          <ul className="nk-tb-actions gx-1">
                            {/* <li className="nk-tb-action-hidden" onClick={() => onEditClick(item.id)}>
                              <TooltipComponent
                                tag="a"
                                containerClassName="btn btn-trigger btn-icon"
                                id={"edit" + item.id}
                                icon="edit-alt-fill"
                                direction="top"
                                text={t("modify", {ns: 'global'})}
                              />
                            </li> */}
                            {(
                              <React.Fragment>
                                <li className="nk-tb-action-hidden" onClick={() => {
                                loadDetail(item.id);
                                setidEmpresa(item.id)
                                toggleModalDetail();
                              }}>
                                  <TooltipComponent
                                    tag="a"
                                    containerClassName="btn btn-trigger btn-icon"
                                    id={"suspend" + item.id }
                                    icon="eye"
                                    direction="top"
                                    text={t("details", {ns: 'global'})}
                                  />
                                </li>
                              </React.Fragment>

                            )}
                            <li>
                              <UncontrolledDropdown>
                                <DropdownToggle tag="a" className="dropdown-toggle btn btn-icon btn-trigger">
                                  <Icon name="more-h"></Icon>
                                </DropdownToggle>
                                <DropdownMenu right>
                                  <ul className="link-list-opt no-bdr">
                                    {/* <li onClick={() => onEditClick(item.id)}>
                                      <DropdownItem
                                        tag="a"
                                        href="#edit"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                        }}
                                      >
                                        <Icon name="edit"></Icon>
                                        <span>{t("modify", {ns: 'global'})}</span>
                                      </DropdownItem>
                                    </li> */}
                                    <li onClick={() => {
                                loadDetail(item);
                                toggleModalDetail();
                              }}>
                                      <DropdownItem
                                        tag="a"

                                      >
                                        <Icon name="eye"></Icon>
                                        <span>{t("details", {ns: 'global'})}</span>
                                      </DropdownItem>
                                    </li>
                                    {item.status.description !== "Suspend" && (
                                      <React.Fragment>
                                        <li className="divider"></li>
                                        <li >
                                          <DropdownItem
                                            tag="a"
                                            href="#suspend"
                                            onClick={() => {
                                              handleSwitchChange(
                                                item,
                                                item.status.id,
                                              );
                                            }}
                                          >
                                            <Icon name="na"></Icon>
                                            <span>{t("activate/desactivate", {ns: 'global'})}</span>
                                          </DropdownItem>
                                        </li>
                                      </React.Fragment>
                                    )}
                                  </ul>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </li>
                          </ul>
                        </DataTableRow>
                      </DataTableItem>
                    );
                  })
                : null}
            </DataTableBody>
            <div className="card-inner">
              {currentItems.length > 0 ? (
                <PaginationComponent
                  itemPerPage={itemPerPage}
                  totalItems={data.length}
                  paginate={paginate}
                  currentPage={currentPage}
                />
              ) : (
                <div className="text-center">
                  <span className="text-silent">No data found</span>
                </div>
              )}
            </div>
          </DataTable>
        </Block>

        {/* <Modal isOpen={modal.edit} toggle={() => setModal({ edit: false })} className="modal-dialog-centered" size="lg">
          <ModalBody>
            <a
              href="#cancel"
              onClick={(ev) => {
                ev.preventDefault();
                onFormCancel();
              }}
              className="close"
            >
              <Icon name="cross-sm"></Icon>
            </a>
            <div className="p-2">
              <h5 className="title">Update Company</h5>
              <div className="mt-4">
                <Form className="row gy-4" onSubmit={handleSubmit(onEditSubmit)}>
                  <Col md="6">
                    <FormGroup>
                      <label className="form-label">Company</label>
                      <input
                        className="form-control"
                        type="text"
                        name="name"
                        defaultValue={formData.name}
                        placeholder="Enter name"
                        ref={register({ required: "This field is required" })}
                      />
                      {errors.name && <span className="invalid">{errors.name.message}</span>}
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <label className="form-label">Identification</label>
                      <input
                        className="form-control"
                        type="text"
                        name="name"
                        defaultValue={formData.name}
                        placeholder="Enter name"
                        ref={register({ required: "This field is required" })}
                      />
                      {errors.name && <span className="invalid">{errors.name.message}</span>}
                    </FormGroup>
                  </Col>
                  <Col md="6">
                  <FormGroup>
                      <label className="form-label">Email </label>
                      <input
                        className="form-control"
                        type="text"
                        name="email"
                        defaultValue={formData.email}
                        placeholder="Enter email"
                        ref={register({
                          required: "This field is required",
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: "invalid email address",
                          },
                        })}
                      />
                      {errors.email && <span className="invalid">{errors.email.message}</span>}
                    </FormGroup>

                  </Col>
                  <Col md="6">
                  <FormGroup>
                      <label className="form-label">Phone Number</label>
                      <input
                        className="form-control"
                        type="text"
                        name="name"
                        defaultValue={formData.name}
                        placeholder="Enter name"
                        ref={register({ required: "This field is required" })}
                      />
                      {errors.name && <span className="invalid">{errors.name.message}</span>}
                    </FormGroup>

                  </Col>
                  <Col md="12">
                  <FormGroup>
                      <label className="form-label">Description</label>
                      <textarea
                        className="form-control"
                        name="description"
                        defaultValue={formData.name}
                        placeholder="Description"
                        ref={register({ required: "This field is required" })}
                      />
                      {errors.name && <span className="invalid">{errors.name.message}</span>}
                    </FormGroup>

                  </Col>
                  <Col md="12">
                    <FormGroup>
                      <label className="form-label">Status</label>
                      <div className="form-control-wrap">
                        <RSelect
                          options={filterStatus}
                          defaultValue={{
                            value: formData.status,
                            label: formData.status,
                          }}
                          onChange={(e) => setFormData({ ...formData, status: e.value })}
                        />
                      </div>
                    </FormGroup>
                  </Col>
                  <Col size="12">
                    <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                      <li>
                        <Button color="primary" size="md" type="submit">
                          Update Company
                        </Button>
                      </li>
                      <li>
                        <a
                          href="#cancel"
                          onClick={(ev) => {
                            ev.preventDefault();
                            onFormCancel();
                          }}
                          className="link link-light"
                        >
                          Cancel
                        </a>
                      </li>
                    </ul>
                  </Col>
                </Form>
              </div>
            </div>
          </ModalBody>
        </Modal> */}
        <Modal isOpen={modalDetail} toggle={() => toggleModalDetail()} className="modal-dialog-centered" size="lg">
          <ModalBody>
            <a
              href="#cancel"
              onClick={(ev) => {
                ev.preventDefault();
                toggleModalDetail();
              }}
              className="close"
            >
              <Icon name="cross-sm"></Icon>
            </a>
            <div className="nk-modal-head mb-3">
              <h4 className="nk-modal-title title">
              {t("lbl33")} <small className="text-primary"></small>
              </h4>
            </div>
            <div className="nk-tnx-details">
              <BlockBetween className="flex-wrap g-3">
                <div className="nk-tnx-type">
                  <div
                    className={`nk-tnx-type-icon text-white`}
                  >
                    <UserAvatar image={detail.logo}>
                    </UserAvatar>

                    {/* <Icon name="arrow-up-right"></Icon> */}
                  </div>
                  <div className="nk-tnx-type-text">
                    <h5 className="title">{detail.name}</h5>
                    <span className="sub-text mt-n1">{t("lbl5")}:{detail.status != undefined ? detail.status.description : ''} </span>
                  </div>

                </div>
                <ul className="align-center flex-wrap gx-3">
                  <li>
                  <button
                                      class="btn buttonAzulModal"
                                      type="button"
                                      id="dropdownMenuButton2"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="false"
                                      onClick={()=>{listChat()}}
                                    >
                                      {" "}
                                      {/* <span>
                                        <img
                                          className="img-fluid "
                                          width="12px"
                                          height="12px"
                                          src={listplas}
                                        />{" "}
                                      </span>{" "} */}
                                      {t("lbl10")}


                    </button>
                  </li>
                  <li>
                  <button
                        class="btn buttonAzulModal"
                        type="button"
                        onClick={() => {downloaddocgettoken()}}
                      >
                        {t("lbl13")}
                      </button>
                    {/* <Badge
                      color={
                        detail.status === "Completed"
                          ? "success"
                          : detail.status === "Upcoming"
                          ? "warning"
                          : detail.status === "Pending"
                          ? "info"
                          : "danger"
                      }
                      size="sm"
                    >
                      {detail.status}
                    </Badge> */}
                  </li>
                </ul>
              </BlockBetween>








              <div className="nk-modal-head mt-4 mb-3">
                <h5 className="title">{t("lbl32")}</h5>
              </div>
              <Row className="gy-3">
                <Col lg={6}>
                  <span className="sub-text">{t("tbl5")}</span>
                  <span className="caption-text">{detail.nit}</span>
                </Col>
                <Col lg={6}>
                  <span className="sub-text">{t("tbl6")}</span>
                  <span className="caption-text text-break">{detail.name}</span>
                </Col>
                <Col lg={12}>
                  <span className="sub-text">{t("tbl2")}</span>
                  <span className="caption-text text-break">{detail.description}</span>
                </Col>
                <Col lg={6}>
                  <span className="sub-text">{t("tbl13")}</span>
                  <span className="caption-text"></span>
                  {/* <span className="caption-text">{detail.legal_representative != undefined ? detail.legal_representative[0].email : ''}</span> */}
                </Col>
                <Col lg={6}>
                  <span className="sub-text">{t("tbl3")}</span>
                  <span className="caption-text">{detail.phone_number}</span>
                </Col>
                <Col lg={6}>
                  <span className="sub-text">{t("tbl7")}</span>
                  <span className="caption-text">{detail.address}</span>
                </Col>
                <Col lg={6}>
                  <span className="sub-text">{t("tbl9")}</span>
                  <span className="caption-text text-break">{detail.department != undefined ? detail.department.name : ''}</span>
                </Col>
                <Col lg={6}>
                  <span className="sub-text">{t("tbl10")}</span>
                  <span className="caption-text">{detail.postal_code != undefined ? detail.postal_code : ''}</span>
                </Col>
                <Col lg={6}>
                  <span className="sub-text">{t("tbl8")}</span>
                  <span className="caption-text text-break">{detail.country != undefined ? detail.country.name : ''}</span>
                </Col>

              </Row>
              <div className="nk-modal-head mt-4 mb-3">
                <h5 className="title">{t("lbl31")}</h5>
              </div>
              <Row className="gy-3">
                <Col lg={6}>
                  <span className="sub-text">{t("ttl2")}</span>
                  <span className="caption-text">{detail.user != undefined ? detail.user.name : ''}</span>
                </Col>
                <Col lg={6}>
                  <span className="sub-text">{t("lbl30")}</span>
                  <span className="caption-text align-center">{detail.user != undefined ? detail.user.email : ''}

                    {/* <Badge color="primary" className="ml-2 text-white">
                      Online Gateway
                    </Badge> */}
                  </span>
                </Col>

              </Row>
            </div>


            <div
                class="dropdown-menu fondoAzulModal"
                aria-labelledby="themes"
              >
                                      <div class="container">
                                        <div class="form-group mb-1 text-center ">
                                          <img
                                            className=" mt-2 img-fluid"
                                            width="20px"
                                            height="20px"
                                            src={userimg}
                                          />
                                        </div>
                                        <div class="form-group mb-2 text-center ">
                                          {t("lbl11")}
                                        </div>
                                      </div>

                                      <div className="linea lineamovil"></div>
                                      <div class=" fondoBlancoModal fondoBlancoModalMovil" >
                                        <div className="anyClass anyClassMovil" id="topabajo">
                                          {comentarios.map((i) => {
                                            if (i.type == 1) {
                                              return (
                                                <>
                                                  <div class="form-group mb-2 text-left mt-2 ">
                                                    <div class="row  my-1 ">
                                                      <div class="col-2 col-sm-2 col-md-2 ">
                                                        <img
                                                          className=" mt-2 img-fluid ingMpvilchat"
                                                          width="50px"
                                                          height="50px"
                                                          src={userchat}
                                                        />
                                                      </div>

                                                      <div class=" col-5 col-sm-5 col-md-5 text-left mt-1 globo">
                                                        <div className="txtSmallGris">
                                                        {i.name}
                                                        </div>

                                                        <p className="text-left"> {i.comment} </p>
                                                        <div class="txtSmallGris"> {i.created_at} </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </>
                                              );
                                            } else {
                                              return (
                                                <>
                                                <div class="form-group mb-2  mt-2 ">
                                                    <div class="row  my-1   justify-content-end    ">
                                                      <div class=" col-5 col-sm-5 col-md-5 text-end mt-1 globo">
                                                        <div className="mt-2 txtSmallGris">
                                                        {i.compname}
                                                        </div>

                                                        <p className="">
                                                        {i.comment}
                                                        </p>
                                                        <div class="txtSmallGris"> {i.created_at} </div>
                                                      </div>

                                                      <div class="col-2 col-sm-2 col-md-2 ">
                                                        <img
                                                          className=" mt-2 img-fluid ingMpvilchat"
                                                          width="50px"
                                                          height="50px"
                                                          src={userchat}
                                                        />
                                                      </div>
                                                    </div>
                                                  </div>
                                                </>
                                              );
                                            }
                                          })}
                                        </div>
                                      </div>

                                      <div class="container">
                                        <div class="row  my-1 ">
                                          <div class="col-10 ">
                                            <input
                                              id="mensaje"
                                              type="text"
                                              name="mensaje"
                                              className="mt-1 form-control imputTextModal"
                                              aria-describedby="emailHelp"
                                              placeholder={t("lbl12")}
                                            />
                                          </div>

                                          <div class="col-2 text-left ">
                                            <a
                                              href="javascript:void(0)"
                                              onClick={() => {
                                                send();
                                              }}
                                            >
                                              <img
                                                className=" mt-2 img-fluid"
                                                width="20px"
                                                height="20px"
                                                src={sendimg}
                                              />
                                            </a>
                                          </div>
                                        </div>
                                      </div>




                      </div>




          </ModalBody>
        </Modal>
      </Content>
    </React.Fragment>
  );
};
export default CompaniesListCompact;

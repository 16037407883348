import React from "react";
import Logo from "../../images/logo_hrh.png";
import LogoDark from "../../images/logo_hrh.png";
import PageContainer from "../../layout/page-container/PageContainer";
import Head from "../../layout/head/Head";
import AuthFooter from "./AuthFooter";
import { Block, BlockContent, BlockDes, BlockHead, BlockTitle, Button, PreviewCard } from "../../components/Component";
import { FormGroup } from "reactstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";
import * as Yup from "yup";
import { Formik, Field, Form, ErrorMessage } from "formik";
import UserProfile from "../../Login/UserProfile";
import Swal from "sweetalert2";

const ForgotPassword = (props) => {
  const [t, i18n] = useTranslation("login");

  const {
    handleSubmit,
    isSubmitting,
    handleChange,
    handleBlur,
    values,
    errors,
    touched,
    resetForm,
  } = props;

const sendCode = (values) => {
    values.lng = localStorage.getItem('lngselect')
      axios.post(`${process.env.REACT_APP_BASE_URL}/api/auth/forgot_password`,values)
      .then(res => {

        if(res.data.status==true){
          Swal.fire({
              icon: "success",
              title: t("msg7"),
              text: res.data.data.message,
            });

            UserProfile.setEmail(values.email);
            props.history.push('/changepassword')
        }else{
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: t("msg7") ,
          });
        }
      })
  }

  return (

    <React.Fragment>
      <Head title="Forgot-Password" />
      <PageContainer>
        <Block className="nk-block-middle nk-auth-body  wide-xs">
          <div className="brand-logo pb-4 text-center">
            <Link to={process.env.PUBLIC_URL + "/"} className="logo-link">
              <img className="logo-light logo-img logo-img-lg" src={Logo} alt="logo" />
              <img className="logo-dark logo-img logo-img-lg" src={LogoDark} alt="logo-dark" />
            </Link>
          </div>
          <PreviewCard className="card-bordered" bodyClass="card-inner-lg">
            <BlockHead>
              <BlockContent>
                <BlockTitle tag="h5">{t("lbl35")}</BlockTitle>
                <BlockDes>
                  <p>{t("ttl5")}</p>
                </BlockDes>
              </BlockContent>
            </BlockHead>

            {/* Old     */}
          <Formik
            initialValues={{
              email: "",
            }}

            validationSchema={Yup.object().shape({
              email: Yup.string().required(t("fr"))
            })}

            validate = {(values) => {
              const errors = {};
              if (!values.email) {
                  errors.email = t("msg6");
                } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
                  errors.email = t("msg6");
                }
              return errors;
            }}

            onSubmit={(val, { resetForm }) => {
              val.status = 2
              sendCode(val, resetForm);
            }}
          >

{({handleSubmit}) => (
                <Form onSubmit={handleSubmit}>
                  <FormGroup>
                 <div className="form-label-group">
                  <label className="form-label" htmlFor="default-01">
                  {t("lbl2")}
                  </label>
                </div>
                    <Field
                      id="email"
                      name="email"
                      type="email"
                      class="form-control form-control-lg"
                      aria-describedby="emailHelp"
                      placeholder={t("lbl11")}
                    />

                      <ErrorMessage name="email">
                          {(message) => <div class="msgerror">{message}</div>}
                      </ErrorMessage>
                  </FormGroup>
                  <FormGroup>
                <Button type="submit" color="primary" size="lg" className="btn-block"  disabled={isSubmitting}
                        >
                {t("lbl36")}
                </Button>
              </FormGroup>


                  {/* <div class="divider d-flex align-items-center my-2">
                    <button
                        class="btn btn-warning  btn-block  buttons mx-2 "
                        disabled={isSubmitting}
                        onClick={()=>props.history.push('/changepassword')}
                    >
                    {t("lbl12")}
                    </button>

                    <button
                        type="submit"
                        class="btn btn-warning  btn-block  buttons mx-2 "
                        disabled={isSubmitting}
                    >
                    {t("lbl13")}
                    </button>
                  </div> */}
                  <div className="form-note-s2 text-center pt-4">
              <Link to={`${process.env.PUBLIC_URL}/auth-login`}>
                <strong> {t("lbl37")}</strong>
              </Link>
            </div>
              </Form>


            )}
          </Formik>

            {/* <form>
              <FormGroup>
                <div className="form-label-group">
                  <label className="form-label" htmlFor="default-01">
                  {t("lbl2")}
                  </label>
                </div>
                <input
                  type="text"
                  className="form-control form-control-lg"
                  id="default-01"
                  placeholder={t("txt3")}
                />
              </FormGroup>
              <FormGroup>
                <Button color="primary" size="lg" className="btn-block" onClick={(ev) => ev.preventDefault()}>
                {t("lbl36")}
                </Button>
              </FormGroup>
            </form>
            <div className="form-note-s2 text-center pt-4">
              <Link to={`${process.env.PUBLIC_URL}/auth-login`}>
                <strong> {t("lbl37")}</strong>
              </Link>
            </div> */}
          </PreviewCard>
        </Block>
        <AuthFooter />
      </PageContainer>
    </React.Fragment>
  );
};
export default ForgotPassword;

import React, { Component, useState, } from "react";
import { useTranslation } from "react-i18next";

// import logoico from "../img/logohrh.png";
import facebook from "../img/facebook.png"
// import instagram from "../img/instagram.png"
// import youtube from "../img/youtube.png"
import linkedin from "../img/linkedinicon.png"

const Footer = (props) => {

  const [t, i18n] = useTranslation("footer")

    return (
      <>
        <div className="container-fluid contentFoteer pt-2">
          <div className="row text-white px-5" >
            <div className="col-12 col-sm-12 col-md-5 mb-5">
             <div className="row">
                <ul>
                <li> <span className="boldtxt" > {t("permise")} </span> </li>
                </ul>
              </div>
              <br/>
              {/* <img src={logoico} alt="" width="200"   className="img-fluid mx-2"/> */}
              <div className="row">
                <span className="boldtxt" > {t("lbl3")} </span>
              </div>

              <div className="row text-white mb-4" >
                 <div className="col-md-1 col-sm-1">
                  <a href={process.env.REACT_APP_FACEBOOK_URL} target="_blank" >
                    <img src={facebook} alt="" width="20" height="20"  className="img-fluid" />
                  </a>
                </div>
                {/*<div className="col-md-1 col-sm-1">
                  <a href={process.env.REACT_APP_INSTAGRAM_URL} target="_blank" >
                    <img src={instagram} alt="" width="20" height="20"  className="img-fluid "/>
                  </a>
                </div>
                <div className="col-md-1 col-sm-1">
                  <a href={process.env.REACT_APP_YOUTUBE_URL} target="_blank" >
                    <img src={youtube} alt="" width="20" height="20"  className="img-fluid "/>
                  </a>
                </div> */}
                <div className="col-md-1 col-sm-1">
                  <a href={process.env.REACT_APP_LINKEDIN_URL} target="_blank" >
                    <img src={linkedin} alt="" width="20" height="20"  className="img-fluid "/>
                  </a>
                </div>
              </div>

              <div className="row">

                <ul>

                  {
                    localStorage.getItem('userrol') == 2 ?
                      <>
                        <li> <span className="boldtxt" > {t("lbl4")} </span> </li>
                        {/* <li> {process.env.REACT_APP_TELEFONOUNO} </li> */}
                        <li> {process.env.REACT_APP_TELEFONODOS} </li>
                        <li> {process.env.REACT_APP_EMAILCONTACT} </li>
                      </>
                    :
                      <>
                        <li> <span className="boldtxt" > {t("lbl4")} </span> </li>
                        {/* <li> {process.env.REACT_APP_TELEFONOUNO_COMPANY} </li> */}
                        <li> {process.env.REACT_APP_TELEFONODOS_COMPANY} </li>
                        <li> {process.env.REACT_APP_EMAILCONTACT_COMPANY} </li>
                      </>
                  }
                </ul>
              </div>

              {/* <div className="row align-items-end" >
                <div className="col" >
                  {t("lbl5")}
                </div>
              </div> */}
            </div>

            <div className="col-md-4 mb-4" >
              {/* <ul>
                {
                  localStorage.getItem('userrol') == 2 ?
                    <>
                      <li> <span className="boldtxt" > {t("lbl4")} </span> </li>
                      <li> {process.env.REACT_APP_TELEFONOUNO} </li>
                      <li> {process.env.REACT_APP_TELEFONODOS} </li>
                      <li> {process.env.REACT_APP_EMAILCONTACT} </li>
                    </>
                  :
                    <>
                      <li> <span className="boldtxt" > {t("lbl4")} </span> </li>
                      <li> {process.env.REACT_APP_TELEFONOUNO_COMPANY} </li>
                      <li> {process.env.REACT_APP_TELEFONODOS_COMPANY} </li>
                      <li> {process.env.REACT_APP_EMAILCONTACT_COMPANY} </li>
                    </>
                }
              </ul> */}
            </div>

            <div className="col-md-3" >
              <ul>
                <li className="">
                  <a
                    href="javascript:void(0)"
                    className={
                      props.opc === "2"
                        ? "   nav-link active fuentesimple "
                        : "   nav-link  fuentesimple "
                    }
                    onClick={() => {
                      props.changeOpc({opc : "2"});
                    }}
                  >
                    {t("aboutus")}
                  </a>
                </li>

                <li className="">
                  <a
                    className={
                      props.opc === "4"
                        ? "   nav-link active fuentesimple "
                        : "   nav-link fuentesimple "
                    }
                    href="javascript:void(0)"
                    onClick={() => {
                      props.changeOpc({opc : "4"});
                    }}
                  >
                    {t("mandv")}
                  </a>
                </li>

                <li className="">
                  <a
                    className={
                      props.opc === ""
                        ? "   nav-link active fuentesimple "
                        : "   nav-link fuentesimple "
                    }
                    href="javascript:void(0)"
                    onClick={() => {
                      window.open(process.env.REACT_APP_DOC_TERM_COND, '_blank')
                    }}
                  >
                    {t("lbl1")}
                  </a>
                </li>

                <li className="">
                  <a
                    className={
                      props.opc === ""
                        ? "   nav-link active fuentesimple "
                        : "   nav-link fuentesimple "
                    }
                    href="javascript:void(0)"
                    onClick={() => {
                      window.open(process.env.REACT_APP_DOC_PRIV_POLC, '_blank')
                    }}
                  >
                    {t("lbl2")}
                  </a>
                </li>

                {/* <li className=" px-3"></li> */}
              </ul>
            </div>
          </div>

          <div className="row text-white text-center">
            <div className="col-md-12 col-sm-12 col-lg-12">
              <p className="txtPequeno"> {t("lbl5")} </p>
            </div>
          </div>

        </div>
        {/* </div> */}
      </>
    );
}

export default Footer;

import React from "react";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
// import { Link } from "react-router-dom";
// import { Block, BlockContent, Button } from "../../components/Component";
import imgconstruction from "../../images/page-under-construction-1.jpg";

const UnderConstructionPage = ({ ...props }) => {
  return (
    <React.Fragment>
      <Head title="Page Under Construction" />
      <Content className="nk-block-middle wide-md mx-auto">
        <img src={imgconstruction} alt="Under Construction" className="center"/>
            {/* <Link to={`${process.env.PUBLIC_URL}/`}>
              <Button color="primary" size="lg" className="mt-2">
                Back To Home
              </Button>
            </Link> */}
      </Content>
    </React.Fragment>
  );
};

export default UnderConstructionPage;

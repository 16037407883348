import React, { useState, useEffect, useContext } from "react";
// Necesary to consume API
import axios from "axios";
import UserProfile from "../../../Login/UserProfile";

import User from "../../../images/avatar/b-sm.jpg";
import User2 from "../../../images/avatar/c-sm.jpg";
import User3 from "../../../images/avatar/a-sm.jpg";
import User4 from "../../../images/avatar/d-sm.jpg";



export const countryOptions =
[
  {
    "value": 1,
    "label": "Afghanistan",
    "label_es": "Afganistán",
    "label_fr": "Afghanistan",
    "status": 1
  },
  {
    "value": 2,
    "label": "Albania",
    "label_es": "Albania",
    "label_fr": "Albanie",
    "status": 1
  },
  {
    "value": 3,
    "label": "Algeria",
    "label_es": "Argelia",
    "label_fr": "Algerie",
    "status": 1
  },
  {
    "value": 6,
    "label": "Andorra",
    "label_es": "Andorra",
    "label_fr": "Andorre",
    "status": 1
  },
  {
    "value": 7,
    "label": "Angola",
    "label_es": "Angola",
    "label_fr": "Angola",
    "status": 1
  },
  {
    "value": 8,
    "label": "Argentina",
    "label_es": "Argentina",
    "label_fr": "Argentine",
    "status": 1
  },
  {
    "value": 9,
    "label": "Armenia",
    "label_es": "Armenia",
    "label_fr": "Arménie",
    "status": 1
  },
  {
    "value": 10,
    "label": "Australia",
    "label_es": "Australia",
    "label_fr": "Australie",
    "status": 1
  },
  {
    "value": 11,
    "label": "Austria",
    "label_es": "Austria",
    "label_fr": "Autriche",
    "status": 1
  },
  {
    "value": 12,
    "label": "Azawad",
    "label_es": "Azawad",
    "label_fr": "Azawad",
    "status": 1
  },
  {
    "value": 13,
    "label": "Azerbaijan",
    "label_es": "Azerbaiyán",
    "label_fr": "Azerbaijan",
    "status": 1
  },
  {
    "value": 14,
    "label": "Bahamas",
    "label_es": "Bahamas",
    "label_fr": "Bahamas",
    "status": 1
  },
  {
    "value": 15,
    "label": "Bahrain",
    "label_es": "Baréin",
    "label_fr": "Bahrein",
    "status": 1
  },
  {
    "value": 16,
    "label": "Bangladesh",
    "label_es": "Bangladesh",
    "label_fr": "Bangladesh",
    "status": 1
  },
  {
    "value": 17,
    "label": "Barbados",
    "label_es": "Barbados",
    "label_fr": "Barbade",
    "status": 1
  },
  {
    "value": 18,
    "label": "Belarus",
    "label_es": "Bielorrusia",
    "label_fr": "Biélorussie",
    "status": 1
  },
  {
    "value": 19,
    "label": "Belgium",
    "label_es": "Béligica",
    "label_fr": "Belgique",
    "status": 1
  },
  {
    "value": 20,
    "label": "Belize",
    "label_es": "Belice",
    "label_fr": "Belize",
    "status": 1
  },
  {
    "value": 21,
    "label": "Benign",
    "label_es": "Benigno",
    "label_fr": "Bénin",
    "status": 1
  },
  {
    "value": 22,
    "label": "Bhutan",
    "label_es": "Bután",
    "label_fr": "Bhoutan",
    "status": 1
  },
  {
    "value": 23,
    "label": "Bolivia",
    "label_es": "Bolivia",
    "label_fr": "Bolivie",
    "status": 1
  },
  {
    "value": 24,
    "label": "Botswana",
    "label_es": "Botsuana",
    "label_fr": "Botswana",
    "status": 1
  },
  {
    "value": 25,
    "label": "Brazil",
    "label_es": "Brasil",
    "label_fr": "Brésil",
    "status": 1
  },
  {
    "value": 26,
    "label": "Brunei",
    "label_es": "Brunéi",
    "label_fr": "Brunei",
    "status": 1
  },
  {
    "value": 27,
    "label": "Bulgaria",
    "label_es": "Bulgaria",
    "label_fr": "Bulgarie",
    "status": 1
  },
  {
    "value": 28,
    "label": "Burkina Faso",
    "label_es": "Burkina Faso",
    "label_fr": "Burkina Faso",
    "status": 1
  },
  {
    "value": 29,
    "label": "Burma",
    "label_es": "Birmania",
    "label_fr": "Birmanie",
    "status": 1
  },
  {
    "value": 30,
    "label": "Burundi",
    "label_es": "Burundi",
    "label_fr": "Burundi",
    "status": 1
  },
  {
    "value": 31,
    "label": "Cambodia",
    "label_es": "Camboya",
    "label_fr": "Cambodge",
    "status": 1
  },
  {
    "value": 32,
    "label": "Cameroon",
    "label_es": "Camerún",
    "label_fr": "Cameroun",
    "status": 1
  },
  {
    "value": 33,
    "label": "Canada",
    "label_es": "Canadá",
    "label_fr": "Canada",
    "status": 1
  },
  {
    "value": 34,
    "label": "Central African Republic",
    "label_es": "República Centroafricana",
    "label_fr": "République Centrafricaine",
    "status": 1
  },
  {
    "value": 35,
    "label": "Chad",
    "label_es": "Chad",
    "label_fr": "Tchad",
    "status": 1
  },
  {
    "value": 36,
    "label": "Chile",
    "label_es": "Chile",
    "label_fr": "Chili",
    "status": 1
  },
  {
    "value": 37,
    "label": "China",
    "label_es": "China",
    "label_fr": "Chine",
    "status": 1
  },
  {
    "value": 38,
    "label": "Colombia",
    "label_es": "Colombia",
    "label_fr": "Colombie",
    "status": 1
  },
  {
    "value": 39,
    "label": "Comoros",
    "label_es": "Comoras",
    "label_fr": "Comors",
    "status": 1
  },
  {
    "value": 40,
    "label": "Costa Rica",
    "label_es": "Costa Rica",
    "label_fr": "Costa Rica",
    "status": 1
  },
  {
    "value": 41,
    "label": "Croatia",
    "label_es": "Croacia",
    "label_fr": "Croatie",
    "status": 1
  },
  {
    "value": 42,
    "label": "Cuba",
    "label_es": "Cuba",
    "label_fr": "Cuba",
    "status": 1
  },
  {
    "value": 43,
    "label": "Cyprus",
    "label_es": "Chipre",
    "label_fr": "Chypre",
    "status": 1
  },
  {
    "value": 44,
    "label": "Czech Republic",
    "label_es": "República Checa",
    "label_fr": "République tchèque",
    "status": 1
  },
  {
    "value": 45,
    "label": "Democratic Republic of Congo",
    "label_es": "República Democrática del Congo",
    "label_fr": "République démocratique du Congo",
    "status": 1
  },
  {
    "value": 46,
    "label": "Denmark",
    "label_es": "Dinamarca",
    "label_fr": "Danemark",
    "status": 1
  },
  {
    "value": 47,
    "label": "Djibouti",
    "label_es": "Yibuti",
    "label_fr": "Djibouti",
    "status": 1
  },
  {
    "value": 48,
    "label": "Dominica",
    "label_es": "Dominica",
    "label_fr": "Dominique",
    "status": 1
  },
  {
    "value": 49,
    "label": "Dominican Republic",
    "label_es": "República Dominicana",
    "label_fr": "République Dominicaine",
    "status": 1
  },
  {
    "value": 50,
    "label": "East Timor",
    "label_es": "Timor Oriental",
    "label_fr": "Timor Oriental",
    "status": 1
  },
  {
    "value": 51,
    "label": "Ecuador",
    "label_es": "Ecuador",
    "label_fr": "Équateur",
    "status": 1
  },
  {
    "value": 52,
    "label": "Egypt",
    "label_es": "Egipto",
    "label_fr": "Égypte",
    "status": 1
  },
  {
    "value": 53,
    "label": "Equatorial guinea",
    "label_es": "Guinea Ecuatorial",
    "label_fr": "Guinée-équatorial",
    "status": 1
  },
  {
    "value": 54,
    "label": "Eritrea",
    "label_es": "Eritrea",
    "label_fr": "Érythrée",
    "status": 1
  },
  {
    "value": 55,
    "label": "Estonia",
    "label_es": "Estonia",
    "label_fr": "Estonie",
    "status": 1
  },
  {
    "value": 56,
    "label": "Eswatini",
    "label_es": "Esuatini",
    "label_fr": "Eswatini",
    "status": 1
  },
  {
    "value": 57,
    "label": "Ethiopia",
    "label_es": "Etiopía",
    "label_fr": "Éthiopie",
    "status": 1
  },
  {
    "value": 58,
    "label": "Fiji",
    "label_es": "Fiyi",
    "label_fr": "Fvalueji",
    "status": 1
  },
  {
    "value": 59,
    "label": "Finland",
    "label_es": "Finlandia",
    "label_fr": "Finlande",
    "status": 1
  },
  {
    "value": 60,
    "label": "France",
    "label_es": "Francia",
    "label_fr": "France",
    "status": 1
  },
  {
    "value": 61,
    "label": "Gabon",
    "label_es": "Gabón",
    "label_fr": "Gabon",
    "status": 1
  },
  {
    "value": 63,
    "label": "Gambia",
    "label_es": "Gambia",
    "label_fr": "Gambie",
    "status": 1
  },
  {
    "value": 64,
    "label": "Georgia",
    "label_es": "Georgia",
    "label_fr": "Georgie",
    "status": 1
  },
  {
    "value": 65,
    "label": "Germany",
    "label_es": "Alemania",
    "label_fr": "Allemagne",
    "status": 1
  },
  {
    "value": 66,
    "label": "Ghana",
    "label_es": "Ghana",
    "label_fr": "Ghana",
    "status": 1
  },
  {
    "value": 67,
    "label": "Greece",
    "label_es": "Grecia",
    "label_fr": "Grèce",
    "status": 1
  },
  {
    "value": 68,
    "label": "Green Cap",
    "label_es": "Gorra verde",
    "label_fr": "Cap-Vert",
    "status": 1
  },
  {
    "value": 69,
    "label": "Grenade",
    "label_es": "Granada",
    "label_fr": "Grenade",
    "status": 1
  },
  {
    "value": 70,
    "label": "Guatamala",
    "label_es": "Guatamala",
    "label_fr": "Guatamala",
    "status": 1
  },
  {
    "value": 71,
    "label": "Guinea",
    "label_es": "Guinea",
    "label_fr": "Guinée",
    "status": 1
  },
  {
    "value": 72,
    "label": "Guine Bissau",
    "label_es": "Guinea-Bissau",
    "label_fr": "Guinée Bissau",
    "status": 1
  },
  {
    "value": 73,
    "label": "Guyana",
    "label_es": "Guayana",
    "label_fr": "Guyana",
    "status": 1
  },
  {
    "value": 74,
    "label": "Haiti",
    "label_es": "Haití",
    "label_fr": "Haiti",
    "status": 1
  },
  {
    "value": 75,
    "label": "Honduras",
    "label_es": "Honduras",
    "label_fr": "Honduras",
    "status": 1
  },
  {
    "value": 76,
    "label": "Hungary",
    "label_es": "Hungría",
    "label_fr": "Hongrie",
    "status": 1
  },
  {
    "value": 77,
    "label": "Iceland",
    "label_es": "Islandia",
    "label_fr": "Islande",
    "status": 1
  },
  {
    "value": 78,
    "label": "India",
    "label_es": "India",
    "label_fr": "Inde",
    "status": 1
  },
  {
    "value": 79,
    "label": "Indonesia",
    "label_es": "Indonesia",
    "label_fr": "Indonésie",
    "status": 1
  },
  {
    "value": 80,
    "label": "Iran",
    "label_es": "Irán",
    "label_fr": "Iran",
    "status": 1
  },
  {
    "value": 81,
    "label": "Irak",
    "label_es": "Irak",
    "label_fr": "Iraq",
    "status": 1
  },
  {
    "value": 82,
    "label": "Ireland",
    "label_es": "Irelanda",
    "label_fr": "Irlande",
    "status": 1
  },
  {
    "value": 83,
    "label": "Israel",
    "label_es": "Israel",
    "label_fr": "Israel",
    "status": 1
  },
  {
    "value": 84,
    "label": "Italy",
    "label_es": "Italia",
    "label_fr": "Italie",
    "status": 1
  },
  {
    "value": 85,
    "label": "Ivory Coast",
    "label_es": "Costa de Marfil",
    "label_fr": "Côte d'Ivoire",
    "status": 1
  },
  {
    "value": 86,
    "label": "Jamaica",
    "label_es": "Jamaica",
    "label_fr": "Jamaique",
    "status": 1
  },
  {
    "value": 87,
    "label": "Jordan",
    "label_es": "Jordán",
    "label_fr": "Jordanie",
    "status": 1
  },
  {
    "value": 88,
    "label": "Kazakhstan",
    "label_es": "Kazajstán",
    "label_fr": "Kazakhstan",
    "status": 1
  },
  {
    "value": 89,
    "label": "Kenya",
    "label_es": "Kenia",
    "label_fr": "Kenya",
    "status": 1
  },
  {
    "value": 90,
    "label": "Kiribati",
    "label_es": "Kiribati",
    "label_fr": "Kiribati",
    "status": 1
  },
  {
    "value": 91,
    "label": "Kuwait",
    "label_es": "Kuwait",
    "label_fr": "Kuwait",
    "status": 1
  },
  {
    "value": 92,
    "label": "Kyrgyzstan",
    "label_es": "Kirguistán",
    "label_fr": "Kirgizistan",
    "status": 1
  },
  {
    "value": 93,
    "label": "Laos",
    "label_es": "Laos",
    "label_fr": "Laos",
    "status": 1
  },
  {
    "value": 94,
    "label": "Latvia",
    "label_es": "Letonia",
    "label_fr": "Lettonie",
    "status": 1
  },
  {
    "value": 95,
    "label": "Lebanon",
    "label_es": "Líbano",
    "label_fr": "Liban",
    "status": 1
  },
  {
    "value": 96,
    "label": "Lesotho",
    "label_es": "Lesoto",
    "label_fr": "Lesotho",
    "status": 1
  },
  {
    "value": 97,
    "label": "Liberia",
    "label_es": "Liberia",
    "label_fr": "Libérie",
    "status": 1
  },
  {
    "value": 98,
    "label": "Libya",
    "label_es": "Libia",
    "label_fr": "Libye",
    "status": 1
  },
  {
    "value": 99,
    "label": "Liechtenstein",
    "label_es": "Liechtenstein",
    "label_fr": "Liechtenstein",
    "status": 1
  },
  {
    "value": 100,
    "label": "Lithuania",
    "label_es": "Lituania",
    "label_fr": "Lituanie",
    "status": 1
  },
  {
    "value": 101,
    "label": "Luxemburg",
    "label_es": "Luxemburgo",
    "label_fr": "Luxembourg",
    "status": 1
  },
  {
    "value": 102,
    "label": "Madagascar",
    "label_es": "Madagascar",
    "label_fr": "Madagascar",
    "status": 1
  },
  {
    "value": 103,
    "label": "Malawi",
    "label_es": "Malaui",
    "label_fr": "Malawi",
    "status": 1
  },
  {
    "value": 104,
    "label": "Malaysia",
    "label_es": "Malasia",
    "label_fr": "Malaisie",
    "status": 1
  },
  {
    "value": 105,
    "label": "Maldives",
    "label_es": "Maldivas",
    "label_fr": "Maldive",
    "status": 1
  },
  {
    "value": 106,
    "label": "Mali",
    "label_es": "Malí",
    "label_fr": "Mali",
    "status": 1
  },
  {
    "value": 107,
    "label": "Malta",
    "label_es": "Malta",
    "label_fr": "Malte",
    "status": 1
  },
  {
    "value": 108,
    "label": "Marshall Islands",
    "label_es": "Islas Marshall",
    "label_fr": "Iles Marshall",
    "status": 1
  },
  {
    "value": 109,
    "label": "Mauritania",
    "label_es": "Mauritania",
    "label_fr": "Mauritanie",
    "status": 1
  },
  {
    "value": 110,
    "label": "Mauritius",
    "label_es": "Mauricio",
    "label_fr": "Mauric",
    "status": 1
  },
  {
    "value": 111,
    "label": "Mexico",
    "label_es": "México",
    "label_fr": "Mexique",
    "status": 1
  },
  {
    "value": 112,
    "label": "Moldova",
    "label_es": "Moldavia",
    "label_fr": "Moldavie",
    "status": 1
  },
  {
    "value": 113,
    "label": "Monaco",
    "label_es": "Mónaco",
    "label_fr": "Monaco",
    "status": 1
  },
  {
    "value": 114,
    "label": "Mongolia",
    "label_es": "Mongolia",
    "label_fr": "Mongolie",
    "status": 1
  },
  {
    "value": 115,
    "label": "Montenegro",
    "label_es": "montenegro",
    "label_fr": "Monténégro",
    "status": 1
  },
  {
    "value": 116,
    "label": "Morocco",
    "label_es": "Marruecos",
    "label_fr": "Maroc",
    "status": 1
  },
  {
    "value": 117,
    "label": "Mozambique",
    "label_es": "Mozambique",
    "label_fr": "Mozambique",
    "status": 1
  },
  {
    "value": 118,
    "label": "Namibia",
    "label_es": "Namibia",
    "label_fr": "Namibie",
    "status": 1
  },
  {
    "value": 119,
    "label": "Nauru",
    "label_es": "Nauru",
    "label_fr": "Nauru",
    "status": 1
  },
  {
    "value": 120,
    "label": "Nepal",
    "label_es": "Nepal",
    "label_fr": "Népal",
    "status": 1
  },
  {
    "value": 121,
    "label": "Netherlands",
    "label_es": "Países Bajos",
    "label_fr": "Pays-Bas",
    "status": 1
  },
  {
    "value": 122,
    "label": "New Zealand",
    "label_es": "Nueva Zelanda",
    "label_fr": "Nouvelle-Zélande",
    "status": 1
  },
  {
    "value": 123,
    "label": "Nicaragua",
    "label_es": "Nicaragua",
    "label_fr": "Nicaragua",
    "status": 1
  },
  {
    "value": 124,
    "label": "Niger",
    "label_es": "Níger",
    "label_fr": "Niger",
    "status": 1
  },
  {
    "value": 125,
    "label": "Nigeria",
    "label_es": "Nigeria",
    "label_fr": "Nigeria",
    "status": 1
  },
  {
    "value": 126,
    "label": "North Macedonia",
    "label_es": "Macedonia del norte",
    "label_fr": "Macédoine du nord",
    "status": 1
  },
  {
    "value": 127,
    "label": "Norway",
    "label_es": "Noruega",
    "label_fr": "Norvège",
    "status": 1
  },
  {
    "value": 128,
    "label": "Oman",
    "label_es": "Omán",
    "label_fr": "Oman",
    "status": 1
  },
  {
    "value": 129,
    "label": "Pakistan",
    "label_es": "Pakistán",
    "label_fr": "Pakistan",
    "status": 1
  },
  {
    "value": 130,
    "label": "Palau",
    "label_es": "Palaos",
    "label_fr": "Palos",
    "status": 1
  },
  {
    "value": 131,
    "label": "Palestine",
    "label_es": "Palestina",
    "label_fr": "Palestine",
    "status": 1
  },
  {
    "value": 132,
    "label": "Panama",
    "label_es": "Panamá",
    "label_fr": "Panama",
    "status": 1
  },
  {
    "value": 133,
    "label": "Paraguay",
    "label_es": "Paraguay",
    "label_fr": "Paraguay",
    "status": 1
  },
  {
    "value": 134,
    "label": "Peru",
    "label_es": "Perú",
    "label_fr": "Perou",
    "status": 1
  },
  {
    "value": 135,
    "label": "Philippines",
    "label_es": "Filipinas",
    "label_fr": "Philippines",
    "status": 1
  },
  {
    "value": 136,
    "label": "Poland",
    "label_es": "Polonia",
    "label_fr": "Pologne",
    "status": 1
  },
  {
    "value": 137,
    "label": "Porto Rico",
    "label_es": "Puerto Rico",
    "label_fr": "Porto Rico",
    "status": 1
  },
  {
    "value": 138,
    "label": "Portugal",
    "label_es": "Portugal",
    "label_fr": "Portugal",
    "status": 1
  },
  {
    "value": 139,
    "label": "Punt",
    "label_es": "Batea",
    "label_fr": "Pount",
    "status": 1
  },
  {
    "value": 140,
    "label": "Qatar",
    "label_es": "Katar",
    "label_fr": "Qatar",
    "status": 1
  },
  {
    "value": 141,
    "label": "Romania",
    "label_es": "Rumania",
    "label_fr": "Romanie",
    "status": 1
  },
  {
    "value": 142,
    "label": "Russia",
    "label_es": "Rusia",
    "label_fr": "Russie",
    "status": 1
  },
  {
    "value": 143,
    "label": "Rwanda",
    "label_es": "Ruanda",
    "label_fr": "Rwanda",
    "status": 1
  },
  {
    "value": 144,
    "label": "Salvadore",
    "label_es": "Salvador",
    "label_fr": "Salvadore",
    "status": 1
  },
  {
    "value": 145,
    "label": "Samoa",
    "label_es": "Samoa",
    "label_fr": "Samoa",
    "status": 1
  },
  {
    "value": 146,
    "label": "Saudi Arabia",
    "label_es": "Arabia Saudita",
    "label_fr": "Arabie Saoudite",
    "status": 1
  },
  {
    "value": 147,
    "label": "Senegal",
    "label_es": "Senegal",
    "label_fr": "Ségénal",
    "status": 1
  },
  {
    "value": 148,
    "label": "Seychelles",
    "label_es": "Seychelles",
    "label_fr": "Seychelles",
    "status": 1
  },
  {
    "value": 149,
    "label": "Sierra Leone",
    "label_es": "Sierra Leone",
    "label_fr": "Sierra Leone",
    "status": 1
  },
  {
    "value": 150,
    "label": "Singapore",
    "label_es": "Singapur",
    "label_fr": "Singapour",
    "status": 1
  },
  {
    "value": 151,
    "label": "Slovakia",
    "label_es": "Eslovaquia",
    "label_fr": "Slovaquie",
    "status": 1
  },
  {
    "value": 152,
    "label": "Slovenia",
    "label_es": "Eslovenia",
    "label_fr": "Slovénie",
    "status": 1
  },
  {
    "value": 153,
    "label": "Somalia",
    "label_es": "Somalia",
    "label_fr": "Somalia",
    "status": 1
  },
  {
    "value": 154,
    "label": "Somaliland",
    "label_es": "somalilandia",
    "label_fr": "Somaliland",
    "status": 1
  },
  {
    "value": 155,
    "label": "South africa",
    "label_es": "Sudáfrica",
    "label_fr": "Afrique du sud",
    "status": 1
  },
  {
    "value": 156,
    "label": "South korea",
    "label_es": "Corea del Sur",
    "label_fr": "Korée du Sud",
    "status": 1
  },
  {
    "value": 157,
    "label": "South Soudan",
    "label_es": "Sudán del Sur",
    "label_fr": "Soudan du sud",
    "status": 1
  },
  {
    "value": 158,
    "label": "Spain",
    "label_es": "España",
    "label_fr": "Espagne",
    "status": 1
  },
  {
    "value": 159,
    "label": "Sudan",
    "label_es": "Sudán",
    "label_fr": "Soudan",
    "status": 1
  },
  {
    "value": 160,
    "label": "Sweden",
    "label_es": "Suecia",
    "label_fr": "Suède",
    "status": 1
  },
  {
    "value": 161,
    "label": "Swiss",
    "label_es": "Suiza",
    "label_fr": "Suisse",
    "status": 1
  },
  {
    "value": 162,
    "label": "Syria",
    "label_es": "Siria",
    "label_fr": "Syrie",
    "status": 1
  },
  {
    "value": 163,
    "label": "Taiwan",
    "label_es": "Taiwán",
    "label_fr": "Taiwan",
    "status": 1
  },
  {
    "value": 164,
    "label": "Tanzania",
    "label_es": "Tanzania",
    "label_fr": "Tanzanie",
    "status": 1
  },
  {
    "value": 165,
    "label": "Thailand",
    "label_es": "Tailandia",
    "label_fr": "Thailande",
    "status": 1
  },
  {
    "value": 166,
    "label": "Togo",
    "label_es": "Togo",
    "label_fr": "Togo",
    "status": 1
  },
  {
    "value": 167,
    "label": "Tonga",
    "label_es": "Tonga",
    "label_fr": "Tonga",
    "status": 1
  },
  {
    "value": 168,
    "label": "Tunisia",
    "label_es": "Túnez",
    "label_fr": "Tunisie",
    "status": 1
  },
  {
    "value": 169,
    "label": "Turkey",
    "label_es": "Turquía",
    "label_fr": "Turquie",
    "status": 1
  },
  {
    "value": 170,
    "label": "Uganda",
    "label_es": "Uganda",
    "label_fr": "Ouganda",
    "status": 1
  },
  {
    "value": 171,
    "label": "UK",
    "label_es": "Inglaterra",
    "label_fr": "Royaume-Uni",
    "status": 1
  },
  {
    "value": 172,
    "label": "Ukraine",
    "label_es": "Ucrania",
    "label_fr": "Ukraine",
    "status": 1
  },
  {
    "value": 173,
    "label": "Uruguay",
    "label_es": "Uruguay",
    "label_fr": "Uruguay",
    "status": 1
  },
  {
    "value": 174,
    "label": "Uzbekistan",
    "label_es": "Uzbekistán",
    "label_fr": "Ouzbekistan",
    "status": 1
  },
  {
    "value": 175,
    "label": "Vanuatu",
    "label_es": "Vanuatu",
    "label_fr": "Vanuatu",
    "status": 1
  },
  {
    "value": 176,
    "label": "Venezuela",
    "label_es": "Venezuela",
    "label_fr": "Venezuala",
    "status": 1
  },
  {
    "value": 177,
    "label": "Vietnam",
    "label_es": "Vietnam",
    "label_fr": "Vietnam",
    "status": 1
  },
  {
    "value": 178,
    "label": "Yemen",
    "label_es": "Yemen",
    "label_fr": "Yémen",
    "status": 1
  },
  {
    "value": 179,
    "label": "Zambia",
    "label_es": "Zambia",
    "label_fr": "Zambie",
    "status": 1
  },
  {
    "value": 180,
    "label": "Zimbabwe",
    "label_es": "Zimbabue",
    "label_fr": "Zimbabwe",
    "status": 1
  },
  {
    "value": 181,
    "label": "United States",
    "label_es": "Estados Unidos",
    "label_fr": "États-Unis",
    "status": 1
  }
];

export const CivilStatus =
 [
			{
				"value": 1,
				"label": "Single",
				"label_es": "Soltero(a)",
				"label_fr": "Célibataire’"
			},
			{
				"value": 2,
				"label": "Married",
				"label_es": "Casado(a)",
				"label_fr": "Marié’"
			},
			{
				"value": 3,
				"label": "Separated/Divorced",
				"label_es": "Separado(a)/Divorciado(a)",
				"label_fr": "Séparés/Divorcés"
			},
			{
				"value": 6,
				"label": "Widowed",
				"label_es": "Viudo(a)",
				"label_fr": "Veuf(ve)"
			}
		];

export const TypeIdentification =
	 [
			{
				"value": 3,
				"code": "13",
				"label": "ID",
				"label_es": "Cédula de ciudadanía",
				"label_fr": "carte d´identité"
			},
			{
				"value": 5,
				"code": "22",
				"label": "Foreigner ID",
				"label_es": "Cédula de extranjería",
				"label_fr": "carte d´identité etranger"
			},
			{
				"value": 6,
				"code": "31",
				"label": "Company ID",
				"label_es": "NIT",
				"label_fr": "identification fiscale"
			},
			{
				"value": 7,
				"code": "41",
				"label": "Passport",
				"label_es": "Pasaporte",
				"label_fr": "passport"
			}
		];




export const userData = [
  {
    id: 1,
    avatarBg: "purple",
    label: "Abu Bin Ishtiyak",
    displaylabel: "Ishtiak",
    dob: "10 Aug, 1980",
    role: "Customer",
    checked: false,
    email: "info@softnio.com",
    balance: "35,040.34",
    phone: "818474958",
    emailStatus: "success",
    kycStatus: "success",
    lastLogin: "10 Feb 2020",
    status: "Active",
    address: "2337 Kildeer Drive",
    state: "Kentucky",
    country: "Canada",
    designation: "UI/UX Designer",
    projects: "213",
    performed: "87.5",
    tasks: "587",
  },
  {
    value: 2,
    avatarBg: "purple",
    image: User3,
    label: "Ashley Lawson",
    dob: "10 Sept, 1990",
    role: "Investor",
    email: "ashley@softnio.com",
    balance: "580.00",
    checked: false,
    phone: "1243941787",
    emailStatus: "success",
    kycStatus: "pending",
    lastLogin: "07 Feb 2020",
    status: "Pending",
    country: "United States",
    designation: "UI/UX Designer",
    projects: "213",
    performed: "87.5",
    tasks: "587",
  },
  {
    value: 3,
    avatarBg: "info",
    label: "Joe Larson",
    dob: "19 Jan, 1985",
    role: "Customer",
    email: "larson@example.com",
    balance: "32,000.34",
    checked: false,
    phone: "1686032320",
    emailStatus: "success",
    kycStatus: "success",
    lastLogin: "04 Feb 2020",
    status: "Active",
    country: "England",
    designation: "UI/UX Designer",
    projects: "213",
    performed: "87.5",
    tasks: "587",
  },
  {
    value: 4,
    avatarBg: "danger",
    label: "Jane Montgomery",
    dob: "24 April, 1985",
    role: "Subscriber",
    email: "jane84@example.com",
    balance: "0.00",
    checked: false,
    phone: "4392715360",
    emailStatus: "alert",
    kycStatus: "alert",
    lastLogin: "01 Feb 2020",
    status: "Suspend",
    country: "United States",
    designation: "UI/UX Designer",
    projects: "213",
    performed: "87.5",
    tasks: "587",
  },
  {
    value: 5,
    avatarBg: "purple",
    label: "Frances Burns",
    dob: "30 May, 2000",
    role: "Manager",
    image: User,
    email: "frances@example.com",
    balance: "42.50",
    checked: false,
    phone: "6391303150",
    emailStatus: "pending",
    kycStatus: "error",
    lastLogin: "31 Jan 2020",
    status: "Active",
    country: "Bangladesh",
    designation: "UI/UX Designer",
    projects: "213",
    performed: "87.5",
    tasks: "587",
  },
  {
    value: 6,
    avatarBg: "primary",
    label: "Alan Butler",
    dob: "10 Feb, 1997",
    role: "Investor",
    image: User2,
    email: "butler@example.com",
    balance: "440.34",
    checked: false,
    phone: "9633091706",
    emailStatus: "pending",
    kycStatus: "warning",
    lastLogin: "18 Jan 2020",
    status: "Inactive",
    country: "India",
    designation: "UI/UX Designer",
    projects: "213",
    performed: "87.5",
    tasks: "587",
  },
  {
    value: 7,
    avatarBg: "warning",
    label: "Victoria Lynch",
    dob: "02 May, 1993",
    role: "Investor",
    email: "victoria@example.com",
    balance: "59,400.68",
    checked: false,
    phone: "8119854846",
    emailStatus: "success",
    kycStatus: "success",
    lastLogin: "15 Jan 2020",
    status: "Active",
    country: "China",
    designation: "UI/UX Designer",
    projects: "213",
    performed: "87.5",
    tasks: "587",
  },
  {
    value: 8,
    avatarBg: "success",
    label: "Patrick Newman",
    dob: "15 Feb, 1997",
    role: "Customer",
    email: "patrick@example.com",
    balance: "30.00",
    checked: false,
    phone: "9422384474",
    emailStatus: "success",
    kycStatus: "pending",
    lastLogin: "08 Jan 2020",
    status: "Active",
    country: "India",
    designation: "UI/UX Designer",
    projects: "213",
    performed: "87.5",
    tasks: "587",
  },
  {
    value: 9,
    avatarBg: "purple",
    label: "Jane Harris",
    dob: "28 Feb, 1985",
    role: "Customer",
    image: User4,
    email: "harris@example.com",
    balance: "5,530.23",
    checked: false,
    phone: "1234472384",
    emailStatus: "pending",
    kycStatus: "pending",
    lastLogin: "02 Jan 2020",
    status: "Pending",
    country: "Vietnam",
    designation: "UI/UX Designer",
    projects: "213",
    performed: "87.5",
    tasks: "587",
  },
  {
    value: 10,
    avatarBg: "purple",
    label: "Emma Walker",
    dob: "30 Dec, 1998",
    role: "Investor",
    email: "walker@example.com",
    balance: "55.00",
    checked: false,
    phone: "4634717173",
    emailStatus: "success",
    kycStatus: "success",
    lastLogin: "25 Dec 2019",
    status: "Active",
    country: "United States",
    designation: "UI/UX Designer",
    projects: "213",
    performed: "87.5",
    tasks: "587",
  },
  {
    value: 11,
    avatarBg: "pink",
    label: "Lilja Peltola",
    dob: "30 Dec, 1998",
    role: "Investor",
    email: "lilja@example.com",
    balance: "105.00",
    checked: false,
    phone: "4634717173",
    emailStatus: "success",
    kycStatus: "pending",
    lastLogin: "25 Dec 2019",
    status: "Active",
    country: "Canada",
    designation: "Web Developer",
    projects: "213",
    performed: "87.5",
    tasks: "587",
  },
  {
    value: 12,
    avatarBg: "secondary",
    label: "Annette Hunter",
    dob: "30 Dec, 1998",
    role: "Investor",
    email: "hunter@example.com",
    balance: "55.00",
    checked: false,
    phone: "4634717173",
    emailStatus: "success",
    kycStatus: "success",
    lastLogin: "25 Dec 2019",
    status: "Pending",
    country: "United States",
    designation: "UI/UX Designer",
    projects: "213",
    performed: "87.5",
    tasks: "587",
  },
  {
    value: 13,
    avatarBg: "pink",
    label: "Sara Koivisto",
    dob: "30 Dec, 1998",
    role: "Customer",
    email: "sara@example.com",
    balance: "165.00",
    checked: false,
    phone: "4634717173",
    emailStatus: "rejected",
    kycStatus: "pending",
    lastLogin: "25 Dec 2019",
    status: "Active",
    country: "Russia",
    designation: "Web Developer",
    projects: "213",
    performed: "87.5",
    tasks: "587",
  },
  {
    value: 14,
    avatarBg: "blue",
    label: "Kianna Pham",
    dob: "30 Dec, 1998",
    role: "Admin",
    email: "kiana@example.com",
    balance: "55.00",
    checked: false,
    phone: "4634717173",
    emailStatus: "success",
    kycStatus: "rejected",
    lastLogin: "25 Dec 2019",
    status: "Suspend",
    country: "South Korea",
    designation: "Accountant",
    projects: "213",
    performed: "87.5",
    tasks: "587",
  },
  {
    value: 15,
    avatarBg: "pink",
    label: "Raymond Atkins",
    dob: "30 Dec, 1998",
    role: "Customer",
    image: User4,
    email: "sara@example.com",
    balance: "165.00",
    checked: false,
    phone: "4634717173",
    emailStatus: "rejected",
    kycStatus: "pending",
    lastLogin: "25 Dec 2019",
    status: "Active",
    country: "Russia",
    designation: "Web Developer",
    projects: "213",
    performed: "87.5",
    tasks: "587",
  },
  {
    value: 16,
    avatarBg: "blue",
    label: "Amira Talley",
    dob: "30 Dec, 1998",
    role: "Admin",
    email: "amira@example.com",
    balance: "55.00",
    checked: false,
    phone: "4634717173",
    emailStatus: "pending",
    kycStatus: "rejected",
    lastLogin: "25 Dec 2019",
    status: "Active",
    country: "Saudi Arabia",
    designation: "Lecturer",
    projects: "213",
    performed: "87.5",
    tasks: "587",
  },
  {
    value: 17,
    avatarBg: "secondary",
    label: "Lana Steiner",
    dob: "30 Dec, 1998",
    role: "Admin",
    email: "steinar@example.com",
    balance: "55.00",
    checked: false,
    phone: "4634717173",
    emailStatus: "pending",
    kycStatus: "rejected",
    lastLogin: "25 Dec 2019",
    status: "Pending",
    country: "Latvia",
    designation: "Accountant",
    projects: "213",
    performed: "87.5",
    tasks: "587",
  },
  {
    value: 18,
    avatarBg: "warning",
    label: "Joshua Mcnair",
    dob: "30 Dec, 1998",
    image: User4,
    role: "Admin",
    email: "joshua@example.com",
    balance: "55.00",
    checked: false,
    phone: "4634717173",
    emailStatus: "pending",
    kycStatus: "rejected",
    lastLogin: "25 Dec 2019",
    status: "Suspend",
    country: "Ireland",
    designation: "Web Developer",
    projects: "213",
    performed: "87.5",
    tasks: "587",
  },
  {
    value: 19,
    avatarBg: "secondary",
    label: "Asiya Wolff",
    dob: "30 Dec, 1998",
    role: "Customer",
    email: "asia@example.com",
    balance: "55.00",
    checked: false,
    phone: "4634717173",
    emailStatus: "success",
    kycStatus: "success",
    lastLogin: "25 Dec 2019",
    status: "Active",
    country: "Latvia",
    designation: "Accountant",
    projects: "213",
    performed: "87.5",
    tasks: "587",
  },
  {
    value: 20,
    avatarBg: "warning",
    label: "Fox Mccloud",
    dob: "30 Dec, 1998",
    role: "Admin",
    email: "fox@example.com",
    balance: "55.00",
    checked: false,
    phone: "4634717173",
    emailStatus: "pending",
    kycStatus: "rejected",
    lastLogin: "25 Dec 2019",
    status: "Suspend",
    country: "Ireland",
    designation: "Web Developer",
    projects: "213",
    performed: "87.5",
    tasks: "587",
  },
];

export const notes = [
  {
    value: 1,
    text: "Aproin at metus et dolor tincvalueunt feugiat eu value quam. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Aenean sollicitudin non nunc vel pharetra.",
    date: "November 18, 2019",
    time: "5:34",
    company: "Softnio",
  },
  {
    value: 2,
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi eleifend libero semper metus aliquam tempus. Sed efficitur elit et ligula lobortis",
    date: "December 27, 2019",
    time: "7:00",
    company: "Softnio",
  },
];

export const filterStatus = [
  { value:1, label: "Active" },
  { value:2, label: "Inactive" },
  { value:3, label: "Edited" },
  { value:4, label: "Published" },
];

export const filterRole = [
  { value: 1, label: "Administrator" },
  { value: 2, label: "Applicant" },
  { value: 3, label: "Company" },
];
export const filterVisible = [
  { value: 1, label: "Visible" },
  { value: 2, label: "No Visible" },
];

// Get all Countries of Database (Danny)
export const FilterCountryb = async () => {
  const [countries, setCountries] = useState([]);
  axios
    .get(
      `${process.env.REACT_APP_BASE_URL}/api/attrs/country`,
      {
        headers: {
          Authorization: `Bearer  ${UserProfile.getToken()}`,
        },
      }
    )
    .then((res) => {
      setCountries(res.data.data.country)
      console.log(countries, "countries");
    });
};

export const FilterCountry = [

  { value: 2, label: "Canada" },
  { value: 1, label: "Colombia" },

];

export const filterLanguage = [
  { value: 'en', label: "English" },
  { value: 'es', label: "Spanish" },
  { value: 'fr', label: "French" },
];


import React from "react";
import Icon from "../../components/icon/Icon";
import Button from "../../components/button/Button";
import { UncontrolledDropdown, CardTitle, DropdownMenu, DropdownItem, DropdownToggle } from "reactstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ActionCenter = () => {
  const [t, i18n] = useTranslation("global")
  return (
    <React.Fragment>
      {" "}
      <div className="card-inner-group">
        <div className="card-inner card-inner-md">
          <div className="card-title-group">
            <CardTitle>
              <h6 className="title">{t("main_actions")}</h6>
            </CardTitle>
            {/* <div className="card-tools mr-n1">
              <UncontrolledDropdown>
                <DropdownToggle tag="a" className="dropdown-toggle btn btn-icon btn-trigger">
                  <Icon name="more-h"></Icon>
                </DropdownToggle>
                <DropdownMenu right>
                  <ul className="link-list-opt no-bdr">
                    <li>
                      <DropdownItem
                        tag="a"
                        href="#dropdownitem"
                        onClick={(ev) => {
                          ev.preventDefault();
                        }}
                      >
                        <Icon name="setting"></Icon>
                        <span>Action Settings</span>
                      </DropdownItem>
                    </li>
                    <li>
                      <DropdownItem
                        tag="a"
                        href="#dropdownitem"
                        onClick={(ev) => {
                          ev.preventDefault();
                        }}
                      >
                        <Icon name="notify"></Icon>
                        <span>Push Notification</span>
                      </DropdownItem>
                    </li>
                  </ul>
                </DropdownMenu>
              </UncontrolledDropdown>
            </div> */}
          </div>
        </div>
        <Link to={`${process.env.PUBLIC_URL}/candidates-list-compact/`}>
        <div className="card-inner">
          <div className="nk-wg-action">
            <div className="nk-wg-action-content">
              <Icon name="user-check"></Icon>
              <div className="title">{t("manage_applicants")}</div>
              <p align="justify">
              {t("manage_applicants_description")}
              </p>
            </div>
            <Button className="btn-icon btn-trigger mr-n2">
              <Icon name="forward-ios"></Icon>
            </Button>
          </div>
        </div>
        </Link>
        <Link to={`${process.env.PUBLIC_URL}/companies-list-compact/`}>
        <div className="card-inner">
          <div className="nk-wg-action">
            <div className="nk-wg-action-content">
              <Icon name="building"></Icon>
              <div className="title">{t("manage_companies")}</div>
              <p align="justify">
              {t("manage_companies_description")}{" "}
              </p>
            </div>
            <Button className="btn-icon btn-trigger mr-n2">
              <Icon name="forward-ios"></Icon>
            </Button>
          </div>
        </div>
        </Link>
        <Link to={`${process.env.PUBLIC_URL}/sales_applicants/`}>
        <div className="card-inner">
          <div className="nk-wg-action">
            <div className="nk-wg-action-content">
              <Icon name="wallet-fill"></Icon>
              <div className="title">{t("manage_sales")}</div>
              <p align="justify">
              {t("manage_sales_description")}
              </p>
            </div>
            <Button className="btn-icon btn-trigger mr-n2">
              <Icon name="forward-ios"></Icon>
            </Button>
          </div>
        </div>
        </Link>
        <Link to={`${process.env.PUBLIC_URL}/user-list-compact/`}>
        <div className="card-inner">
          <div className="nk-wg-action">
            <div className="nk-wg-action-content">
              <Icon name="account-setting"></Icon>
              <div className="title">{t("manage_users")}</div>
              <p align="justify">
              {t("manage_users_description")}
              </p>
            </div>
            <Button className="btn-icon btn-trigger mr-n2">
              <Icon name="forward-ios"></Icon>
            </Button>
          </div>
        </div>
        </Link>
        <Link to={`${process.env.PUBLIC_URL}/config_data_admin/`}>
        <div className="card-inner">
          <div className="nk-wg-action">
            <div className="nk-wg-action-content">
              <Icon name="setting-fill"></Icon>
              <div className="title">{t("manage_data_admin")}</div>
              <p align="justify">
              {t("manage_data_description")}
              </p>
            </div>
            <Button className="btn-icon btn-trigger mr-n2">
              <Icon name="forward-ios"></Icon>
            </Button>
          </div>
        </div>
        </Link>
      </div>
    </React.Fragment>
  );
};
export default ActionCenter;

import React, { useState, useEffect, useContext } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import { findUpper } from "../../../utils/Utils";
import { userData, filterVisible, filterStatus, filterLanguage } from "./UserData";
import {
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  UncontrolledDropdown,
  Modal,
  ModalBody,
  DropdownItem,
  Form,
} from "reactstrap";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Row,
  Col,
  UserAvatar,
  PaginationComponent,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableRow,
  DataTableItem,
  Button,
  RSelect,
  TooltipComponent,
} from "../../../components/Component";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { UserContext } from "./UserContext";
import axios from "axios";
import { bulkActionOptions } from "../../../utils/Utils";
import UserProfile from "../../../Login/UserProfile";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import ReactPaginate from "react-paginate";

const ServiceListCompact = (props) => {
  const [t, i18n] = useTranslation(["serviceadm", "global"])
  const { contextData } = useContext(UserContext);
  // listServices is the response from the API without filtering, sorting or anytype of manipulation from the front
  const [ listServices, setlistServices ] = useState([]);
  // data is the response from the API with order or filter applied by the user in the front
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);

  const [sm, updateSm] = useState(false);
  const [tablesm, updateTableSm] = useState(false);
  const [onSearch, setonSearch] = useState(true);
  const [onSearchText, setSearchText] = useState("");
  const [modal, setModal] = useState({
    edit: false,
    add: false,
  });
  const [editId, setEditedId] = useState();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    type: "",
    status: 1,
  });
  const [actionText, setActionText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(10);
  const [sort, setSortState] = useState("");

  const [ filtypeuser, setfiltypeuser ] = useState("");
  const [ filstatus, setfilstatus ] = useState("");

  // Sorting data
  const sortFunc = (params) => {
    let defaultData = data;
    if (params === "asc") {
      let sortedData = defaultData.sort((a, b) => a.name.localeCompare(b.name));
      setData([...sortedData]);
    } else if (params === "dsc") {
      let sortedData = defaultData.sort((a, b) => b.name.localeCompare(a.name));
      setData([...sortedData]);
    }
  };
  // Changing state value when searching name
  useEffect(() => {
    if (onSearchText !== "") {
      // console.log(data, "data");
      const filteredObject = listServices.filter((item) => {
        return (
          item.name.toLowerCase().includes(onSearchText.toLowerCase()) ||
          item.description.toLowerCase().includes(onSearchText.toLowerCase())
        );
      });
      setData([...filteredObject]);
    } else {
      setData([...data]);
    }
  }, [onSearchText, setData]);

  // onChange function for searching name
  const onFilterChange = (e) => {
    setSearchText(e.target.value);
  };

//  filter by type user and status
  useEffect(() => {
    if(filstatus != "") {
      const filteredObject = listServices.filter((item) => {
        return (
          item.visible == filstatus.value
        );
      });
      setData([...filteredObject]);
    }
    else {
      setData([...data]);
    }
  }, [filstatus, setData]);

// function to reset the form
const resetFilter = () => {
  setfilstatus("");
  setData([...listServices]);
};


  // function to set the action to be taken in table header
  const onActionText = (e) => {
    setActionText(e.value);
  };

  // function to reset the form
  const resetForm = () => {
    setFormData({
      name: "",
      email: "",
      type:1,
      password: "",
      status: 1,
    });
  };

  // function to close the form modal
  const onFormCancel = () => {
    setModal({ edit: false, add: false });
    resetForm();
  };

  // // submit function to add a new item
  // const onFormSubmit = (submitData) => {
  //   const { name, email, type, password, status } = submitData;
  //   let submittedData = {
  //     // id: data.length + 1,
  //     // avatarBg: "purple",
  //     name: name,
  //     type: type,
  //     email: email,
  //     password: password,
  //     type: type,
  //     status: status
  //     // balance: balance,
  //     // phone: phone,
  //     // emailStatus: "success",
  //     // kycStatus: "alert",
  //     // lastLogin: "10 Feb 2020",
  //     // status: 1,

  //   };
  //   setData([submittedData, ...data]);
  //   resetForm();
  //   setModal({ edit: false }, { add: false });
  // };

  const onEditSubmit = async (submitData) => {
    // console.log(formData.status,'type');
    const valuesupdateData = {
        name: submitData.name,
        description: submitData.description,
        price: submitData.price,
        visible: formData.visible,
      };
      axios
          .put(
            `${process.env.REACT_APP_BASE_URL}/api/services/${editId}`,
            valuesupdateData,
            {
              headers: {
                Authorization: `Bearer  ${UserProfile.getToken()}`,
              }
            }
          )
          .then((res) => {
            if (res.data.status == true) {
              Swal.fire({
                icon: "success",
                title: t("msg3"),
                text: t("msg6"),
                confirmButtonColor: "#071b30",
                confirmButtonText: t("ok"),
              });
              setModal({ edit: false });
              list();
            }else{
              console.log(res)
            }
          })
          .catch((error) => console.log(error));
    // document.getElementById("updateModalUser").click();
  };

  // // submit function to update a new item
  // const onEditSubmit = (submitData) => {
  //   const { name, email} = submitData;
  //   let submittedData;
  //   let newitems = data;
  //   newitems.forEach((item) => {
  //     if (item.id === editId) {
  //       submittedData = {
  //         id: item.id,
  //         // avatarBg: item.avatarBg,
  //         name: name,
  //         // image: item.image,
  //         // role: item.type,
  //         email: email,
  //         // balance: formData.balance,
  //         // phone: "+" + phone,
  //         // emailStatus: item.emailStatus,
  //         // kycStatus: item.kycStatus,
  //         // lastLogin: item.lastLogin,
  //         // status: formData.status,
  //         // country: item.country,
  //       };
  //     }
  //   });
  //   let index = newitems.findIndex((item) => item.id === editId);
  //   newitems[index] = submittedData;
  //   setModal({ edit: false });
  // };

  // function that loads the want to editted data
  const onEditClick = (id) => {

    listServices.forEach((item) => {
      if (item.id === id) {
        setFormData({
          name: item.name,
          description: item.description,
          price: item.price,
          visible: item.visible,

        });
        setModal({ edit: true }, { add: false });
        setEditedId(id);
      }
    });

  };

  // // function to change to suspend property for an item
  // const suspendUser = (id) => {
  //   let newData = data;
  //   let index = newData.findIndex((item) => item.id === id);
  //   newData[index].status = "Suspend";
  //   setData([...newData]);
  // };

  // // function which fires on applying selected action
  // const onActionClick = (e) => {
  //   if (actionText === "suspend") {
  //     let newData = data.map((item) => {
  //       if (item.checked === true) item.status = "Suspend";
  //       return item;
  //     });
  //     setData([...newData]);
  //   } else if (actionText === "delete") {
  //     let newData;
  //     newData = data.filter((item) => item.checked !== true);
  //     setData([...newData]);
  //   }
  // };

  // // function which selects all the items
  // const selectorCheck = (e) => {
  //   let newData;
  //   newData = data.map((item) => {
  //     item.checked = e.currentTarget.checked;
  //     return item;
  //   });
  //   setData([...newData]);
  // };

  // function to toggle the search option
  const toggle = () => setonSearch(!onSearch);

  const { errors, register, handleSubmit } = useForm();

  // Get current list, pagination
  const indexOfLastItem = currentPage * itemPerPage;
  const indexOfFirstItem = indexOfLastItem - itemPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  // Change Page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);




  // const [ usersTem, setusersTem ] = useState([]);
  // const [ typeDocument, settypeDocument ] = useState([]);
  const [ typeUser, settypeUser ] = useState([]);
  // const [ UsuarioForm, setUsuarioForm ] = useState([]);
  // const [ estado, setestado ] = useState(0);
  // const [ filter, setfilter ] = useState(0);
  //    /*paginacion*/
  // const [ pageNex, setpageNex ] = useState(0);
  // const [ pageCount, setpageCount ] = useState(0);
  // const [ selectedPage, setselectedPage ] = useState(1);

  // Add a service
  const AddService = async (value) => {
    const data = {
      name: value.name,
      description: value.description,
      price: value.price,
      // Using formData because is a component RSelect
      visible: formData.visible,
    };
    axios
    .post(`${process.env.REACT_APP_BASE_URL}/api/services`, data, {
      headers: {
        Authorization: `Bearer  ${UserProfile.getToken()}`,
      }
    })
    .then((res) => {
      console.log(res);
      if (res.data.status == true) {
        Swal.fire({
          icon: "success",
          title: t("msg2"),
          text: t("msg7"),
          confirmButtonColor: "#071b30",
          confirmButtonText: t("ok"),
        });
        // Close the modal
        resetForm();
        setModal({ edit: false }, { add: false });
        // Get again all users
        list();

      }else{
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: res.data.error[0],
          confirmButtonColor: "#071b30",
          confirmButtonText: t("ok"),
        });
        // setModal({ edit: false }, { add: false });
      }
      list();
    })
    .catch((error) => console.log(error));
  };


  const deleter = async (id) => {
    Swal.fire({
      title: t("are_you_sure", {ns:"global"}),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: t("ok"),
      cancelButtonText: t("cancel"),
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`${process.env.REACT_APP_BASE_URL}/api/services/${id}`, {
            headers: {
              Authorization: `Bearer  ${UserProfile.getToken()}`,
            },
          })
          .then((res) => {
            list();
          })
          .catch((error) => console.log(error));
      }
    });
  };

  const list = async (search) => {
    setlistServices([])
    var newsearch = {...search}
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/services`,
      {
        headers: {
          Authorization: `Bearer  ${UserProfile.getToken()}`,
        },
        params:{
            // page: selectedPage
            params: newsearch
        }
      })
      .then((res) => {
        if (res.data.error ? res.data.error[0] == 'El Token a Expirado' : false) {props.history.push('/login'); return}
        setlistServices(res.data.data.services)
        setData(res.data.data.services)
        setTotal(res.data.data.total)
        // setpageCount(res.data.data.pages)
      })
      .catch((error) => console.log(error));
  };

  const handleSwitchChange = async (value,estado) => {
    if (estado == 1) {
      const valuesupdate = {
        // id: value.id,
        // name: value.name,
        // email: value.email,
        // type: value.type,
        visible: 2
      };
      axios
        .put(
          `${process.env.REACT_APP_BASE_URL}/api/services/${value.id}`,
          valuesupdate,
          {
          headers: {
            Authorization: `Bearer  ${UserProfile.getToken()}`,
          }
        }

        )
        .then((res) => {
          if (res.data.status == true) {
            Swal.fire({
              icon: "success",
              title: t("msg3"),
              text: t("msg4"),
              confirmButtonColor: "#071b30",
              confirmButtonText: t("ok"),
            });
            list();
          }else{
            console.log(res)
          }
        })
        .catch((error) => console.log(error));
    } else {
      const valuesupdate = {
        // name: value.name,
        // email: value.email,
        // type: value.type,
        visible: 1
      };
      axios
        .put(
          `${process.env.REACT_APP_BASE_URL}/api/services/${value.id}`,
          valuesupdate,
          {
            headers: {
              Authorization: `Bearer  ${UserProfile.getToken()}`,
            }
          }
        )
        .then((res) => {
          if (res.data.status == true) {
            Swal.fire({
              icon: "success",
              title: t("msg3"),
              text: t("msg5"),
              confirmButtonColor: "#071b30",
              confirmButtonText: t("ok"),
            });
            list();
          }else{
            console.log(res)
          }
        })
        .catch((error) => console.log(error));
    }
};


  useEffect(() => {
    if (navigator.onLine) {
      UserProfile.setToken(localStorage.getItem('token'));
      list();
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: t("msg1"),
        confirmButtonColor: "#071b30",
        confirmButtonText: t("ok"),
      });
    }
  }, [])



//  useEffect(() => {
//   handleClickFilter()
// },[data, filtypeuser, filstatus])

  // const handlePageClick = (val) => {
  //   setselectedPage(val.selected+1)
  //   setpageNex(val.selected)
  // }


  // let paginationElement;
  //   if (pageCount > 1) {
  //     paginationElement = (
  //       <ReactPaginate
  //         previousLabel={"Prev"}
  //         nextLabel={"Sig"}
  //         breakLabel={"..."}
  //         breakClassName={"break-me"}
  //         pageCount={pageCount} //NUMERO TOTAL DE PÁGINAS
  //         onPageChange={handlePageClick} //FUNCION QUE SE LLAMA EN EL onChange DE LA PÁGINA
  //         forcePage={pageNex}
  //         containerClassName={"pagination justify-content-center"}
  //         subContainerClassName={"pages pagination"}
  //         activeClassName={"active"}
  //       />
  //     );
  //   }

  return (
    <React.Fragment>
      <Head title="Services List "></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h3" page>
              {t("services_list")}
              </BlockTitle>
              <BlockDes className="text-soft">
              <p>{t("you_have", {ns: 'global'})} <strong>{total}</strong> {t("services", {ns: 'global'})}.</p>
              </BlockDes>
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <Button
                  className={`btn-icon btn-trigger toggle-expand mr-n1 ${sm ? "active" : ""}`}
                  onClick={() => updateSm(!sm)}
                >
                  <Icon name="menu-alt-r"></Icon>
                </Button>
                <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                  <ul className="nk-block-tools g-3">
                    {/* <li>
                      <a
                        href="#export"
                        onClick={(ev) => {
                          ev.preventDefault();
                        }}
                        className="btn btn-white btn-outline-light"
                      >
                        <Icon name="download-cloud"></Icon>
                        <span>Export</span>
                      </a>
                    </li> */}
                    <li className="nk-block-tools-opt">
                      <Button color="primary" className="btn-icon" onClick={() => setModal({ add: true })}>
                        <Icon name="plus"></Icon>
                      </Button>
                    </li>
                  </ul>
                </div>
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>

        <Block>
          <DataTable className="card-stretch">
            {/* Filters, search, order */}
            <div className="card-inner position-relative card-tools-toggle">
              <div className="card-title-group">
              <div className="card-title">
                  <h5 className="title">{t("all_services")}</h5>
                </div>
                <div className="card-tools">
                  <div className="form-inline flex-nowrap gx-3">
                    {/* <div className="form-wrap">
                      <RSelect
                        options={bulkActionOptions}
                        className="w-130px"
                        placeholder="Bulk Action"
                        onChange={(e) => onActionText(e)}
                      />
                    </div>
                    <div className="btn-wrap">
                      <span className="d-none d-md-block">
                        <Button
                          disabled={actionText !== "" ? false : true}
                          color="light"
                          outline
                          className="btn-dim"
                          onClick={(e) => onActionClick(e)}
                        >
                          Apply
                        </Button>
                      </span>
                      <span className="d-md-none">
                        <Button
                          color="light"
                          outline
                          disabled={actionText !== "" ? false : true}
                          className="btn-dim  btn-icon"
                          onClick={(e) => onActionClick(e)}
                        >
                          <Icon name="arrow-right"></Icon>
                        </Button>
                      </span>
                    </div> */}
                  </div>
                </div>
                <div className="card-tools mr-n1">
                  <ul className="btn-toolbar gx-1">
                    <li>
                      <a
                        href="#search"
                        onClick={(ev) => {
                          ev.preventDefault();
                          toggle();
                        }}
                        className="btn btn-icon search-toggle toggle-search"
                      >
                        <Icon name="search"></Icon>
                      </a>
                    </li>
                    <li className="btn-toolbar-sep"></li>
                    <li>
                      <div className="toggle-wrap">
                        <Button
                          className={`btn-icon btn-trigger toggle ${tablesm ? "active" : ""}`}
                          onClick={() => updateTableSm(true)}
                        >
                          <Icon name="menu-right"></Icon>
                        </Button>
                        <div className={`toggle-content ${tablesm ? "content-active" : ""}`}>
                          <ul className="btn-toolbar gx-1">
                            <li className="toggle-close">
                              <Button className="btn-icon btn-trigger toggle" onClick={() => updateTableSm(false)}>
                                <Icon name="arrow-left"></Icon>
                              </Button>
                            </li>
                            <li>
                              <UncontrolledDropdown>
                                <DropdownToggle tag="a" className="btn btn-trigger btn-icon dropdown-toggle">
                                  <div className="dot dot-primary"></div>
                                  <Icon name="filter-alt"></Icon>
                                </DropdownToggle>
                                <DropdownMenu
                                  right
                                  className="filter-wg dropdown-menu-xl"
                                  style={{ overflow: "visible" }}
                                >
                                  <div className="dropdown-head">
                                    <span className="sub-title dropdown-title">  {t("search", {ns: 'global'})}</span>
                                    <div className="dropdown">
                                      <DropdownItem
                                        href="#more"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                        }}
                                        className="btn btn-sm btn-icon"
                                      >
                                        <Icon name="more-h"></Icon>
                                      </DropdownItem>
                                    </div>
                                  </div>
                                  <div className="dropdown-body dropdown-body-rg">
                                    <Row className="gx-6 gy-3">
                                      <Col size="6">
                                        <FormGroup>
                                          <label className="overline-title overline-title-alt"> {t("state", {ns: 'global'})}</label>
                                          <RSelect options={filterVisible} placeholder={t("select_state", {ns: 'global'})}  value={filstatus}  onChange={(e) =>
                            setfilstatus(e)
                          }/>
                                        </FormGroup>
                                      </Col>
                                      {/* <Col size="12">
                                        <FormGroup className="form-group">
                                          <Button color="secondary">Filter</Button>
                                        </FormGroup>
                                      </Col> */}
                                    </Row>
                                  </div>
                                  <div className="dropdown-foot between">
                                    <a
                                      href="#reset"
                                      onClick={(ev) => {
                                        ev.preventDefault();
                                        resetFilter();
                                      }}
                                      className="clickable"
                                    >
                                       {t("reset_filters", {ns: 'global'})}
                                    </a>
                                    {/* <a
                                      href="#save"
                                      onClick={(ev) => {
                                        ev.preventDefault();
                                      }}
                                    >
                                      Save Filter
                                    </a> */}
                                  </div>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </li>
                            <li>
                              <UncontrolledDropdown>
                                <DropdownToggle tag="a" className="btn btn-trigger btn-icon dropdown-toggle">
                                  <Icon name="setting"></Icon>
                                </DropdownToggle>
                                <DropdownMenu right className="dropdown-menu-xs">
                                  <ul className="link-check">
                                    <li>
                                    <span>{t("show", {ns: 'global'})}</span>
                                    </li>
                                    <li className={itemPerPage === 10 ? "active" : ""}>
                                      <DropdownItem
                                        tag="a"
                                        href="#dropdownitem"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                          setItemPerPage(10);
                                        }}
                                      >
                                        10
                                      </DropdownItem>
                                    </li>
                                    <li className={itemPerPage === 15 ? "active" : ""}>
                                      <DropdownItem
                                        tag="a"
                                        href="#dropdownitem"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                          setItemPerPage(15);
                                        }}
                                      >
                                        15
                                      </DropdownItem>
                                    </li>
                                  </ul>
                                  <ul className="link-check">
                                    <li>
                                    <span>{t("order", {ns: 'global'})}</span>
                                    </li>
                                    <li className={sort === "dsc" ? "active" : ""}>
                                      <DropdownItem
                                        tag="a"
                                        href="#dropdownitem"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                          setSortState("dsc");
                                          sortFunc("dsc");
                                        }}
                                      >
                                        DESC
                                      </DropdownItem>
                                    </li>
                                    <li className={sort === "asc" ? "active" : ""}>
                                      <DropdownItem
                                        tag="a"
                                        href="#dropdownitem"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                          setSortState("asc");
                                          sortFunc("asc");
                                        }}
                                      >
                                        ASC
                                      </DropdownItem>
                                    </li>
                                  </ul>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className={`card-search search-wrap ${!onSearch && "active"}`}>
                <div className="card-body">
                  <div className="search-content">
                    <Button
                      className="search-back btn-icon toggle-search active"
                      onClick={() => {
                        setSearchText("");
                        toggle();
                      }}
                    >
                      <Icon name="arrow-left"></Icon>
                    </Button>
                    <input
                      type="text"
                      className="border-transparent form-focus-none form-control"
                      placeholder={t("search_by_name_description")}
                      value={onSearchText}
                      onChange={(e) => onFilterChange(e)}
                    />
                    <Button className="search-submit btn-icon">
                      <Icon name="search"></Icon>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <DataTableBody compact>
              <DataTableHead>
                {/* <DataTableRow className="nk-tb-col-check">
                  <div className="custom-control custom-control-sm custom-checkbox notext">
                    <input
                      type="checkbox"
                      className="custom-control-input form-control"
                      onChange={(e) => selectorCheck(e)}
                      id="uid"
                    />
                    <label className="custom-control-label" htmlFor="uid"></label>
                  </div>
                </DataTableRow> */}
                <DataTableRow>
                  <span className="sub-text">{t("tbl1")}</span>
                </DataTableRow>
                <DataTableRow size="md">
                  <span className="sub-text">{t("tbl2")}</span>
                </DataTableRow>
                <DataTableRow size="sm">
                  <span className="sub-text">{t("tbl3")}</span>
                </DataTableRow>
                <DataTableRow size="sm">
                  <span className="sub-text">{t("STATUS",{ns:"global"})}</span>
                </DataTableRow>
                <DataTableRow size="sm">
                  <span className="sub-text">{t("tbl4")}</span>
                </DataTableRow>
              </DataTableHead>
              {/*Head*/}
              {currentItems.length > 0
                ? currentItems.map((item) => {
                    return (
                      <DataTableItem key={item.id}>
                        {/* <DataTableRow className="nk-tb-col-check">
                          <div className="custom-control custom-control-sm custom-checkbox notext">
                            <input
                              type="checkbox"
                              className="custom-control-input form-control"
                              defaultChecked={item.checked}
                              id={item.id + "uid1"}
                              key={Math.random()}
                              onChange={(e) => onSelectChange(e, item.id)}
                            />
                            <label className="custom-control-label" htmlFor={item.id + "uid1"}></label>
                          </div>
                        </DataTableRow> */}
                        <DataTableRow>
                          <Link to={`${process.env.PUBLIC_URL}/user-details-regular/${item.name}`}>
                            <div className="user-card">
                              {/* <UserAvatar
                                theme={item.name}
                                className="xs"
                                text={item.name}
                                image={item.name}
                              ></UserAvatar> */}
                              <div className="user-info">
                                <span className="tb-lead">{item.name}</span>
                              </div>
                            </div>
                          </Link>
                        </DataTableRow>
                        <DataTableRow size="md">
                          <span>{item.description}</span>
                        </DataTableRow>
                        <DataTableRow size="sm">
                          <span>{item.price}</span>
                        </DataTableRow>
                        {/* <DataTableRow size="md">
                          <span>Si</span>
                        </DataTableRow> */}
                        {/* <DataTableRow size="xxl">
                          <span>{item.country}</span>
                        </DataTableRow> */}
                        <DataTableRow size="lg">
                          <ul className="list-status">
                            <li>
                              <Icon
                                className={`text-${
                                  item.visible === 2 ? "danger" : "success"

                                }`}
                                name={`${
                                  item.visible === 1
                                    ? "check-circle"
                                    : "alert-circle"
                                }`}
                              ></Icon>{" "}
                              {/* <span>Email</span> */}
                            </li>
                          </ul>
                        </DataTableRow>
                        {/* <DataTableRow>
                          <span
                            className={`tb-status text-${
                              item.status.description === "Active" ? "success" : item.status.description === "Pending" ? "warning" : "danger"
                            }`}
                          >
                            {item.status.description}
                          </span>
                        </DataTableRow> */}
                        <DataTableRow className="nk-tb-col-tools">
                          <ul className="nk-tb-actions gx-1">
                            {/* Update */}
                            <li className="nk-tb-action-hidden" onClick={() => onEditClick(item.id)}>
                              <TooltipComponent
                                tag="a"
                                containerClassName="btn btn-trigger btn-icon"
                                id={"edit" + item.id}
                                icon="edit-alt-fill"
                                direction="top"
                                text={t('modify',{ns:'global'})}
                              />
                            </li>
                            {/* Activate */}
                            <li className="nk-tb-action-hidden" onClick={() => {
                                        handleSwitchChange(
                                        item,
                                        item.visible,
                                        );
                                    }} >
                                <TooltipComponent
                                tag="a"
                                containerClassName="btn btn-trigger btn-icon"
                                id={"suspend" + item.id}
                                icon="user-cross-fill"
                                direction="top"
                                text={t('activate/desactivate',{ns:'global'})}
                                />
                            </li>
                            {/* Trash */}
                            { item.id != 1 && item.id != 2 ?
                            <li className="nk-tb-action-hidden"  onClick={() =>
                                            deleter(item.id)
                                          }>
                              <TooltipComponent
                                tag="a"
                                containerClassName="btn btn-trigger btn-icon"
                                id={"delete" + item.id}
                                icon="trash-fill"
                                direction="top"
                                text={t('delete',{ns:'global'})}
                              />
                            </li>

                            : null }
                        {/* ... */}
                            <li>
                              <UncontrolledDropdown>
                                <DropdownToggle tag="a" className="dropdown-toggle btn btn-icon btn-trigger">
                                  <Icon name="more-h"></Icon>
                                </DropdownToggle>
                                <DropdownMenu right>
                                  <ul className="link-list-opt no-bdr">
                                    <li onClick={() => onEditClick(item.id)}>
                                      <DropdownItem
                                        tag="a"
                                        href="#edit"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                        }}
                                      >
                                        <Icon name="edit"></Icon>
                                        <span>{t('modify',{ns:'global'})}</span>
                                      </DropdownItem>
                                    </li>


                                        <li className="divider"></li>
                                        <li onClick={() => {
                                          handleSwitchChange(
                                            item,
                                            item.visible,
                                          );
                                        }}>
                                          <DropdownItem
                                            tag="a"
                                            // href="#suspend"
                                            onClick={() => {
                                              handleSwitchChange(
                                                item,
                                                item.visible,
                                              );
                                            }}
                                          >
                                            <Icon name="na"></Icon>
                                            <span>{t('activate/desactivate',{ns:'global'})}</span>
                                          </DropdownItem>
                                        </li>


                                  </ul>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </li>
                          </ul>
                        </DataTableRow>
                      </DataTableItem>
                    );
                  })
                : null}
            </DataTableBody>
            <div className="card-inner">
              {currentItems.length > 0 ? (
                <PaginationComponent
                  itemPerPage={itemPerPage}
                  totalItems={data.length}
                  // pageCount={pageCount} //NUMERO TOTAL DE PÁGINAS
                  // onPageChange={handlePageClick} //FUNCION QUE SE LLAMA EN EL onChange DE LA PÁGINA
                  paginate={paginate}
                  currentPage={currentPage}
                />
                // <div>{paginationElement}</div>
              ) : (
                <div className="text-center">
                  <span className="text-silent">No data found</span>
                </div>
              )}
            </div>
          </DataTable>
        </Block>

        {/* Modal add */}

        <Modal isOpen={modal.add} toggle={() => setModal({ add: false })} className="modal-dialog-centered" size="lg">
          <ModalBody>
            <a
              href="#cancel"
              onClick={(ev) => {
                ev.preventDefault();
                onFormCancel();
              }}
              className="close"
            >
              <Icon name="cross-sm"></Icon>
            </a>
            <div className="p-2">
              <h5 className="title">{t("ttl1")}</h5>
              <div className="mt-4">
                {/* <Form className="row gy-4" onSubmit={handleSubmit(onFormSubmit)}> */}
                <Form className="row gy-4" onSubmit={handleSubmit(AddService)}>
                  <Col md="6">
                    <FormGroup>
                      <label className="form-label">{t("lbl4")}</label>
                      <input
                        className="form-control"
                        type="text"
                        name="name"
                        defaultValue={formData.name}
                        // placeholder={t("lbl37")}
                        ref={register({ required: "This field is required" })}
                      />
                      {errors.name && <span className="invalid">{errors.name.message}</span>}
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <label className="form-label">{t("lbl5")} </label>
                      <input
                        className="form-control"
                        type="text"
                        name="description"
                        defaultValue={formData.description}
                        // placeholder={t("lbl38")}
                        ref={register({
                          required: "This field is required",
                        //   pattern: {
                        //     value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        //     message: "invalid email address",
                        //   },
                        })}
                      />
                      {errors.description && <span className="invalid">{errors.description.message}</span>}
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <label className="form-label">{t("lbl7")} </label>
                      <input
                        className="form-control"
                        type="text"
                        name="price"
                        defaultValue={formData.price}
                        // placeholder={t("lbl38")}
                        ref={register({
                          required: "This field is required",
                        //   pattern: {
                        //     value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        //     message: "invalid price",
                        //   },
                        })}
                      />
                      {errors.price && <span className="invalid">{errors.price.message}</span>}
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <label className="form-label">{t("status", {ns:"global"})}</label>
                      <div className="form-control-wrap">
                        <RSelect
                          name="visible"
                          id="visible"
                          options={filterVisible}
                          defaultValue={{ value: formData.visible, label: formData.visible }}
                          onChange={(e) => setFormData({ ...formData, visible: e.value })}
                        />
                      </div>
                    </FormGroup>
                  </Col>
                  <Col size="12">
                    <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                      <li>
                        <Button color="primary" size="md" type="submit">
                        {t("lbl6")}
                        </Button>
                      </li>
                      <li>
                        <a
                          href="#cancel"
                          onClick={(ev) => {
                            ev.preventDefault();
                            onFormCancel();
                          }}
                          className="link link-light"
                        >
                          {t("cancel",{ns:'global'})}
                        </a>
                      </li>
                    </ul>
                  </Col>
                </Form>
              </div>
            </div>
          </ModalBody>
        </Modal>

        {/* Modal Update */}
        <Modal isOpen={modal.edit} toggle={() => setModal({ edit: false })} className="modal-dialog-centered" size="lg">
          <ModalBody>
            <a
              href="#cancel"
              onClick={(ev) => {
                ev.preventDefault();
                onFormCancel();
              }}
              className="close"
            >
              <Icon name="cross-sm"></Icon>
            </a>
            <div className="p-2">
              <h5 className="title">{t("tbl6")}</h5>
              <div className="mt-4">
                <Form className="row gy-4" onSubmit={handleSubmit(onEditSubmit)}>
                <Col md="6">
                    <FormGroup>
                      <label className="form-label">{t("lbl4")}</label>
                      <input
                        className="form-control"
                        type="text"
                        name="name"
                        defaultValue={formData.name}
                        // placeholder={t("lbl37")}
                        ref={register({ required: "This field is required" })}
                      />
                      {errors.name && <span className="invalid">{errors.name.message}</span>}
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <label className="form-label">{t("lbl5")} </label>
                      <input
                        className="form-control"
                        type="text"
                        name="description"
                        defaultValue={formData.description}
                        // placeholder={t("lbl38")}
                        ref={register({
                          required: "This field is required",
                        //   pattern: {
                        //     value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        //     message: "invalid email address",
                        //   },
                        })}
                      />
                      {errors.description && <span className="invalid">{errors.description.message}</span>}
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <label className="form-label">{t("lbl7")} </label>
                      <input
                        className="form-control"
                        type="text"
                        name="price"
                        defaultValue={formData.price}
                        // placeholder={t("lbl38")}
                        ref={register({
                          required: "This field is required",
                        //   pattern: {
                        //     value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        //     message: "invalid price",
                        //   },
                        })}
                      />
                      {errors.price && <span className="invalid">{errors.price.message}</span>}
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <label className="form-label">{t("status", {ns:"global"})}</label>
                      <div className="form-control-wrap">
                        <RSelect
                          name="visible"
                          id="visible"
                          options={filterVisible}
                          defaultValue={{ value: formData.visible, label: formData.visible }}
                          onChange={(e) => setFormData({ ...formData, visible: e.value })}
                        />
                      </div>
                    </FormGroup>
                  </Col>
                  <Col size="12">
                    <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                      <li>
                        <Button color="primary" size="md" type="submit">
                        {t("lbl11")}
                        </Button>
                      </li>
                      <li>
                        <a
                          href="#cancel"
                          onClick={(ev) => {
                            ev.preventDefault();
                            onFormCancel();
                          }}
                          className="link link-light"
                        >
                          {t("cancel",{ns:'global'})}
                        </a>
                      </li>
                    </ul>
                  </Col>
                </Form>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </Content>
    </React.Fragment>
  );
};
export default ServiceListCompact;

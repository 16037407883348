import React, { Component, useEffect, useState } from "react";
// import withFirebaseAuth from "react-with-firebase-auth";
// // import firebase from "firebase/app";
// import "firebase/auth";
// // import firebaseConfig from "../Login/firebaseConfig";
import Footer from "../Footer/Footer";

import About from "../pages/About"
// import  Homep from "../pages/Homep"
import  HomeCompany from "../pages/HomePageCompany"
import  HomeApplicant from "../pages/HomePageApplicant"
import  HomeComporApp from "../pages/HomeComporApp"
import  Canada from "../pages/Canada"
import  MisionVision from "../pages/VisionMison"
import  Contact from "../pages/Contact"
import  Portal from "../pages/Portal"


// // const firebaseApp = !firebase.apps.length
  // // ? firebase.initializeApp(firebaseConfig)
  // : firebase.app();

const Home = (props) => {

  const [ state, setstate ] = useState({homeopc: "1"})

  const changeHome = (opc) => {
    setstate({ homeopc:opc })
  }

  useEffect(()=>{
    changeHome(props.opc)
  }, [props.timechange])

    return (
      <>
          <div>
            {
                props.opc =="1"
                  ?(
                    <>
                      {
                        state.homeopc == 1 ?
                          <div>
                            <HomeComporApp changeHome={changeHome} history={props.history} match={props.match} />
                          </div>
                        : ""
                      }
                      {
                        state.homeopc == 2 ?
                          <div className="position-relative backthitehome">
                            <HomeCompany changeHome={changeHome}  changeOpc={props.changeOpc} history={props.history}/>
                          </div>
                          : ""
                      }
                      {
                        state.homeopc == 3 ?
                        <div className="position-relative backthitehome">
                          <HomeApplicant changeHome={changeHome} changeOpc={props.changeOpc} history={props.history}/>
                        </div>
                        : ""
                      }
                    </>
                  )
                  :(
                        props.opc =="2"
                        ?(
                            <About/>
                        )
                        :(
                          props.opc =="3"
                          ?(
                              <Canada/>
                          )
                          :(
                            props.opc =="4"
                            ?(
                                <MisionVision/>
                            )
                            :(
                              props.opc =="5"
                              ?(
                                <Contact />
                              )
                              :(
                                <Portal/>
                              )

                            )
                          )
                        )
                  )
            }

          </div>

        <Footer changeOpc={props.changeOpc} opc={props.opc} />
      </>
    );
  }
// }

// // const firebaseAppAuth = firebaseApp.auth();

// const providers = {
  // googleProvider: new firebase.auth.GoogleAuthProvider(),
  // facebookProvider: new firebase.auth.FacebookAuthProvider(),
// };

// export default withFirebaseAuth({providers,firebaseAppAuth,})(Home);

export default Home
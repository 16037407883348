var menu = [];
    menu = [
      { heading: "home" },
      {
        icon: "cc-alt2",
        text: "menu_home",
        link: "/company",
      },
      { heading: "joffers" },
      {
        icon: "check-round",
        text: "my_Offers",
        link: "/jobs_company",
      },
      { heading: "candidates" },
      {
        icon: "users",
        text: "menu_list_candidates",
        link: "/candidates-list-compact",
      },
      { heading: "profile" },
      {
        icon: "users",
        text: "myprofile",
        link: "/user-profile-regular",
      },
    ];

 export default menu;





import React, { Component } from "react";
import { useTranslation } from "react-i18next";

import geojobs from "../img/goejobs.png";
import img_1 from "../img/1.jpeg";
import geojobscanada from "../img/imghrh.png";
import geojobs11 from "../img/11.png";
import photo1 from "../img/photo1.png";
import photo2 from "../img/photo2.png";



const About = () => {
  const [t, i18n] = useTranslation("about","footer")
  // i18n.changeLanguage("")
    return (
      <>
        <div className="item-group" xyz="fade">
          <div className="square xyz-in">
            {/* <div className="row  d-flex align-items-center  h-100 my-4">
              <div className="col-12 col-sm-12 col-md-4 center-block centrador ">
                <img src={geojobscanada} className="img-fluid" />
              </div>
              <div className="col-12 col-sm-12 col-md-8 center-block ">
                <div className="letreroWeb  fs-1 px-5 ">{t("about")}</div>

                <p className="txtPequenoAzul mt-3 px-5 fontmont">
                  {t("text1")}
                </p>

                <p className="txtPequenoAzul mt-3 px-5 fontmont">
                  {t("text2")}
                </p>
              </div>
            </div> */}
            <div className="row  d-flex align-items-center  h-100">
              <div className="col-12 col-sm-12 col-md-7 center-block ">
                <div className="letreroWeb  fs-1 px-5 ">{t("about")}</div>

                <p className="txtPequenoAzul mt-3 px-5 fontmont">
                  {t("text3")}
                </p>

                <p className="txtPequenoAzul mt-3 px-5 fontmont">
                  {t("text4")}
                </p>
                {/* permise */}
              <b><div className="txtPequenoAzul fs-1 px-5 ">{t("permise", {ns: 'footer'})}</div></b>
              </div>
              <div className="col-12 col-sm-12 col-md-5 center-block person ">
                {/* <img src={geojobs11} className="img-fluid" /> */}
                <img src={photo1} className="img-fluid" />
              </div>
            </div>
          </div>

          <div className="contentAbout"></div>

          <div className="row  d-flex align-items-center  h-100 my-4">
            <div className="col-12 col-sm-12 col-md-7 center-block ">
              <div className="letreroWeb  fs-1 px-5 ">{t("aliances")}</div>

              <p className="txtPequenoAzul mt-3 px-5 fontmont">
                {t("text5")}
              </p>

              <div className="italic mt-5 px-5">
                <div className="letreroWeb  fs-1 px-5 ">{t("gpr")}</div>
                <p className="txtPequenoAzul mt-3 px-5">
                  "{t("text6")}"
                </p>
              </div>
            </div>

            <div className="col-12 col-sm-12 col-md-5 center-block person ">
              {/* <img src={img_1} className="img-fluid" /> */}
              <img src={photo2} className="img-fluid" />
            </div>
          </div>
        </div>
      </>
    );
}

export default About;

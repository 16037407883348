import React, { useState, useEffect, useContext } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import { findUpper } from "../../../utils/Utils";
import { userData, filterRole, filterStatus, filterLanguage } from "./UserData";
import {
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  UncontrolledDropdown,
  Modal,
  ModalBody,
  DropdownItem,
  Form,
} from "reactstrap";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Row,
  Col,
  UserAvatar,
  PaginationComponent,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableRow,
  DataTableItem,
  Button,
  RSelect,
  TooltipComponent,
} from "../../../components/Component";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { UserContext } from "./UserContext";
import axios from "axios";
import { bulkActionOptions } from "../../../utils/Utils";
import UserProfile from "../../../Login/UserProfile";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import ReactPaginate from "react-paginate";

const UserListCompact = (props) => {
  const [t, i18n] = useTranslation("useradm")
  const { contextData } = useContext(UserContext);
  // data is the response from the API with order or filter applied by the user in the front
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);

  const [sm, updateSm] = useState(false);
  const [tablesm, updateTableSm] = useState(false);
  const [onSearch, setonSearch] = useState(true);
  const [onSearchText, setSearchText] = useState("");
  const [modal, setModal] = useState({
    edit: false,
    add: false,
  });
  const [editId, setEditedId] = useState();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    type: "",
    status: 1,
  });
  const [actionText, setActionText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(10);
  const [sort, setSortState] = useState("");

  const [ filtypeuser, setfiltypeuser ] = useState("");
  const [ filstatus, setfilstatus ] = useState("");

  // Sorting data
  const sortFunc = (params) => {
    let defaultData = data;
    if (params === "asc") {
      let sortedData = defaultData.sort((a, b) => a.name.localeCompare(b.name));
      setData([...sortedData]);
    } else if (params === "dsc") {
      let sortedData = defaultData.sort((a, b) => b.name.localeCompare(a.name));
      setData([...sortedData]);
    }
  };

  // unselects the data on mount
  // useEffect(() => {
  //   let newData;
  //   newData = data.map((item) => {
  //     item.checked = false;
  //     return item;
  //   });
  //   setData([...newData]);
  // }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Changing state value when searching name
  useEffect(() => {
    if (onSearchText !== "") {
      // console.log(data, "data");
      const filteredObject = users.filter((item) => {
        return (
          item.name.toLowerCase().includes(onSearchText.toLowerCase()) ||
          item.email.toLowerCase().includes(onSearchText.toLowerCase())
        );
      });
      // console.log(filteredObject, "filteredObject");
      setData([...filteredObject]);
    } else {
      setData([...data]);
    }
  }, [onSearchText, setData]);

  // onChange function for searching name
  const onFilterChange = (e) => {
    setSearchText(e.target.value);
  };

  // // function to change the selected property of an item
  // const onSelectChange = (e, id) => {
  //   let newData = data;
  //   let index = newData.findIndex((item) => item.id === id);
  //   newData[index].checked = e.currentTarget.checked;
  //   setData([...newData]);
  // };

//  filter by type user and status
  useEffect(() => {
    if (filtypeuser !== "" && filstatus === "") {
      const filteredObject = users.filter((item) => {
        return (
          item.type.id == filtypeuser.value
        );
      });
      setData([...filteredObject]);
    } else if(filtypeuser != "" && filstatus != "") {
      const filteredObject = users.filter((item) => {
        return (
          item.type.id == filtypeuser.value && item.status.id == filstatus.value
        );
      });
      setData([...filteredObject]);
    }
    else if(filtypeuser == "" && filstatus != "") {
      const filteredObject = users.filter((item) => {
        return (
          item.status.id == filstatus.value
        );
      });
      setData([...filteredObject]);
    }
    else {
      setData([...data]);
    }
  }, [filtypeuser, filstatus, setData]);

// function to reset the form
const resetFilter = () => {
  setfilstatus("");
  setfiltypeuser("");
  setData([...users]);
};


  // function to set the action to be taken in table header
  const onActionText = (e) => {
    setActionText(e.value);
  };

  // function to reset the form
  const resetForm = () => {
    setFormData({
      name: "",
      email: "",
      type:1,
      password: "",
      status: 1,
    });
  };

  // function to close the form modal
  const onFormCancel = () => {
    setModal({ edit: false, add: false });
    resetForm();
  };

  // // submit function to add a new item
  // const onFormSubmit = (submitData) => {
  //   const { name, email, type, password, status } = submitData;
  //   let submittedData = {
  //     // id: data.length + 1,
  //     // avatarBg: "purple",
  //     name: name,
  //     type: type,
  //     email: email,
  //     password: password,
  //     type: type,
  //     status: status
  //     // balance: balance,
  //     // phone: phone,
  //     // emailStatus: "success",
  //     // kycStatus: "alert",
  //     // lastLogin: "10 Feb 2020",
  //     // status: 1,

  //   };
  //   setData([submittedData, ...data]);
  //   resetForm();
  //   setModal({ edit: false }, { add: false });
  // };

  const onEditSubmit = async (submitData) => {
    // console.log(formData.status,'type');
    const valuesupdateData = {
      name: submitData.name,
      email: submitData.email,
      type: formData.role,
      };
      if (submitData.password.length != "") {
        valuesupdateData.password = submitData.password
      }
      if (typeof(formData.status) == "object") {
        valuesupdateData.status = formData.status.id
      }
      else {
        valuesupdateData.status = formData.status
      }
      axios
          .put(
            `${process.env.REACT_APP_BASE_URL}/api/users/${editId}`,
            valuesupdateData,
            {
              headers: {
                Authorization: `Bearer  ${UserProfile.getToken()}`,
              }
            }
          )
          .then((res) => {
            if (res.data.status == true) {
              Swal.fire({
                icon: "success",
                title: t("msg3"),
                text: t("msg6"),
                confirmButtonColor: "#071b30",
                confirmButtonText: t("ok"),
              });
              setModal({ edit: false });
              list();
            }else{
              console.log(res)
            }
          })
          .catch((error) => console.log(error));
    // document.getElementById("updateModalUser").click();
  };

  // // submit function to update a new item
  // const onEditSubmit = (submitData) => {
  //   const { name, email} = submitData;
  //   let submittedData;
  //   let newitems = data;
  //   newitems.forEach((item) => {
  //     if (item.id === editId) {
  //       submittedData = {
  //         id: item.id,
  //         // avatarBg: item.avatarBg,
  //         name: name,
  //         // image: item.image,
  //         // role: item.type,
  //         email: email,
  //         // balance: formData.balance,
  //         // phone: "+" + phone,
  //         // emailStatus: item.emailStatus,
  //         // kycStatus: item.kycStatus,
  //         // lastLogin: item.lastLogin,
  //         // status: formData.status,
  //         // country: item.country,
  //       };
  //     }
  //   });
  //   let index = newitems.findIndex((item) => item.id === editId);
  //   newitems[index] = submittedData;
  //   setModal({ edit: false });
  // };

  // function that loads the want to editted data
  const onEditClick = (id) => {

    users.forEach((item) => {
      if (item.id === id) {
        setFormData({
          name: item.name,
          email: item.email,
          status: item.status,
          type: item.type,
          password: item.password,
        });
        setModal({ edit: true }, { add: false });
        setEditedId(id);
      }
    });

  };

  // // function to change to suspend property for an item
  // const suspendUser = (id) => {
  //   let newData = data;
  //   let index = newData.findIndex((item) => item.id === id);
  //   newData[index].status = "Suspend";
  //   setData([...newData]);
  // };

  // // function which fires on applying selected action
  // const onActionClick = (e) => {
  //   if (actionText === "suspend") {
  //     let newData = data.map((item) => {
  //       if (item.checked === true) item.status = "Suspend";
  //       return item;
  //     });
  //     setData([...newData]);
  //   } else if (actionText === "delete") {
  //     let newData;
  //     newData = data.filter((item) => item.checked !== true);
  //     setData([...newData]);
  //   }
  // };

  // // function which selects all the items
  // const selectorCheck = (e) => {
  //   let newData;
  //   newData = data.map((item) => {
  //     item.checked = e.currentTarget.checked;
  //     return item;
  //   });
  //   setData([...newData]);
  // };

  // function to toggle the search option
  const toggle = () => setonSearch(!onSearch);

  const { errors, register, handleSubmit } = useForm();

  // Get current list, pagination
  const indexOfLastItem = currentPage * itemPerPage;
  const indexOfFirstItem = indexOfLastItem - itemPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  // Change Page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);






  // users is the response from the API without filtering, sorting or anytype of manipulation from the front
  const [ users, setusers ] = useState([]);
  // const [ usersTem, setusersTem ] = useState([]);
  // const [ typeDocument, settypeDocument ] = useState([]);
  const [ typeUser, settypeUser ] = useState([]);
  // const [ UsuarioForm, setUsuarioForm ] = useState([]);
  // const [ estado, setestado ] = useState(0);
  // const [ filter, setfilter ] = useState(0);
  //    /*paginacion*/
  // const [ pageNex, setpageNex ] = useState(0);
  // const [ pageCount, setpageCount ] = useState(0);
  // const [ selectedPage, setselectedPage ] = useState(1);

  const AddUser = async (value) => {
    const user ={
        name: value.name,
        email: value.email,
        password: value.password,
        // Using formData because is a component RSelect
        type: formData.type,
        lang: formData.lang,
        status: formData.status,
    }

    axios
      .post(`${process.env.REACT_APP_BASE_URL}/api/users`,user,
    {
      headers: {
        Authorization: `Bearer  ${UserProfile.getToken()}`,
      }
    })
      .then((res) => {
        if (res.data.status == true) {
          Swal.fire({
            icon: "success",
            title: t("msg2"),
            text: t("msg7"),
            confirmButtonColor: "#071b30",
            confirmButtonText: t("ok"),
          });
          // Close the modal
          resetForm();
          setModal({ edit: false }, { add: false });
          // Get again all users
          list();

        }else{
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: res.data.error[0],
            confirmButtonColor: "#071b30",
            confirmButtonText: t("ok"),
          });
          // setModal({ edit: false }, { add: false });
        }
      })
      .catch((error) =>
      console.log(error));
      // Swal.fire({
      //   icon: "error",
      //   title: "Oops...",
      //   text: res.data.error[0],
      //   confirmButtonColor: "#071b30",
      //   confirmButtonText: t("ok"),
      // });

  };


  const ListTypeUser = async () => {
    settypeUser([])
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/user_type`,
    {
      headers: {
        Authorization: `Bearer  ${UserProfile.getToken()}`,
      }
    })
      .then((res) => {
        settypeUser(res.data.data.user_type)
      })
      .catch((error) => console.log(error));
  };



 const list = async (search) => {
      setusers([])
      // var newsearch = {...search, page : selectedPage}
      var newsearch = {...search}
      console.log(newsearch,'newsearch');
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/users`,
      {
        headers: {
          Authorization: `Bearer  ${UserProfile.getToken()}`,
        },
        params: newsearch
    })
      .then((res) => {
        if (res.data.error ? res.data.error[0] == 'El Token a Expirado' : false) {props.history.push('/login'); return}
        setusers(res.data.data.users)
        setData(res.data.data.users)
        setTotal(res.data.data.total)

        // console.log(getData,'setusers')
        // setData(res.data.data.users);

        //console.log(res.data.data.users,'users');
        // setpageCount(res.data.data.pages)
      })
      .catch((error) => console.log(error));
  };

  const handleSwitchChange = async (value,estado) => {
    if (estado == 1) {
      const valuesupdate = {
        // id: value.id,
        // name: value.name,
        // email: value.email,
        // type: value.type,
        status: 2
      };
      axios
        .put(
          `${process.env.REACT_APP_BASE_URL}/api/users/${value.id}`,
          valuesupdate,
          {
          headers: {
            Authorization: `Bearer  ${UserProfile.getToken()}`,
          }
        }

        )
        .then((res) => {
          if (res.data.status == true) {
            Swal.fire({
              icon: "success",
              title: t("msg3"),
              text: t("msg4"),
              confirmButtonColor: "#071b30",
              confirmButtonText: t("ok"),
            });
            list();
          }else{
            console.log(res)
          }
        })
        .catch((error) => console.log(error));
    } else {
      const valuesupdate = {
        // name: value.name,
        // email: value.email,
        // type: value.type,
        status: 1
      };
      axios
        .put(
          `${process.env.REACT_APP_BASE_URL}/api/users/${value.id}`,
          valuesupdate,
          {
            headers: {
              Authorization: `Bearer  ${UserProfile.getToken()}`,
            }
          }
        )
        .then((res) => {
          if (res.data.status == true) {
            Swal.fire({
              icon: "success",
              title: t("msg3"),
              text: t("msg5"),
              confirmButtonColor: "#071b30",
              confirmButtonText: t("ok"),
            });
            list();
          }else{
            console.log(res)
          }
        })
        .catch((error) => console.log(error));
    }
};


  useEffect(() => {
    if (navigator.onLine) {
      UserProfile.setToken(localStorage.getItem('token'));
      list();
      ListTypeUser()
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: t("msg1"),
        confirmButtonColor: "#071b30",
        confirmButtonText: t("ok"),
      });
    }
  }, [])



//  useEffect(() => {
//   handleClickFilter()
// },[data, filtypeuser, filstatus])

  // const handlePageClick = (val) => {
  //   setselectedPage(val.selected+1)
  //   setpageNex(val.selected)
  // }


  // let paginationElement;
  //   if (pageCount > 1) {
  //     paginationElement = (
  //       <ReactPaginate
  //         previousLabel={"Prev"}
  //         nextLabel={"Sig"}
  //         breakLabel={"..."}
  //         breakClassName={"break-me"}
  //         pageCount={pageCount} //NUMERO TOTAL DE PÁGINAS
  //         onPageChange={handlePageClick} //FUNCION QUE SE LLAMA EN EL onChange DE LA PÁGINA
  //         forcePage={pageNex}
  //         containerClassName={"pagination justify-content-center"}
  //         subContainerClassName={"pages pagination"}
  //         activeClassName={"active"}
  //       />
  //     );
  //   }

  return (
    <React.Fragment>
      <Head title="User List "></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h3" page>
              {t("lbl31")}
              </BlockTitle>
              <BlockDes className="text-soft">
                <p>{t("lbl32")} {total} {t("lbl2")}.</p>
              </BlockDes>
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <Button
                  className={`btn-icon btn-trigger toggle-expand mr-n1 ${sm ? "active" : ""}`}
                  onClick={() => updateSm(!sm)}
                >
                  <Icon name="menu-alt-r"></Icon>
                </Button>
                <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                  <ul className="nk-block-tools g-3">
                    {/* <li>
                      <a
                        href="#export"
                        onClick={(ev) => {
                          ev.preventDefault();
                        }}
                        className="btn btn-white btn-outline-light"
                      >
                        <Icon name="download-cloud"></Icon>
                        <span>Export</span>
                      </a>
                    </li> */}
                    <li className="nk-block-tools-opt">
                      <Button color="primary" className="btn-icon" onClick={() => setModal({ add: true })}>
                        <Icon name="plus"></Icon>
                      </Button>
                    </li>
                  </ul>
                </div>
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>

        <Block>
          <DataTable className="card-stretch">
            {/* Filters, search, order */}
            <div className="card-inner position-relative card-tools-toggle">
              <div className="card-title-group">
              <div className="card-title">
                  <h5 className="title">{t("lbl33")}</h5>
                </div>
                <div className="card-tools">
                  <div className="form-inline flex-nowrap gx-3">
                    {/* <div className="form-wrap">
                      <RSelect
                        options={bulkActionOptions}
                        className="w-130px"
                        placeholder="Bulk Action"
                        onChange={(e) => onActionText(e)}
                      />
                    </div>
                    <div className="btn-wrap">
                      <span className="d-none d-md-block">
                        <Button
                          disabled={actionText !== "" ? false : true}
                          color="light"
                          outline
                          className="btn-dim"
                          onClick={(e) => onActionClick(e)}
                        >
                          Apply
                        </Button>
                      </span>
                      <span className="d-md-none">
                        <Button
                          color="light"
                          outline
                          disabled={actionText !== "" ? false : true}
                          className="btn-dim  btn-icon"
                          onClick={(e) => onActionClick(e)}
                        >
                          <Icon name="arrow-right"></Icon>
                        </Button>
                      </span>
                    </div> */}
                  </div>
                </div>
                <div className="card-tools mr-n1">
                  <ul className="btn-toolbar gx-1">
                    <li>
                      <a
                        href="#search"
                        onClick={(ev) => {
                          ev.preventDefault();
                          toggle();
                        }}
                        className="btn btn-icon search-toggle toggle-search"
                      >
                        <Icon name="search"></Icon>
                      </a>
                    </li>
                    <li className="btn-toolbar-sep"></li>
                    <li>
                      <div className="toggle-wrap">
                        <Button
                          className={`btn-icon btn-trigger toggle ${tablesm ? "active" : ""}`}
                          onClick={() => updateTableSm(true)}
                        >
                          <Icon name="menu-right"></Icon>
                        </Button>
                        <div className={`toggle-content ${tablesm ? "content-active" : ""}`}>
                          <ul className="btn-toolbar gx-1">
                            <li className="toggle-close">
                              <Button className="btn-icon btn-trigger toggle" onClick={() => updateTableSm(false)}>
                                <Icon name="arrow-left"></Icon>
                              </Button>
                            </li>
                            <li>
                              <UncontrolledDropdown>
                                <DropdownToggle tag="a" className="btn btn-trigger btn-icon dropdown-toggle">
                                  <div className="dot dot-primary"></div>
                                  <Icon name="filter-alt"></Icon>
                                </DropdownToggle>
                                <DropdownMenu
                                  right
                                  className="filter-wg dropdown-menu-xl"
                                  style={{ overflow: "visible" }}
                                >
                                  <div className="dropdown-head">
                                    <span className="sub-title dropdown-title">  {t("lbl1")}</span>
                                    <div className="dropdown">
                                      <DropdownItem
                                        href="#more"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                        }}
                                        className="btn btn-sm btn-icon"
                                      >
                                        <Icon name="more-h"></Icon>
                                      </DropdownItem>
                                    </div>
                                  </div>
                                  <div className="dropdown-body dropdown-body-rg">
                                    <Row className="gx-6 gy-3">
                                      {/* <Col size="6">
                                        <div className="custom-control custom-control-sm custom-checkbox">
                                          <input
                                            type="checkbox"
                                            className="custom-control-input form-control"
                                            id="hasBalance"
                                          />
                                          <label className="custom-control-label" htmlFor="hasBalance">
                                            {" "}
                                            Have Balance
                                          </label>
                                        </div>
                                      </Col>
                                      <Col size="6">
                                        <div className="custom-control custom-control-sm custom-checkbox">
                                          <input
                                            type="checkbox"
                                            className="custom-control-input form-control"
                                            id="hasKYC"
                                          />
                                          <label className="custom-control-label" htmlFor="hasKYC">
                                            {" "}
                                            KYC Verified
                                          </label>
                                        </div>
                                      </Col> */}
                                      <Col size="6">
                                        <FormGroup>
                                          <label className="overline-title overline-title-alt">  {t("lbl3")}</label>
                                          <RSelect options={filterRole} placeholder={t("lbl39")} value={filtypeuser} onChange={(e) =>
                            setfiltypeuser(e)
                          } />
                                        </FormGroup>
                                      </Col>
                                      <Col size="6">
                                        <FormGroup>
                                          <label className="overline-title overline-title-alt">  {t("lbl6")}</label>
                                          <RSelect options={filterStatus} placeholder={t("lbl41")}  value={filstatus}  onChange={(e) =>
                            setfilstatus(e)
                          }/>
                                        </FormGroup>
                                      </Col>
                                      {/* <Col size="12">
                                        <FormGroup className="form-group">
                                          <Button color="secondary">Filter</Button>
                                        </FormGroup>
                                      </Col> */}
                                    </Row>
                                  </div>
                                  <div className="dropdown-foot between">
                                    <a
                                      href="#reset"
                                      onClick={(ev) => {
                                        ev.preventDefault();
                                        resetFilter();
                                      }}
                                      className="clickable"
                                    >
                                      {t("lbl30")}
                                    </a>
                                    {/* <a
                                      href="#save"
                                      onClick={(ev) => {
                                        ev.preventDefault();
                                      }}
                                    >
                                      Save Filter
                                    </a> */}
                                  </div>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </li>
                            <li>
                              <UncontrolledDropdown>
                                <DropdownToggle tag="a" className="btn btn-trigger btn-icon dropdown-toggle">
                                  <Icon name="setting"></Icon>
                                </DropdownToggle>
                                <DropdownMenu right className="dropdown-menu-xs">
                                  <ul className="link-check">
                                    <li>
                                      <span>{t("lbl42")}</span>
                                    </li>
                                    <li className={itemPerPage === 10 ? "active" : ""}>
                                      <DropdownItem
                                        tag="a"
                                        href="#dropdownitem"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                          setItemPerPage(10);
                                        }}
                                      >
                                        10
                                      </DropdownItem>
                                    </li>
                                    <li className={itemPerPage === 15 ? "active" : ""}>
                                      <DropdownItem
                                        tag="a"
                                        href="#dropdownitem"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                          setItemPerPage(15);
                                        }}
                                      >
                                        15
                                      </DropdownItem>
                                    </li>
                                  </ul>
                                  <ul className="link-check">
                                    <li>
                                      <span>{t("lbl43")}</span>
                                    </li>
                                    <li className={sort === "dsc" ? "active" : ""}>
                                      <DropdownItem
                                        tag="a"
                                        href="#dropdownitem"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                          setSortState("dsc");
                                          sortFunc("dsc");
                                        }}
                                      >
                                        DESC
                                      </DropdownItem>
                                    </li>
                                    <li className={sort === "asc" ? "active" : ""}>
                                      <DropdownItem
                                        tag="a"
                                        href="#dropdownitem"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                          setSortState("asc");
                                          sortFunc("asc");
                                        }}
                                      >
                                        ASC
                                      </DropdownItem>
                                    </li>
                                  </ul>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className={`card-search search-wrap ${!onSearch && "active"}`}>
                <div className="card-body">
                  <div className="search-content">
                    <Button
                      className="search-back btn-icon toggle-search active"
                      onClick={() => {
                        setSearchText("");
                        toggle();
                      }}
                    >
                      <Icon name="arrow-left"></Icon>
                    </Button>
                    <input
                      type="text"
                      className="border-transparent form-focus-none form-control"
                      placeholder={t("lbl44")}
                      value={onSearchText}
                      onChange={(e) => onFilterChange(e)}
                    />
                    <Button className="search-submit btn-icon">
                      <Icon name="search"></Icon>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <DataTableBody compact>
              <DataTableHead>
                {/* <DataTableRow className="nk-tb-col-check">
                  <div className="custom-control custom-control-sm custom-checkbox notext">
                    <input
                      type="checkbox"
                      className="custom-control-input form-control"
                      onChange={(e) => selectorCheck(e)}
                      id="uid"
                    />
                    <label className="custom-control-label" htmlFor="uid"></label>
                  </div>
                </DataTableRow> */}
                <DataTableRow>
                  <span className="sub-text">{t("lbl15")}</span>
                </DataTableRow>
                <DataTableRow size="md">
                  <span className="sub-text">{t("lbl3")}</span>
                </DataTableRow>
                <DataTableRow size="sm">
                  <span className="sub-text">{t("lbl16")}</span>
                </DataTableRow>
                {/* <DataTableRow size="md">
                  <span className="sub-text">Phone</span>
                </DataTableRow>
                <DataTableRow size="xxl">
                  <span className="sub-text">Company</span>
                </DataTableRow> */}
                <DataTableRow size="lg">
                  <span className="sub-text">{t("lbl45")}</span>
                </DataTableRow>
                <DataTableRow size="xxl">
                  <span className="sub-text">{t("lbl46")}</span>
                </DataTableRow>
                <DataTableRow size="xxl">
                  <span className="sub-text">{t("lbl47")}</span>
                </DataTableRow>
                <DataTableRow size="xxl">
                  <span className="sub-text">{t("lbl48")}</span>
                </DataTableRow>
                <DataTableRow>
                  <span className="sub-text">{t("lbl6")}</span>
                </DataTableRow>
                <DataTableRow className="nk-tb-col-tools text-right">
                  {/* <UncontrolledDropdown>
                    <DropdownToggle tag="a" className="btn btn-xs btn-outline-light btn-icon dropdown-toggle">
                      <Icon name="plus"></Icon>
                    </DropdownToggle>
                    <DropdownMenu right className="dropdown-menu-xs">
                      <ul className="link-tidy sm no-bdr">
                        <li>
                          <div className="custom-control custom-control-sm custom-checkbox">
                            <input type="checkbox" className="custom-control-input form-control" id="bl" />
                            <label className="custom-control-label" htmlFor="bl">
                              Balance
                            </label>
                          </div>
                        </li>
                        <li>
                          <div className="custom-control custom-control-sm custom-checkbox">
                            <input type="checkbox" className="custom-control-input form-control" id="ph" />
                            <label className="custom-control-label" htmlFor="ph">
                              Phone
                            </label>
                          </div>
                        </li>
                        <li>
                          <div className="custom-control custom-control-sm custom-checkbox">
                            <input type="checkbox" className="custom-control-input form-control" id="vri" />
                            <label className="custom-control-label" htmlFor="vri">
                              Verified
                            </label>
                          </div>
                        </li>
                        <li>
                          <div className="custom-control custom-control-sm custom-checkbox">
                            <input type="checkbox" className="custom-control-input form-control" id="st" />
                            <label className="custom-control-label" htmlFor="st">
                              Status
                            </label>
                          </div>
                        </li>
                      </ul>
                    </DropdownMenu>
                  </UncontrolledDropdown> */}
                </DataTableRow>
              </DataTableHead>
              {/*Head*/}
              {currentItems.length > 0
                ? currentItems.map((item) => {
                    return (
                      <DataTableItem key={item.id}>
                        {/* <DataTableRow className="nk-tb-col-check">
                          <div className="custom-control custom-control-sm custom-checkbox notext">
                            <input
                              type="checkbox"
                              className="custom-control-input form-control"
                              defaultChecked={item.checked}
                              id={item.id + "uid1"}
                              key={Math.random()}
                              onChange={(e) => onSelectChange(e, item.id)}
                            />
                            <label className="custom-control-label" htmlFor={item.id + "uid1"}></label>
                          </div>
                        </DataTableRow> */}
                        <DataTableRow>
                          <Link to={`${process.env.PUBLIC_URL}/user-details-regular/${item.id}`}>
                            <div className="user-card">
                              {/* <UserAvatar
                                theme={item.name}
                                className="xs"
                                text={item.name}
                                image={item.name}
                              ></UserAvatar> */}
                              <div className="user-info">
                                <span className="tb-lead">{item.name}</span>
                              </div>
                            </div>
                          </Link>
                        </DataTableRow>
                        <DataTableRow size="md">
                          <span>{item.type.description}</span>
                        </DataTableRow>
                        <DataTableRow size="sm">
                          <span>{item.email}</span>
                        </DataTableRow>
                        {/* <DataTableRow size="md">
                          <span>Si</span>
                        </DataTableRow> */}
                        {/* <DataTableRow size="xxl">
                          <span>{item.country}</span>
                        </DataTableRow> */}
                        <DataTableRow size="lg">
                          <ul className="list-status">
                            <li>
                              <Icon
                                className={`text-${
                                  item.email_verified_at === null ? "danger" : "success"

                                }`}
                                name={`${
                                  item.email_verified_at !== null
                                    ? "check-circle"
                                    : "alert-circle"
                                }`}
                              ></Icon>{" "}
                              {/* <span>Email</span> */}
                            </li>
                          </ul>
                        </DataTableRow>
                        <DataTableRow size="xxl">
                        <span>{format(new Date(item.created_at), "dd MMMM Y")}</span>
                          {/* <span>{item.created_at}</span> */}
                        </DataTableRow>
                        <DataTableRow size="xxl">
                        <span>{format(new Date(item.last_login_at), "dd MMMM Y k:m:s")}</span>
                          {/* <span>{item.last_login_at}</span> */}
                        </DataTableRow>
                        <DataTableRow size="xxl">
                          <span>{item.last_login_ip}</span>
                        </DataTableRow>
                        <DataTableRow>
                          <span
                            className={`tb-status text-${
                              item.status.description === "Active" ? "success" : item.status.description === "Pending" ? "warning" : "danger"
                            }`}
                          >
                            {item.status.description}
                          </span>
                        </DataTableRow>
                        <DataTableRow className="nk-tb-col-tools">
                          <ul className="nk-tb-actions gx-1">
                            <li className="nk-tb-action-hidden" onClick={() => onEditClick(item.id)}>
                              <TooltipComponent
                                tag="a"
                                containerClassName="btn btn-trigger btn-icon"
                                id={"edit" + item.id}
                                icon="edit-alt-fill"
                                direction="top"
                                text={t('lbl13')}
                              />
                            </li>
                            {item.status.description !== "Suspend" && (
                              <React.Fragment>
                                {/* <li className="nk-tb-action-hidden" onClick={() => suspendUser(item.id)}> */}
                                <li className="nk-tb-action-hidden" onClick={() => {
                                          handleSwitchChange(
                                            item,
                                            item.status.id,
                                          );
                                        }} >
                                  <TooltipComponent
                                    tag="a"
                                    containerClassName="btn btn-trigger btn-icon"
                                    id={"suspend" + item.id}
                                    icon="user-cross-fill"
                                    direction="top"
                                    text={t('lbl49')}
                                  />
                                </li>
                              </React.Fragment>
                            )}
                            <li>
                              <UncontrolledDropdown>
                                <DropdownToggle tag="a" className="dropdown-toggle btn btn-icon btn-trigger">
                                  <Icon name="more-h"></Icon>
                                </DropdownToggle>
                                <DropdownMenu right>
                                  <ul className="link-list-opt no-bdr">
                                    <li onClick={() => onEditClick(item.id)}>
                                      <DropdownItem
                                        tag="a"
                                        href="#edit"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                        }}
                                      >
                                        <Icon name="edit"></Icon>
                                        <span>{t('lbl13')}</span>
                                      </DropdownItem>
                                    </li>
                                    {item.status.description !== "Suspend" && (
                                      <React.Fragment>
                                        <li className="divider"></li>
                                        <li onClick={() => {
                                          handleSwitchChange(
                                            item,
                                            item.status.id,
                                          );
                                        }}>
                                          <DropdownItem
                                            tag="a"
                                            // href="#suspend"
                                            onClick={() => {
                                              handleSwitchChange(
                                                item,
                                                item.status.id,
                                              );
                                            }}
                                          >
                                            <Icon name="na"></Icon>
                                            <span>{t('lbl49')}</span>
                                          </DropdownItem>
                                        </li>
                                      </React.Fragment>
                                    )}
                                  </ul>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </li>
                          </ul>
                        </DataTableRow>
                      </DataTableItem>
                    );
                  })
                : null}
            </DataTableBody>
            <div className="card-inner">
              {currentItems.length > 0 ? (
                <PaginationComponent
                  itemPerPage={itemPerPage}
                  totalItems={data.length}
                  // pageCount={pageCount} //NUMERO TOTAL DE PÁGINAS
                  // onPageChange={handlePageClick} //FUNCION QUE SE LLAMA EN EL onChange DE LA PÁGINA
                  paginate={paginate}
                  currentPage={currentPage}
                />
                // <div>{paginationElement}</div>
              ) : (
                <div className="text-center">
                  <span className="text-silent">No data found</span>
                </div>
              )}
            </div>
          </DataTable>
        </Block>
        <Modal isOpen={modal.add} toggle={() => setModal({ add: false })} className="modal-dialog-centered" size="lg">
          <ModalBody>
            <a
              href="#cancel"
              onClick={(ev) => {
                ev.preventDefault();
                onFormCancel();
              }}
              className="close"
            >
              <Icon name="cross-sm"></Icon>
            </a>
            <div className="p-2">
              <h5 className="title">{t("lbl14")}</h5>
              <div className="mt-4">
                {/* <Form className="row gy-4" onSubmit={handleSubmit(onFormSubmit)}> */}
                <Form className="row gy-4" onSubmit={handleSubmit(AddUser)}>
                  <Col md="6">
                    <FormGroup>
                      <label className="form-label">{t("lbl15")}</label>
                      <input
                        className="form-control"
                        type="text"
                        name="name"
                        defaultValue={formData.name}
                        placeholder={t("lbl37")}
                        ref={register({ required: "This field is required" })}
                      />
                      {errors.name && <span className="invalid">{errors.name.message}</span>}
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <label className="form-label">{t("lbl16")} </label>
                      <input
                        className="form-control"
                        type="text"
                        name="email"
                        defaultValue={formData.email}
                        placeholder={t("lbl38")}
                        ref={register({
                          required: "This field is required",
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: "invalid email address",
                          },
                        })}
                      />
                      {errors.email && <span className="invalid">{errors.email.message}</span>}
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <label className="form-label">{t("lbl17")}</label>
                      <div className="form-control-wrap">
                        <RSelect
                          // name="type"
                          // id="type"
                          options={filterRole}
                          placeholder={t("lbl39")}
                          // defaultValue={{ value: 2, label: "Empleado" }}
                          onChange={(e) => setFormData({ ...formData, type: e.value })}
                        />
                      </div>
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <label className="form-label">{t("lbl36")}</label>
                      <input
                        className="form-control"
                        type="password"
                        name="password"
                        placeholder = {t("lbl18")}
                        defaultValue={formData.password}
                        ref={register({
                          required: "This field is required",
                        })}
                      />
                      {errors.password && <span className="invalid">{errors.password.message}</span>}
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <label className="form-label">{t("lbl34")}</label>
                      <div className="form-control-wrap">
                        <RSelect
                          // name="type"
                          // id="type"
                          options={filterLanguage}
                          placeholder={t("lbl40")}
                          // defaultValue={{ value: 2, label: "Empleado" }}
                          onChange={(e) => setFormData({ ...formData, lang: e.value })}
                        />
                      </div>
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <label className="form-label">{t("lbl8")}</label>
                      <div className="form-control-wrap">
                        <RSelect
                          // name="status"
                          // id="status"
                          options={filterStatus}
                          placeholder={t("lbl41")}
                          // defaultValue={{ value: 1, label: "Active" }}
                          onChange={(e) => setFormData({ ...formData, status: e.value })}
                        />
                      </div>
                    </FormGroup>
                  </Col>
                  <Col size="12">
                    <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                      <li>
                        <Button color="primary" size="md" type="submit">
                        {t("lbl14")}
                        </Button>
                      </li>
                      <li>
                        <a
                          href="#cancel"
                          onClick={(ev) => {
                            ev.preventDefault();
                            onFormCancel();
                          }}
                          className="link link-light"
                        >
                          {t("lbl35")}
                        </a>
                      </li>
                    </ul>
                  </Col>
                </Form>
              </div>
            </div>
          </ModalBody>
        </Modal>
        <Modal isOpen={modal.edit} toggle={() => setModal({ edit: false })} className="modal-dialog-centered" size="lg">
          <ModalBody>
            <a
              href="#cancel"
              onClick={(ev) => {
                ev.preventDefault();
                onFormCancel();
              }}
              className="close"
            >
              <Icon name="cross-sm"></Icon>
            </a>
            <div className="p-2">
              <h5 className="title">Update User</h5>
              <div className="mt-4">
                <Form className="row gy-4" onSubmit={handleSubmit(onEditSubmit)}>
                  <Col md="6">
                    <FormGroup>
                      <label className="form-label">Name</label>
                      <input
                        className="form-control"
                        type="text"
                        name="name"
                        defaultValue={formData.name}
                        placeholder="Enter name"
                        ref={register({ required: "This field is required" })}
                      />
                      {errors.name && <span className="invalid">{errors.name.message}</span>}
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <label className="form-label">Email </label>
                      <input
                        className="form-control"
                        type="text"
                        name="email"
                        defaultValue={formData.email}
                        placeholder="Enter email"
                        ref={register({
                          required: "This field is required",
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: "invalid email address",
                          },
                        })}
                      />
                      {errors.email && <span className="invalid">{errors.email.message}</span>}
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <label className="form-label">Role</label>
                      <div className="form-control-wrap">
                        <RSelect
                          name="type"
                          id="type"
                          options={filterRole}
                          defaultValue={{ value: formData.type.id, label: formData.type.description }}
                          onChange={(e) => setFormData({ ...formData, role: e.value })}
                        />
                      </div>
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <label className="form-label">Password</label>
                      <input
                        className="form-control"
                        type="password"
                        name="password"
                        ref={register({
                          required: false,
                        })}
                      />
                      {errors.password && <span className="invalid">{errors.password.message}</span>}
                    </FormGroup>
                  </Col>
                  <Col md="12">
                    <FormGroup>
                      <label className="form-label">Status</label>
                      <div className="form-control-wrap">
                        <RSelect
                          options={filterStatus}
                          defaultValue={{
                            value: formData.status.id,
                            label: formData.status.description,
                          }}
                          onChange={(e) => setFormData({ ...formData, status: e.value })}
                        />
                      </div>
                    </FormGroup>
                  </Col>
                  <Col size="12">
                    <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                      <li>
                        <Button color="primary" size="md" type="submit">
                          Update User
                        </Button>
                      </li>
                      <li>
                        <a
                          href="#cancel"
                          onClick={(ev) => {
                            ev.preventDefault();
                            onFormCancel();
                          }}
                          className="link link-light"
                        >
                          Cancel
                        </a>
                      </li>
                    </ul>
                  </Col>
                </Form>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </Content>
    </React.Fragment>
  );
};
export default UserListCompact;

import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import UserProfile from "../../Login/UserProfile";
import * as Yup from "yup";
import Swal from "sweetalert2";

import { Formik, Field, Form, ErrorMessage } from "formik";


const AddLanguage = (props) => {

    const [t, i18n] = useTranslation("detailapplap")

    const [ loadd, setloadd ] = useState(0)
    const [ languagetipo, setlanguagetipo ] = useState([]);
    const [ nivelLanguage, setnivelLanguage ] = useState([]);
    const [ languageRes, setlanguageRes ] = useState([]);

    const   addLanguages = (val) => {
        var len = languagetipo.find( item => item.id == val.language )
        var lvl = nivelLanguage.find( item => item.id == val.level )

        const temp = {
          "language": len,
          "level": lvl
        }
        setlanguageRes([...languageRes, temp])
      };

      const getLanguage = async () => {
        await axios
          .get(`${process.env.REACT_APP_BASE_URL}/api/attrs/languages`, {
            headers: {
              Authorization: `Bearer  ${UserProfile.getToken()}`,
            },
          })
          .then((res) => {
            setlanguagetipo(res.data.data.languages)
          });
      };

      const getLevelLanguage = async () => {
        await axios
          .get(`${process.env.REACT_APP_BASE_URL}/api/attrs/language_level`, {
            headers: {
              Authorization: `Bearer  ${UserProfile.getToken()}`,
            },
          })
          .then((res) => {
            setnivelLanguage(res.data.data.language_level)
          });
      };


      const deleteLanguages = (val) => {
        setlanguageRes(languageRes.filter((lng,itm)=> itm !== val))
      };

    const saveLanguage = (values,resetForm) =>{
        setloadd(1)
        axios
        .post(
            `${process.env.REACT_APP_BASE_URL}/api/attrs/languages`, values,
            {
            headers: {
                Authorization: `Bearer  ${UserProfile.getToken()}`,
                }
            }
        )
        .then((res) => {
            props.addlanguagetipo(res.data.data)
            setloadd(0)
            resetForm()
        })
        .catch((error) => console.log(error));
    }

    useEffect(() => {
        if (navigator.onLine) {
          UserProfile.setToken(localStorage.getItem("token"));
          UserProfile.setId(localStorage.getItem("id"));
          getLanguage();
          getLevelLanguage();
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: t("msg4"),
            confirmButtonColor: "#071b30",
            confirmButtonText: t("ok"),
          });
        }
    }, [])



    return (
        <>
            <div>
            <Formik
                    initialValues={{
                      language: "",
                      level: "",
                    }}

                    validationSchema={Yup.object().shape({
                      language: Yup.string().required(t("fr")),
                      level: Yup.string().required(t("fr")),
                    })}

                    onSubmit={(val, { resetForm }) => {
                      resetForm();
                    //   close();
                      addLanguages(val);
                    }}
                    render={({ errors, status, touched }) => (

                      <Form class="container">
                        <div class="form-group mb-4">
                          <Field
                            as="select"
                            id="language"
                            name="language"
                            className="form-control"
                          >
                            <option className="fondo fw-bolder" value="" selected>
                              {t("lbl31")}
                            </option>

                            {languagetipo.map((i) => {
                              return (
                                <option className="form-control" value={i.id}>
                                  {
                                    i18n.language == 'en' ?
                                      i.name :
                                    i18n.language == 'es' ?
                                      i.name_es :
                                    i18n.language == 'fr' ?
                                      i.name_fr : ""
                                  }
                                </option>
                              );
                            })}
                          </Field>

                          <ErrorMessage
                            name="language"
                            component="div"
                            className="invalid"
                          />
                        </div>

                        <div class="form-group mb-4">
                          <Field
                            as="select"
                            id="level"
                            name="level"
                            className="form-control"
                            aria-label="Default select example"
                          >
                            <option className="form-control" value="" selected>
                              {t("lbl32")}
                            </option>

                            {nivelLanguage.map((i) => {
                              return (
                                <option className="form-control" value={i.id}>
                                  {
                                    i18n.language == 'en' ?
                                      i.description :
                                    i18n.language == 'es' ?
                                      i.description_es :
                                    i18n.language == 'fr' ?
                                      i.description_fr : ""
                                  }
                                </option>
                              );
                            })}
                          </Field>

                          <ErrorMessage
                            name="level"
                            component="div"
                            className="msgerror mx-2"
                          />
                        </div>

                        <div class="divider d-flex align-items-center my-2">
                          <button
                            type="submit"
                            class="btn btn-warning  btn-block  buttons "
                          >
                            {t("lbl33")}
                          </button>
                        </div>
                      </Form>
                    )}
                  />
            </div>
        </>
    );
}

export default AddLanguage;
import React  from "react";
import { useTranslation } from "react-i18next";
import UserProfileSoftSkills from "./UserProfileSoftSkills";
import UserProfileHardSkills from "./UserProfileHardSkills";

const UserProfileSkillsPage = (props) => {
  const [t, i18n] = useTranslation("detailapplap");
  return (
    <div>
    <UserProfileSoftSkills />
    <UserProfileHardSkills />
    </div>

  );
};
export default UserProfileSkillsPage;

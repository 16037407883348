import React, { Component } from "react";
import { useTranslation } from "react-i18next";
import visionmision from "../img/visionmision.png";
import photo3 from "../img/photo3.png";
import photo5 from "../img/photo5.png";
import panelcanada from "../img/panelcanada.jpeg";

// import arriba1 from "../img/arriba1.png";
// import arriba2 from "../img/arriba2.png";
// import arriba3 from "../img/arriba3.png";
// import arriba4 from "../img/arriba4.png"
import arriba1 from "../img/up1.png";
import arriba4 from "../img/up2.png";
import arriba3 from "../img/up3.png";
import arriba2 from "../img/up4.png"
import abajo from "../img/abajo.png";

import pdf from "../pdf/Manual HRH.pdf"


const VisionMison = () => {

  const [t, i18n] = useTranslation("mandv");
// i18n.changeLanguage("")

    return (
      <>
        <div className="item-group" xyz="fade">
          <div className="square xyz-in">
            <div className="row  d-flex align-items-center  h-100 my-4">
              <div className="col-12 col-sm-12 col-md-6 center-block ">


                <div className="letreroWeb mt-3  fs-1 px-5 ">{t("ttl1")}</div>

                <p className="txtPequenoAzul px-5 fontmont">
                  {t("text1")}
                </p>

                <div className="letreroWeb mt-4  fs-1 px-5 ">{t("ttl2")}</div>

                <p className="txtPequenoAzul px-5 fontmont">
                  {t("text2")}
                </p>

                <p className="txtPequenoAzul px-5 fontmont">
                  {t("text3")}
                </p>

                {/* <div className="divider d-flex align-items-center mt-5  my-3">
                  <a
                    onClick={() => this.registro()}
                    className="btn btn-warning   buttonsBuscartrabajo"
                  >
                    {" "}
                    {t("ttl3")}
                  </a>
                </div> */}
              </div>

              <div className="col-12 col-sm-12 col-md-6 ">
                {/* <img src={visionmision} className="img-fluid imgmov " /> */}
                <img src={photo3} className="img-fluid imgmov " />
              </div>
            </div>

            <div className="col-12 col-sm-12 col-md-12 center-block ">
              <div className="letreroWeb text-center fs-1 px-5 ">
                {t("ttl4")}
              </div>
            </div>

            <div className="container my-5">
              <div className="row  d-flex align-items-center justify-content-center h-100">


                <div className="col-12 col-sm-12 col-md-3">
                  <img src={arriba1} className="img-fluid" />

                  <div className="heigthtarget">
                    <h4 className="text-center txtMedianoAmarillo">{t("ttl5")}</h4>
                    <div className="row  d-flex align-items-center">
                      <p className="txtSmallAzul px-5 text-center">
                        {t("text4")}
                      </p>
                    </div>
                  </div>

                  <img src={abajo} className="img-fluid" />
                </div>


                <div className="col-12 col-sm-12 col-md-3">
                  <img src={arriba2} className="img-fluid" />

                  <div className="heigthtarget">
                    <h4 className="text-center txtMedianoAmarillo">{t("ttl6")}</h4>
                    <div className="row  d-flex align-items-center">
                      <p className="txtSmallAzul px-5 text-center">
                        {t("text5")}
                      </p>
                    </div>
                  </div>

                  <img src={abajo} className="img-fluid" />
                </div>


                <div className="col-12 col-sm-12 col-md-3">
                  <img src={arriba3} className="img-fluid" />

                  <div className="heigthtarget">
                    <h4 className="text-center txtMedianoAmarillo">{t("ttl7")}</h4>

                    <div className="row  d-flex align-items-center">
                      <p className="txtSmallAzul px-5 text-center">
                        {t("text6")}
                      </p>
                    </div>
                  </div>

                  <img src={abajo} className="img-fluid" />
                </div>
                <div className="col-12 col-sm-12 col-md-3 center-block ">
                  <img src={arriba4} className="img-fluid" />

                  <div className="heigthtarget">
                    <h4 className="text-center txtMedianoAmarillo">{t("ttl8")}</h4>

                    <div className="row  d-flex align-items-center">
                      <p className="txtSmallAzul px-5 text-center">
                        {t("text7")}
                      </p>
                    </div>
                  </div>

                  <img src={abajo} className="img-fluid" />
                </div>
              </div>
            </div>

            <div className="container my-5">
              <div className="row  d-flex align-items-center justify-content-center h-100">
                <div className="col-12 col-sm-12 col-md-6">
                  <div className="letreroWeb mt-3  fs-1 px-5 ">
                    {t("ttl9")}
                  </div>

                  <p className="txtPequenoAzul px-5 mt-3 fontmont">
                    {t("text8")}
                  </p>

                  <p className="txtPequenoAzul px-5 mt-3 fontmont">
                    {t("text9")}
                  </p>
                </div>
                <div className="col-12 col-sm-12 col-md-6">


                  <img src={photo5} className="img-fluid imgmov rounded" />
                {/* <div>

                <embed src={pdf}  type="application/pdf" width="100%" height="600px" />

                 </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
}

export default VisionMison;

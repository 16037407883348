import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import "../Css/Estilos.css";
import "../Css/header.css";
import Home from "../Home/Home";

import googleIco from "../img/google_ico.png";
import homeimg from "../img/housedoorwhite.png";
import logoico from "../img/formhrh.png";

import LanguageHead from "../layout/header/dropdown/language/Language";


const Header = (props) => {

 const [t, i18n] = useTranslation("header")

  const [ opc, setopc ] = useState("1");
  const [ idreg, setidreg ] = useState(1);
  const [ timechange, settimechange ] = useState(new Date);


  const changeOpc = async ({ opc = 1, data }) => {
    setidreg(data);
    hederClick(opc);
  };

  const hederClick = async (item) => {
    setopc(item)
    switch (item) {
      case "6":
        props.history.push("/auth-login");
        break;
    }
  };



    return (
      <>
        <header className="header sticky-top">
          <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
            <div className="container-fluid">
              <div className="" >
                  <img
                    src={logoico}
                    alt=""
                    width="200"
                    className="img-fluid margin-left"
                    onClick={() => {
                      // hederClick("1");
                      settimechange(new Date()); hederClick("1");
                    }}
                    // onClick={()=>settimechange(new Date())}
                  />
              </div>

              <button
                  className="navbar-toggler"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                  onClick={() => {
                    // hederClick("1");
                  document.getElementById("navbarSupportedContent").classList.toggle("show");
                  }}
                  >
                  <span className="navbar-toggler-icon"></span>
                </button>

              <div
                className="navbar-collapse collapse alingend"
                id="navbarSupportedContent" style={{"flex-grow":0}} >
                <ul className="navbar-nav">
                  <li className="nav-item">
                    <a
                      className={
                        opc === "1"
                          ? " mx-3 active fuente  barra"
                          : " mx-3  fuente  barra"
                      }
                      aria-current="page"
                      href="javascript:void(0)"
                      onClick={() => {
                        // hederClick("1");
                        settimechange(new Date()); hederClick("1");
                      }}
                    >
                      <img src={homeimg} alt="" width="20" height="20"  className="text-white" />
                    </a>
                  </li>
                  {/* <li className="nav-item">
                    <a
                      href="javascript:void(0)"
                      className={
                        opc === "2"
                          ? "   nav-link active fuente "
                          : "   nav-link  fuente "
                      }
                      onClick={() => {
                        hederClick("2");
                      }}
                    >
                      {t("aboutus")}
                    </a>
                  </li> */}

                  <li className="nav-item">
                    <a
                      href="javascript:void(0)"
                      className={
                        opc === "3"
                          ? "   nav-link active fuente "
                          : "   nav-link  fuente "
                      }
                      onClick={() => {
                        hederClick("3");
                      }}
                    >
                      {t("canada")}
                    </a>
                  </li>

                  {/* <li className="nav-item">
                    <a
                      className={
                        opc === "4"
                          ? "   nav-link active fuente "
                          : "   nav-link fuente "
                      }
                      href="javascript:void(0)"
                      onClick={() => {
                        hederClick("4");
                      }}
                    >
                      {t("mandv")}
                    </a>
                  </li> */}

                  <li className="nav-item">
                    <a
                      className={
                        opc === "5"
                          ? "   nav-link active fuente "
                          : "   nav-link  fuente "
                      }
                      href="javascript:void(0)"
                      onClick={() => {
                        hederClick("5");
                      }}
                    >
                      {t("contactus")}
                    </a>
                  </li>

                  <li className="nav-item">
                    <a
                      className={
                        opc === "6"
                          ? "   nav-link active fuente "
                          : "   nav-link  fuente "
                      }
                      // href="/auth-login"
                      role="button"
                      onClick={() => {
                        hederClick("6");
                      }}
                    >
                      {t("login")}
                    </a>
                  </li>


              <li className="language-dropdown d-none d-sm-block mr-n1">
                <LanguageHead />
              </li>

                </ul>

              </div>

            </div>
          </nav>

          {/* <div className="container mx-2">
            <h1 className="inicio">
              {
                <div className="item-group" xyz="fade">
                  <div className="square xyz-in">{opcText}</div>
                </div>
              }
            </h1>

            <div className="d-flex align-items-left">
              <div className="indicador">{opcText}/</div>

              <div className="ruta mx-1">{opcText}</div>
            </div>
          </div> */}
        </header>

        <Home opc={opc} changeOpc={changeOpc} dataxtr={idreg} timechange={timechange} history={props.history} match={props.match} />
      </>
    );
}

export default Header;

import React, { useState, useEffect }  from "react";
import EnglishFlag from "../../images/flags/english-sq.png";
import SpanishFlag from "../../images/flags/spanish-sq.png";
import FrenchFlag from "../../images/flags/french-sq.png";
import { DropdownItem, DropdownMenu } from "reactstrap";
import { useTranslation } from "react-i18next";

const LangDropdown = ({ className, size }) => {
  const [t, i18n] = useTranslation("footer");
  const languages = {
    1 : {
      lang: "en",
      icon: EnglishFlag
    },
    2 : {
      lang: "es",
      icon: SpanishFlag
    },
    3 : {
      lang: "fr",
      icon: FrenchFlag
    }
  }

  const changeLanguage = async (opc) => {
    let ln = languages[opc]
    localStorage.setItem('lngselect', ln.lang)
    localStorage.setItem('lngiconselect', ln.icon)
    i18n.changeLanguage(localStorage.getItem('lngselect'))
  }

  useEffect(()=>{
    changeLanguage(1)
  },[])

  return (
    <DropdownMenu right className={`${size ? `dropdown-menu-${size}` : ""} ${className ? className : ""}`}>
    {/* <DropdownMenu right className="dropdown-menu-sm"> */}
      <ul className="language-list">
        <li>
          <DropdownItem tag="a" className="language-item" onClick={() => {
                            changeLanguage(1);
                          }}>
            <img src={EnglishFlag} alt="" className="language-flag" />
            <span className="language-name">{t("en")}</span>
          </DropdownItem>
        </li>
        <li>
          <DropdownItem tag="a" className="language-item" onClick={() => {
                            changeLanguage(2);
                          }}>
            <img src={SpanishFlag} alt="" className="language-flag" />
            <span className="language-name"> {t("es")} </span>
          </DropdownItem>
        </li>
        <li>
          <DropdownItem tag="a" className="language-item" onClick={() => {
                            changeLanguage(3);
                          }}>
            <img src={FrenchFlag} alt="" className="language-flag" />
            <span className="language-name">{t("fr")}</span>
          </DropdownItem>
        </li>

      </ul>
    </DropdownMenu>
  );
};

export default LangDropdown;

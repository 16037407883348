var menu = [];
    menu = [
      { heading: "home" },
      {
        icon: "home",
        text: "menu_home",
        link: "/applicant",
      },
      { heading: "joffers" },
      {
        icon: "check-round",
        text: "my_Offers",
        link: "/my_jobs",
      },
      {
        icon: "card-view",
        text: "Offers",
        link: "/jobs_applicant",
      },
      { heading: "companies" },
      {
        icon: "building",
        text: "menu_list_companies",
        link: "/companies-list-applicant",
      },
      { heading: "profile" },
      {
        icon: "users",
        text: "myprofile",
        link: "/user-profile-regular",
      },

    ];

    export default menu;


import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import Head from "../../layout/head/Head";
import axios from "axios";
import UserProfile from "../../Login/UserProfile";
import RegisterEducation from "./RegisterEducation";
import RegisterLevelEducation from "./RegisterLevelEducation";
import {
    DropdownMenu,
    DropdownToggle,
    FormGroup,
    UncontrolledDropdown,
    Modal,
    ModalBody,
    DropdownItem,
  } from "reactstrap";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Button,
  Row,
  Col,
  PaginationComponent,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableRow,
  DataTableItem,
  RSelect,
  TooltipComponent,
} from "../../components/Component";
// import { useForm } from "react-hook-form";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

const UserProfileStudies = (props) => {

    const [t, i18n] = useTranslation("detailapplap")
    const [modalTab, setModalTab] = useState("1");
    const [ idApplicant, setidApplicant ] = useState(0);
    const [educationRes, seteducationRes] = useState([]);
    const [ levelStudy, setlevelStudy ] = useState([]);
    const [data, setData] = useState("");

    const [sm, updateSm] = useState(false);
    const [tablesm, updateTableSm] = useState(false);
    const [onSearch, setonSearch] = useState(true);
    const [onSearchText, setSearchText] = useState("");
    // const [modal, setModal] = useState({
    //   edit: false,
    //   add: false,
    // });
    const [modal, setModal] = useState(false);
    const [editId, setEditedId] = useState();
    const [formData, setFormData] = useState({
      name: "",
      email: "",
      balance: "",
      phone: "",
      status: "Active",
    });
    const [currentPage, setCurrentPage] = useState(1);
    const [itemPerPage, setItemPerPage] = useState(10);
    const [sort, setSortState] = useState("");

    const [ educacionInstitucion, seteducacionInstitucion ] = useState([]);

    const [ pais, setpais ] = useState([]);
    const [ paisLocation, setpaisLocation ] = useState([]);
    const [ departamento, setdepartamento ] = useState([]);
    const [ city, setcity ] = useState([]);
    const [ email, setEmail ] = useState([]);
    const [ optionSelected, setoptionSelected ] = useState(null);

    // Sorting data
  const sortFunc = (params) => {
    let defaultData = data;
    if (params === "asc") {
      let sortedData = defaultData.sort((a, b) => a.name.localeCompare(b.name));
      setData([...sortedData]);
    } else if (params === "dsc") {
      let sortedData = defaultData.sort((a, b) => b.name.localeCompare(a.name));
      setData([...sortedData]);
    }
  };

  // Changing state value when searching name
  useEffect(() => {
    if (onSearchText !== "") {
      const filteredObject = educationRes.filter((item) => {
        return (
          item.name.toLowerCase().includes(onSearchText.toLowerCase()) ||
          item.email.toLowerCase().includes(onSearchText.toLowerCase())
        );
      });
      setData([...filteredObject]);
    } else {
      setData([...data]);
    }
  }, [onSearchText, setData]);

  // onChange function for searching name
  const onFilterChange = (e) => {
    setSearchText(e.target.value);
  };

    // function to reset the form
    const resetForm = () => {
      setFormData({
        name: "",
        email: "",
        type:1,
        password: "",
        status: 1,
      });
    };

    const addNewEducation = (data) => {
      seteducacionInstitucion([...educacionInstitucion, data])
    }

    const addNewlevelEducation = (data) => {
      setlevelStudy([...levelStudy, data])
    }

    // const closeEducation = async (values) => {
    //   document.getElementById("clocedModalEducation").click();
    // };

    const addEducation = (val) => {
      var inst = educacionInstitucion.find( item => item.id == val.educational_institution )
      var lvl = levelStudy.find( item => item.id == val.level_study )
      // var uni = unidad.find( item => item.id == val.unit_measurement )

      const temp = {
        "name": val.name,
        "educational_institution": inst,
        "level_study": lvl,
        "inprogress": parseInt(val.inprogress) ? parseInt(val.inprogress) : 0,
        "starting_date": val.starting_date,
        "finishing_date": val.finishing_date,
      }
      console.log(temp,"temp")
      // seteducationRes([...educationRes, temp]);
      seteducationRes(educationRes => [...educationRes, temp]);
      console.log(educationRes,"educationRes2")

    };

    const saveEditApplicant = (val) => {
      val.lng = localStorage.getItem('lngselect');
      // val.studies = educationRes,
      val.email = email;
      console.log(educationRes,"studies");
      console.log(val,"val");
      // console.log("editando aplicante");
      axios
        .put(
          `${process.env.REACT_APP_BASE_URL}/api/applicants/${idApplicant}`,
          val,
          {
            headers: {
              Authorization: `Bearer  ${UserProfile.getToken()}`,
            },
          }
        )
        .then((res) => {
          if (res.data.data.code == "new") {
            registro(val)
            return
          }
          if (!res.data.status) {
            Swal.fire({
              icon: "error",
              title: "Oops..",
              confirmButtonColor: "#071b30",
              confirmButtonText: t("ok"),
            });
            return
          }
          Swal.fire({
            icon: "success",
            title: t("updated_data"),
            confirmButtonColor: "#071b30",
            confirmButtonText: t("ok"),
          });
        })
        .catch((error) => console.log(error));
    };

    const registro = (val) => {
      val.email = email;
      console.log(email,"email2");
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}/api/applicants`,
          val,
          {
            headers: {
              Authorization: `Bearer  ${UserProfile.getToken()}`,
            },
          }
        )
        .then((res) => {
          if (!res.data.status) {
            Swal.fire({
              icon: "error",
              title: t("fail_updated_data"),
              confirmButtonColor: "#071b30",
              confirmButtonText: t("ok"),
            });
            return
          }
          Swal.fire({
            icon: "success",
            title: "success",
            confirmButtonColor: "#071b30",
            confirmButtonText: t("ok"),
          });
         localStorage.setItem('idapplicant', res.data.data.id);
        })
        .catch((error) => console.log(error));
    };

    const deleteEducation = (val) => {
      seteducationRes(educationRes.filter((ed,itm) => itm !== val))
    };

    // function to close the form modal
    const onFormCancel = () => {
      setModal({ edit: false, add: false });
      resetForm();
    };

      // function to toggle the search option
  const toggle = () => setonSearch(!onSearch);
    // Get current list, pagination
  const indexOfLastItem = currentPage * itemPerPage;
  const indexOfFirstItem = indexOfLastItem - itemPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  // Change Page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const getLevelStudy = async () => {
    await axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/attrs/level_study`, {
        headers: {
          Authorization: `Bearer  ${UserProfile.getToken()}`,
        },
      })
      .then((res) => {
        setlevelStudy(res.data.data.level_study)
      });
  };

  const getEducationInstitution = async () => {
    await axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/api/attrs/educational_institutions`,
        {
          headers: {
            Authorization: `Bearer  ${UserProfile.getToken()}`,
          },
        }
      )
      .then((res) => {
        seteducacionInstitucion(res.data.data.educational_institutions)
      });
  };

const getApplicant = async () => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/api/applicants/profile`,
        {
          headers: {
            Authorization: `Bearer  ${UserProfile.getToken()}`,
          },
        }
      )
      .then((res) => {
        if (res.data.error ? res.data.error[0] == 'El Token a Expirado' : false) {props.history.push('/login'); return}
        setidApplicant(res.data.data.id)
        setEmail(res.data.data.email)
        // multiples
        // setlanguageRes(res.data.data.languages_applicant)
        seteducationRes(res.data.data.studies)
        setData(res.data.data.studies)
        let opactsel = []
        for (const acti of res.data.data.applicant_activities) {
          opactsel.push({"value":acti.activity.id, "label":acti.activity.name})
        }
        setoptionSelected(opactsel)

      })
      .catch((error) => console.log(error));
  };


  const onEditClick = (id) => {
    console.log('clic')

    setModal({ edit: true }, { add: false });
    setEditedId(id);

  };

  useEffect(() => {
    if (navigator.onLine) {
      UserProfile.setToken(localStorage.getItem("token"));
      UserProfile.setId(localStorage.getItem("id"));
      getLevelStudy();
      getEducationInstitution();
      // getLanguage();
      // getLevelLanguage();
      // getEducationInstitution();

      /***************** */
      getApplicant();

    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "msg",
        confirmButtonColor: "#071b30",
        confirmButtonText: "ok",
      });
    }
  }, [])

  return (
    <React.Fragment>
      <Head title="Studies - Profile"></Head>

      <BlockHead size="lg">
        <BlockBetween>
          <BlockHeadContent>
            {/* Work Experience */}
            <BlockTitle tag="h4">{t("studies_info")}</BlockTitle>
            <BlockDes>
              <p>{t("studies_basic_info")}</p>
            </BlockDes>
          </BlockHeadContent>
          <BlockHeadContent>

            <div className="toggle-wrap nk-block-tools-toggle">
                <Button
                  className={`btn-icon btn-trigger toggle-expand mr-n1 ${sm ? "active" : ""}`}
                  onClick={() => updateSm(!sm)}
                >
                  <Icon name="menu-alt-r"></Icon>
                </Button>
                <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                  <ul className="nk-block-tools g-3">
                    <li className="nk-block-tools-opt">
                      <Button color="primary" className="btn-icon" onClick={() => setModal({ add: true })}>
                        <Icon name="plus"></Icon> {t("ttl2")} &nbsp;
                      </Button>
                    </li>
                  </ul>
                </div>
              </div>
          </BlockHeadContent>
        </BlockBetween>
      </BlockHead>

      <Block>

      <DataTable className="card-stretch">
            <div className="card-inner position-relative card-tools-toggle">
              <div className="card-title-group">
              <div className="card-title">
                  <h5 className="title">{t("your_studies")}</h5>
                </div>
              <div className="card-tools">
                  <div className="form-inline flex-nowrap gx-3">
                  </div>
                </div>
                <div className="card-tools mr-n1">
                  <ul className="btn-toolbar gx-1">
                    <li>
                      <a
                        href="#search"
                        onClick={(ev) => {
                          ev.preventDefault();
                          toggle();
                        }}
                        className="btn btn-icon search-toggle toggle-search"
                      >
                        <Icon name="search"></Icon>
                      </a>
                    </li>
                    <li className="btn-toolbar-sep"></li>
                    <li>
                      <div className="toggle-wrap">
                        <Button
                          className={`btn-icon btn-trigger toggle ${tablesm ? "active" : ""}`}
                          onClick={() => updateTableSm(true)}
                        >
                          <Icon name="menu-right"></Icon>
                        </Button>
                        <div className={`toggle-content ${tablesm ? "content-active" : ""}`}>
                          <ul className="btn-toolbar gx-1">
                            {/* <li className="toggle-close">
                              <Button className="btn-icon btn-trigger toggle" onClick={() => updateTableSm(false)}>
                                <Icon name="arrow-left"></Icon>
                              </Button>
                            </li>
                            <li>
                              <UncontrolledDropdown>
                                <DropdownToggle tag="a" className="btn btn-trigger btn-icon dropdown-toggle">
                                  <div className="dot dot-primary"></div>
                                  <Icon name="filter-alt"></Icon>
                                </DropdownToggle>
                                <DropdownMenu
                                  right
                                  className="filter-wg dropdown-menu-xl"
                                  style={{ overflow: "visible" }}
                                >
                                  <div className="dropdown-head">
                                    <span className="sub-title dropdown-title">Filter Users</span>
                                    <div className="dropdown">
                                      <DropdownItem
                                        href="#more"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                        }}
                                        className="btn btn-sm btn-icon"
                                      >
                                        <Icon name="more-h"></Icon>
                                      </DropdownItem>
                                    </div>
                                  </div>
                                  <div className="dropdown-body dropdown-body-rg">
                                    <Row className="gx-6 gy-3">
                                      <Col size="12">
                                        <FormGroup className="form-group">
                                          <Button color="secondary">Filter</Button>
                                        </FormGroup>
                                      </Col>
                                    </Row>
                                  </div>
                                  <div className="dropdown-foot between">
                                    <a
                                      href="#reset"
                                      onClick={(ev) => {
                                        ev.preventDefault();
                                      }}
                                      className="clickable"
                                    >
                                      Reset Filter
                                    </a>
                                    <a
                                      href="#save"
                                      onClick={(ev) => {
                                        ev.preventDefault();
                                      }}
                                    >
                                      Save Filter
                                    </a>
                                  </div>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </li> */}
                            <li>
                              <UncontrolledDropdown>
                                <DropdownToggle tag="a" className="btn btn-trigger btn-icon dropdown-toggle">
                                  <Icon name="setting"></Icon>
                                </DropdownToggle>
                                <DropdownMenu right className="dropdown-menu-xs">
                                  <ul className="link-check">
                                    <li>
                                      <span>{t("show", {ns:"global"})}</span>
                                    </li>
                                    <li className={itemPerPage === 10 ? "active" : ""}>
                                      <DropdownItem
                                        tag="a"
                                        href="#dropdownitem"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                          setItemPerPage(10);
                                        }}
                                      >
                                        10
                                      </DropdownItem>
                                    </li>
                                    <li className={itemPerPage === 15 ? "active" : ""}>
                                      <DropdownItem
                                        tag="a"
                                        href="#dropdownitem"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                          setItemPerPage(15);
                                        }}
                                      >
                                        15
                                      </DropdownItem>
                                    </li>
                                  </ul>
                                  <ul className="link-check">
                                    <li>
                                      <span>{t("order", {ns:"global"})}</span>
                                    </li>
                                    <li className={sort === "dsc" ? "active" : ""}>
                                      <DropdownItem
                                        tag="a"
                                        href="#dropdownitem"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                          setSortState("dsc");
                                          sortFunc("dsc");
                                        }}
                                      >
                                        DESC
                                      </DropdownItem>
                                    </li>
                                    <li className={sort === "asc" ? "active" : ""}>
                                      <DropdownItem
                                        tag="a"
                                        href="#dropdownitem"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                          setSortState("asc");
                                          sortFunc("asc");
                                        }}
                                      >
                                        ASC
                                      </DropdownItem>
                                    </li>
                                  </ul>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className={`card-search search-wrap ${!onSearch && "active"}`}>
                <div className="card-body">
                  <div className="search-content">
                    <Button
                      className="search-back btn-icon toggle-search active"
                      onClick={() => {
                        setSearchText("");
                        toggle();
                      }}
                    >
                      <Icon name="arrow-left"></Icon>
                    </Button>
                    <input
                      type="text"
                      className="border-transparent form-focus-none form-control"
                      placeholder={t("search_by",{ns:"global"}) + ' ' + t("lbl57")}
                      value={onSearchText}
                      onChange={(e) => onFilterChange(e)}
                    />
                    <Button className="search-submit btn-icon">
                      <Icon name="search"></Icon>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <DataTableBody compact>
              <DataTableHead>
              <DataTableRow>
                  <span className="sub-text">{t("lbl57")}</span>
                </DataTableRow>
                <DataTableRow>
                  <span className="sub-text">{t("lbl61")}</span>
                </DataTableRow>
                <DataTableRow size="md">
                  <span className="sub-text">{t("lbl39")}</span>
                </DataTableRow>
                <DataTableRow size="sm">
                  <span className="sub-text">{t("lbl59")}</span>
                </DataTableRow>
                <DataTableRow size="md">
                  <span className="sub-text">{t("lbl60")}</span>
                </DataTableRow>
                <DataTableRow size="md">
                  <span className="sub-text">{t("status",{ns:"global"})}</span>
                </DataTableRow>
                <DataTableRow size="md">
                  <span className="sub-text">{t("options",{ns:"global"})}</span>
                </DataTableRow>
              </DataTableHead>
              {/*Head*/}
              {currentItems.length > 0
                ? currentItems.map((item) => {
                    return (
                      <DataTableItem key={item.id}>
                        <DataTableRow>
                        {item.name}
                        </DataTableRow>
                       <DataTableRow>
                       { i18n.language == 'en' ? item.level_study.description
                  : i18n.language == 'es' ? item.level_study.description_es : item.level_study.description_fr }
                        </DataTableRow>
                        <DataTableRow>
                        {item.educational_institution.name}
                        </DataTableRow>
                        <DataTableRow>
                        {item.starting_date}
                        </DataTableRow>
                        <DataTableRow>
                        {item.finishing_date}
                        </DataTableRow>
                        <DataTableRow>
                        {item.inprogress == 1 ? "in progress" : "Finish"}
                        </DataTableRow>
                        <DataTableRow className="nk-tb-col-tools">
                          <ul className="nk-tb-actions gx-1">
                            <li className="nk-tb-action-hidden" onClick={() => onEditClick(item.id)}>
                              <TooltipComponent
                                tag="a"
                                containerClassName="btn btn-trigger btn-icon"
                                id={"edit" + item.id}
                                icon="edit-alt-fill"
                                direction="top"
                                text={t("modify",{ns:"global"})}
                              />
                            </li>
                            <li>
                              <UncontrolledDropdown>
                                <DropdownToggle tag="a" className="dropdown-toggle btn btn-icon btn-trigger">
                                  <Icon name="more-h"></Icon>
                                </DropdownToggle>
                                <DropdownMenu right>
                                  <ul className="link-list-opt no-bdr">
                                    <li onClick={() => onEditClick(item.id)}>
                                      <DropdownItem
                                        tag="a"
                                        href="#edit"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                        }}
                                      >
                                        <Icon name="edit"></Icon>
                                        <span>{t("modify",{ns:"global"})}</span>
                                      </DropdownItem>
                                    </li>
                                  </ul>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </li>
                          </ul>
                        </DataTableRow>
                      </DataTableItem>
                    );
                  })
                : null}
            </DataTableBody>
            <div className="card-inner">
              {currentItems.length > 0 ? (
                <PaginationComponent
                  itemPerPage={itemPerPage}
                  totalItems={data.length}
                  paginate={paginate}
                  currentPage={currentPage}
                />
              ) : (
                <div className="text-center">
                  <span className="text-silent">No studies found</span>
                </div>
              )}
            </div>
          </DataTable>
      </Block>

      {/* Modal to Add Studies */}

      <Modal isOpen={modal} className="modal-dialog-centered" size="lg" toggle={() => setModal(false)}>
        <ModalBody>
        <Formik
                            initialValues={{
                              name: "",
                              educational_institution: "",
                              starting_date: "",
                              finishing_date: "",
                              level_study: "",
                              inprogress: "",

                            }}
                            validationSchema={Yup.object().shape({
                              educational_institution:
                                Yup.string().required(t("fr")),
                              starting_date: Yup.string().required(t("fr")),
                              finishing_date: Yup.string(),
                              level_study: Yup.string().required(t("fr")),
                            })}

                            onSubmit={(val, { resetForm }) => {
                              resetForm();
                              // closeEducation();
                              setModal(false);
                              addEducation(val);
                              const data ={
                                studies:  educationRes,
                              }
                              saveEditApplicant(data);
                            }}
                            >
                            { ({handleSubmit}) => (
                            <Form class="container" onSubmit={handleSubmit}>



          <a
            href="#dropdownitem"
            onClick={(ev) => {
              ev.preventDefault();
              setModal(false);
            }}
            className="close"
          >
            <Icon name="cross-sm"></Icon>
          </a>
          <div className="p-2">
            <h5 className="title">{t("lbl38")}</h5>
            <ul className="nk-nav nav nav-tabs">
              <li className="nav-item">
                <a
                  className={`nav-link ${modalTab === "1" && "active"}`}
                  onClick={(ev) => {
                    ev.preventDefault();
                    setModalTab("1");
                  }}
                  href="#actualeducation"
                >
                  {t("ttl2")}
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={`nav-link ${modalTab === "2" && "active"}`}
                  onClick={(ev) => {
                    ev.preventDefault();
                    setModalTab("2");
                  }}
                  href="#neweducation"
                >
                 {t("ttl6")}
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={`nav-link ${modalTab === "3" && "active"}`}
                  onClick={(ev) => {
                    ev.preventDefault();
                    setModalTab("3");
                  }}
                  href="#newleveleducation"
                >
                 {t("ttl7")}
                </a>
              </li>
            </ul>
            <div className="tab-content">
              <div className={`tab-pane ${modalTab === "1" ? "active" : ""}`} id="actualeducation">
                <Row className="gy-4">
                  <Col md="6">
                    <FormGroup>
                    <label for="name" class="form-label">{t("lbl57")} *</label>
                        <Field
                                id="name"
                                name="name"
                                type="text"
                                class="form-control"
                                placeholder={t("lbl57")}
                              />

                              <ErrorMessage
                                name="name"
                                component="div"
                                className="invalid"
                              />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                    <label for="educational_institution" class="form-label">{t("lbl40")}</label>
                    <Field
                            as="select"
                            id="educational_institution"
                            name="educational_institution"
                            className="form-control"
                          >

                            {educacionInstitucion.map((i) => {
                              return (
                                <option className="form-control" value={i.id}>
                                  {i.name}
                                </option>
                              );
                            })}
                          </Field>

                          <ErrorMessage
                            name="educational_institution"
                            component="div"
                            className="invalid"
                          />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                    <label for="starting_date" class="form-label">{t("lbl59")} *</label>
                    <Field
                            id="starting_date"
                            name="starting_date"
                            type="date"
                            class="form-control"
                          />

                          <ErrorMessage
                            name="starting_date"
                            component="div"
                            className="msgerror mx-2"
                          />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                    <label for="finishing_date" class="form-label">{t("lbl60")}</label>
                    <Field
                            id="finishing_date"
                            name="finishing_date"
                            type="date"
                            class="form-control"
                          />

                          <ErrorMessage
                            name="finishing_date"
                            component="div"
                            className="invalid"
                          />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                  <FormGroup>
                    <label for="level_study" class="form-label">{t("lbl61")} *</label>
                    <Field
                            as="select"
                            id="level_study"
                            name="level_study"
                            className="form-control"
                          >

                            {levelStudy.map((i) => {
                              return (
                                <option className="form-control" value={i.id}>
                                  {
                                    i18n.language == 'en' ?
                                      i.description  :
                                    i18n.language == 'es' ?
                                      i.description_es  :
                                    i18n.language == 'fr' ?
                                      i.description_fr  : ""
                                  }
                                </option>
                              );
                            })}
                          </Field>

                          <ErrorMessage
                            name="level_study"
                            component="div"
                            className="invalid"
                          />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>

                    <Field
                              id="inprogress"
                              type="checkbox"
                              name="inprogress"
                            />

                    &nbsp; &nbsp;
                    <label for="inprogress" class="form-label">{t("lbl43")}</label>

                            <ErrorMessage
                              name="inprogress"
                              component="div"
                              className="msgerror mx-2"
                            />
                    </FormGroup>
                  </Col>
                  <Col size="12">
                    <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                      <li>
                        {/* <Button
                          color="primary"
                          size="lg"
                          onClick={(ev) => {
                            ev.preventDefault();
                            submitForm();
                          }}
                        > */}
                        <Button
                          color="primary"
                          size="lg"
                         type="submit"
                        >
                         {t("lbl33")}
                        </Button>
                      </li>
                      <li>
                        <a
                          href="#dropdownitem"
                          onClick={(ev) => {
                            ev.preventDefault();
                            setModal(false);
                          }}
                          className="link link-light"
                        >
                          {t("cancel")}
                        </a>
                      </li>
                    </ul>
                  </Col>
                </Row>
              </div>
              <div className={`tab-pane ${modalTab === "2" ? "active" : ""}`} id="neweducation">
                <RegisterEducation/>
                {/* Register Education Component */}
              </div>
              {/* Tab 3 */}
              <div className={`tab-pane ${modalTab === "3" ? "active" : ""}`} id="newleveleducation">
                {/* Register Level Education Component */}
                <RegisterLevelEducation/>
              </div>
            </div>
          </div>
          </Form>
        )}
      </Formik>
        </ModalBody>
      </Modal>



    </React.Fragment>

  );
};
export default UserProfileStudies;

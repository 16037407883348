import React, { useState, useEffect } from "react";
import { DropdownToggle, DropdownMenu, UncontrolledDropdown } from "reactstrap";
import { useTranslation } from "react-i18next";
import axios from "axios";

import Icon from "../../../../components/icon/Icon";
import data from "./NotificationData";

import ReactPaginate from "react-paginate";



const Notification = (props) => {

  const [ notifications, setnotifications ] = useState([])

const getNotifications = async () => {
    setnotifications([])
    await axios
    .get(
        `${process.env.REACT_APP_BASE_URL}/api/notifications`,
        {
        headers: {
            // Authorization: `Bearer  ${props.token}`,
            Authorization: `Bearer  ${localStorage.getItem('token')}`,
            }
        }
    )
    .then((res) => {
        if (res.data.error ? res.data.error[0] == 'El Token a Expirado' : false) {props.history.push('/login'); return}
        setnotifications(res.data.data.notifications.filter((itm)=> itm.visible == 1 || itm.visible == "1"))
        props.cantNotifications(res.data.data.total)
    })
    .catch((error) => console.log(error));
}

const changeState = (id) => {
    var data = {
        "user": localStorage.getItem('id'),
        "visible": 0
    }
    axios
    .put(
        `${process.env.REACT_APP_BASE_URL}/api/notifications/${id}`, data,
        {
        headers: {
            Authorization: `Bearer  ${props.token}`,
            }
        }
    )
    .then((res) => {
    })
    .catch((error) => console.log(error));
}

const redirect = (data) => {
    let type = data.type
    var now = new Date();
    // console.log(data, 'data');
    // let rol = localStorage.getItem('userrol')
    if (data.iddata > 0) {
        if (type == 'company' || type == 'commentscompany') {
            props.changeOpc({opc: "3", data: data.iddata, time: now.getMilliseconds()})
        }
        if (type == 'applicants' || type == 'commentsapplicants') {
            props.changeOpc({opc: "21", data: data.iddata})
        }
        if (type == 'job_offers' || type == 'commentsjob_offers') {
            props.changeOpc({opc: "9", data: data.iddata, time: now.getMilliseconds()})
        }
        if (type == 'applicant_pays') {
            props.changeOpc({opc: "4", data: data.iddata, type: "applicants", time: now.getMilliseconds()})
        }
        if (type == 'company_services') {
            props.changeOpc({opc: "4", data: data.iddata, type: "service", time: now.getMilliseconds()})
        }
    }
}


const NotificationItem = (props) => {
  const { icon, iconStyle, text,description, time, id, table, idvalue } = props;
  return (
    <a aria-current="true" key={id} onClick={()=>redirect({id: id, type: table, iddata: idvalue})} >
    <div className="nk-notification-item" key={id} id={id}>
      <div className="nk-notification-icon">
        <Icon name={icon} className={[`icon-circle ${iconStyle ? " " + iconStyle : ""}`]} />
      </div>
      <div className="nk-notification-content">
        <div className="nk-notification-text">{text}</div>
        <div className="nk-notification-time">{description}</div>
        <div className="nk-notification-time">{time}</div>
      </div>
    </div>
    </a>
  );
};

useEffect(() => {
    getNotifications()
}, [props.token])


  return (
    <UncontrolledDropdown className="user-dropdown">
      <DropdownToggle tag="a" className="dropdown-toggle nk-quick-nav-icon">
        <div className="icon-status icon-status-info">
          <Icon name="bell" />
        </div>
      </DropdownToggle>
      <DropdownMenu right className="dropdown-menu-xl dropdown-menu-s1">
        {/* <div className="dropdown-head">
          <span className="sub-title nk-dropdown-title">{data.title}</span>
          <a href="#markasread" onClick={(ev) => ev.preventDefault()}>
            Mark All as Read
          </a>
        </div> */}
        <div className="dropdown-body">
          <div className="nk-notification">
            {notifications.map((notif) => {
              return (

                <NotificationItem
                  key={notif.id}
                  id={notif.id}
                  icon="curve-down-right"
                  iconStyle="bg-warning-dim"
                  text={notif.name}
                  description={notif.description.substr(0, 25)}
                  time={notif.created_at.split('T')[0]}
                  table={notif.table}
                  idvalue={notif.idvalue}

                />

              );
            })}
          </div>
        </div>
        <div className="dropdown-foot center">
        <a href="javascript:void(0)"
              onClick={()=>props.changeOpc({opc: props.opcnotif})}
              >
              View All
          </a>
          {/* <a href="#viewall" onClick={(ev) => ev.preventDefault()}>

          </a> */}
        </div>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

export default Notification;

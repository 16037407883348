import React, { Component, useState } from "react";
import { useTranslation } from "react-i18next";

import { Formik, Field, Form, ErrorMessage } from "formik";

import phone from "../img/phonesf.png";
import addres from "../img/addresssf.png";
import facebook from "../img/facebooksf.png";
import emailicon from "../img/email.png";
// import instagram from "../img/instagramsf.png";
// import instagram from "../img/instagram.jpeg";
// import youtube from "../img/youtubesf.png";
import linkedin from "../img/linkediniconsf.png"

import Swal from "sweetalert2";
import axios from "axios";


const Contact = (props) => {

const [t, i18n] = useTranslation("contact")

  const [ name, setname ] = useState([]);
  const [ email, setemail ] = useState([]);
  const [ subject, setsubject ] = useState([]);
  const [ message, setmessage ] = useState([]);


  const validate = (values) => {
    const errors ={};
    if(!values.name ){
        errors.name ="Nombre requerido"
    }
    if(!values.email ){
      errors.email ="Correo requerido"
    }else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email ="Correo invalido"
    }
    if(!values.subject ){
      errors.subject ="Asunto requerido"
    }
    if(!values.message ){
      errors.message ="Mensaje requerido"
    }
    return errors;
  }

  const registro = (values) => {
      axios
      .post(`${process.env.REACT_APP_BASE_URL}/api/contact`, values)
      .then((res) => {
        console.log(res);
        if (res.data.status == true) {
          Swal.fire({
            icon: "success",
            text: "mensaje enviado con exito",
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Al enviar mensaje",
          });
        }
      });
  }

    return (
      <>
        <div className="item-group" xyz="fade">
          <div className="square xyz-in">
            <div className="row  d-flex align-items-center">
              <div className="col-12 col-sm-12 col-md-7 center-block px-4 ">
                <div className="container">
                  <div className="txtSmallLightAzul mt-3 mt-3 ">{t("ttl1")}</div>
                  <div className="letreroWeb">{t("ttl2")}</div>

                  <div className="txtSmallAzul mt-1">
                    {t("text1")}
                  </div>
                </div>

                <div className="container px-2">
                  <div className="mt-5">
                  <Formik
                      initialValues={{
                        name: "",
                        email: "",
                        subject: "",
                        message: ""
                      }}

                      validate={(values) => {
                        const pattern = new RegExp(
                          "^[a-zA-ZñÑáéíóúÁÉÍÓÚÇ ]+$",
                          "i"
                        );
                        const errors = {};

                        if (!values.name) errors.name = t("fr");
                        if (!values.email) errors.email = t("fr");
                        if (!values.subject) errors.subject = t("fr");
                        if (!values.message) errors.message = t("fr");

                        return errors;
                      }}

                      // validationSchema={Yup.object().shape({
                      //   name: Yup.string().required(t("fr")),
                      //   email: Yup.string().required(t("fr")),
                      //   subject: Yup.string().required(t("fr")),
                      //   message: Yup.string().required(t("fr"))
                      // })}

                      onSubmit={(val, { resetForm }) => {
                        const data = {
                          name: val.name,
                          email: val.email,
                          subject: val.subject,
                          message: val.message,
                          lng: localStorage.getItem('lngselect'),
                        };
                        registro(data, resetForm);
                      }}

                      // enableReinitialize
                    >
                      {({handleSubmit}) => (
                        <Form className="container" onSubmit={handleSubmit}>
                          <div className="form-group mb-4">
                            <Field
                              id="name"
                              name="name"
                              type="text"
                              className="form-control imputText"
                              id="exampleInputEmail1"
                              aria-describedby="emailHelp"
                              placeholder={t("lbl1")}
                            />
                            <ErrorMessage name="name">
                                {message => <div className="msgerror mx-2">{message}</div>}
                            </ErrorMessage>
                          </div>

                          <div className="form-group mb-4">
                            <Field
                              id="email"
                              name="email"
                              type="email"
                              className="form-control imputText"
                              id="exampleInputEmail1"
                              aria-describedby="emailHelp"
                              placeholder={t("lbl2")}
                            />
                              <ErrorMessage name="email">
                                {message => <div className="msgerror mx-2">{message}</div>}
                              </ErrorMessage>
                          </div>

                          <div className="form-group mb-4">
                            <Field
                              id="subject"
                              name="subject"
                              type="text"
                              className="form-control imputText"
                              id="exampleInputEmail1"
                              aria-describedby="emailHelp"
                              placeholder={t("lbl3")}
                            />
                              <ErrorMessage name="subject">
                                {message => <div className="msgerror mx-2">{message}</div>}
                              </ErrorMessage>
                          </div>

                          <div className="form-group mb-4 ">
                            <Field
                              id="message"
                              name="message"
                              type="textarea"
                              className="form-control rounded-0 textArea"
                              component="textarea"
                              aria-describedby="emailHelp"
                              placeholder={t("lbl4")}
                              rows="3"
                            />

                              <ErrorMessage name="message">
                                {message => <div className="msgerror mx-2">{message}</div>}
                              </ErrorMessage>
                          </div>

                          {/* <div className="divider d-flex align-items-center mt-3 px-5 mb-5">
                            <button
                              type ="submit"
                              className="btn btn-warning  btn-block  buttons "
                            >
                              {t("text2")}
                            </button>
                          </div> */}

                          <div className="divider d-flex align-items-center my-2">
                            <button
                              className="btn btn-warning  btn-block  buttons "
                              type="submit"
                            >
                              {t("ttl7")}
                            </button>
                          </div>
                      </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>

              <div className="col-12 col-sm-12 col-md-5 center-block fondoAmarillo">
                <div className="">
                <div className="container px-4">
                  <div className="mt-5">
                    <div className="row  mt-5">
                      <div className=" col-2 col-sm-2 col-md-2">
                        <img src={phone} className="img-fluid" />
                      </div>

                      <div className="col-sm-12 col-md-10">
                        <h2 className="letreroWebBlnaco mt-2">{t("ttl3")}</h2>

                        <h2 className="txtSmallLightBlanco mt-2">{localStorage.getItem('userrol') == 2 ? process.env.REACT_APP_TELEFONOUNO : process.env.REACT_APP_TELEFONOUNO_COMPANY}</h2>

                        {/* <h2 className="txtSmallLightBlanco mt-2">{localStorage.getItem('userrol') == 2 ? process.env.REACT_APP_TELEFONODOS : process.env.REACT_APP_TELEFONODOS_COMPANY}</h2> */}
                      </div>
                    </div>

                    <div className="row  mt-5">
                      <div className=" col-2 col-sm-2 col-md-2">
                        <img src={emailicon} className="img-fluid" />
                      </div>
                      <div className="col-sm-12 col-md-4">
                        <h2 className="letreroWebBlnaco mt-2">{t("ttl4")}</h2>

                        <h2 className="txtSmallLightBlanco mt-2">{localStorage.getItem('userrol') == 2 ? process.env.REACT_APP_EMAILCONTACT : process.env.REACT_APP_EMAILCONTACT_COMPANY}</h2>

                        {/* <h2 className="txtSmallLightBlanco mt-2">correo1@gmail.com</h2> */}
                      </div>
                    </div>


                    <div className="row mt-5 ">
                      <div className=" col-2 col-sm-2 col-md-2">
                        <img src={addres} className="img-fluid" />
                      </div>

                      <div className=" col-sm-3 col-md-10">
                        <h2 className="letreroWebBlnaco mt-2">{t("ttl5")}</h2>
                        <h2 className="txtSmallLightBlanco mt-2">{process.env.REACT_APP_DIRECCION}</h2>
                      </div>
                    </div>

                    <div className="row mt-5 ">
                      <div className=" col-sm-12 col-md-5 mx-3">
                        <h2 className="letreroWebBlnaco mt-5">{t("ttl6")}</h2>
                      </div>
                    </div>
                    <div className="row mt-3 justify-content-center  px-4 panel">
                      <div className=" col-sm-3 col-md-2  ">
                        <a href={process.env.REACT_APP_LINKEDIN_URL} target="_blank" >
                          <img className="img-fluid" src={linkedin}/>
                        </a>
                      </div>

                      <div className=" col-sm-3 col-md-2  ">
                        <a href={process.env.REACT_APP_FACEBOOK_URL} target="_blank" >
                          <img className="img-fluid" src={facebook}/>
                        </a>
                      </div>

                      {/* <div className=" col-sm-3 col-md-2  ">
                        <a href={process.env.REACT_APP_INSTAGRAM_URL} target="_blank" >
                          <img className="img-fluid" src={instagram}/>
                        </a>

                      </div>
                      <div className=" col-3 col-sm-3 col-md-2  ">
                        <a href={process.env.REACT_APP_YOUTUBE_URL} target="_blank" >
                          <img className="img-fluid" src={youtube}/>
                        </a>
                      </div> */}
                    </div>
                  </div>
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
}

export default Contact
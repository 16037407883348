import React, { useState } from "react";
import Head from "../../layout/head/Head";
import Content from "../../layout/content/Content";
import OrderOverview from "../../components/partials/crypto/order-overview/OrderOverview";
import ActionCenter from "../components/ActionCenter";
import CoinOrder from "../../components/partials/crypto/coin-order/CoinOrder";
// import UserActivity from "../components/partials/crypto/user-activity/UserActivity";
import RecentApplicants from "../components/RecentApplicants";
import RecentCompanies from "../components/RecentCompanies";
import RecentJobs from "../components/RecentJobs";
import SkillsReport from "../components/SkillsReport";
import { Card, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import { useTranslation } from "react-i18next";
import {
  Block,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockBetween,
  BlockTitle,
  PreviewAltCard,
  Icon,
  Button,
  Row,
  Col,
} from "../../components/Component";

// import Layout from "../.././layout/Index";


const CryptoHomePage = () => {
  const [sm, updateSm] = useState(false);
  const [t, i18n] = useTranslation("global")
  return (

    <React.Fragment>
      <Head title={t("title_dashboard")} />
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle page>{t("title_dashboard")}</BlockTitle>
              <BlockDes className="text-soft">
                <p>{t("dashboard_description")}</p>
              </BlockDes>
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <Button
                  className={`btn-icon btn-trigger toggle-expand mr-n1 ${sm ? "active" : ""}`}
                  onClick={() => updateSm(!sm)}
                >
                  <Icon name="more-v" />
                </Button>
                {/* <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                  <ul className="nk-block-tools g-3">
                    <li>
                      <Button color="primary" outline className="btn-dim btn-white">
                        <Icon name="download-cloud"></Icon>
                        <span>Export</span>
                      </Button>
                    </li>
                    <li>
                      <Button color="primary" outline className="btn-dim btn-white">
                        <Icon name="reports"></Icon>
                        <span>Reports</span>
                      </Button>
                    </li>
                    <li className="nk-block-tools-opt">
                      <UncontrolledDropdown>
                        <DropdownToggle color="transparent" className="btn btn-primary btn-icon dropdown-toggle">
                          <Icon name="plus"></Icon>
                        </DropdownToggle>
                        <DropdownMenu right>
                          <ul className="link-list-opt no-bdr">
                            <li>
                              <DropdownItem
                                tag="a"
                                href="#dropdownitem"
                                onClick={(ev) => {
                                  ev.preventDefault();
                                }}
                              >
                                <Icon name="user-add-fill"></Icon>
                                <span>Add User</span>
                              </DropdownItem>
                            </li>
                            <li>
                              <DropdownItem
                                tag="a"
                                href="#dropdownitem"
                                onClick={(ev) => {
                                  ev.preventDefault();
                                }}
                              >
                                <Icon name="coin-alt-fill"></Icon>
                                <span>Add Order</span>
                              </DropdownItem>
                            </li>
                            <li>
                              <DropdownItem
                                tag="a"
                                href="#dropdownitem"
                                onClick={(ev) => {
                                  ev.preventDefault();
                                }}
                              >
                                <Icon name="note-add-fill-c"></Icon>
                                <span>Add Page</span>
                              </DropdownItem>
                            </li>
                          </ul>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </li>
                  </ul>
                </div> */}
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Block>
          <Row className="g-gs">
            {/* <Col lg="8">
              <PreviewAltCard className="h-100">
                <OrderOverview />
              </PreviewAltCard>
            </Col> */}
            <Col lg="8">
              <Card className="card-bordered card-full">
                <RecentApplicants />
              </Card>
            </Col>
            <Col lg="4">
            <Row className="g-gs">
                <Col md="6" lg="12">
                <Card className="card-bordered h-100">
                <ActionCenter />
                </Card>
                </Col>
                {/* <Col md="6" lg="12">
                  <Card className="card-bordered card-full">
                    <UserActivity />
                  </Card>
                </Col> */}
              </Row>

            </Col>
            <Col lg="8">
              <Card className="card-bordered card-full">
                <RecentCompanies />
              </Card>
            </Col>
            <Col lg="4">
              <Card className="card-bordered card-full">
                <SkillsReport />
              </Card>
            </Col>
            {/* <Col lg="4">
              <Row className="g-gs">
                <Col md="6" lg="12">
                  <PreviewAltCard className="card-full">
                    <CoinOrder />
                  </PreviewAltCard>
                </Col>
                <Col md="6" lg="12">
                  <Card className="card-bordered card-full">
                    <UserActivity />
                  </Card>
                </Col>
              </Row>
            </Col> */}
          </Row>
        </Block>
      </Content>
    </React.Fragment>
  );
};

export default CryptoHomePage;

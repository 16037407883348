import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { Row, Col, FormGroup, Label } from "reactstrap";
// Form
import { useForm } from "react-hook-form";
import { Button, OverlineTitle } from "../../../components/Component";

import { useTranslation } from "react-i18next";
import axios from "axios";
import Swal from "sweetalert2";
import UserProfile from "../../../Login/UserProfile";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import Resizer from "react-image-file-resizer";

const FormValidationComponent = ({ alter }) => {
  const [t, i18n] = useTranslation("regcomp");
  const [pais, setpais] = useState([]);
  const [departamento, setdepartamento] = useState([]);
  const [selcountry, setselcountry] = useState(0);
  const [conta, setconta] = useState(0);
  const [reponsable, setreponsable] = useState([]);
  const [cargo, setcargo] = useState([]);
  const [actividades, setactividades] = useState([]);
  const [optionSelected, setoptionSelected] = useState([]);
  const [actividadesempresa, setactividadesempresa] = useState([]);
  const [newImage, setnewImage] = useState("");
  const [pdfcoding, setpdfcoding] = useState("");
  const [regerrors, setregerrors] = useState([]);
  const [countdescp, setcountdescp] = useState(400);

  const { errors, register, handleSubmit } = useForm();
  // const onFormSubmit = (e) => {};
  const formClass = classNames({
    "form-validate": true,
    "is-alter": alter,
  });

  const addNewActivity = (data) => {
    setactividades([...actividades, data]);
  };

  const fileChangedHandler = (event) => {
    var fileInput = false;
    if (event.target.files[0]) {
      fileInput = true;
    }
    if (fileInput) {
      try {
        Resizer.imageFileResizer(
          event.target.files[0],
          500,
          500,
          "png",
          85,
          0,
          (uri) => {
            setnewImage(uri);
          },
          "base64"
        );
      } catch (err) {
        console.log(err);
      }
    }
  };

  const registro = (val, resetForm) => {
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/api/companies`, val, {
        headers: {
          Authorization: `Bearer  ${UserProfile.getToken()}`,
          // Authorization: `Bearer  eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC9sb2NhbGhvc3Q6ODAwMFwvYXBpXC9hdXRoXC9sb2dpbiIsImlhdCI6MTY4MzMzMjkzOCwiZXhwIjoxNjgzMzY4OTM4LCJuYmYiOjE2ODMzMzI5MzgsImp0aSI6IjJMRWRzZ01GYmlFWDNXaTAiLCJzdWIiOjI4OCwicHJ2IjoiMjNiZDVjODk0OWY2MDBhZGIzOWU3MDFjNDAwODcyZGI3YTU5NzZmNyJ9.rXvze6knPVlczYUj5CZG1UTSN082_7RrvoIfJs7KxpU`,
        },
      })
      .then((res) => {
        // console.log(res);
        if (res.data.error ? res.data.error[0] == "El Token a Expirado" : false) {
          window.location.href = "#/login";
          //props.history.push("/login");
          return;
        }
        if (res.data.status === true || res.data.status == 200) {
          Swal.fire({
            icon: "success",
            title: t("good_job"),
            text: t("data_saved"),
            confirmButtonColor: "#071b30",
            confirmButtonText: t("ok"),
          });
          window.location.href = "#/company";
          // props.history.push("/company");
          resetForm();
        }
        if (res.data.status === false) {
          setregerrors(res.data.error);
        }
      })
      .catch((error) => {
        console.log(error);
        window.location.href = "#/login";
        // props.history.push("/login");
      });
  };

  const getActividad = async () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/attrs/activities`, {
        headers: {
          Authorization: `Bearer  ${UserProfile.getToken()}`,
        },
      })
      .then((res) => {
        setactividades(res.data.data.activities);
      });
  };

  const getCargo = async () => {
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/api/attrs/filterpositions/1`, [], {
        headers: {
          Authorization: `Bearer  ${UserProfile.getToken()}`,
        },
      })
      .then((res) => {
        setcargo(res.data.data);
      });
  };

  const getDeparment = async (val) => {
    // val = val.id
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/departments/by_country/${val}`, {
        headers: {
          Authorization: `Bearer  ${UserProfile.getToken()}`,
        },
      })
      .then((res) => {
        setdepartamento(res.data.data.department);
      });
  };

  const getCountry = async () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/attrs/country`, {
        headers: {
          Authorization: `Bearer  ${UserProfile.getToken()}`,
        },
      })
      .then((res) => {
        setpais(res.data.data.country);
      });
  };

  const filedocChangedHandler = (event) => {
    var fileInput = false;
    if (event.target.files[0]) {
      fileInput = true;
    }
    if (fileInput) {
      var ext = event.target.files[0].name.split(".").pop();
      if (ext == "pdf") {
        if (event.target.files[0].size > 5242880) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: t("msg1"),
            confirmButtonColor: "#071b30",
            confirmButtonText: t("ok"),
          });
          return;
        }
        let fileToLoad = event.target.files[0];
        let file = null;
        let fileName = null;
        fileName = fileToLoad.name;
        // FileReader function for read the file.
        let fileReader = new FileReader();
        // Onload of file read the file content
        // let cntx = this
        fileReader.onload = function (fileLoadedEvent) {
          file = fileLoadedEvent.target.result;
          setpdfcoding(file);
        };
        // Convert data to base64
        fileReader.readAsDataURL(fileToLoad);
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: t("msg2"),
          confirmButtonColor: "#071b30",
          confirmButtonText: t("ok"),
        });
      }
    }
  };

  useEffect(() => {
    if (navigator.onLine) {
      UserProfile.setToken(localStorage.getItem("token"));
      getCountry();
      getCargo();
      getActividad();
      // Remember to activate the account
      Swal.fire({
        icon: "info",
        title: t("title_activate"),
        text: t("remember_activate_account"),
        confirmButtonColor: "#071b30",
        confirmButtonText: t("ok"),
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: t("msg3"),
        confirmButtonColor: "#071b30",
        confirmButtonText: t("ok"),
      });
    }
  }, []);

  const renderList = () => {
    return actividades.map((data) => ({
      label: data.name,
      value: data.id,
    }));
  };

  const renderListActividad = () => {
    return optionSelected.map((data) => ({
      activity: data.value,
    }));
  };

  return (
    <React.Fragment>
      <Formik
        initialValues={{
          identification: "",
          name: "",
          description: "",
          phone: "",
          addres: "",
          country: "",
          departmen: "",
          codepostal: "",

          //responsable
          namerespon: "",
          namerespons: "",
          identificacionespons: "",
          correorespons: "",
          positionrespon: "",
          conditions: "",
        }}
        validate={(values) => {
          const pattern = new RegExp("^[a-zA-ZñÑáéíóúÁÉÍÓÚÇ ]+$", "i");
          const errors = {};
          if (!values.name) errors.name = t("fr");

          if (!values.namerespon) errors.namerespon = t("fr");
          else if (!pattern.test(values.namerespon)) errors.namerespon = t("ot");

          if (!values.namerespons) errors.namerespons = t("fr");
          else if (!pattern.test(values.namerespons)) errors.namerespons = t("ot");
          if (selcountry < 1) errors.country = t("fr");
          // if (!values.country)
          //   errors.country = t("fr");
          // else
          //  getDeparment(values.country)

          if (values.description) {
            setcountdescp(401 - values.description.length);
          }

          return errors;
        }}
        validationSchema={Yup.object().shape({
          identification: Yup.string().typeError(t("fin")).required(t("fr")),

          description: Yup.string().required(t("fr")),
          conditions: Yup.string().required(t("fr")),
          phone: Yup.number().typeError(t("fin")).required(t("fr")),
          addres: Yup.string().required(t("fr")),
          country: Yup.string(),
          departmen: Yup.string().required(t("fr")),
          codepostal: Yup.string().typeError(t("fin")).required(t("fr")),

          identificacionespons: Yup.number().typeError(t("fin")).required(t("fr")),

          correorespons: Yup.string().required(t("fr")).email(t("ein")),

          positionrespon: Yup.string().required(t("fr")),

          //  actividadempresa:
          //Yup.string().required(t("fr")),
        })}
        onSubmit={(val, { resetForm }) => {
          const data = {
            name: val.name,
            logo: newImage,
            document: pdfcoding.replace("data:application/pdf;base64,", ""),
            nit: val.identification,
            description: val.description,
            phone_number: val.phone,
            address: val.addres,
            country: selcountry,
            department: val.departmen,
            postal_code: val.codepostal,
            status: 1,
            // company_activities:
            //   renderListActividad()
            // ,

            legal_representative: [
              {
                first_name: val.namerespon.trim(),
                last_name: val.namerespons.trim(),
                identification: val.identificacionespons.trim(),
                email: val.correorespons.trim(),
                position: val.positionrespon,
              },
            ],
          };
          registro(data, resetForm);
        }}
        enableReinitialize
        render={({ errors, status, touched }) => (
          <Form className={formClass}>
            <Row className="g-gs">
              <Col md="6">
                <FormGroup>
                  <Label for="identification" className="form-label">
                    {t("lbl1")} *
                  </Label>
                  <div className="form-control-wrap">
                    <Field
                      id="identification"
                      name="identification"
                      type="text"
                      className="form-control imputText"
                      placeholder={t("lbl1")}
                    />

                    <ErrorMessage name="identification" component="div" className="invalid" />
                  </div>
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label for="country" className="form-label">
                    {t("lbl6")} *
                  </Label>
                  <div className="form-control-wrap">
                    <div className="form-control-select">
                      <select
                        id="country"
                        name="country"
                        className="form-control form-select"
                        onChange={(e) => (setselcountry(e.target.value), getDeparment(e.target.value))}
                        // value={country}
                      >
                        <option value="" selected>
                          {t("lbl6")}
                        </option>

                        {pais.map((i) => {
                          return <option value={i.id}>{i.name}</option>;
                        })}
                      </select>
                      <ErrorMessage name="country" component="div" className="invalid" />
                    </div>
                  </div>
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label for="name" className="form-label">
                    {t("lbl2")} *
                  </Label>
                  <div className="form-control-wrap">
                    <Field
                      id="name"
                      name="name"
                      type="text"
                      className="form-control imputText"
                      placeholder={t("lbl2")}
                    />

                    <ErrorMessage name="name" component="div" className="invalid" />
                  </div>
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label for="departmen" className="form-label">
                    {t("lbl7")} *
                  </Label>
                  <div className="form-control-wrap">
                    <div className="form-control-select">
                      <Field as="select" id="departmen" name="departmen" className="form-control form-select">
                        <option value="" selected>
                          {t("lbl7")}
                        </option>

                        {departamento.map((i) => {
                          return <option value={i.id}>{i.name}</option>;
                        })}
                      </Field>

                      <ErrorMessage name="departmen" component="div" className="invalid" />
                    </div>
                  </div>
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label for="phone" className="form-label">
                    {t("lbl4")} *
                  </Label>
                  <div className="form-control-wrap">
                    <Field
                      id="phone"
                      name="phone"
                      type="number"
                      className="form-control imputText"
                      placeholder={t("lbl4")}
                    />

                    <ErrorMessage name="phone" component="div" className="invalid" />
                  </div>
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label for="codepostal" className="form-label">
                    {t("lbl8")} *
                  </Label>
                  <div className="form-control-wrap">
                    <Field
                      id="codepostal"
                      type="text"
                      name="codepostal"
                      className="form-control imputText"
                      placeholder={t("lbl8")}
                    />

                    <ErrorMessage name="codepostal" component="div" className="invalid" />
                  </div>
                </FormGroup>
              </Col>

              <Col md="6">
                <FormGroup>
                  <Label for="addres" className="form-label">
                    {t("lbl5")} *
                  </Label>
                  <div className="form-control-wrap">
                    <Field
                      id="addres"
                      name="addres"
                      type="text"
                      className="form-control imputText"
                      placeholder={t("lbl5")}
                    />

                    <ErrorMessage name="addres" component="div" className="invalid" />
                  </div>
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label for="description" className="form-label">
                    {t("lbl9")}
                  </Label>
                  <div className="form-control-wrap">
                    <input
                      id="file"
                      type="file"
                      className="form-control"
                      accept="image/png, image/jpeg,image/jpg "
                      title="Choose a photo please"
                      webkitRelativePath
                      directory
                      multiple
                      onChange={fileChangedHandler}
                    />
                    <ErrorMessage name="file" component="div" className="invalid" />
                  </div>
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label for="description" className="form-label">
                    {t("lbl3")} *
                  </Label>
                  <div className="form-control-wrap">
                    <Field
                      id="description"
                      name="description"
                      type="textarea"
                      className="form-control imputText textArea"
                      component="textarea"
                      placeholder={t("lbl3")}
                    />

                    <ErrorMessage name="description" component="div" className="invalid" />
                    <label>{countdescp}</label>
                  </div>
                </FormGroup>
              </Col>

              <Col md="6">
                <FormGroup>
                  <Label for="description" className="form-label">
                    {t("lbl10")}
                  </Label>
                  <div className="form-control-wrap">
                    {/* <div className="alert alert-light" role="alert"> */}
                    {t("lbl11")}
                    {/* </div> */}

                    <input
                      id="filedoc"
                      type="file"
                      className="form-control"
                      accept="application/pdf"
                      title="Choose a photo please"
                      webkitRelativePath
                      directory
                      multiple
                      onChange={filedocChangedHandler}
                    />

                    <ErrorMessage name="filedoc" component="div" className="invalid" />
                  </div>
                </FormGroup>
              </Col>

              <Col md="12">
                <OverlineTitle tag="span" className="preview-title-lg">
                  {" "}
                  {t("lbl12")}{" "}
                </OverlineTitle>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label for="namerespon" className="form-label">
                    {t("lbl13")} *
                  </Label>
                  <div className="form-control-wrap">
                    <Field
                      id="namerespon"
                      type="text"
                      name="namerespon"
                      className="form-control imputText"
                      placeholder={t("lbl13")}
                    />

                    <ErrorMessage name="namerespon" component="div" className="invalid" />
                  </div>
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <label for="namerespons" className="form-label">
                    {t("lbl14")} *
                  </label>
                  <div className="form-control-wrap">
                    <Field
                      id="namerespons"
                      type="text"
                      name="namerespons"
                      className="form-control imputText"
                      placeholder={t("lbl14")}
                    />

                    <ErrorMessage name="namerespons" component="div" className="invalid" />
                  </div>
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <label for="identificacionespons" className="form-label">
                    {t("lbl15")} *
                  </label>
                  <div className="form-control-wrap">
                    <Field
                      id="identificacionespons"
                      type="text"
                      name="identificacionespons"
                      className="form-control imputText"
                      placeholder={t("lbl15")}
                    />

                    <ErrorMessage name="identificacionespons" component="div" className="invalid" />
                  </div>
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <label for="correorespons" className="form-label">
                    {t("lbl16")} *
                  </label>
                  <div className="form-control-wrap">
                    <Field
                      id="correorespons"
                      type="text"
                      name="correorespons"
                      className="form-control imputText"
                      placeholder={t("lbl16")}
                    />

                    <ErrorMessage name="correorespons" component="div" className="invalid" />
                  </div>
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label for="positionrespon" className="form-label">
                    {t("lbl17")} *
                  </Label>
                  <div className="form-control-wrap">
                    <div className="form-control-select">
                      <Field as="select" id="positionrespon" name="positionrespon" className="form-control form-select">
                        <option value="" selected>
                          {t("lbl17")}
                        </option>

                        {cargo.map((i) => {
                          return <option value={i.id}>{i.name}</option>;
                        })}
                      </Field>
                      <ErrorMessage name="positionrespon" component="div" className="invalid" />
                    </div>
                  </div>
                </FormGroup>
              </Col>
              <Col md="12">
                <FormGroup>
                  <ul className="custom-control-group g-3 align-center">
                    <li>
                      <div className="custom-control custom-checkbox">
                        <Field
                          id="conditions"
                          name="conditions"
                          className="form-control custom-control-input"
                          ref={register({
                            required: true,
                          })}
                          type="checkbox"
                          placeholder={t("lbl22")}
                        />
                        <Label className="custom-control-label" htmlFor="conditions">
                          {" "}
                          {t("lbl22")}{" "}
                        </Label>
                        <a
                          className="fs-6"
                          target="_blank"
                          onClick={() => window.open(process.env.REACT_APP_DOC_TRAT_DATA, "_blank")}
                        >
                          &nbsp;&nbsp;&nbsp; {t("lbl23")}{" "}
                        </a>
                        <ErrorMessage name="conditions" component="div" className="invalid" />
                      </div>
                    </li>
                  </ul>
                </FormGroup>
              </Col>

              {regerrors.map((item) => (
                <div className="alert alert-danger" role="alert">
                  {t(item)}
                </div>
              ))}
              <Col md="12">
                <FormGroup>
                  <Button color="primary" size="lg" type="submit">
                    {t("lbl19")}
                  </Button>
                </FormGroup>
              </Col>
            </Row>
          </Form>
        )}
      />
    </React.Fragment>
  );
};
export default FormValidationComponent;

import React, { useState, useEffect } from "react";
import Logo from "../../images/logo2x.png";
import LogoDark from "../../images/logo2x.png";
import PageContainer from "../../layout/page-container/PageContainer";
import Head from "../../layout/head/Head";
import AuthFooter from "./AuthFooter";
import { useTranslation } from "react-i18next";
// import svgFacebook from "../img/facebook.svg";
import icoGoogle from "../../images/google_ico.png";
import linkedin from "../../images/linkedinicon.png";
import UserProfile from "../../Login/UserProfile";
import {
  Block,
  BlockContent,
  BlockDes,
  BlockHead,
  BlockTitle,
  Button,
  Icon,
  PreviewCard,
} from "../../components/Component";
import { Form, FormGroup, Spinner, Alert } from "reactstrap";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import { useLocation } from "react-router-dom";

const Login = (props) => {
  const [ chekContras, setchekContras ] = useState(false);
  const [ tokentemp, settokentemp ] = useState("");
  const [ idusertemp, setidusertemp ] = useState("");
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [passState, setPassState] = useState(false);
  const [errorVal, setError] = useState("");
  const [t, i18n] = useTranslation("login");

  const onFormSubmit = (formData) => {
    setLoading(true);
    // console.log(formData,'formData');
    setLogin(formData);
    // const loginName = "info@softnio.com";
    // const pass = "123456";
    // if (formData.name === loginName && formData.passcode === pass) {
    //   localStorage.setItem("accessToken", "token");
    //   setTimeout(() => {
    //     window.history.pushState(
    //       `${process.env.PUBLIC_URL ? process.env.PUBLIC_URL : "/"}`,
    //       "auth-login",
    //       `${process.env.PUBLIC_URL ? process.env.PUBLIC_URL : "/"}`
    //     );
    //     window.location.reload();
    //   }, 2000);
    // } else {
    //   setTimeout(() => {
    //     setError(t("lbl_invalid_credentials"));
    //     setLoading(false);
    //   }, 2000);
    // }
  };


  const { errors, register, handleSubmit } = useForm();

  const iniciaSesionGoogle = (nombre, correo, id) => {
    const user = { id: 1}
    // const user = { id: id, name: nombre, email: correo }

    axios
      .post(`${process.env.REACT_APP_BASE_URL}/api/auth/social/google/login`, user)
      .then((res) => {
        if (res.data.status == true) {
          UserProfile.setToken(res.data.data.token);
          props.history.push("/home");
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "to Log in",
          });
        }
      });
  }

  // const registerFacebook = (nombre, correo, id, foto) => {}

  const check = async () => {
    if (chekContras != false) {
      setchekContras(false)
    } else {
      setchekContras(true)
    }
  }

  const crearCuenta = async () => {
    props.history.push("/register");
  }

  const recuperacionCuenta = async () => {
    props.history.push("/paswordrecovery");
  }

  const setLogin = async (value) => {
    const user = {
      email: value.email,
      password: value.password,
    }
      axios
        .post(`${process.env.REACT_APP_BASE_URL}/api/auth/login`, user)
        .then((res) => {
          if (res.data.status == true) {
            // Check if the user is active (Danny)
            if (res.data.data.user.status.id == 2) {
              activeAcount(res)
            }else{
              loadSessionData(res)
              localStorage.setItem("accessToken", "token");
              // setTimeout(() => {
              //   window.history.pushState(
              //     `${process.env.PUBLIC_URL ? process.env.PUBLIC_URL : "/"}`,
              //     "auth-login",
              //     `${process.env.PUBLIC_URL ? process.env.PUBLIC_URL : "/"}`
              //   );
              //   window.location.reload();
              // }, 2000);
            }
          } else {
            setTimeout(() => {
                  setError(t("lbl_invalid_credentials"));
                  setLoading(false);
                }, 2000);
            // Swal.fire({
            //   icon: "error",
            //   title:t("lbl_invalid_credentials"),
            //   text: t("lbl_text_invalid_credentials"),
            // });
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
  }

  const changeTypeUser = (type) => {
    const valuesupdate = {
      userid: idusertemp,
      type: type,
    }
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/api/auth/changetypeuser`,
        valuesupdate, {
           headers: {
             Authorization: `Bearer  ${tokentemp}`,
           }
         }
      )
      .then((res) => {
        document.getElementById("closemodal").click()
        loadSessionData(res)
      })
      .catch((error) => console.log(error));
  }

  const loadSessionData = (res) => {
    if ( !((res.data.data.user.type ? res.data.data.user.type.id : 0) > 0) || res.data.data.user.type.id == 4 ) {
      document.getElementById("btnmdlchangeusertype").click()
      settokentemp(res.data.data.token)
      setidusertemp(res.data.data.user.id)
      console.log('en el if de loadSessionData');
    }else{
      console.log(res.data.data.user.type.id,"tipo user");
      switch (res.data.data.user.type.id) {
        case 1:
          localStorage.setItem('token',res.data.data.token);
          localStorage.setItem('id',res.data.data.user.id);
          localStorage.setItem('typeuser',res.data.data.user.type.id);
          localStorage.setItem('user',JSON.stringify(res.data.data.user));
          localStorage.setItem('name',res.data.data.user.name);
          localStorage.setItem('email',res.data.data.user.email);
          localStorage.setItem('rol',res.data.data.user.type.description);
          console.log("admin");
          props.history.push("/admin");
        break;

        case 2:
          localStorage.setItem('token',res.data.data.token);
          localStorage.setItem('id',res.data.data.user.id);
          localStorage.setItem('typeuser',res.data.data.user.type.id);
          localStorage.setItem('user',JSON.stringify(res.data.data.user));
          localStorage.setItem('name',res.data.data.user.name);
          localStorage.setItem('email',res.data.data.user.email);
          localStorage.setItem('rol',res.data.data.user.type.description);
          axios
          .get(`${process.env.REACT_APP_BASE_URL}/api/has_profile_applicant`,{
            headers: {
              Authorization: `Bearer  ${res.data.data.token}`,
            }
          })
          .then((res) => {
            if(res.data.status===true){
              props.history.push("/applicant");
            }else{
              props.history.push("/applicant");
              // props.history.push("/registerapplicant")
            }
          });
        break;

        case 3:
        localStorage.setItem('user',JSON.stringify(res.data.data.user));
        localStorage.setItem('typeuser',res.data.data.user.type.id);
        localStorage.setItem('token',res.data.data.token);
        localStorage.setItem('id',res.data.data.user.id);
        localStorage.setItem('name',res.data.data.user.name);
          localStorage.setItem('email',res.data.data.user.email);
          localStorage.setItem('rol',res.data.data.user.type.description);
          axios
          .get(`${process.env.REACT_APP_BASE_URL}/api/has_companies`, {
            headers: {
              Authorization: `Bearer  ${res.data.data.token}`,
            }
          })
          .then((res) => {
            if(res.data.status===true){
              props.history.push("/company");
            }else{
              props.history.push("/registercompany");
            }
          });
        break;
      }
    }
  }

 console.log(localStorage.getItem('typeuser'),'user');
//  console.log(localStorage.getItem('id'),'id');

// Redirect if is Logged

//  if (localStorage.getItem('typeuser')) {
//   if(localStorage.getItem('typeuser')==1){
//     console.log('en el if de loadSessionData');
//     props.history.push("/admin")
//   } else if(localStorage.getItem('typeuser')==2){
//     props.history.push("/applicant")
//   }
//   else if(localStorage.getItem('typeuser')==3){
//     props.history.push("/company")
//   }
//  }
//  else {

//  }


  const activeAcount = (res) => {
    Swal.fire({
      title: t("lbl14"),
      text: t("lbl15"),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: t("lbl16")
    }).then((result) => {
      if (result.isConfirmed) {
        updateStatus(res)
        loadSessionData(res)
      }
    })
  }

  const updateStatus = (user) => {
    const valuesupdate = {
      name: user.data.data.user.name,
      email: user.data.data.user.email,
      type: 2,
      status: 1
    }
    axios
      .put(
        `${process.env.REACT_APP_BASE_URL}/api/users/${user.data.data.user.id}`,
        valuesupdate
      )
      .then(() => {
        Swal.fire({
          icon: "success",
          title: t('msg6'),
          confirmButtonColor: "#071b30",
          confirmButtonText: t("ok"),
        });
      })
      .catch((error) => console.log(error));
  }

    // const auth = firebase.auth()

    const validateactivate = () => {
      // console.log(location,'loc');
      if (location.search=="?status=activated"){
        Swal.fire({
          icon: "success",
          title: t('msg_successful_activated'),
          confirmButtonColor: "#071b30",
          confirmButtonText: t("ok"),
        });
      }
    }

    const validregisterlinkedin = () => {
      let params = props.match.params
      if (params.data) {
        const data = JSON.parse(params.data)
        if (data.original.status) {
          const res = {
            'data': {
              'data': {
                'user' : data.original.data.user,
                'token' : data.original.data.token
              }
            }
          }
          loadSessionData(res)
        }else{
          Swal.fire({
            icon: "success",
            title: "Opss",
            text: data.original.error[0],
          });
        }
      }
    }

    useEffect(()=>{
      // console.log(location,'location');
      validateactivate()
      validregisterlinkedin()
    }, [])


  return (
    <React.Fragment>
      <Head title="Login" />
      <PageContainer>
        <Block className="nk-block-middle nk-auth-body  wide-xs">
          <div className="brand-logo pb-4 text-center">
            <Link to={process.env.PUBLIC_URL + "/"} className="logo-link">
              <img className="logo-light logo-img logo-img-auth" src={Logo} alt="logo" />
              <img className="logo-dark logo-img logo-img-auth" src={LogoDark} alt="logo-dark" />
            </Link>
          </div>

          <PreviewCard className="card-bordered" bodyClass="card-inner-lg">
            <BlockHead>
              <BlockContent>
                <BlockTitle tag="h4">{t("lbl32")}</BlockTitle>
                <BlockDes>
                  <p>{t("lbl33")}</p>
                </BlockDes>
              </BlockContent>
            </BlockHead>
            {errorVal && (
              <div className="mb-3">
                <Alert color="danger" className="alert-icon">
                  {" "}
                  <Icon name="alert-circle" /> {t("lbl_invalid_credentials")}{" "}
                </Alert>
              </div>
            )}
            <Form className="is-alter" onSubmit={handleSubmit(onFormSubmit)}>
              <FormGroup>
                <div className="form-label-group">
                  <label className="form-label" htmlFor="default-01">
                  {t("lbl2")}
                  </label>
                </div>
                <div className="form-control-wrap">
                  <input
                    type="text"
                    id="email"
                    name="email"
                    ref={register({ required: t("fr") })}
                    defaultValue=""
                    placeholder= {t("txt3")}
                    className="form-control-lg form-control"
                  />
                  {errors.name && <span className="invalid">{errors.name.message}</span>}
                </div>
              </FormGroup>
              <FormGroup>
                <div className="form-label-group">
                  <label className="form-label" htmlFor="password">
                  {t("lbl5")}
                  </label>
                  <Link className="link link-primary link-sm" to={`${process.env.PUBLIC_URL}/auth-reset`}>
                  {t("lbl29")}
                  </Link>
                </div>
                <div className="form-control-wrap">
                  <a
                    href="#password"
                    onClick={(ev) => {
                      ev.preventDefault();
                      setPassState(!passState);
                    }}
                    className={`form-icon lg form-icon-right passcode-switch ${passState ? "is-hidden" : "is-shown"}`}
                  >
                    <Icon name="eye" className="passcode-icon icon-show"></Icon>

                    <Icon name="eye-off" className="passcode-icon icon-hide"></Icon>
                  </a>
                  <input
                    type={passState ? "text" : "password"}
                    id="password"
                    name="password"
                    defaultValue=""
                    ref={register({ required: t("fr") })}
                    placeholder= {t("txt4")}
                    className={`form-control-lg form-control ${passState ? "is-hidden" : "is-shown"}`}
                  />
                  {errors.passcode && <span className="invalid">{errors.passcode.message}</span>}
                </div>
              </FormGroup>
              <FormGroup>
                <Button size="lg" className="btn-block" type="submit" color="primary">
                  {loading ? <Spinner size="sm" color="light" /> :  t("lbl27")}
                </Button>
              </FormGroup>
            </Form>
            <div className="form-note-s2 text-center pt-4">
              {" "}
              {t("lbl30")} <Link to={`${process.env.PUBLIC_URL}/auth-register`}> {t("lbl28")}</Link>
            </div>
            <div className="text-center pt-4 pb-3">
              <h6 className="overline-title overline-title-sap">
                <span> {t("lbl25")}</span>
              </h6>
            </div>
            <ul className="nav justify-center gx-4">
              <li className="nav-item">
              <a href={process.env.REACT_APP_URLENCODE_LOGIN_LINKEDIN} >
                      <div>
                        <img
                          className="logo-img-sm"
                          src={linkedin}
                        ></img>
                      </div>
              </a>
              </li>
              <li className="nav-item">
              <a  href={process.env.REACT_APP_BASE_URL+"/auth/google-redirect"} >
                      <div>
                        <img
                          className="logo-img-sm"
                          src={icoGoogle}
                        ></img>
                      </div>
              </a>
              </li>
            </ul>
          </PreviewCard>
        </Block>
        <AuthFooter />
      </PageContainer>
    </React.Fragment>
  );
};
export default Login;

import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import Head from "../../layout/head/Head";
import axios from "axios";
import UserProfile from "../../Login/UserProfile";
import RegisterLanguage from "./RegisterLanguage";
import AddLanguage from "./AddLanguage";
import {
    DropdownMenu,
    DropdownToggle,
    FormGroup,
    UncontrolledDropdown,
    Modal,
    ModalBody,
    DropdownItem,
  } from "reactstrap";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Button,
  Row,
  Col,
  PaginationComponent,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableRow,
  DataTableItem,
  RSelect,
  TooltipComponent,
} from "../../components/Component";
// import { useForm } from "react-hook-form";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

const UserProfileLanguages = (props) => {

    const [t, i18n] = useTranslation("detailapplap")
    const [modalTab, setModalTab] = useState("1");
    const [ idApplicant, setidApplicant ] = useState(0);
    const [ educationRes, seteducationRes ] = useState([]);
    const [ levelStudy, setlevelStudy ] = useState([]);
    const [data, setData] = useState("");

    const [sm, updateSm] = useState(false);
    const [tablesm, updateTableSm] = useState(false);
    const [onSearch, setonSearch] = useState(true);
    const [onSearchText, setSearchText] = useState("");
    const [modal, setModal] = useState({
      edit: false,
      add: false,
    });
    const [editId, setEditedId] = useState();
    const [formData, setFormData] = useState({
      name: "",
      email: "",
      balance: "",
      phone: "",
      status: "Active",
    });
    const [currentPage, setCurrentPage] = useState(1);
    const [itemPerPage, setItemPerPage] = useState(10);
    const [sort, setSortState] = useState("");

    const [ pais, setpais ] = useState([]);
    const [ paisLocation, setpaisLocation ] = useState([]);
    const [ departamento, setdepartamento ] = useState([]);
    const [ city, setcity ] = useState([]);
    const [ email, setEmail ] = useState([]);
    const [ optionSelected, setoptionSelected ] = useState(null);
    const [ languageRes, setlanguageRes ] = useState([]);
    const [ languagetipo, setlanguagetipo ] = useState([]);
    const [ nivelLanguage, setnivelLanguage ] = useState([]);

    // Sorting data
  const sortFunc = (params) => {
    let defaultData = data;
    if (params === "asc") {
      let sortedData = defaultData.sort((a, b) => a.name.localeCompare(b.name));
      setData([...sortedData]);
    } else if (params === "dsc") {
      let sortedData = defaultData.sort((a, b) => b.name.localeCompare(a.name));
      setData([...sortedData]);
    }
  };

  const   addLanguages = (val) => {
    var len = languagetipo.find( item => item.id == val.language )
    var lvl = nivelLanguage.find( item => item.id == val.level )

    const temp = {
      "language": len,
      "level": lvl
    }
    setlanguageRes([...languageRes, temp])
  };

  const deleteLanguages = (val) => {
    setlanguageRes(languageRes.filter((lng,itm)=> itm !== val))
  };
  const getLanguage = async () => {
    await axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/attrs/languages`, {
        headers: {
          Authorization: `Bearer  ${UserProfile.getToken()}`,
        },
      })
      .then((res) => {
        setlanguagetipo(res.data.data.languages)
      });
  };

  const getLevelLanguage = async () => {
    await axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/attrs/language_level`, {
        headers: {
          Authorization: `Bearer  ${UserProfile.getToken()}`,
        },
      })
      .then((res) => {
        setnivelLanguage(res.data.data.language_level)
      });
  };

  // Changing state value when searching name
  useEffect(() => {
    if (onSearchText !== "") {
      const filteredObject = educationRes.filter((item) => {
        return (
          item.name.toLowerCase().includes(onSearchText.toLowerCase()) ||
          item.email.toLowerCase().includes(onSearchText.toLowerCase())
        );
      });
      setData([...filteredObject]);
    } else {
      setData([...data]);
    }
  }, [onSearchText, setData]);

  // onChange function for searching name
  const onFilterChange = (e) => {
    setSearchText(e.target.value);
  };

    // function to reset the form
    const resetForm = () => {
      setFormData({
        name: "",
        email: "",
        type:1,
        password: "",
        status: 1,
      });
    };

    // function to close the form modal
    const onFormCancel = () => {
      setModal({ edit: false, add: false });
      resetForm();
    };

      // function to toggle the search option
  const toggle = () => setonSearch(!onSearch);
    // Get current list, pagination
  const indexOfLastItem = currentPage * itemPerPage;
  const indexOfFirstItem = indexOfLastItem - itemPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  // Change Page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const addlanguagetipo = (data) => {
    setlanguagetipo([...languagetipo, data])
  }

const getApplicant = async () => {
    var name = "";
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/api/applicants/profile`,
        {
          headers: {
            Authorization: `Bearer  ${UserProfile.getToken()}`,
          },
        }
      )
      .then((res) => {
        if (res.data.error ? res.data.error[0] == 'El Token a Expirado' : false) {props.history.push('/login'); return}
        setidApplicant(res.data.data.id)
        setEmail(res.data.data.email)
        // multiples
        setlanguageRes(res.data.data.languages_applicant)
        setData(res.data.data.languages_applicant)
        let opactsel = []
        for (const acti of res.data.data.applicant_activities) {
          opactsel.push({"value":acti.activity.id, "label":acti.activity.name})
        }
        setoptionSelected(opactsel)

      })
      .catch((error) => console.log(error));
  };


  const onEditClick = (id) => {
    console.log('clic')

    setModal({ edit: true }, { add: false });
    setEditedId(id);

  };

  useEffect(() => {
    if (navigator.onLine) {
      UserProfile.setToken(localStorage.getItem("token"));
      UserProfile.setId(localStorage.getItem("id"));
      getLanguage();
      getLevelLanguage();
      // getEducationInstitution();

      /***************** */
      getApplicant();

    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "msg",
        confirmButtonColor: "#071b30",
        confirmButtonText: "ok",
      });
    }
  }, [])

  return (
    <React.Fragment>
      <Head title="Studies and Languages - Profile"></Head>

      <BlockHead size="lg">
        <BlockBetween>
          <BlockHeadContent>
            {/* Work Experience */}
            <BlockTitle tag="h4">{t("languages_info")}</BlockTitle>
            <BlockDes>
              <p>{t("languages_basic_info")}</p>
            </BlockDes>
          </BlockHeadContent>
          <BlockHeadContent>

            <div className="toggle-wrap nk-block-tools-toggle">
                <Button
                  className={`btn-icon btn-trigger toggle-expand mr-n1 ${sm ? "active" : ""}`}
                  onClick={() => updateSm(!sm)}
                >
                  <Icon name="menu-alt-r"></Icon>
                </Button>
                <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                  <ul className="nk-block-tools g-3">
                    <li className="nk-block-tools-opt">
                      <Button color="primary" className="btn-icon" onClick={() => setModal({ add: true })}>
                        <Icon name="plus"></Icon> {t("ttl1")} &nbsp;
                      </Button>
                    </li>
                  </ul>
                </div>
              </div>
          </BlockHeadContent>
        </BlockBetween>
      </BlockHead>

      <Block>

      <DataTable className="card-stretch">
            <div className="card-inner position-relative card-tools-toggle">
              <div className="card-title-group">
              <div className="card-title">
                  <h5 className="title">{t("your_languages")}</h5>
                </div>
              <div className="card-tools">
                  <div className="form-inline flex-nowrap gx-3">
                  </div>
                </div>
                <div className="card-tools mr-n1">
                  <ul className="btn-toolbar gx-1">
                    <li>
                      <a
                        href="#search"
                        onClick={(ev) => {
                          ev.preventDefault();
                          toggle();
                        }}
                        className="btn btn-icon search-toggle toggle-search"
                      >
                        <Icon name="search"></Icon>
                      </a>
                    </li>
                    <li className="btn-toolbar-sep"></li>
                    <li>
                      <div className="toggle-wrap">
                        <Button
                          className={`btn-icon btn-trigger toggle ${tablesm ? "active" : ""}`}
                          onClick={() => updateTableSm(true)}
                        >
                          <Icon name="menu-right"></Icon>
                        </Button>
                        <div className={`toggle-content ${tablesm ? "content-active" : ""}`}>
                          <ul className="btn-toolbar gx-1">
                            <li>
                              <UncontrolledDropdown>
                                <DropdownToggle tag="a" className="btn btn-trigger btn-icon dropdown-toggle">
                                  <Icon name="setting"></Icon>
                                </DropdownToggle>
                                <DropdownMenu right className="dropdown-menu-xs">
                                  <ul className="link-check">
                                    <li>
                                      <span>{t("show", {ns:"global"})}</span>
                                    </li>
                                    <li className={itemPerPage === 10 ? "active" : ""}>
                                      <DropdownItem
                                        tag="a"
                                        href="#dropdownitem"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                          setItemPerPage(10);
                                        }}
                                      >
                                        10
                                      </DropdownItem>
                                    </li>
                                    <li className={itemPerPage === 15 ? "active" : ""}>
                                      <DropdownItem
                                        tag="a"
                                        href="#dropdownitem"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                          setItemPerPage(15);
                                        }}
                                      >
                                        15
                                      </DropdownItem>
                                    </li>
                                  </ul>
                                  <ul className="link-check">
                                    <li>
                                      <span>{t("order", {ns:"global"})}</span>
                                    </li>
                                    <li className={sort === "dsc" ? "active" : ""}>
                                      <DropdownItem
                                        tag="a"
                                        href="#dropdownitem"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                          setSortState("dsc");
                                          sortFunc("dsc");
                                        }}
                                      >
                                        DESC
                                      </DropdownItem>
                                    </li>
                                    <li className={sort === "asc" ? "active" : ""}>
                                      <DropdownItem
                                        tag="a"
                                        href="#dropdownitem"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                          setSortState("asc");
                                          sortFunc("asc");
                                        }}
                                      >
                                        ASC
                                      </DropdownItem>
                                    </li>
                                  </ul>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className={`card-search search-wrap ${!onSearch && "active"}`}>
                <div className="card-body">
                  <div className="search-content">
                    <Button
                      className="search-back btn-icon toggle-search active"
                      onClick={() => {
                        setSearchText("");
                        toggle();
                      }}
                    >
                      <Icon name="arrow-left"></Icon>
                    </Button>
                    <input
                      type="text"
                      className="border-transparent form-focus-none form-control"
                      placeholder={t("search_by",{ns:"global"}) + " " + t("language")}
                      value={onSearchText}
                      onChange={(e) => onFilterChange(e)}
                    />
                    <Button className="search-submit btn-icon">
                      <Icon name="search"></Icon>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <DataTableBody compact>
              <DataTableHead>
                <DataTableRow>
                  <span className="sub-text">{t("language")}</span>
                </DataTableRow>
                <DataTableRow size="md">
                  <span className="sub-text">{t("level_language")}</span>
                </DataTableRow>
                <DataTableRow size="md">
                  <span className="sub-text">{t("options", {ns:"global"})}</span>
                </DataTableRow>
              </DataTableHead>
              {/*Head*/}
              {currentItems.length > 0
                ? currentItems.map((item) => {
                    return (
                      <DataTableItem key={item.id}>
                       <DataTableRow>
                       { i18n.language == 'en' ? item.language.name
                  : i18n.language == 'es' ? item.language.name_es : item.language.name_fr }
                        </DataTableRow>
                        <DataTableRow>
                        {item.level.description}
                        </DataTableRow>
                        <DataTableRow className="nk-tb-col-tools">
                          <ul className="nk-tb-actions gx-1">
                            <li className="nk-tb-action-hidden" onClick={() => onEditClick(item.id)}>
                              <TooltipComponent
                                tag="a"
                                containerClassName="btn btn-trigger btn-icon"
                                id={"edit" + item.id}
                                icon="edit-alt-fill"
                                direction="top"
                                text={t("modify",{ns:"global"})}
                              />
                            </li>
                            <li>
                              <UncontrolledDropdown>
                                <DropdownToggle tag="a" className="dropdown-toggle btn btn-icon btn-trigger">
                                  <Icon name="more-h"></Icon>
                                </DropdownToggle>
                                <DropdownMenu right>
                                  <ul className="link-list-opt no-bdr">
                                    <li onClick={() => onEditClick(item.id)}>
                                      <DropdownItem
                                        tag="a"
                                        href="#edit"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                        }}
                                      >
                                        <Icon name="edit"></Icon>
                                        <span>{t("modify",{ns:"global"})}</span>
                                      </DropdownItem>
                                    </li>
                                  </ul>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </li>
                          </ul>
                        </DataTableRow>
                      </DataTableItem>
                    );
                  })
                : null}
            </DataTableBody>
            <div className="card-inner">
              {currentItems.length > 0 ? (
                <PaginationComponent
                  itemPerPage={itemPerPage}
                  totalItems={data.length}
                  paginate={paginate}
                  currentPage={currentPage}
                />
              ) : (
                <div className="text-center">
                  <span className="text-silent">No lenguages found</span>
                </div>
              )}
            </div>
          </DataTable>
      </Block>

      {/* Modal to Add Language */}

      <Modal isOpen={modal} className="modal-dialog-centered" size="lg" toggle={() => setModal(false)}>
        <ModalBody>
          <a
            href="#dropdownitem"
            onClick={(ev) => {
              ev.preventDefault();
              setModal(false);
            }}
            className="close"
          >
            <Icon name="cross-sm"></Icon>
          </a>
          <div className="p-2">
            <h5 className="title">{t("lbl30")}</h5>
            <ul className="nk-nav nav nav-tabs">
              <li className="nav-item">
                <a
                  className={`nav-link ${modalTab === "1" && "active"}`}
                  onClick={(ev) => {
                    ev.preventDefault();
                    setModalTab("1");
                  }}
                  href="#actuallanguage"
                >
                 {t("lbl30")}
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={`nav-link ${modalTab === "2" && "active"}`}
                  onClick={(ev) => {
                    ev.preventDefault();
                    setModalTab("2");
                  }}
                  href="#newlanguage"
                >
                 {t("ttl5")}
                </a>
              </li>
            </ul>
            <div className="tab-content">
              <div className={`tab-pane ${modalTab === "1" ? "active" : ""}`} id="actuallanguage">
              {/* Component to add language */}
              <AddLanguage addlanguagetipo={addlanguagetipo}/>
              </div>
              <div className={`tab-pane ${modalTab === "2" ? "active" : ""}`} id="newlanguage">
                {/* Register new language type Component */}
                <RegisterLanguage addlanguagetipo={addlanguagetipo}/>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>

    </React.Fragment>

  );
};
export default UserProfileLanguages;

import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import axios from "axios";
import UserProfile from "../../Login/UserProfile";
import Content from "../../layout/content/Content";
import UserProfileRegularPage from "../components/UserProfileRegular";
import UserProfileLocationPage from "../components/UserProfileLocation";
import UserProfileExperiencePage from "../components/UserProfileExperience";
import UserProfileNotificationPage from "../components/UserProfileNotification";
import UserProfileActivityPage from "../components/UserProfileActivity";
import UserProfileSkillsPage from "../components/UserProfileSkills";
import UserProfileDocsPage from "../components/UserProfileDocs";
import { Route, Switch, Link } from "react-router-dom";
import { Icon, UserAvatar } from "../../components/Component";
import { findUpper } from "../../utils/Utils";
import { Card, DropdownItem, UncontrolledDropdown, DropdownMenu, DropdownToggle } from "reactstrap";

const UserProfileLayout = (props) => {
  // Translations
  const [t, i18n] = useTranslation("detailapplap");


  const [sm, updateSm] = useState(false);
  const [mobileView, setMobileView] = useState(false);

    const [ first_nameRes, setfirst_nameRes ] = useState("");
    const [ first_lastnameRes, setfirst_lastnameRes ] = useState("");
    const [ emailRes, setemailRes ] = useState("");
    const [ experienceRes, setexperienceRes ] = useState([]);

    const [ user, setUser ] = useState("");



  // function to change the design view under 990 px
  const viewChange = () => {
    if (window.innerWidth < 990) {
      setMobileView(true);
    } else {
      setMobileView(false);
      updateSm(false);
    }
  };


  const getApplicant = async () => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/api/applicants/profile`,
        {
          headers: {
            Authorization: `Bearer  ${UserProfile.getToken()}`,
          },
        }
      )
      .then((res) => {
        if (res.data.error ? res.data.error[0] == 'El Token a Expirado' : false) {props.history.push('/login'); return}
        // setidApplicant(res.data.data.id)
        setfirst_nameRes(res.data.data.first_name)
        setfirst_lastnameRes(res.data.data.first_lastname)
        setemailRes(res.data.data.email)
        setexperienceRes(res.data.data.experiences)
        setUser(res.data.data.user);


      })
      .catch((error) => console.log(error));
  };


  useEffect(() => {
    viewChange();
    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    document.getElementsByClassName("nk-header")[0].addEventListener("click", function () {
      updateSm(false);
    });
    return () => {
      window.removeEventListener("resize", viewChange);
      window.removeEventListener("load", viewChange);
    };
  }, []);

  useEffect(() => {
    // To know if the user had internet
    if (navigator.onLine) {
      UserProfile.setToken(localStorage.getItem("token"));
      UserProfile.setId(localStorage.getItem("id"));
      /***************** */
      getApplicant();

    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "msg",
        confirmButtonColor: "#071b30",
        confirmButtonText: "ok",
      });
    }
  }, [])

  return (
    <React.Fragment>
      <Content>
        <Card className="card-bordered">
          <div className="card-aside-wrap">
            <div
              className={`card-aside card-aside-left user-aside toggle-slide toggle-slide-left toggle-break-lg ${
                sm ? "content-active" : ""
              }`}
            >
              <div className="card-inner-group">
                <div className="card-inner">
                  <div className="user-card">
                    <UserAvatar text={findUpper(first_nameRes)}  theme="primary" />
                    <div className="user-info">
                      <span className="lead-text">{first_nameRes} {first_lastnameRes}</span>
                      <span className="sub-text">{emailRes}</span>
                    </div>
                    <div className="user-action">
                      <UncontrolledDropdown>
                        <DropdownToggle tag="a" className="btn btn-icon btn-trigger mr-n2">
                          <Icon name="more-v"></Icon>
                        </DropdownToggle>
                        <DropdownMenu right>
                          <ul className="link-list-opt no-bdr">
                            <li>
                              <DropdownItem
                                tag="a"
                                href="#dropdownitem"
                                onClick={(ev) => {
                                  ev.preventDefault();
                                }}
                              >
                                <Icon name="camera-fill"></Icon>
                                <span>Change Photo</span>
                              </DropdownItem>
                            </li>
                            <li>
                              <DropdownItem
                                tag="a"
                                href="#dropdownitem"
                                onClick={(ev) => {
                                  ev.preventDefault();
                                }}
                              >
                                <Icon name="edit-fill"></Icon>
                                <span>Update Profile</span>
                              </DropdownItem>
                            </li>
                          </ul>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                  </div>
                </div>
                <div className="card-inner">
                  <div className="user-account-info py-0">
                    <h6 className="overline-title-alt">HRH Workforce Account</h6>
                    <div className="user-balance">
                      0 <small className="currency currency-btc">Applied Jobs</small>
                    </div>
                    <div className="user-balance-sub">
                    Last Login:{" "}
                      <span>
                        {user.last_login_at ? user.last_login_at : "Never"}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="card-inner p-0">
                  <ul className="link-list-menu">
                  {/* Personal Information */}
                    <li onClick={() => updateSm(false)}>
                      <Link
                        to={`${process.env.PUBLIC_URL}/user-profile-regular`}
                        className={
                          window.location.pathname === `${process.env.PUBLIC_URL}/user-profile-regular` ? "active" : ""
                        }
                      >
                        <Icon name="user-fill-c"></Icon>
                        <span>{t("lbl1")}</span>
                      </Link>
                    </li>
                    {/* Location Information */}
                    <li onClick={() => updateSm(false)}>
                      <Link
                        to={`${process.env.PUBLIC_URL}/user-profile-location`}
                        className={
                          window.location.pathname === `${process.env.PUBLIC_URL}/user-profile-location`
                            ? "active"
                            : ""
                        }
                      >
                        <Icon name="map-pin-fill"></Icon>
                        <span>{t("location_info")}</span>
                      </Link>
                    </li>
                    {/* Studies and Languages */}
                    <li onClick={() => updateSm(false)}>
                      <Link
                        to={`${process.env.PUBLIC_URL}/user-profile-activity`}
                        className={
                          window.location.pathname === `${process.env.PUBLIC_URL}/user-profile-activity` ? "active" : ""
                        }
                      >
                        <Icon name="book-fill"></Icon>
                        <span>{t("studies_languages_info")}</span>
                      </Link>
                    </li>
                    {/* Work Experience */}
                    <li onClick={() => updateSm(false)}>
                      <Link
                        to={`${process.env.PUBLIC_URL}/user-profile-experience`}
                        className={
                          window.location.pathname === `${process.env.PUBLIC_URL}/user-profile-experience` ? "active" : ""
                        }
                      >
                        <Icon name="list-fill"></Icon>
                        <span>{t("work_experience")}</span>
                      </Link>
                    </li>
                    {/* Skills */}
                    <li onClick={() => updateSm(false)}>
                      <Link
                        to={`${process.env.PUBLIC_URL}/user-profile-skills`}
                        className={
                          window.location.pathname === `${process.env.PUBLIC_URL}/user-profile-skills` ? "active" : ""
                        }
                      >
                        <Icon name="check-fill-c"></Icon>
                        <span>{t("skills")}</span>
                      </Link>
                    </li>
                    {/* Social, Videos and Docs */}
                    <li onClick={() => updateSm(false)}>
                      <Link
                        to={`${process.env.PUBLIC_URL}/user-profile-social-docs`}
                        className={
                          window.location.pathname === `${process.env.PUBLIC_URL}/user-profile-social-docs` ? "active" : ""
                        }
                      >
                        <Icon name="user-list-fill"></Icon>
                        <span>{t("social_docs")}</span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="card-inner card-inner-lg">
              {sm && mobileView && <div className="toggle-overlay" onClick={() => updateSm(!sm)}></div>}
              <Switch>
                 {/* Send the method set as props to update the name in the menu without consume API        */}
                 {/* Personal Information */}
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/user-profile-regular`}
                  render={() => <UserProfileRegularPage updateSm={updateSm} sm={sm} set_nameRes={setfirst_nameRes}/>}
                ></Route>
                {/* Location */}
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/user-profile-location`}
                  render={() => <UserProfileLocationPage updateSm={updateSm} sm={sm} />}
                ></Route>
                {/* Studies and Languages */}
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/user-profile-notification`}
                  render={() => <UserProfileNotificationPage updateSm={updateSm} sm={sm} />}
                ></Route>
                {/* Work Experience */}
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/user-profile-activity`}
                  render={() => <UserProfileActivityPage updateSm={updateSm} sm={sm} />}
                ></Route>
                {/* Skills */}
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/user-profile-experience`}
                  render={() => <UserProfileExperiencePage updateSm={updateSm} sm={sm} experienceRes={experienceRes} />}
                ></Route>
                {/* Skills */}
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/user-profile-skills`}
                  render={() => <UserProfileSkillsPage updateSm={updateSm} sm={sm} />}
                ></Route>
                 {/* Social, Videos, Docs */}
                 <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/user-profile-social-docs`}
                  render={() => <UserProfileDocsPage updateSm={updateSm} sm={sm} />}
                ></Route>
              </Switch>
            </div>
          </div>
        </Card>
      </Content>
    </React.Fragment>
  );
};

export default UserProfileLayout;

import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import { findUpper } from "../../../utils/Utils";
import { FilterCountry, filterStatus } from "./UserData";
import {
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  UncontrolledDropdown,
  Modal,
  ModalBody,
  DropdownItem,
} from "reactstrap";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Row,
  Col,
  UserAvatar,
  PaginationComponent,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableRow,
  DataTableItem,
  Button,
  RSelect,
  TooltipComponent,
} from "../../../components/Component";
import axios from "axios";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
// import { UserContext } from "./UserContext";
import UserProfile from "../../../Login/UserProfile";
import { format } from "date-fns";

const CandidatesListCompact = (props) => {
  // Translations
  const [t, i18n] = useTranslation(['applsadmin','global']);
   // Variables to use in the component
  const [ applicant, setapplicant ] = useState([]);
  const [ idApplicant, setidApplicant ] = useState("");
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [ countryfilter, setcountryfilter ] = useState([]);
  const [ departments, setdepartments ] = useState([]);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    balance: "",
    phone: "",
    status: "Active",
  });

  // Modals
  const [modal, setModal] = useState({
    edit: false,
    add: false,
  });
  const [modalDetail, setModalDetail] = useState(false);
  const [detail, setDetail] = useState({});

  // Filters to Apply
  const [ filcountry, setfilcountry ] = useState("");
  const [ filstatus, setfilstatus ] = useState("");

  const [sm, updateSm] = useState(false);
  const [tablesm, updateTableSm] = useState(false);
  const [onSearch, setonSearch] = useState(true);
  const [onSearchText, setSearchText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(10);
  const [sort, setSortState] = useState("");

// ------- Functions to Order, filter and search -------

  // Sorting data by First Name
  const sortFunc = (params) => {
    let defaultData = data;
    if (params === "asc") {
      let sortedData = defaultData.sort((a, b) => a.first_name.localeCompare(b.first_name));
      setData([...sortedData]);
    } else if (params === "dsc") {
      let sortedData = defaultData.sort((a, b) => b.first_name.localeCompare(a.first_name));
      setData([...sortedData]);
    }
  };

  // Changing state value when searching name
  useEffect(() => {
    if (onSearchText !== "") {
      const filteredObject = applicant.filter((item) => {
        return (
          item.first_name.toLowerCase().includes(onSearchText.toLowerCase()) ||
          item.email.includes(onSearchText.toLowerCase())
        );
      });
      setData([...filteredObject]);
    } else {
      setData([...applicant]);
    }
  }, [onSearchText, setData]);

  // onChange function for searching name
  const onFilterChange = (e) => {
    setSearchText(e.target.value);
  };

  //  filter by country and status
  useEffect(() => {
    if (filcountry !== "" && filstatus === "") {
      const filteredObject = applicant.filter((item) => {
        return (
          item.country.id == filcountry.value
        );
      });
      setData([...filteredObject]);
    } else if(filcountry != "" && filstatus != "") {
      const filteredObject = applicant.filter((item) => {
        return (
          item.country.id == filcountry.value && item.status.id == filstatus.value
        );
      });
      setData([...filteredObject]);
    }
    else if(filcountry == "" && filstatus != "") {
      const filteredObject = applicant.filter((item) => {
        return (
          item.status.id == filstatus.value
        );
      });
      setData([...filteredObject]);
    }
    else {
      setData([...data]);
    }
  }, [filcountry, filstatus, setData]);

  // function to reset the form
const resetFilter = () => {
  setfilstatus("");
  setfilcountry("");
  setData([...applicant]);
};

// ------- End Functions to Order, filter and search -------

// Functions to Manage forms

  // function to reset the form
  const resetForm = () => {
    setFormData({
      name: "",
      nit: "",
      status: "Active",
    });
  };

  // function to close the form modal
  const onFormCancel = () => {
    setModal({ edit: false, add: false });
    resetForm();
  };

  // function to load detail data
  // Show details Applicant
  const loadDetail = (id) => {
    let index = data.findIndex((item) => item.id === id);
    setDetail(data[index]);
  };

  // function to toggle the search option
  const toggle = () => setonSearch(!onSearch);

  // function to toggle details modal
  const toggleModalDetail = () => setModalDetail(!modalDetail);

  const { errors, register, handleSubmit } = useForm();

  // Get current list, pagination
  const indexOfLastItem = currentPage * itemPerPage;
  const indexOfFirstItem = indexOfLastItem - itemPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  // Change Page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  //get data X applicant
  const seeApplDetails = async ({id}) => {
    props.changeOpc({opc:"21", data:id})
  }

  const list = (search) => {
    setapplicant([])
    // let newsearch = {...search, page: selectedPage}
    axios
    .get(`${process.env.REACT_APP_BASE_URL}/api/applicants`, {
      headers: {
        Authorization: `Bearer  ${UserProfile.getToken()}`,
      },
      // params: newsearch
    })
    .then((res) => {
      if (res.data.error ? res.data.error[0] == 'El Token a Expirado' : false) {props.history.push('/login'); return}
        setapplicant(res.data.data.applicants)
        setData(res.data.data.applicants)
        setTotal(res.data.data.total )
      })
      .catch((error) => console.log(error));
  };

  // Activate/Desactivate Applicant
  const handleSwitchChange = async (value,estado) => {
    var values = []
    if (estado == 1) {
      const valuesupdate = {
        status: 2
      };
      axios
        .put(
          `${process.env.REACT_APP_BASE_URL}/api/applicants/${value}`,valuesupdate ,{
            headers: {
              Authorization: `Bearer  ${UserProfile.getToken()}`,
            },
          }
        )
        .then((res) => {
          if (res.data.status == true) {
            Swal.fire({
              icon: "success",
              title: t("user_updated", {ns: 'global'}),
              text: t("user_inactivated", {ns: 'global'}),
              confirmButtonColor: "#071b30",
              confirmButtonText: t("ok"),
            });
            list();
          }
          else{
            console.log(res)
          }
        })
        .catch((error) => console.log(error));
    }
    else {
      const valuesupdate = {
        // name: value.name,
        // email: value.email,
        // type: value.type,
        status: 1
      };
      axios
        .put(
          `${process.env.REACT_APP_BASE_URL}/api/applicants/${value}`,
          valuesupdate,
          {
            headers: {
              Authorization: `Bearer  ${UserProfile.getToken()}`,
            }
          }
        )
        .then((res) => {
          if (res.data.status == true) {
            Swal.fire({
              icon: "success",
              title: t("user_updated", {ns: 'global'}),
              text: t("user_activated", {ns: 'global'}),
              confirmButtonColor: "#071b30",
              confirmButtonText: t("ok"),
            });
            list();
          }else{
            console.log(res)
          }
        })
        .catch((error) => console.log(error));
    }
  };


  useEffect(() => {
    if (navigator.onLine) {
      UserProfile.setToken(localStorage.getItem('token'));
      list();
      // getActivity()
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: t("msg1"),
        confirmButtonColor: "#071b30",
        confirmButtonText: t("ok"),
      });
    }
  }, [])

  return (
    <React.Fragment>
      <Head title="Candidates List"></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h3" page>
              {t("ttl9")}
              </BlockTitle>
              <BlockDes className="text-soft">
                <p>{t("you_have", {ns: 'global'})} <strong>{total}</strong> {t("candidates", {ns: 'global'})}.</p>
              </BlockDes>
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <Button
                  className={`btn-icon btn-trigger toggle-expand mr-n1 ${sm ? "active" : ""}`}
                  onClick={() => updateSm(!sm)}
                >
                  <Icon name="menu-alt-r"></Icon>
                </Button>

              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>

        <Block>
          <DataTable className="card-stretch">
    {/* Filters, search, order */}
          <div className="card-inner position-relative card-tools-toggle">
              <div className="card-title-group">
              <div className="card-title">
                  <h5 className="title">{t("ttl10")}</h5>
                </div>
                <div className="card-tools">
                  <div className="form-inline flex-nowrap gx-3">

                  </div>
                </div>
                <div className="card-tools mr-n1">
                  <ul className="btn-toolbar gx-1">
                    <li>
                      <a
                        href="#search"
                        onClick={(ev) => {
                          ev.preventDefault();
                          toggle();
                        }}
                        className="btn btn-icon search-toggle toggle-search"
                      >
                        <Icon name="search"></Icon>
                      </a>
                    </li>
                    <li className="btn-toolbar-sep"></li>
                    <li>
                      <div className="toggle-wrap">
                        <Button
                          className={`btn-icon btn-trigger toggle ${tablesm ? "active" : ""}`}
                          onClick={() => updateTableSm(true)}
                        >
                          <Icon name="menu-right"></Icon>
                        </Button>
                        <div className={`toggle-content ${tablesm ? "content-active" : ""}`}>
                          <ul className="btn-toolbar gx-1">
                            <li className="toggle-close">
                              <Button className="btn-icon btn-trigger toggle" onClick={() => updateTableSm(false)}>
                                <Icon name="arrow-left"></Icon>
                              </Button>
                            </li>
                            <li>
                              <UncontrolledDropdown>
                                <DropdownToggle tag="a" className="btn btn-trigger btn-icon dropdown-toggle">
                                  <div className="dot dot-primary"></div>
                                  <Icon name="filter-alt"></Icon>
                                </DropdownToggle>
                                <DropdownMenu
                                  right
                                  className="filter-wg dropdown-menu-xl"
                                  style={{ overflow: "visible" }}
                                >
                                  <div className="dropdown-head">
                                    <span className="sub-title dropdown-title">  {t("search", {ns: 'global'})}</span>
                                    <div className="dropdown">
                                      <DropdownItem
                                        href="#more"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                        }}
                                        className="btn btn-sm btn-icon"
                                      >
                                        <Icon name="more-h"></Icon>
                                      </DropdownItem>
                                    </div>
                                  </div>
                                  <div className="dropdown-body dropdown-body-rg">
                                    <Row className="gx-6 gy-3">

                                      <Col size="6">
                                        <FormGroup>
                                          <label className="overline-title overline-title-alt">  {t("country", {ns: 'global'})}</label>
                                          <RSelect options={FilterCountry} placeholder={t("select_country", {ns: 'global'})} value={filcountry} onChange={(e) =>
                            setfilcountry(e)
                          } />
                                        </FormGroup>
                                      </Col>
                                      <Col size="6">
                                        <FormGroup>
                                          <label className="overline-title overline-title-alt">  {t("state", {ns: 'global'})}</label>
                                          <RSelect options={filterStatus} placeholder={t("select_state", {ns: 'global'})}  value={filstatus}  onChange={(e) =>
                            setfilstatus(e)
                          }/>
                                        </FormGroup>
                                      </Col>

                                    </Row>
                                  </div>
                                  <div className="dropdown-foot between">
                                    <a
                                      href="#reset"
                                      onClick={(ev) => {
                                        ev.preventDefault();
                                        resetFilter();
                                      }}
                                      className="clickable"
                                    >
                                     {t("reset_filters", {ns: 'global'})}
                                    </a>

                                  </div>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </li>
                            <li>
                              <UncontrolledDropdown>
                                <DropdownToggle tag="a" className="btn btn-trigger btn-icon dropdown-toggle">
                                  <Icon name="setting"></Icon>
                                </DropdownToggle>
                                <DropdownMenu right className="dropdown-menu-xs">
                                  <ul className="link-check">
                                    <li>
                                      <span>{t("show", {ns: 'global'})}</span>
                                    </li>
                                    <li className={itemPerPage === 10 ? "active" : ""}>
                                      <DropdownItem
                                        tag="a"
                                        href="#dropdownitem"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                          setItemPerPage(10);
                                        }}
                                      >
                                        10
                                      </DropdownItem>
                                    </li>
                                    <li className={itemPerPage === 15 ? "active" : ""}>
                                      <DropdownItem
                                        tag="a"
                                        href="#dropdownitem"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                          setItemPerPage(15);
                                        }}
                                      >
                                        15
                                      </DropdownItem>
                                    </li>
                                  </ul>
                                  <ul className="link-check">
                                    <li>
                                      <span>{t("order", {ns: 'global'})}</span>
                                    </li>
                                    <li className={sort === "dsc" ? "active" : ""}>
                                      <DropdownItem
                                        tag="a"
                                        href="#dropdownitem"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                          setSortState("dsc");
                                          sortFunc("dsc");
                                        }}
                                      >
                                        DESC
                                      </DropdownItem>
                                    </li>
                                    <li className={sort === "asc" ? "active" : ""}>
                                      <DropdownItem
                                        tag="a"
                                        href="#dropdownitem"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                          setSortState("asc");
                                          sortFunc("asc");
                                        }}
                                      >
                                        ASC
                                      </DropdownItem>
                                    </li>
                                  </ul>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className={`card-search search-wrap ${!onSearch && "active"}`}>
                <div className="card-body">
                  <div className="search-content">
                    <Button
                      className="search-back btn-icon toggle-search active"
                      onClick={() => {
                        setSearchText("");
                        toggle();
                      }}
                    >
                      <Icon name="arrow-left"></Icon>
                    </Button>
                    <input
                      type="text"
                      className="border-transparent form-focus-none form-control"
                      placeholder={t("search_by_keyword_applicant", {ns: 'global'})}
                      value={onSearchText}
                      onChange={(e) => onFilterChange(e)}
                    />
                    <Button className="search-submit btn-icon">
                      <Icon name="search"></Icon>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <DataTableBody compact>
              <DataTableHead>
                <DataTableRow className="nk-tb-col-check">
                <span className="sub-text">#</span>
                  {/* <div className="custom-control custom-control-sm custom-checkbox notext">
                    <input
                      type="checkbox"
                      className="custom-control-input form-control"
                      onChange={(e) => selectorCheck(e)}
                      id="uid"
                    />
                    <label className="custom-control-label" htmlFor="uid"></label>
                  </div> */}
                </DataTableRow>
                <DataTableRow>
                  <span className="sub-text">{t("tbl1")}</span>
                </DataTableRow>
                <DataTableRow size="md">
                  <span className="sub-text">{t("tbl2")}</span>
                </DataTableRow>
                <DataTableRow size="sm">
                  <span className="sub-text">{t("tbl3")}</span>
                </DataTableRow>

                {/* <DataTableRow size="lg">
                  <span className="sub-text">{t("tbl4")}</span>
                </DataTableRow> */}
                <DataTableRow size="sm">
                  <span className="sub-text">{t("tbl5")}</span>
                </DataTableRow>
                <DataTableRow size="sm">
                  <span className="sub-text">{t("tbl6")}</span>
                </DataTableRow>
                <DataTableRow>
                <span className="sub-text">{t("tbl7")}</span>
                </DataTableRow>
                <DataTableRow>
                <span className="sub-text">{t("tbl9")}</span>
                </DataTableRow>
                <DataTableRow>
                <span className="sub-text">{t("tbl10")}</span>
                </DataTableRow>
                {/* Date */}
                {/* <DataTableRow>
                <span className="sub-text">{t("tbl13")}</span>
                </DataTableRow> */}
                <DataTableRow>
                <span className="sub-text">{t("tbl11")}</span>
                </DataTableRow>
                <DataTableRow>
                <span className="sub-text">{t("tbl12")}</span>
                </DataTableRow>
                <DataTableRow className="nk-tb-col-tools text-right">

                </DataTableRow>

              </DataTableHead>
              {/*Head*/}

             {currentItems.length > 0
              ? currentItems.map((item) => {
              //  let datet = applicant[i]['created_at'].split("T")[0]
                    return (
                      <DataTableItem key={ item.id}>
                        <DataTableRow className="nk-tb-col-check">
                        <Link to={`${process.env.PUBLIC_URL}/user-details-regular/${item.id }`}>
                        <span className="tb-lead">{ item.id }</span>
                        </Link>
                        </DataTableRow>
                        <DataTableRow>
                          <Link to={`${process.env.PUBLIC_URL}/user-details-regular/${item.id}`}>
                            <div className="user-card">
                              <UserAvatar
                                theme={item.avatarBg}
                                className="xs"
                                text={findUpper(item.first_name)}
                                image={item.logo}
                              ></UserAvatar>
                              <div className="user-info">
                                <span className="tb-lead">{ item.first_name }</span>
                                {/* <span className="tb-lead">{ applicant[i]['first_name'] } { applicant[i]['first_lastname'] }</span> */}
                              </div>
                            </div>
                          </Link>
                        </DataTableRow>
                        <DataTableRow size="md">
                          <span className="tb-lead-sub">{ item.first_lastname }</span>
                          {/* <span className="tb-sub">{item.role}</span> */}
                        </DataTableRow>
                        <DataTableRow size="sm">
                          <span>{item.title}</span>
                        </DataTableRow>
                        <DataTableRow size="sm">
                          <span>{item.identification}</span>
                        </DataTableRow>
                        <DataTableRow size="sm">
                        <span>{item.email}</span>
                        </DataTableRow>
                        <DataTableRow size="sm">
                        <span>
                        { i18n.language == 'en' ? item?.country?.name
                  : i18n.language == 'es' ? item?.country?.name_es : item?.country?.name_fr }
                        </span>
                        </DataTableRow>
                        <DataTableRow size="sm">
                        <span>
                        { i18n.language == 'en' ? item?.country_nacionality?.name
                  : i18n.language == 'es' ? item?.country_nacionality?.name_es : item?.country_nacionality?.name_fr }
                        </span>
                        </DataTableRow>
                        <DataTableRow size="sm">
                        <span>
                          { i18n.language == 'en' ? item?.civil_status?.description
                  : i18n.language == 'es' ? item?.civil_status?.description_es : item?.civil_status?.description_fr }
                          </span>
                        </DataTableRow>
                        <DataTableRow>
                          <span
                            className={`tb-status text-${
                              item.status.description === "Active" ? "success" : item.status.description === "Pending" ? "warning" : "danger"
                            }`}
                          >
                            {item.status.description}
                          </span>
                        </DataTableRow>
                        <DataTableRow className="nk-tb-col-tools">
                          <ul className="nk-tb-actions gx-1">
                            {(
                              <React.Fragment>
                                <li className="nk-tb-action-hidden" onClick={() => {
                                loadDetail(item.id);
                                setidApplicant(item.id)
                                toggleModalDetail();
                              }}>
                                  <TooltipComponent
                                    tag="a"
                                    containerClassName="btn btn-trigger btn-icon"
                                    id={"suspend" + item.id }
                                    icon="eye"
                                    direction="top"
                                    text={t("details", {ns: 'global'})}
                                  />
                                </li>
                              </React.Fragment>
                            )}
                            <li>
                              <UncontrolledDropdown>
                                <DropdownToggle tag="a" className="dropdown-toggle btn btn-icon btn-trigger">
                                  <Icon name="more-h"></Icon>
                                </DropdownToggle>
                                <DropdownMenu right>
                                  <ul className="link-list-opt no-bdr">

                                    <li onClick={() => {
                                loadDetail(item.id);
                                toggleModalDetail();
                              }}>
                                      <DropdownItem
                                        tag="a"

                                      >
                                        <Icon name="eye"></Icon>
                                        <span>{t("details", {ns: 'global'})}</span>
                                      </DropdownItem>
                                    </li>
                                  </ul>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </li>
                          </ul>
                        </DataTableRow>
                      </DataTableItem>
                    );
                  })
               : null}
            </DataTableBody>
            <div className="card-inner">
              {currentItems.length > 0 ? (
                <PaginationComponent
                  itemPerPage={itemPerPage}
                  totalItems={data.length}
                  paginate={paginate}
                  currentPage={currentPage}
                />
              ) : (
                <div className="text-center">
                  <span className="text-silent">No data found</span>
                </div>
              )}
            </div>
          </DataTable>
        </Block>


        <Modal isOpen={modalDetail} toggle={() => toggleModalDetail()} className="modal-dialog-centered" size="lg">
          <ModalBody>
            <a
              href="#cancel"
              onClick={(ev) => {
                ev.preventDefault();
                toggleModalDetail();
              }}
              className="close"
            >
              <Icon name="cross-sm"></Icon>
            </a>
            <div className="nk-modal-head mb-3">
              <h4 className="nk-modal-title title">
              {t("general_info", { ns:"global" })} <small className="text-primary"></small>
              </h4>
            </div>
            <div className="nk-tnx-details">
              <BlockBetween className="flex-wrap g-3">
                <div className="nk-tnx-type">
                  <div
                    className={`nk-tnx-type-icon text-white`}
                  >
                    <UserAvatar image={detail.logo}>
                    </UserAvatar>

                    {/* <Icon name="arrow-up-right"></Icon> */}
                  </div>
                  <div className="nk-tnx-type-text">
                    <h5 className="title">{detail.first_name} {detail.second_name ?? ''} {detail.first_lastname ?? ''} {detail.second_lastname ?? ''}</h5>
                    { detail.user ?
                    <span className="sub-text mt-n1">{t("last_login", {ns: "global"})}: {format(new Date(detail?.user?.last_login_at), "dd MMMM Y k:m:s")} </span>
                    : null }
                    </div>

                </div>
                <ul className="align-center flex-wrap gx-3">
                  <li>
                  {/* <button
                                      class="btn buttonAzulModal"
                                      type="button"
                                      id="dropdownMenuButton2"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="false"
                                      onClick={()=>{listChat()}}
                                    >
                                      {" "}

                                      {t("lbl10")}


                    </button> */}
                  </li>
                  <li>
                  {/* <button
                        class="btn buttonAzulModal"
                        type="button"
                        onClick={() => {downloaddocgettoken()}}
                      >
                        {t("lbl13")}
                      </button> */}
                    {/* <Badge
                      color={
                        detail.status === "Completed"
                          ? "success"
                          : detail.status === "Upcoming"
                          ? "warning"
                          : detail.status === "Pending"
                          ? "info"
                          : "danger"
                      }
                      size="sm"
                    >
                      {detail.status}
                    </Badge> */}
                  </li>
                </ul>
              </BlockBetween>








              <div className="nk-modal-head mt-4 mb-3">
                <h5 className="title">{t("candidate_info", { ns:"global" })}</h5>
              </div>
              <Row className="gy-3">
              <Col lg={6}>
                  <span className="sub-text">{t("tbl4")}</span>
                  <span className="caption-text text-break">{detail?.identification_type?.description ?? ''}</span>
                </Col>
                <Col lg={6}>
                  <span className="sub-text">{t("tbl5")}</span>
                  <span className="caption-text">{detail?.id}</span>
                </Col>
                <Col lg={6}>
                  <span className="sub-text">{t("tbl1")}</span>
                  {/* The ? is to check that the object if it exists and is not undefined, the ?? is to check that the object is not null */}
                  <span className="caption-text text-break">{`${detail?.first_name} ${detail?.second_name ?? ''}`}</span>
                </Col>
                <Col lg={6}>
                  <span className="sub-text">{t("tbl2")}</span>
                  <span className="caption-text text-break">{`${detail?.first_lastname} ${detail?.second_lastname ?? ''}`}</span>
                </Col>
                <Col lg={6}>
                  <span className="sub-text">{t("tbl6")}</span>
                  <span className="caption-text text-break">{detail?.email ?? ''}</span>
                </Col>
                <Col lg={6}>
                  <span className="sub-text">{t("tbl14")}</span>
                  <span className="caption-text text-break">{detail?.gender ?? ''}</span>
                </Col>

                <Col lg={6}>
                  <span className="sub-text">{t("tbl3")}</span>
                  <span className="caption-text"> { detail.title }</span>
                </Col>
                <Col lg={6}>
                  <span className="sub-text">{t("tbl10")}</span>
                  <span className="caption-text">
                  { i18n.language == 'en' ? detail?.civil_status?.description
                  : i18n.language == 'es' ? detail?.civil_status?.description_es : detail?.civil_status?.description_fr }
                    </span>
                </Col>

                <Col lg={12}>
                  <span className="sub-text" style={{display: detail.description ? '' : 'none' }}>{t("tbl15")}</span>
                  <span className="caption-text text-break">{detail.description}</span>
                </Col>
                <Col lg={6}>
                  <span className="sub-text">{t("tbl7")}</span>
                  <span className="caption-text">{ i18n.language == 'en' ? detail?.country_nacionality?.name
                  : i18n.language == 'es' ? detail?.country_nacionality?.name_es : detail?.country_nacionality?.name_fr }</span>
                </Col>
                <Col lg={6}>
                  <span className="sub-text">{t("tbl9")}</span>

                  <span className="caption-text text-break">
                  { i18n.language == 'en' ? detail?.country?.name
                  : i18n.language == 'es' ? detail?.country?.name_es : detail?.country?.name_fr }
                  </span>
                </Col>

                <Col lg={6}>
                  <span className="sub-text">{t("tbl8")}</span>
                  <span className="caption-text text-break">{detail?.department?.name }</span>
                </Col>
                <Col lg={6}>
                  <span className="sub-text">{t("tbl16")}</span>
                  <span className="caption-text text-break">{detail?.city?.name }</span>
                </Col>


              </Row>

            </div>












          </ModalBody>
        </Modal>
      </Content>
    </React.Fragment>
  );
};
export default CandidatesListCompact;

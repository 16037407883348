import React, { useState, useEffect, useContext }  from "react";
import { useTranslation } from "react-i18next";
import Head from "../../layout/head/Head";
import axios from "axios";
import Swal from "sweetalert2";
import UserProfile from "../../Login/UserProfile";
import UserProfileStudies from "./UserProfileStudies";
import UserProfileLanguages from "./UserProfileLanguages";
import {
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  UncontrolledDropdown,
  Modal,
  ModalBody,
  DropdownItem,
} from "reactstrap";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  StudiesTable,
  LanguagesTable,
  Button,
  Row,
  Col,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableRow,
  DataTableItem,
  TooltipComponent,
  PaginationComponent,
} from "../../components/Component";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";


const UserProfileActivityPage = (props) => {
  const [t, i18n] = useTranslation("detailapplap")

  const [ experienceRes, setexperienceRes ] = useState([]);
    const [ idApplicant, setidApplicant ] = useState(0);
    // const { contextData } = useContext(UserContext);
    const [data, setData] = useState("");
    // const { errors, register, handleSubmit } = useForm();

    const [sm, updateSm] = useState(false);
    const [tablesm, updateTableSm] = useState(false);
    const [onSearch, setonSearch] = useState(true);
    const [onSearchText, setSearchText] = useState("");
    const [modal, setModal] = useState({
      edit: false,
      add: false,
    });
    const [editId, setEditedId] = useState();
    const [formData, setFormData] = useState({
      name: "",
      email: "",
      balance: "",
      phone: "",
      status: "Active",
    });
    const [currentPage, setCurrentPage] = useState(1);
    const [itemPerPage, setItemPerPage] = useState(10);
    const [sort, setSortState] = useState("");



    const [ pais, setpais ] = useState([]);
    const [ paisLocation, setpaisLocation ] = useState([]);
    const [ departamento, setdepartamento ] = useState([]);
    const [ city, setcity ] = useState([]);
    const [ email, setEmail ] = useState([]);

    const [ departamentoNacionality, setdepartamentoNacionality ] = useState([]);

    const [ unidad, setunidad ] = useState([]);
    const [ positions, setpositions ] = useState([]);

    const [ companyWork, setcompanyWork ] = useState([]);

    const [ actividades, setactividades ] = useState([]);
    const [ optionSelected, setoptionSelected ] = useState(null);

    // Sorting data
  const sortFunc = (params) => {
    let defaultData = data;
    if (params === "asc") {
      let sortedData = defaultData.sort((a, b) => a.name.localeCompare(b.name));
      setData([...sortedData]);
    } else if (params === "dsc") {
      let sortedData = defaultData.sort((a, b) => b.name.localeCompare(a.name));
      setData([...sortedData]);
    }
  };

  // Changing state value when searching name
  useEffect(() => {
    if (onSearchText !== "") {
      // console.log(data, "data");
      const filteredObject = experienceRes.filter((item) => {
        return (
          item.name.toLowerCase().includes(onSearchText.toLowerCase()) ||
          item.email.toLowerCase().includes(onSearchText.toLowerCase())
        );
      });
      // console.log(filteredObject, "filteredObject");
      setData([...filteredObject]);
    } else {
      setData([...data]);
    }
  }, [onSearchText, setData]);

  // onChange function for searching name
  const onFilterChange = (e) => {
    setSearchText(e.target.value);
  };

    // function to reset the form
    const resetForm = () => {
      setFormData({
        name: "",
        email: "",
        type:1,
        password: "",
        status: 1,
      });
    };

    // function to close the form modal
    const onFormCancel = () => {
      setModal({ edit: false, add: false });
      resetForm();
    };

      // function to toggle the search option
  const toggle = () => setonSearch(!onSearch);

    // Get current list, pagination
  const indexOfLastItem = currentPage * itemPerPage;
  const indexOfFirstItem = indexOfLastItem - itemPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  // Change Page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const addNewXpCompany = (data) => {
    setcompanyWork([...companyWork, data])
  }

  const addNewPosition = (data) => {
    setpositions([...positions, data])
  }

  const addNewSkill = (data) => {
    setactividades([...actividades, data])
    // renderList()
    document.getElementById("clocedModal").click()
  }

  const getCompanyWork = async () => {
    await axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/attrs/company_experience`, {
        headers: {
          Authorization: `Bearer  ${UserProfile.getToken()}`,
        },
      })
      .then((res) => {
        setcompanyWork(res.data.data.company_experience)
      });
  };

  const getPositions = async () => {
    await axios
      // .get(`${process.env.REACT_APP_BASE_URL}/api/attrs/positions`, {
      .post(`${process.env.REACT_APP_BASE_URL}/api/attrs/filterpositions/2`, [], {
        headers: {
          Authorization: `Bearer  ${UserProfile.getToken()}`,
        },
      })
      .then((res) => {
        console.log(res)
        setpositions(res.data.data)
      });
  };

  const getInitMeasure = async () => {
    await axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/attrs/unit_measurement`, {
        headers: {
          Authorization: `Bearer  ${UserProfile.getToken()}`,
        },
      })
      .then((res) => {
        setunidad(res.data.data.unit_measurement)
      });
  };

  const addExperience = (val) => {
    console.log(val, "val")
    var comp = companyWork.find( item => item.id == val.company )
    var posi = positions.find( item => item.id == val.position )
    var uni = unidad.find( item => item.id == val.unit_measurement )
    const temp = {
      "functions": val.functions,
      "goals": val.goals,
      "initial_year": val.initial_year,
      "company": comp,
      "position": posi,
      "duration_time": val.duration_time,
      "unit_measurement": uni
    }
    console.log(temp, "temp")
    setexperienceRes([experienceRes, temp])

    val.lng = localStorage.getItem('lngselect');
    val.email = email;
    console.log(experienceRes, "experienceRes")
    val.experiences = temp;

    // console.log("editando aplicante");
    axios
      .put(
        `${process.env.REACT_APP_BASE_URL}/api/applicants/${idApplicant}`,
        val,
        {
          headers: {
            Authorization: `Bearer  ${UserProfile.getToken()}`,
          },
        }
      )
      .then((res) => {
        if (res.data.data.code == "new") {
          registro(val)
          return
        }
        if (!res.data.status) {
          Swal.fire({
            icon: "error",
            title: "Oops..",
            confirmButtonColor: "#071b30",
            confirmButtonText: t("ok"),
          });
          return
        }
        Swal.fire({
          icon: "success",
          title: t("updated_data"),
          confirmButtonColor: "#071b30",
          confirmButtonText: t("ok"),
        });
      })
      .catch((error) => console.log(error));
  };

  const deleteExperience = (val) => {
    setexperienceRes(experienceRes.filter((xp, itm) => itm !== val))
  };

  // const saveEditApplicant = (val) => {
  //   val.lng = localStorage.getItem('lngselect');

  //   // console.log("editando aplicante");
  //   axios
  //     .put(
  //       `${process.env.REACT_APP_BASE_URL}/api/applicants/${idApplicant}`,
  //       val,
  //       {
  //         headers: {
  //           Authorization: `Bearer  ${UserProfile.getToken()}`,
  //         },
  //       }
  //     )
  //     .then((res) => {
  //       if (res.data.data.code == "new") {
  //         registro(val)
  //         return
  //       }
  //       if (!res.data.status) {
  //         Swal.fire({
  //           icon: "error",
  //           title: "Oops..",
  //           confirmButtonColor: "#071b30",
  //           confirmButtonText: t("ok"),
  //         });
  //         return
  //       }
  //       Swal.fire({
  //         icon: "success",
  //         title: t("updated_data"),
  //         confirmButtonColor: "#071b30",
  //         confirmButtonText: t("ok"),
  //       });
  //     })
  //     .catch((error) => console.log(error));
  // };

  const registro = (val) => {
    // console.log("tregistrando aplicante");
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/api/applicants`,
        val,
        {
          headers: {
            Authorization: `Bearer  ${UserProfile.getToken()}`,
          },
        }
      )
      .then((res) => {
        if (!res.data.status) {
          Swal.fire({
            icon: "error",
            title: t("fail_updated_data"),
            confirmButtonColor: "#071b30",
            confirmButtonText: t("ok"),
          });
          return
        }
        Swal.fire({
          icon: "success",
          title: "success",
          confirmButtonColor: "#071b30",
          confirmButtonText: t("ok"),
        });
       localStorage.setItem('idapplicant', res.data.data.id);
      })
      .catch((error) => console.log(error));
  };

  // const closeExperience = () => {
  //   document.getElementById("clocedModalExperience").click();
  // };


const getApplicant = async () => {
    var name = "";
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/api/applicants/profile`,
        {
          headers: {
            Authorization: `Bearer  ${UserProfile.getToken()}`,
          },
        }
      )
      .then((res) => {
        if (res.data.error ? res.data.error[0] == 'El Token a Expirado' : false) {props.history.push('/login'); return}
        setidApplicant(res.data.data.id)
        // setfirst_nameRes(res.data.data.first_name)
        // setmiddle_nameRes(res.data.data.second_name)
        // setsecond_lasnameRes(res.data.data.second_lastname)
        // setfirst_lastnameRes(res.data.data.first_lastname)
        // settitleRes(res.data.data.title)
        // setdescriptionRes(res.data.data.description)
        // setidentification_typeRes(res.data.data.identification_type ? res.data.data.identification_type.id : "")
        // setidentificationRes(res.data.data.identification)
        // setimg_perfilRes(res.data.data.img_perfil)

        // setcountryRes(res.data.data.country.id)
        // getDeparment(res.data.data.country.id,"1")

        // setdepartmentRes(res.data.data.department ? res.data.data.department.id : "")
        // getCities(res.data.data.department ? res.data.data.department.id : "")
        // setcityRes(res.data.data.city ? res.data.data.city.id : "")

        // setcountry_nacionalityRes(res.data.data.country_nacionality.id)
        // getDeparment(res.data.data.country_nacionality.id,"2")
        // setdepartment_nacionalityRes(res.data.data.department_nacionality ? res.data.data.department_nacionality.id : "")

        setEmail(res.data.data.email)
        // setcivil_statusRes(res.data.data.civil_status.id)

        // //falta img
        // setvideo_presentationRes(res.data.data.video_presentation)
        // setstatusRes(res.data.data.status.id)

        // setbirthdayRes(res.data.data.birthday)

        // multiples
        // setlanguageRes(res.data.data.languages_applicant)
        // seteducationRes(res.data.data.studies)
        setexperienceRes(res.data.data.experiences)
        setData(res.data.data.experiences)
        console.log(data, "data");
        // setsocialNetworkRes(res.data.data.applicant_social_networks)

        // setactividadesres(res.data.data.applicant_activities)
        let opactsel = []
        for (const acti of res.data.data.applicant_activities) {
          opactsel.push({"value":acti.activity.id, "label":acti.activity.name})
        }
        setoptionSelected(opactsel)

      })
      .catch((error) => console.log(error));
  };



  const getDeparment = async (val, opc) => {
    await axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/api/departments/by_country/${val}`,
        {
          headers: {
            Authorization: `Bearer  ${UserProfile.getToken()}`,
          },
        }
      )
      .then((res) => {
        if (opc === "1")
          setdepartamento(res.data.data.department)
          else setdepartamentoNacionality(res.data.data.department)
      });
  };



  const getCities = async (val) => {
    await axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/api/cities/by_department/${val}`,
        {
          headers: {
            Authorization: `Bearer  ${UserProfile.getToken()}`,
          },
        }
      )
      .then((res) => {
        setcity(res.data.data.city)
      });
  };


  const getCountry = async () => {
    await axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/attrs/country`, {
        headers: {
          Authorization: `Bearer  ${UserProfile.getToken()}`,
        },
      })
      .then((res) => {
        setpais(res.data.data.country)
        setpaisLocation(res.data.data.country)
      });
  };

  const getCargo = async () => {
    await axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/attrs/positions`, {
        headers: {
          Authorization: `Bearer  ${UserProfile.getToken()}`,
        },
      })
      .then((res) => {
        setpositions(res.data.data.positions)
      });
  };

  // const getCompanyWork = async () => {
  //   await axios
  //     .get(`${process.env.REACT_APP_BASE_URL}/api/attrs/company_experience`, {
  //       headers: {
  //         Authorization: `Bearer  ${UserProfile.getToken()}`,
  //       },
  //     })
  //     .then((res) => {
  //       setcompanyWork(res.data.data.company_experience)
  //     });
  // };

  // function that loads the want to editted data
  const onEditClick = (id) => {
    console.log('clic')

    setModal({ edit: true }, { add: false });
    setEditedId(id);


    // companyWork.forEach((item) => {
    //   if (item.id === id) {
    //     console.log('si')
    //     setFormData({
    //       name: item.name,
    //       email: item.email,
    //       status: item.status,
    //       type: item.type,
    //       password: item.password,
    //     });
    //     setModal({ edit: true }, { add: false });
    //     setEditedId(id);
    //   }
    // });

  };

  useEffect(() => {
    if (navigator.onLine) {
      UserProfile.setToken(localStorage.getItem("token"));
      UserProfile.setId(localStorage.getItem("id"));
      // setData(props.experienceRes);
      // setexperienceRes(props.experienceRes);
      // console.log(experienceRes,'data');
      getCountry();
      // getIdentificationType();
      // getCivilStatus();
      // getLevelStudy();
      // getSocialNetwork();
      // getLanguage();
      // getLevelLanguage();
      // getEducationInstitution();
      // getActividad();
      getPositions();
      getCompanyWork();
      getInitMeasure();
      getCargo();

      /***************** */
      getApplicant();

    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "msg",
        confirmButtonColor: "#071b30",
        confirmButtonText: "ok",
      });
    }
  }, [])

  return (
    <div>
    <UserProfileStudies />
    <UserProfileLanguages />
    </div>

  );
};
export default UserProfileActivityPage;

import React, { useEffect, useState } from "react";
import Icon from "../../components/icon/Icon";
// import { orderActivityData } from "./OrderData";
import { CardTitle } from "reactstrap";
import { DataTableBody, DataTableHead, DataTableItem, DataTableRow } from "../../components/table/DataTable";
import { Link } from "react-router-dom";

import { useTranslation } from "react-i18next";
import UserProfile from "../../Login/UserProfile";
import Swal from "sweetalert2";
import axios from "axios";

const RecentCompanies = (props) => {
  // const [orderData, setOrderData] = useState(orderActivityData);
  const [orderActivity, setActivity] = useState("");
  // useEffect(() => {
  //   let data;
  //   if (orderActivity === "Buy") {
  //     data = orderActivityData.filter((item) => item.desc.split(" ")[0] === "Buy");
  //   } else if (orderActivity === "Sell") {
  //     data = orderActivityData.filter((item) => item.desc.split(" ")[0] === "Sell");
  //   } else {
  //     data = orderActivityData;
  //   }
  //   setOrderData(data);
  // }, [orderActivity]);

  const [t, i18n] = useTranslation("recentscomps")

  const [ companies, setcompanies ] = useState([]);
  const [ company, setcompany ] = useState([]);

  const list = async () => {
    setcompanies([])
    axios
        .get(`${process.env.REACT_APP_BASE_URL}/api/companies`, {
          headers: {
            Authorization: `Bearer  ${UserProfile.getToken()}`,
          },
          params:{
            page: 1
        }}
      )
      .then((res) => {
        if (res.data.error ? res.data.error[0] == 'El Token a Expirado' : false) {props.history.push('/login'); return}
        setcompanies( res.data.data.companies )
      })
      .catch((error) => console.log(error));
  };


  useEffect(() => {
    if (navigator.onLine) {
      UserProfile.setToken(localStorage.getItem('token'));
      list();
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: t("msg1"),
        confirmButtonColor: "#071b30",
        confirmButtonText: t("ok"),
      });
    }
  }, [])

  return (
    <React.Fragment>
      <div className="card-inner">
        <div className="card-title-group">
          <CardTitle>
            <h6 className="title">
              <span className="mr-2">{t('recent_companies')}</span>{" "}
              <Link to={`${process.env.PUBLIC_URL}/companies-list-compact`} className="link d-none d-sm-inline">
              {t('see_all')}
              </Link>
            </h6>
          </CardTitle>
          {/* <div className="card-tools">
            <ul className="card-tools-nav">
              <li className={orderActivity === "Buy" ? "active" : ""} onClick={() => setActivity("Buy")}>
                <a
                  href="#buy"
                  onClick={(ev) => {
                    ev.preventDefault();
                  }}
                >
                  <span>Buy</span>
                </a>
              </li>
              <li className={orderActivity === "Sell" ? "active" : ""} onClick={() => setActivity("Sell")}>
                <a
                  href="#sell"
                  onClick={(ev) => {
                    ev.preventDefault();
                  }}
                >
                  <span>Sell</span>
                </a>
              </li>
              <li className={orderActivity === "" ? "active" : ""} onClick={() => setActivity("")}>
                <a
                  href="#all"
                  onClick={(ev) => {
                    ev.preventDefault();
                  }}
                >
                  <span>All</span>
                </a>
              </li>
            </ul>
          </div> */}
        </div>
      </div>
      <DataTableBody className="border-top" bodyclass="nk-tb-orders">
        <DataTableHead>
          <DataTableRow className="nk-tb-orders-type">
            <span>{t('ttl1')}</span>
          </DataTableRow>
          <DataTableRow>
            <span>{t('ttl7')}</span>
          </DataTableRow>
          <DataTableRow size="sm">
            <span>{t('ttl2')}</span>
          </DataTableRow>
          <DataTableRow size="xl">
            <span>{t('ttl3')}</span>
          </DataTableRow>
          <DataTableRow size="xxl">
            <span>{t('ttl4')}</span>
          </DataTableRow>
          <DataTableRow size="sm" className="text-right">
            <span>{t('ttl5')}</span>
          </DataTableRow>
          {/* <DataTableRow className="text-right">
            <span>Amount</span>
          </DataTableRow> */}
        </DataTableHead>
        {companies.map((comp) => {
          return (
            <DataTableItem key={comp.id}>
              <DataTableRow className="nk-tb-orders-type">
              <span className="tb-lead">{comp.created_at ? comp.created_at.split('T')[0] : "" }</span>
              </DataTableRow>
              <DataTableRow>
                <span className="tb-lead">{comp.nit}</span>
              </DataTableRow>
              <DataTableRow size="sm">
                <span className="tb-sub">{comp.name}</span>
              </DataTableRow>
              <DataTableRow size="xl">
                <span className="tb-sub">{comp.department.name}</span>
              </DataTableRow>
              <DataTableRow size="xxl">
                <span className="tb-sub">{comp.phone_number}</span>
              </DataTableRow>
              <DataTableRow size="sm" className="text-center">
              <Link to={`${process.env.PUBLIC_URL}/companies-list-compact`} className="link d-none d-sm-inline">
                 <Icon name="eye" title={t('lbl1')}></Icon>
              </Link>
              </DataTableRow>
              {/* <DataTableRow className="text-right">
                <span className="tb-sub tb-amount ">
                  {item.amount} <span>BTC</span>
                </span>
              </DataTableRow> */}
            </DataTableItem>
          );
        })}
      </DataTableBody>
    </React.Fragment>
  );
};
export default RecentCompanies;

import React, { useState, useEffect }  from "react";
import { DropdownToggle, UncontrolledDropdown } from "reactstrap";
import { LangDropdown } from "../../components/Component";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const [t, i18n] = useTranslation("footer");
  return (
    <div className="nk-footer">
      <div className="container-fluid">
        <div className="nk-footer-wrap">
          <div className="nk-footer-copyright">
            {" "}
            {t("lbl5")}
          </div>
          <div className="nk-footer-links">
            <ul className="nav nav-sm">
              <li className="nav-item active current-page">
                <UncontrolledDropdown direction="up">
                  <DropdownToggle
                    tag="a"
                    href="#toggle"
                    onClick={(ev) => ev.preventDefault()}
                    className="dropdown-toggle dropdown-indicator has-indicator nav-link"
                  >
                    <span>{t(localStorage.getItem('lngselect'))}</span>
                   <img
                          width="25"
                          height="17"
                          className="rounded-circle mx-3"
                          src={localStorage.getItem('lngiconselect')}
                        />
                  </DropdownToggle>
                  <LangDropdown />
                </UncontrolledDropdown>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Footer;

import React from "react";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import FormValidationComponent from "../../components/partials/form/FormValidation";
import { Block, BlockDes, PreviewCard, BlockHead, BlockHeadContent, BlockTitle } from "../../components/Component";
import { useTranslation } from "react-i18next";

const FormValidation = (props) => {
  const [t, i18n] = useTranslation("regcomp");
  return (
    <React.Fragment>
      <Head title="Form Validation"></Head>
      <Content page="component">
        <Block size="lg">
          <BlockHead>
            <BlockHeadContent>
              <BlockTitle tag="h5">{t("profile-company")}</BlockTitle>
              <BlockDes>
                <p>{t("text_profile-company")}</p>
              </BlockDes>
            </BlockHeadContent>
          </BlockHead>
          <PreviewCard>
            <FormValidationComponent id="form-2" alter />
          </PreviewCard>
        </Block>
      </Content>
    </React.Fragment>
  );
};
export default FormValidation;

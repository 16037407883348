import React, { useState, useEffect } from "react";

import { useTranslation } from "react-i18next";
import UserProfile from "../../Login/UserProfile";
import Swal from "sweetalert2";
import axios from "axios";
// To manage Forms
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import { components } from "react-select";

import ReactPaginate from "react-paginate";

import Head from "../../layout/head/Head";
import Content from "../../layout/content/Content";
import DatePicker from "react-datepicker";
import {
  Block,
  BlockHead,
  BlockBetween,
  BlockHeadContent,
  BlockTitle,
  BlockDes,
  Icon,
  Button,
  Row,
  PreviewAltCard,
  UserAvatar,
  Col,
  PaginationComponent,
  RSelect,
} from "../../components/Component";
// import { projectData, teamList } from "./ProjectData";
import { findUpper, setDeadline, setDeadlineDays, calcPercentage } from "../../utils/Utils";
import {
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  Modal,
  ModalBody,
  ModalFooter,
  FormGroup,
  Progress,
  DropdownItem,
  // Form,
} from "reactstrap";
import { useForm } from "react-hook-form";

const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          class="form-check-input"
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};

const JobsCompanyPage = (props) => {
  // Translations
  const [t, i18n] = useTranslation("offercomp")


  // Change Page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // const { errors, register, handleSubmit } = useForm();
  const { register, handleSubmit } = useForm();

  const [ loading, setloading ] = useState([]);

  const [ ofertas, setofertas ] = useState([]);
  const [ jobofert, setjobofert ] = useState([]);
  const [ idioma, setidioma ] = useState([]);

  const [ datos, setdatos ] = useState([]);

  const [ countryselect,  setcountryselect,] = useState(0);
  const [ departmentselect, setdepartmentselect ] = useState(0);
  const [ cityselect, setcityselect ] = useState(0);

  const [ country, setcountry ] = useState(0);
  const [ deparmet, setdeparmet ] = useState(0);

  const [ countryName,  setcountryName,] = useState("");
  const [ deparmetName, setdeparmetName ] = useState("");
  const [ estadoName, setestadoName ] = useState("");

  const [ status, setstatus ] = useState(0);
  const [ user, setuser ] = useState(0);

  const [ pais, setpais ] = useState([]);
  const [ departamento, setdepartamento ] = useState([]);
  const [ department, setdepartment ] = useState([]);
  const [ cities, setcities ] = useState([]);
  const [ usuario, setusuario ] = useState([]);
  const [ errors, seterrors ] = useState([]);

    //mods
  const [ applicantspost, setapplicantspost ] = useState([]);

    // selects
  const [ empresa, setempresa ] = useState([]);
  const [ actividad, setactividad ] = useState([]);
  const [ cargo, setcargo ] = useState([]);
  const [ salaries, setsalaries ] = useState([]);
  // const [ unitmeasure, setunitmeasure ] = useState([]);
  const [ xpreq, setxpreq ] = useState([]);
  const [ schedules, setschedules ] = useState([]);
  const [ filstatus, setfilstatus ] = useState([]);

  const [ enddateoffer, setenddateoffer ] = useState(new Date());
  const [ endselectiondateoffer, setendselectiondateoffer ] = useState(new Date());

  const [ activities, setactivities ] = useState([]);
  const [ languages, setlanguages ] = useState([]);

  const [ optionSelected, setoptionSelected, ] = useState(null);
  const [ optionLanguageSelected, setoptionLanguageSelected ] = useState(null);

    /*paginacion*/

  // const [ offset, setoffset ] = useState(0);
  // const [ currentPage, setcurrentPage ] = useState(0);
  // const [ pageCount, setpageCount ] = useState(0);

  // const [perPage, setperPage] = useState(10);
  const [ selectedPage, setselectedPage ] = useState(1);
  const [ pageCount, setpageCount ] = useState(0);
  const [ pageNex, setpageNex ] = useState()
  const [ offset, setoffset ] = useState(0);
  const [ perPage, setperPage] = useState(10);

  const [ elements, setelements ] = useState([]);
  const [ coment, setcoment ] = useState([]);

  const [ identificador, setidentificador] = useState("inicio");

  const [ pagePais, setpagePais ] = useState(1);
  const [ pageDepartamento, setpageDepartamento ] = useState(1);
  const [ pageEmpresa, setpageEmpresa ] = useState(1);
  const [ pageActividad, setpageActividad ] = useState(1);
  const [ pageCargo, setpageCargo ] = useState(1);
  const [ pageEstado, setpageEstado ] = useState(1);

  const [ targetmodel, settargetmodel ] = useState([]);
  const [ comentarios, setcomentarios ] = useState([]);

  const [ idoffert, setidoffert ] = useState(0);

  const [ oitem, setoitem ] = useState(1);

    // datos para actualizar la oferta
  const [ titleoffer, settitleoffer ] = useState("")
  const [ positionoffer, setpositionoffer ] = useState("")
  const [ salaryoffer, setsalaryoffer ] = useState("")
  const [ scheduleoffer, setscheduleoffer ] = useState("")
  const [ durationtimeoffer, setdurationtimeoffer ] = useState("")
  // const [ unitmeasureoffer, setunitmeasureoffer ] = useState("")
  const [ xpreqoffer, setxpreqoffer ] = useState("")
  const [ postalcodeoffer, setpostalcodeoffer ] = useState("")
  const [ vacancynumberoffer, setvacancynumberoffer ] = useState("")
  const [ descriptionoffer, setdescriptionoffer ] = useState("")

  const [ countryoffer, setcountryoffer ] = useState("")
  const [ departmenoffer, setdepartmenoffer ] = useState("")
  const [ cityoffer, setcityoffer ] = useState("")

  const [ idEmpresa, setidEmpresa ] = useState("")

    // paid aplicants
  const [ listServices, setlistServices ] = useState([])
  const [ applicant_offers, setapplicant_offers ] = useState([])
  const [ amounttopay, setamounttopay ] = useState(0)
  const [ preferenceId, setpreferenceId] = useState()

  //selects filter
  const [ citiesfil, setcitiesfil ] = useState([]);
  const [ deparmentsfil, setdeparmentsfil ] = useState([]);

    //         // filters
  const [ filtxtsearch, setfiltxtsearch ] = useState("");
  const [ filcountry, setfilcountry ] = useState("");
  const [ fildepartment, setfildepartment ] = useState("");
  const [ filcity, setfilcity ] = useState("");
  const [ filactivity, setfilactivity ] = useState("");
  const [ filposition, setfilposition ] = useState("");
  const [ filsalary, setfilsalary ] = useState("");
  const [ filschedule, setfilschedule ] = useState("");

  const addNewPosition = (data) => {
    setcargo([...cargo, data])
  }

  const addNewSalary = (data) => {
    setsalaries([...salaries, data])
  }

  const addNewSchedule = (data) => {
    setschedules([...schedules, data])
  }

  const addNewSkill = (data) => {
    setactivities([...activities, data])
  }



const resetList  = async () => {
        var search = {}
        setfiltxtsearch("")
        setfilactivity(0)
        setfilposition(0)
        setfilcountry(0)
        setfildepartment(0)
        setfilcity(0)
        setfilschedule(0)
        setfilsalary(0)
        setfilstatus(0)
        list(search)
    }

    const handleClickFilter = async () => {
        var search = {}
        if (filtxtsearch.length > 0) search.query = filtxtsearch
        if (filactivity > 0) search.activity = filactivity
        if (filposition > 0) search.position = filposition
        if (filcountry > 0) search.country = filcountry
        if (fildepartment > 0) search.department = fildepartment
        if (filcity > 0) search.city = filcity
        if (filschedule > 0) search.schedule = filschedule
        if (filsalary > 0) search.salary = filsalary
        if (filstatus > 0) search.salary = filstatus
        list(search)
    }

    const handlePageClick = (val) => {
        setselectedPage(val.selected+1)
        setpageNex(val.selected)
    }

 useEffect(() => {
  handleClickFilter()
}, [filtxtsearch, selectedPage, filcountry, fildepartment, filcity, filactivity, filposition, filsalary, filschedule,])


  const send = async () => {
    if (document.getElementById("mensajeoffer").value != "") {
      var data = {
        comment: document.getElementById("mensajeoffer").value,
        offer: idoffert,
      };
      axios
        .post(`${process.env.REACT_APP_BASE_URL}/api/comment_offer`, data, {
          headers: {
            Authorization: `Bearer  ${localStorage.getItem('token')}`,
          },
        })
        .then((res) => {
          if (res.data.status === true) {
            listChat(idoffert);
          }
        })
        .catch((error) => console.log(error));
        document.getElementById("mensajeoffer").value=""
    }
  }


  const listChat = async (id) => {
    setidoffert(id)
    setcomentarios([])
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/api/commentoffer/${id}`,
        {
          headers: {
            Authorization: `Bearer  ${localStorage.getItem('token')}`,
          },
        }
      )
      .then((res) => {
          if(res.data.status==true){
            setcomentarios(res.data.data.comment_offer);
          }
      });
  }

  const getUser = async () => {
    axios.get(`${process.env.REACT_APP_BASE_URL}/api/user_type`,
    {
      headers: {
        Authorization: `Bearer  ${localStorage.getItem('token')}`,
      }
    }
    ).then((res) => {
      setusuario(res.data.data.user_type);
    });
  }

  const getCountry = async () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/attrs/country`,
      {
        headers: {
          Authorization: `Bearer  ${localStorage.getItem('token')}`,
        }
      })
      .then((res) => {
        setpais(res.data.data.country );
      });
  }

  const getDeparment = async () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/attrs/department`,
      {
        headers: {
          Authorization: `Bearer  ${localStorage.getItem('token')}`,
        }
      }
      )
      .then((res) => {
        setdepartamento(res.data.data.department);
      });
  }

  const getCities = async (val) => {
    await axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/attrs/municipality`,
        {
          headers: {
            Authorization: `Bearer  ${localStorage.getItem('token')}`,
          },
        }
      )
      .then((res) => {
        setcities(res.data.data.city)
      });
  };

  const handleChange = (selected) => {
    setoptionSelected(selected);
  }

  const handleChangeLanguage = (selected) => {
    setoptionLanguageSelected(selected);
  }

  const getActivity = async () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/attrs/activities`,
      {
        headers: {
          Authorization: `Bearer  ${localStorage.getItem('token')}`,
        }
      })
      .then((res) => {
        setactivities(res.data.data.activities);
      });
  }

  const getLanguages = async () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/attrs/languages`,
      {
        headers: {
          Authorization: `Bearer  ${localStorage.getItem('token')}`,
        }
      })
      .then((res) => {
        setlanguages(res.data.data.languages );
      });
  }

  const getCargo = async () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/attrs/positions`,
      {
        headers: {
          Authorization: `Bearer  ${localStorage.getItem('token')}`,
        }
      })
      .then((res) => {
        setcargo(res.data.data.positions );
      });
  }

  const getsalaries = async () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/attrs/salary_ranges`,
      {
        headers: {
          Authorization: `Bearer  ${localStorage.getItem('token')}`,
        }
      })
      .then((res) => {
        setsalaries(res.data.data.salary_ranges );
      });
  }


  // const getunitmeasure = async () => {
  //   axios
  //     .get(`${process.env.REACT_APP_BASE_URL}/api/attrs/unit_measurement`,
  //     {
  //       headers: {
  //         Authorization: `Bearer  ${localStorage.getItem('token')}`,
  //       }
  //     })
  //     .then((res) => {
  //       setunitmeasure(res.data.data.unit_measurement );
  //     });
  // }

  const getexperiencereq = async () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/attrs/experience_required`)
      .then((res) => {
        setxpreq(res.data.data.experience_required );
      });
  }

  const getworkinghours = async () => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/attrs/work_hours`,
      {
        headers: {
          Authorization: `Bearer  ${localStorage.getItem('token')}`,
        }
      })
      .then((res) => {
        setschedules(res.data.data.work_hours );
      });
  }

  const getDeparmentByCountry = ({id, fil}) => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/api/departments/by_country/${id}`,
        {
          headers: {
            Authorization: `Bearer  ${localStorage.getItem('token')}`,
          },
        })
      .then((res) => {
        if (fil) {
          setdeparmentsfil(res.data.data.department)
        }else{
          setdepartamento(res.data.data.department);
        }
        setcountryselect(id);
      });
  }

  const getCityByDepartment = ({id, fil}) => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/api/cities/by_department/${id}`,
        {
          headers: {
            Authorization: `Bearer  ${localStorage.getItem('token')}`,
          },
        }
      )
      .then((res) => {
        if (fil) {
          setcitiesfil(res.data.data.city);
        }else{
          setcities(res.data.data.city);
        }
        setdepartmentselect(id);
      });
  }


  const updateForm = async (value, countryf, deparmentf, statusf, userf) => {
    getCountry();
    getDeparment();
    getUser();

    setdatos([]);
    setcountry(0)
    setdepartment(0)
    setstatus(0)
    setuser(0)

    setdatos({ ...datos, datos: value });
    setcountry({ ...country, country: countryf });
    setdeparmet({ ...deparmet, deparmet: deparmentf });
    setstatus({ ...status, status: statusf });
    setuser({ ...user, user: userf });

    document.getElementById("openModalUpdate").click();
  }

  const renderList = () => {
    return activities.map((data) => ({
      label: data.name,
      value: data.id,
    }));
  }

  const renderListActividad = () => {
    if (!optionSelected) {
      return
    }
    return optionSelected.map((data) => ({
      activity: data.value,
    }));
  }

  const renderListLanguages = () => {
    return languages.map((data) => ({
      label: data.name,
      value: data.id,
    }));
  }

  const list = (search) => {
    setofertas([])
    let newsearch = {...search, page: selectedPage}
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/job_offers`,{
        headers: {
          Authorization: `Bearer  ${localStorage.getItem('token')}`,
        },
        params: newsearch
      })
      .then((res) => {
        if (res.data.error ? res.data.error[0] == 'El Token a Expirado' : false) {props.history.push('/login'); return}
        setofertas(res.data.data.job_offers)
        setoitem(0)
        setpageCount(res.data.data.pages)
      })
      .catch((error) => console.log(error));
  }


  const editoffert = (id) => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/api/job_offers/${id}`,
        {
          headers: {
            Authorization: `Bearer  ${localStorage.getItem('token')}`,
          }
      })
      .then((res) => {
        const offer = res.data.data

        setidoffert( offer.id)
        settitleoffer( offer.title)
        setpositionoffer( offer.position ? offer.position.id : 0)
        setsalaryoffer( offer.salary ? offer.salary.id : 0)
        setscheduleoffer( offer.schedule ? offer.schedule.id : 0)
        // setdurationtimeoffer( offer.duration_time)
        // setunitmeasureoffer( offer.unit_measurement ? offer.unit_measurement.id : 0)
        setxpreqoffer( offer.experience_required ? offer.experience_required.id : 0)
        setpostalcodeoffer( offer.postal_code)
        setvacancynumberoffer( offer.vacancy_numbers)
        setdescriptionoffer( offer.description)

        getDeparmentByCountry({id: offer.country ? offer.country.id : 0})
        getCityByDepartment({id: offer.department ? offer.department.id : 0})

        setcountryoffer( offer.country ? offer.country.id : 0)
        setdepartmenoffer( offer.department ? offer.department.id : 0)
        setcityoffer( offer.city ? offer.city.id : 0)

        setenddateoffer( new Date(offer.end_offer))
        setendselectiondateoffer( new Date(offer.end_selection))

        let opactsel = []
        for (const {activity:{id, name}} of offer.activities_offer) {
          opactsel.push({"value":id, "label":name})
        }

        let oplansel = []
        for (const {language:{id, name}} of offer.language_offers) {
          oplansel.push({"value":id, "label":name})
        }

        setoptionSelected(opactsel)
        setoptionLanguageSelected(oplansel)
      })
      .catch((error) => console.log(error));
  }

  const saveEditOffer = ({val, resetForm}) => {
    let id = idoffert
    let acts = [];
    let lengs = []
    for (const {value: id} of optionSelected) {
      acts.push({"activity":id})
    }
    for (const {value: id} of optionLanguageSelected) {
      lengs.push({"language":id})
    }

    const values = {
            "title": val.titleoffer,
            "description": val.descriptionoffer,
            "position": val.positionoffer,
            // "duration_time": val.durationtimeoffer,
            // "unit_measurement": val.unitmeasureoffer,
            "experience_required": val.xpreqoffer,
            "salary": val.salaryoffer,
            "schedule": val.scheduleoffer,
            "end_offer": val.enddateoffer,
            "end_selection": val.endselectiondateoffer,
            "country": countryselect,
            "department": val.departmenoffer,
            "city": val.cityoffer,
            "postal_code": val.postalcodeoffer,
            "vacancy_numbers": val.vacancynumberoffer,
            "activity": acts,
            "languages": lengs,
          }
    axios
      .put(
        `${process.env.REACT_APP_BASE_URL}/api/job_offers/${id}`,
        values,
        {
          headers: {
            Authorization: `Bearer  ${localStorage.getItem('token')}`,
          }
      })
      .then((res) => {
        if (res.data.status == false) {
          seterrors(res.data.error)
        }
        if (res.data.status == true) {
          list();
          seterrors([])
          resetForm();
          Swal.fire({
            icon: "success",
            title: t("msg1"),
            confirmButtonColor: "#071b30",
            confirmButtonText: t("ok"),
          });
        }
      })
      .catch((error) => console.log(error));
  }

  const saveOffer = ({val, resetForm}) => {
    let acts = [];
    let lengs = []
    for (const {value: id} of optionSelected) {
      acts.push({"activity":id})
    }
    for (const {value: id} of optionLanguageSelected) {
      lengs.push({"language":id})
    }

    const values = {
            "title": val.titleoffer,
            "description": val.descriptionoffer,
            "position": val.positionoffer,
            "experience_required": val.xpreqoffer,
            "salary": val.salaryoffer,
            "schedule": val.scheduleoffer,
            "end_offer": val.enddateoffer,
            "end_selection": val.endselectiondateoffer,
            "country": countryselect,
            "department": val.departmenoffer,
            "city": val.cityoffer,
            "postal_code": val.postalcodeoffer,
            "vacancy_numbers": val.vacancynumberoffer,
            "activity": acts,
            "languages": lengs,
          };

    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/api/job_offers`,
        values,
        {
          headers: {
            Authorization: `Bearer  ${localStorage.getItem('token')}`,
          }
      })
      .then((res) => {
        if (res.data.status == false) {
          seterrors(res.data.error)
        }
        if (res.data.status == true) {
          list();
          seterrors([])
          resetForm();
          Swal.fire({
            icon: "success",
            title: t("msg1"),
            confirmButtonColor: "#071b30",
            confirmButtonText: t("ok"),
          });
        }
      })
      .catch((error) => console.log(error));
  }

  const listApplicantpost = ({id}) =>{
    axios
    .get(
      `${process.env.REACT_APP_BASE_URL}/api/applicant_offers?offer=${id}`,
      {
        headers: {
          Authorization: `Bearer  ${localStorage.getItem('token')}`,
        },
          params:{
            page: 1
          }
        })
        .then((res) => {
        setapplicantspost(res.data.data.applicant_offers)
      })
      .catch((error) => console.log(error));
  }

  // get services
  const getServices = async () => {
    setlistServices([])
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/services`,
      {
        headers: {
          Authorization: `Bearer  ${localStorage.getItem('token')}`,
        },
        params:{
            page: pageNex
        }
      })
      .then((res) => {
        setlistServices(res.data.data.services)
      })
      .catch((error) => console.log(error));
  };

  // Sum value to pay form applicants
  const sumpay = ({applicant, offer, company}) =>{
    var serbuydata = listServices.find( item => item.id == 1 ) // id 1, service applicant information
    var delet = applicant_offers.findIndex(item => item.applicant == applicant)

    var temp = applicant_offers
    if (delet >= 0) {
      temp.splice(delet, 1)
    }else{
      temp.push({
        "applicant" : applicant,
        "offer" : offer,
      })
    }
    var sum = parseFloat(temp.length) == 0 ? 0 : parseFloat(temp.length) * parseFloat(serbuydata.price)
    setapplicant_offers(temp)
    setamounttopay(sum)
  }

  const buyapplicants = async () => {
    var data = {applicant_offers}
    // setloading(1)
    // Show Message and Spinner
    Swal.fire(t("msg_loading"))
    Swal.showLoading()
    axios
    .post(`${process.env.REACT_APP_BASE_URL}/api/applicant_pays`, data, {
      headers: {
        Authorization: `Bearer  ${localStorage.getItem('token')}`,
      },
    })
      .then((res) => {
        if (!res.data.status) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: res.data.error[0],
            confirmButtonColor: "#071b30",
            confirmButtonText: t("ok"),
          });
          return
        }


        // window.open(res.data.data.paypal_url)
        setloading(0)
        window.location.replace(res.data.data.paypal_url)
      })
      .catch((error) => console.log(error));
  }

  const lookingStaff = (id) => {
    const service = listServices.find(ser => ser.id === 2)
    Swal.fire({
      title: t("msg3"),
      showDenyButton: true,
      confirmButtonText: t("tbl21") + " $ " + (service ? service.price : ""),
      denyButtonText: t("cancel"),
    }).then((result) => {
      if (result.isConfirmed) {
        var data = {
          offer: id,
          applicant: 0
        }
        axios
        .post(`${process.env.REACT_APP_BASE_URL}/api/company_services/search_staff_buy`, data, {
        headers: {
            Authorization: `Bearer  ${localStorage.getItem('token')}`,
          },
        })
        .then((res) => {
        if (!res.data.status) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: res.data.error[0],
            confirmButtonColor: "#071b30",
            confirmButtonText: t("ok"),
          });
          return
        }
        // window.open(res.data.data.paypal_url)
        window.location.replace(res.data.data.paypal_url)
        })
        .catch((error) => console.log(error));
      }
    })

  }


  useEffect(() => {
    UserProfile.setToken(localStorage.getItem('token'));
    list()
    getCountry();
    getActivity();
    getLanguages();
    getCargo();
    getsalaries();
    // getunitmeasure();
    getexperiencereq();
    getworkinghours();
    getServices();
  }, []);

  let paginationElement
  {
    if(pageCount > 1) {
      paginationElement = (
        <ReactPaginate
          previousLabel={"Prev"}
          nextLabel={"Sig"}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={pageCount} //NUMERO TOTAL DE PÁGINAS
          onPageChange={handlePageClick} //FUNCION QUE SE LLAMA EN EL onChange DE LA PÁGINA
          forcePage={pageNex}
          containerClassName={"pagination justify-content-center"}
          subContainerClassName={"pages pagination"}
          activeClassName={"active"}
        />
      )
    }
  }


  const [sm, updateSm] = useState(false);
  const [modal, setModal] = useState({
    view: false,
    add: false,
    edit: false,
  });
  const [editId, setEditedId] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage] = useState(9);
  const [formData, setFormData] = useState({
    title: "",
    subtitle: "",
    description: "",
    lead: "",
    tasks: 0,
    team: [],
    totalTask: 0,
    date: new Date(),
  });

  // OnChange function to get the input data
  const onInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // function to reset the form
  const resetForm = () => {
    setFormData({
      title: "",
      subtitle: "",
      description: "",
      lead: "",
      tasks: 0,
      totalTask: 0,
      team: [],
      date: new Date(),
    });
  };

  // function to close the modal
  const onFormCancel = () => {
    setModal({ add: false }, { edit: false });
    resetForm();
  };

  // submit function to add a new item
  // const onFormSubmit = (sData) => {
  //   const { title, subtitle, description, tasks, totalTask } = sData;
  //   let submittedData = {
  //     id: data.length + 1,
  //     avatarClass: "pink",
  //     title: title,
  //     subtitle: subtitle,
  //     desc: description,
  //     lead: formData.lead,
  //     team: formData.team,
  //     tasks: tasks,
  //     totalTask: totalTask,
  //     deadline: new Date(`${formData.date}`), // Format ** mm/dd/yyyy
  //   };
  //   setData((data) => [submittedData, ...data]);
  //   resetForm();
  //   setModal({ add: false });
  // };

  // submit function to update a new item
  // const onEditSubmit = (sData) => {
  //   const { title, subtitle, description, tasks, totalTask } = sData;
  //   let submittedData;
  //   let newitems = data;
  //   newitems.forEach((item) => {
  //     if (item.id === editId) {
  //       submittedData = {
  //         id: item.id,
  //         avatarClass: item.avatarClass,
  //         title: title,
  //         subtitle: subtitle,
  //         desc: description,
  //         lead: formData.lead,
  //         tasks: tasks,
  //         totalTask: totalTask,
  //         deadline: new Date(`${formData.date}`), // Format ** mm/dd/yyyy
  //         team: formData.team,
  //       };
  //     }
  //   });
  //   let index = newitems.findIndex((item) => item.id === editId);
  //   newitems[index] = submittedData;
  //   setModal({ edit: false });
  //   resetForm();
  // };

  // function that loads the want to view data
  const onEditClick = (id) => {
    ofertas.forEach((item) => {
      if (item.id === id) {
        console.log(item, "item");
        setFormData({
          id: item.id,
          title: item.title,
          description: item.description,
          company: item.company, // Object (name)
          activity: item.activity, // Object (name_es)
          position: item.position, // Object (name_es)
          salary: item.salary.name,
          schedule: item.schedule, // Object (name_es)
          experience_required: item.experience_required, // Object (name_es)
          unit_measurement : item.unit_measurement,
          end_offer: item.end_offer,
          end_selection: item.end_selection,
          date_selection: item.date_selection,
          country : item.country, // Object (name_es)
          department : item.department, // Object (name_es)
          city:  item.city, // Object (name_es)
          postal_code: item.postal_code,
          vacancy_numbers: item.vacancy_numbers,
          status: item.status.description,
          activities_offer: item.activities_offer, // Array (activity.name_es)
          language_offers: item.language_offers, // Array (language.name_es)
          postulates: item.postulates,
        });
        setModal({ view: true }, { edit: false }, { add: false });
        setEditedId(id);
      }
    });
  };

  // function to change the complete a project property
  // const completeProject = (id) => {
  //   let newData = data;
  //   let index = newData.findIndex((item) => item.id === id);
  //   newData[index].deadline = setDeadline(0);
  //   setData([...newData]);
  // };

  // Get current list, pagination
  const indexOfLastItem = currentPage * itemPerPage;
  const indexOfFirstItem = indexOfLastItem - itemPerPage;
  const currentItems = ofertas.slice(indexOfFirstItem, indexOfLastItem);



  return (
    <React.Fragment>
      <Head title={t("title_page")}></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle page> {t('job_offers')}</BlockTitle>
              <BlockDes className="text-soft">{t('you_have')} {ofertas.length} {t('job_offers')}</BlockDes>
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <Button
                  className={`btn-icon btn-trigger toggle-expand mr-n1 ${sm ? "active" : ""}`}
                  onClick={() => updateSm(!sm)}
                >
                  <Icon name="menu-alt-r"></Icon>
                </Button>
                <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                  <ul className="nk-block-tools g-3">
                    {/* Filter desactivated temporaly by Danny */}
                    {/* <li>
                      <UncontrolledDropdown>
                        <DropdownToggle tag="a" className="dropdown-toggle btn btn-white btn-dim btn-outline-light">
                          <Icon name="filter-alt" className="d-none d-sm-inline"></Icon>
                          <span>Filtered By</span>
                          <Icon name="chevron-right" className="dd-indc"></Icon>
                        </DropdownToggle>
                        <DropdownMenu right>
                          <ul className="link-list-opt no-bdr">
                            <li>
                              <DropdownItem
                                tag="a"
                                href="#dropdownitem"
                                onClick={(ev) => {
                                  ev.preventDefault();
                                }}
                              >
                                <span>Open</span>
                              </DropdownItem>
                            </li>
                            <li>
                              <DropdownItem
                                tag="a"
                                href="#dropdownitem"
                                onClick={(ev) => {
                                  ev.preventDefault();
                                }}
                              >
                                <span>Closed</span>
                              </DropdownItem>
                            </li>
                            <li>
                              <DropdownItem
                                tag="a"
                                href="#dropdownitem"
                                onClick={(ev) => {
                                  ev.preventDefault();
                                }}
                              >
                                <span>OnGoing</span>
                              </DropdownItem>
                            </li>
                          </ul>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </li> */}
                    {props.match.url == '/jobs_company' ? (
                    <li className="nk-block-tools-opt" onClick={() => setModal({ add: true })}>
                      <Button color="primary">
                        <Icon name="plus"></Icon>
                        <span>{t("lbl13")}</span>
                      </Button>
                    </li>
                    ) : null}
                  </ul>
                </div>
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>

        <Block>
          {/* List of Job Offers */}
          <Row className="g-gs">
            {ofertas.length > 0 && (
              ofertas.map((oferta, i) => {
                var days = setDeadlineDays(setDeadline(5));
                return (
                  <Col sm="6" xl="4" key={oferta.id}>
                    <PreviewAltCard className="h-100">
                      <div className="project-head">
                        <a
                          href="#title"
                          onClick={(ev) => {
                            ev.preventDefault();
                          }}
                          className="project-title"
                        >
                          <UserAvatar className="sq" theme="info" text={findUpper(oferta.title)} />
                          <div className="project-info">
                            <h6 className="title">{oferta.title}</h6>
                            <span className="sub-text">{t("tbl4")}: {oferta.salary.name}</span>
                          </div>
                        </a>
                        <UncontrolledDropdown>
                          <DropdownToggle
                            tag="a"
                            className="dropdown-toggle btn btn-sm btn-icon btn-trigger mt-n1 mr-n1"
                          >
                            <Icon name="more-h"></Icon>
                          </DropdownToggle>
                          <DropdownMenu right>
                            <ul className="link-list-opt no-bdr">
                              <li onClick={() => ''}>
                                <DropdownItem
                                  tag="a"
                                  href="#edit"
                                  onClick={(ev) => {
                                    ev.preventDefault();
                                  }}
                                >
                                  <Icon name="edit"></Icon>
                                  <span>{t("ttl2")}</span>
                                </DropdownItem>
                              </li>
                              {days >= 0 && (
                                <li onClick={() => '' }>
                                  <DropdownItem
                                    tag="a"
                                    href="#markasdone"
                                    onClick={(ev) => {
                                      ev.preventDefault();
                                    }}
                                  >
                                    <Icon name="check-round-cut"></Icon>
                                    <span>
                                    {oferta.status.description == 'Active'||oferta.status.description == 'Publish'  ? t("desactivate") : t("activate")}
                                    </span>
                                  </DropdownItem>
                                </li>
                              )}
                            </ul>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </div>
                      <div className="project-details">
                      <strong>{t("tbl3")}: </strong>
                      {
                      i18n.language == 'en' ?
                        oferta.position.name :
                        i18n.language == 'es' ?
                        oferta.position.name_es :
                        oferta.position.name_fr
                      }
                      </div>

                      <div className="project-details">
                        {oferta.description.length > 90 ? oferta.description.substring(0, 89) + "... " : oferta.description}
                      </div>
                      <div className="project-progress">
                        <div className="project-progress-details">
                          <div className="project-progress-task">
                            <Icon name="users-fill"></Icon>
                            <span>{t("tbl6")}: {oferta.vacancy_numbers}</span>
                          </div>
                          <div className="project-progress-task">
                          <Icon name="location"></Icon>
                            <span>
                            {
                      i18n.language == 'en' ?
                        oferta.country?.name :
                        i18n.language == 'es' ?
                        oferta.country?.name_es :
                        oferta.country?.name_fr
                      }
                            </span>
                          </div>
                        </div>
                        <div className="project-progress-details">
                          <div className="project-progress-task">
                            <Icon name="list-check"></Icon>
                            <span>{t("tbl11")}:
                            <br/>
                            {
                      i18n.language == 'en' ?
                        oferta.experience_required?.name :
                        i18n.language == 'es' ?
                        oferta.experience_required?.name_es :
                        oferta.experience_required?.name_fr
                      }
                            </span>
                          </div>
                          <div className="project-progress-task">
                          <Icon name="map-pin-fill"></Icon>
                            <span>
                              {
                      i18n.language == 'en' ?
                        oferta.city?.name :
                        i18n.language == 'es' ?
                        oferta.city?.name_es :
                        oferta.city?.name_fr
                      }
                            </span>
                          </div>
                        </div>
                        <div className="project-progress-details">
                          <div className="project-progress-task">
                            <Icon name="calender-date-fill"></Icon>
                            <span>{t("tbl5")}: {oferta.end_offer}</span>
                          </div>
                        </div>
                      </div>
                      <div className="project-meta">
                      <span
                          className={`badge badge-dim badge-${
                            oferta.status.description=="Active" || oferta.status.description == 'Publish' ? "success" : "danger"
                          }`}
                        >
                          <Icon name="layout-alt-fill"></Icon>
                          <span>{oferta.status.description}</span>
                        </span>
                        {/* Days Left */}
                        {/* <span
                          className={`badge badge-dim badge-${
                            days > 10
                              ? "light"
                              : days <= 10 && days >= 2
                              ? "warning"
                              : days === 1
                              ? "danger"
                              : days <= 0 && "success"
                          }`}
                        >
                          <Icon name="clock"></Icon>
                          <span>{days <= 0 ? "Done" : days === 1 ? "Due Tomorrow" : days + " Days Left"}</span>
                        </span> */}
                        {/* Button View Details */}
                          <Button className="btn-round" color="primary" onClick={() => onEditClick(oferta.id) }>
                            <span>{t("view_details")}</span>
                            <Icon name="eye-fill" />
                          </Button>
                      </div>
                    </PreviewAltCard>
                  </Col>
                );
              }))}
          </Row>
          <div className="mt-5">
            <PaginationComponent
              itemPerPage={itemPerPage}
              totalItems={ofertas.length}
              paginate={paginate}
              currentPage={currentPage}
            />
          </div>
        </Block>

        {/* Modal to see Details of Job Offer */}
        <Modal isOpen={modal.view} toggle={() => setModal({ view: false })} className="modal-dialog-centered" size="lg">
          <ModalBody>
            <a
              href="#cancel"
              onClick={(ev) => {
                ev.preventDefault();
                onFormCancel();
              }}
              className="close"
            >
              <Icon name="cross-sm"></Icon>
            </a>

            <div className="card-inner">
                    <Block>
                      <BlockHead>
                        <BlockTitle tag="h5">{formData.title}</BlockTitle>
                        { props.role != 'applicant' ? t("company") + ': ' + formData.company?.name : null }

                        <hr/>
                        <p>
                        {/* {t("text_modal_view")}. */}
                        { props.role == 'applicant' ?
                       t("text_to_apply")
                        : null }

                        </p>
                      </BlockHead>

                      <div className="profile-ud-list">
                        <div className="profile-ud-item100">
                          <div className="profile-ud wider ">
                            <span className="profile-ud-label">{t("tbl2")}</span>
                            <span className="profile-ud-value">{formData.title}</span>
                          </div>
                        </div>

                        <div className="profile-ud-item">
                          <div className="profile-ud wider">
                            <span className="profile-ud-label">{t("tbl9")}</span>
                            <span className="profile-ud-value">
                            {
                      i18n.language == 'en' ?
                      formData.position?.name :
                        i18n.language == 'es' ?
                        formData.position?.name_es :
                        formData.position?.name.name_fr
                      }
                            </span>
                          </div>
                        </div>
                        <div className="profile-ud-item">
                          <div className="profile-ud wider">
                            <span className="profile-ud-label">{t("tbl10")}</span>
                            <span className="profile-ud-value">{formData.salary}</span>
                          </div>
                        </div>
                        {/* Description */}
                        <div className="profile-ud-item100">
                          <div className="profile-ud wider">
                            <span className="profile-ud-label">{t("tbl14")}</span>
                            <span className="profile-ud-value">{formData.description}</span>
                          </div>
                        </div>

                        {/* Schedule */}
                        <div className="profile-ud-item">
                          <div className="profile-ud wider">
                            <span className="profile-ud-label">{t("lbl20")}</span>
                            <span className="profile-ud-value">
                            {
                      i18n.language == 'en' ?
                      formData.schedule?.name :
                        i18n.language == 'es' ?
                        formData.schedule?.name_es :
                        formData.schedule?.name_fr
                      }
                              </span>
                          </div>
                        </div>
                        {/* Experience */}
                        <div className="profile-ud-item">
                          <div className="profile-ud wider">
                            <span className="profile-ud-label">{t("lbl21")}</span>
                            <span className="profile-ud-value">
                            {
                      i18n.language == 'en' ?
                      formData.experience_required?.name :
                        i18n.language == 'es' ?
                        formData.experience_required?.name_es :
                        formData.experience_required?.name_fr
                      }
                              </span>
                          </div>
                        </div>
                        <div className="profile-ud-item">
                          <div className="profile-ud wider">
                            <span className="profile-ud-label">{t("tbl5")}</span>
                            <span className="profile-ud-value">
                            {
                      i18n.language == 'en' ?
                      formData.activity?.name :
                        i18n.language == 'es' ?
                        formData.activity?.namee_es :
                        formData.activity?.name_fr
                      }
                              </span>
                          </div>
                        </div>
                        <div className="profile-ud-item">
                          <div className="profile-ud wider">
                            <span className="profile-ud-label">{t("lbl22")}</span>
                            <span className="profile-ud-value">{formData.unit_measurement}</span>
                          </div>
                        </div>
                        <div className="profile-ud-item">
                          <div className="profile-ud wider">
                            <span className="profile-ud-label">{t("activity_offer")}</span>
                            <span className="profile-ud-value">
                              <ul className="g-1">
                              {formData.activities_offer?.map((activity) => (
                                <>
                                <li className="btn-group">
                                <Button
                                  key={activity.id}
                                  color="light"
                                  size="xs"
                                  className="btn-dim"
                                >
                                   {
                      i18n.language == 'en' ?
                      activity.activity.name :
                        i18n.language == 'es' ?
                        activity.activity.name_es :
                        activity.activity.name_fr
                      }
                                </Button>
                              </li>
                              </>
                              ))}
                               </ul>
                            </span>
                          </div>
                        </div>
                        <div className="profile-ud-item">
                          <div className="profile-ud wider">
                            <span className="profile-ud-label">{t("lbl30")}</span>
                            <span className="profile-ud-value">
                              <ul className="g-1">
                              {formData.language_offers?.map((language) => (
                                <>
                                <li className="btn-group">
                                <Button
                                  key={language.id}
                                  color="light"
                                  size="xs"
                                  className="btn-dim"
                                >
                                   {
                      i18n.language == 'en' ?
                      language.language.name :
                        i18n.language == 'es' ?
                        language.language.name_es :
                        language.language.name_fr
                      }
                                </Button>
                              </li>
                              </>
                              ))}
                               </ul>
                            </span>
                          </div>
                        </div>



                        <div className="profile-ud-item">
                          <div className="profile-ud wider">
                            <span className="profile-ud-label">{t("lbl27")}</span>
                            <span className="profile-ud-value">{formData.end_offer}</span>
                          </div>
                        </div>
                        <div className="profile-ud-item">
                          <div className="profile-ud wider">
                            <span className="profile-ud-label">{t("tbl5")}</span>
                            <span className="profile-ud-value">{formData.end_selection}</span>
                          </div>
                        </div>
                        <div className="profile-ud-item">
                          <div className="profile-ud wider">
                            <span className="profile-ud-label">{t("lbl28")}</span>
                            <span className="profile-ud-value">{formData.date_selection}</span>
                          </div>
                        </div>
                        <div className="profile-ud-item">
                          <div className="profile-ud wider">
                            <span className="profile-ud-label">{t("tbl19")}</span>
                            <span className="profile-ud-value">
                            <span
                          className={`badge badge-dim badge-${
                            formData.status=="Active" || formData.status == 'Publish' ? "success" : "danger"
                          }`}
                        >
                          <Icon name="layout-alt-fill"></Icon>
                          <span>{formData.status}</span>
                        </span>
                            </span>
                          </div>
                        </div>
                        <div className="profile-ud-item">
                          <div className="profile-ud wider">
                            <span className="profile-ud-label">{t("tbl13")}</span>
                            <span className="profile-ud-value">{formData.vacancy_numbers}</span>
                          </div>
                        </div>
                        <div className="profile-ud-item">
                          <div className="profile-ud wider">
                            <span className="profile-ud-label">{t("postulates")}</span>
                            <span className="profile-ud-value">{formData.postulates}</span>
                          </div>
                        </div>
                      </div>
                    </Block>

                    <Block>
                      <BlockHead className="nk-block-head-line">
                        <BlockTitle tag="h6" className="overline-title text-base">
                        {t("location")}
                        </BlockTitle>
                      </BlockHead>
                      <div className="profile-ud-list">
                        <div className="profile-ud-item">
                          <div className="profile-ud wider">
                            <span className="profile-ud-label">{t("tbl17")}</span>
                            <span className="profile-ud-value">
                            {
                      i18n.language == 'en' ?
                      formData.country?.name :
                        i18n.language == 'es' ?
                        formData.country?.name_es :
                        formData.country?.name_fr
                      }
                              </span>
                          </div>
                        </div>
                        <div className="profile-ud-item">
                          <div className="profile-ud wider">
                            <span className="profile-ud-label">{t("department")}</span>
                            <span className="profile-ud-value">
                            {
                      i18n.language == 'en' ?
                      formData.deparment?.name :
                        i18n.language == 'es' ?
                        formData.deparment?.name_es :
                        formData.deparment?.name_fr
                      }
                              </span>
                          </div>
                        </div>
                        <div className="profile-ud-item">
                          <div className="profile-ud wider">
                            <span className="profile-ud-label">{t("tbl30")}</span>
                            <span className="profile-ud-value">
                            {
                      i18n.language == 'en' ?
                      formData.city?.name :
                        i18n.language == 'es' ?
                        formData.city?.name_es :
                        formData.city?.name_fr
                      }
                            </span>
                          </div>
                        </div>
                        <div className="profile-ud-item">
                          <div className="profile-ud wider">
                            <span className="profile-ud-label">{t("tbl18")}</span>
                            <span className="profile-ud-value">{formData.postal_code ?? ''}</span>
                          </div>
                        </div>
                      </div>
                    </Block>

                    {/* <hr/> */}
                    <div className="nk-divider divider md"></div>
                    {/* <Block>
                      <div className="bq-note">
                      </div>
                    </Block> */}
                     { props.role == 'applicant' ? (
                    <ModalFooter >
        <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                    {/* Cancel Button */}
                      <li>
                        <Button
                          onClick={(ev) => {
                            ev.preventDefault();
                            onFormCancel();
                          }}
                          className="link link-light"
                        >
                          {t("cancel")}
                        </Button>
                      </li>
                      {/* Apply Button */}
                      <li>
                        <Button color="primary" size="lg" type="submit">
                        {t("apply")}
                        </Button>
                      </li>
                    </ul>
      </ModalFooter>
                     ) : (null) }

                  </div>

          </ModalBody>
        </Modal>

        {/* Modal to add new Job Offer */}
        <Modal isOpen={modal.add} toggle={() => setModal({ add: false })} className="modal-dialog-centered" size="lg">
          <ModalBody>
            <a
              href="#cancel"
              onClick={(ev) => {
                ev.preventDefault();
                onFormCancel();
              }}
              className="close"
            >
              <Icon name="cross-sm"></Icon>
            </a>

            <div className="p-2">
              <h5 className="title">{t("ttl1")}</h5>
              <div className="mt-4">

            {/* Form old Frontend */}
            <Formik
                    initialValues={{
                      titleoffer: "",
                      positionoffer: "",
                      salaryoffer: "",
                      scheduleoffer: "",
                      durationtimeoffer: "",
                      xpreqoffer: "",
                      postalcodeoffer: "",
                      vacancynumberoffer: "",
                      descriptionoffer: "",
                      actividadoffer: renderListActividad(),
                      countryoffer: "",
                      departmenoffer: "",
                      cityoffer: "",
                      enddateoffer: new Date(),
                      endselectiondateoffer: new Date(),
                    }}
                    validationSchema={Yup.object().shape({
                      titleoffer: Yup.string().required(t("fr")),
                      positionoffer: Yup.string().required(t("fr")),
                      salaryoffer: Yup.string().required(t("fr")),
                      scheduleoffer: Yup.string().required(t("fr")),
                      xpreqoffer: Yup.string().required(t("fr")),
                      postalcodeoffer: Yup.string().required(t("fr")),
                      vacancynumberoffer: Yup.number().required(t("fr")).positive().integer(),
                      descriptionoffer: Yup.string().required(t("fr")),

                      countryoffer: Yup.number(),
                      departmenoffer: Yup.number().required(t("fr")),
                      enddateoffer: Yup.date(),
                      endselectiondateoffer: Yup.date(),

                    })}

                    validate = {(values) => {
                      if (values.countryoffer != countryselect && values.countryoffer > 0) getDeparmentByCountry({id:values.countryoffer})
                      if (values.departmenoffer != departmentselect && values.departmenoffer > 0) getCityByDepartment({id:values.departmenoffer})
                    }}

                    onSubmit={(val, { resetForm }) => {
                        saveOffer({val, resetForm})
                    }}
                  >

{({handleSubmit}) => (
                      <Form className="row gy-4" onSubmit={handleSubmit}>
                         <Col md="6">
                          <FormGroup>
                          <label htmlFor="titleoffer" class="form-label">{t("lbl17")}</label>
                          <Field
                                id="titleoffer"
                                name="titleoffer"
                                type="text"
                                className="form-control"
                                placeholder={t("lbl17")}
                              />
                              <ErrorMessage
                                name="titleoffer"
                                component="div"
                                className="invalid"
                              />
                          </FormGroup>
                          </Col>
                        <Col md="6">
                          <FormGroup>
                              <label htmlFor="positionoffer" className="form-label">{t("lbl18")}</label>
                              <Field
                                as="select"
                                id="positionoffer"
                                name="positionoffer"
                                className="form-control"
                              >
                                <option
                                  className="form-control"
                                  value=""
                                  defaultValue
                                >
                                  {t("lbl18")}
                                </option>
                                {cargo.map((i) => {
                                  return (
                                    <option
                                      className="form-control"
                                      value={i.id}
                                    >
                                      {i.name}
                                    </option>
                                  );
                                })}
                              </Field>
                              <ErrorMessage
                                name="positionoffer"
                                component="div"
                                className="invalid"
                              />
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup>
                              <label htmlFor="salaryoffer" className="form-label">{t("lbl19")}</label>
                              <Field
                                as="select"
                                id="salaryoffer"
                                name="salaryoffer"
                                className="form-control"
                              >
                                <option
                                  className="form-control"
                                  value=""
                                  defaultValue
                                >
                                  {t("lbl19")}
                                </option>
                                {salaries.map((i) => {
                                  return (
                                    <option
                                      className="form-control"
                                      value={i.id}
                                    >
                                      {i.name}
                                    </option>
                                  );
                                })}
                              </Field>
                              <ErrorMessage
                                name="salaryoffer"
                                component="div"
                                className="invalid"
                              />
                            </FormGroup>
                           </Col>
                        <Col md="6">
                          <FormGroup>
                              <label htmlFor="scheduleoffer" className="form-label">{t("lbl20")}</label>
                              <Field
                                as="select"
                                id="scheduleoffer"
                                name="scheduleoffer"
                                className="form-control"
                              >
                                <option
                                  className="form-control"
                                  value=""
                                  defaultValue
                                >
                                  {t("lbl20")}
                                </option>
                                {schedules.map((i) => {
                                  return (
                                    <option
                                      className="form-control"
                                      value={i.id}
                                    >
                                      {i.name}
                                    </option>
                                  );
                                })}
                              </Field>
                              <ErrorMessage
                                name="scheduleoffer"
                                component="div"
                                className="invalid"
                              />
                          </FormGroup>
                         </Col>
                          <Col md="6">
                            <FormGroup>
                              <label htmlFor="xpreqoffer" className="form-label">{t("lbl21")}</label>
                              <Field
                                as="select"
                                id="xpreqoffer"
                                name="xpreqoffer"
                                className="form-control"
                              >
                                <option
                                  className="form-control"
                                  value=""
                                  defaultValue
                                >
                                  {t("lbl21")}
                                </option>
                                {xpreq.map((i) => {
                                  return (
                                    <option
                                      className="form-control"
                                      value={i.id}
                                    >
                                      {i.name}
                                    </option>
                                  );
                                })}
                              </Field>
                              <ErrorMessage
                                name="xpreqoffer"
                                component="div"
                                className="invalid"
                              />
                            </FormGroup>
                        </Col>
                      <Col md="6">
                        <FormGroup>
                              <label htmlFor="countryoffer" className="form-label">{t("lbl23")}</label>
                              <Field
                                as="select"
                                id="countryoffer"
                                name="countryoffer"
                                className="form-control"
                              >
                                <option
                                  className="form-control"
                                  value=""
                                >
                                  {t("lbl23")}
                                </option>
                                {pais.map((i) => {
                                  return (
                                    <option
                                      className="form-control"
                                      value={i.id}
                                    >
                                      {i.name}
                                    </option>
                                  );
                                })}
                              </Field>
                              <ErrorMessage
                                name="countryoffer"
                                component="div"
                                className="invalid"
                              />
                             </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <label htmlFor="departmenoffer" className="form-label">{t("lbl24")}</label>
                              <Field as="select"
                                id="departmenoffer"
                                name="departmenoffer"
                                className="form-control"
                                aria-label="Default select example"
                              >
                                <option
                                  className="form-control"
                                  value=""
                                  defaultValue
                                >
                                  {t("lbl24")}
                                </option>
                                {departamento.map((i) => {
                                  return (
                                    <option
                                      className="form-control"
                                      value={i.id}
                                    >
                                      {i.name}
                                    </option>
                                  );
                                })}
                              </Field>
                              <ErrorMessage
                                name="departmenoffer"
                                component="div"
                                className="msgerror mx-2"
                              />
                            </FormGroup>
                          </Col>
                          <Col md="6">
                            <FormGroup>
                              <label htmlFor="cityoffer" className="form-label">{t("tbl29")}</label>
                              <Field as="select"
                                id="cityoffer"
                                name="cityoffer"
                                className="form-control"
                                aria-label="Default select example"
                              >
                                <option
                                  className="form-control"
                                  value=""
                                  defaultValue
                                >
                                  {t("tbl29")}
                                </option>
                                {cities.map((i) => {
                                  return (
                                    <option
                                      className="form-control"
                                      value={i.id}
                                    >
                                      {i.name}
                                    </option>
                                  );
                                })}
                              </Field>
                              <ErrorMessage
                                name="cityoffer"
                                component="div"
                                className="invalid"
                              />
                               </FormGroup>
                         </Col>
                      <Col md="6">
                        <FormGroup>
                              <label htmlFor="postalcodeoffer" className="form-label">{t("lbl25")}</label>
                              <Field
                                id="postalcodeoffer"
                                name="postalcodeoffer"
                                type="text"
                                className="form-control"
                                placeholder={t("lbl25")}
                              />
                              <ErrorMessage
                                name="postalcodeoffer"
                                component="div"
                                className="invalid"
                              />
                        </FormGroup>
                     </Col>
                     <Col md="6">
                    <FormGroup>
                              <label htmlFor="vacancynumberoffer" className="form-label">{t("lbl26")}</label>
                              <Field
                                id="vacancynumberoffer"
                                name="vacancynumberoffer"
                                type="number"
                                className="form-control"
                                placeholder={t("lbl26")}
                              />
                              <ErrorMessage
                                name="vacancynumberoffer"
                                component="div"
                                className="invalid"
                              />
                         </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                              <label htmlFor="enddateoffer">{t("lbl27")}</label>
                              <DatePicker
                                selected={enddateoffer}
                                dateFormat="Y-M-d"
                                className="form-control"
                                id="enddateoffer"
                                name="enddateoffer"
                                onChange={(e) => {
                                  setenddateoffer(e)
                                }}
                              />
                              <ErrorMessage
                                name="enddateoffer"
                                component="div"
                                className="msgerror mx-2"
                              />
                           </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                              <label htmlFor="endselectiondateoffer">{t("lbl28")}</label>
                              <DatePicker
                                selected={endselectiondateoffer}
                                dateFormat="Y-M-d"
                                className="form-control"
                                id="endselectiondateoffer"
                                name="endselectiondateoffer"
                                onChange={(e) => {
                                  setendselectiondateoffer(e)
                                }}
                              />
                              <ErrorMessage
                                name="endselectiondateoffer"
                                component="div"
                                className="invalid"
                              />
                               </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                              <label htmlFor="actividadoffer" className="form-label">{t("lbl29")}</label>
                              <RSelect name="actividadoffer"
                                placeholder={t("lbl29")}
                                options={renderList()}
                                isMulti onChange={handleChange}
                                allowSelectAll={true}
                                value={optionSelected} />
                              {/* <Select
                                name="actividadoffer"
                                placeholder={
                                  <div className="form-control">
                                    {t("lbl29")}
                                  </div>
                                }
                                options={renderList()}
                                isMulti
                                closeMenuOnSelect={false}
                                hideSelectedOptions={false}
                                components={{
                                  Option,
                                }}
                                onChange={handleChange}
                                allowSelectAll={true}
                                value={optionSelected}
                              /> */}
                                  <ErrorMessage
                                    name="actividadoffer"
                                    component="div"
                                    className="invalid"
                                  />
                                   </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                              <label htmlFor="languagesoffer" className="form-label">{t("lbl30")}</label>
                              <RSelect name="actividadoffer"
                                placeholder={t("lbl30")}
                                options={renderListLanguages()}
                                isMulti onChange={handleChangeLanguage}
                                allowSelectAll={true}
                                value={optionLanguageSelected} />
                              {/* <Select
                                name="languagesoffer"
                                placeholder={
                                  <div className="form-control">
                                    {t("lbl30")}
                                  </div>
                                }
                                options={renderListLanguages()}
                                isMulti
                                closeMenuOnSelect={false}
                                hideSelectedOptions={false}
                                components={{
                                  Option,
                                }}
                                onChange={handleChangeLanguage}
                                allowSelectAll={true}
                                value={optionLanguageSelected}
                              /> */}
                                  <ErrorMessage
                                    name="languagesoffer"
                                    component="div"
                                    className="invalid"
                                  />
                                   </FormGroup>
                  </Col>
                  <Col md="12">
                    <FormGroup>
                              <label htmlFor="descriptionoffer" className="form-label">{t("lbl31")}</label>
                              <Field
                                id="descriptionoffer"
                                name="descriptionoffer"
                                component="textarea"
                                className="form-control"
                                placeholder={t("lbl31")}
                              />
                              <ErrorMessage
                                name="descriptionoffer"
                                component="div"
                                className="invalid"
                              />
                               </FormGroup>
                  </Col>
                  <Col md="12">
                    <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                    <li>
                        <Button color="primary" size="md" type="submit">
                        {t("lbl32")}
                        </Button>
                      </li>
                      <li>
                        <Button
                          onClick={(ev) => {
                            ev.preventDefault();
                            onFormCancel();
                          }}
                          className="link link-light"
                        >
                          Cancel
                        </Button>
                      </li>
                    </ul>
                  </Col>
                      </Form>
                    )}
                  </Formik>
              </div>
            </div>
          </ModalBody>

        {/* Modal to edit Job Offer */}

        </Modal>
        <Modal isOpen={modal.edit} toggle={() => setModal({ edit: false })} className="modal-dialog-centered" size="lg">
          <ModalBody>
            <a
              href="#cancel"
              onClick={(ev) => {
                ev.preventDefault();
                onFormCancel();
              }}
              className="close"
            >
              <Icon name="cross-sm"></Icon>
            </a>
            <div className="p-2">
              <h5 className="title">Update Job Offer</h5>
              <div className="mt-4">
                {/* <Form className="row gy-4" onSubmit={handleSubmit(onEditSubmit)}>
                  <Col md="6">
                    <FormGroup>
                      <label className="form-label">Title</label>
                      <input
                        type="text"
                        name="title"
                        defaultValue={formData.title}
                        placeholder="Enter Title"
                        onChange={(e) => onInputChange(e)}
                        ref={register({ required: "This field is required" })}
                        className="form-control"
                      />
                      {errors.title && <span className="invalid">{errors.title.message}</span>}
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <label className="form-label">Salary</label>
                      <input
                        type="text"
                        name="subtitle"
                        defaultValue={formData.subtitle}
                        placeholder="Enter client name"
                        onChange={(e) => onInputChange(e)}
                        ref={register({ required: "This field is required" })}
                        className="form-control"
                      />
                      {errors.subtitle && <span className="invalid">{errors.subtitle.message}</span>}
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <label className="form-label">Number of Vacancies</label>
                      <input
                        type="number"
                        name="tasks"
                        defaultValue={formData.tasks}
                        onChange={(e) => onInputChange(e)}
                        ref={register({ required: "This field is required" })}
                        className="form-control"
                      />
                      {errors.tasks && <span className="invalid">{errors.tasks.message}</span>}
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <label className="form-label">Experience Required</label>
                      <input
                        type="number"
                        name="totalTask"
                        defaultValue={formData.totalTask}
                        onChange={(e) => onInputChange(e)}
                        ref={register({ required: "This field is required" })}
                        className="form-control"
                      />
                      {errors.totalTask && <span className="invalid">{errors.totalTask.message}</span>}
                    </FormGroup>
                  </Col>
                  <Col size="12">
                    <FormGroup>
                      <label className="form-label">Description</label>
                      <textarea
                        name="description"
                        defaultValue={formData.description}
                        placeholder="Your description"
                        onChange={(e) => onInputChange(e)}
                        ref={register({ required: "This field is required" })}
                        className="form-control no-resize"
                      />
                      {errors.description && <span className="invalid">{errors.description.message}</span>}
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <label className="form-label">Deadline Date</label>
                      <DatePicker
                        selected={formData.date}
                        className="form-control"
                        onChange={(date) => setFormData({ ...formData, date: date })}
                        minDate={new Date()}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <label className="form-label">End Selection</label>
                      <DatePicker
                        selected={formData.date}
                        className="form-control"
                        onChange={(date) => setFormData({ ...formData, date: date })}
                        minDate={new Date()}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <label className="form-label">Team Members</label>
                      <RSelect
                        options={teamList}
                        isMulti
                        defaultValue={formData.team}
                        onChange={(e) => setFormData({ ...formData, team: e })}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <label className="form-label">Lead</label>
                      <RSelect
                        options={formData.team}
                        defaultValue={[{ value: formData.lead, label: formData.lead }]}
                        onChange={(e) => setFormData({ ...formData, lead: e.value })}
                      />
                    </FormGroup>
                  </Col>
                  <Col size="12">
                    <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                      <li>
                        <Button color="primary" size="md" type="submit">
                          Update Project
                        </Button>
                      </li>
                      <li>
                        <Button
                          onClick={(ev) => {
                            ev.preventDefault();
                            onFormCancel();
                          }}
                          className="link link-light"
                        >
                          Cancel
                        </Button>
                      </li>
                    </ul>
                  </Col>
                </Form> */}
              </div>
            </div>
          </ModalBody>
        </Modal>
      </Content>
    </React.Fragment>
  );
};
export default JobsCompanyPage;

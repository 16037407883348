import React, { useState, useEffect  } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import Swal from "sweetalert2";
import Logo from "../../images/logo_hrh.png";
import LogoDark from "../../images/logo_hrh.png";
import icoGoogle from "../../images/google_ico.png";
import linkedin from "../../images/linkedinicon.png";
import PageContainer from "../../layout/page-container/PageContainer";
import Head from "../../layout/head/Head";
import AuthFooter from "./AuthFooter";
import {
  Block,
  BlockContent,
  BlockDes,
  BlockHead,
  BlockTitle,
  Button,
  Icon,
  PreviewCard,
} from "../../components/Component";
// Import to Modals
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
  FormGroup,
} from "reactstrap";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
// To manage forms with Formik and Yup
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";


const Register = (props) => {

  // Translations
  const [t, i18n] = useTranslation("login");
  // To use in the process
  const [ typeUser, settypeUser ] = useState([])
  const [ idusertemp, setidusertemp ] = useState("");
  const [ tokentemp, settokentemp ] = useState("");
  const [ user, setuser ] = useState({
    name : "",
    email : ""
  })

  // Modal
  const [modalSuccess, setModalSuccess] = useState(false);
  const toggleSuccess = () => setModalSuccess(!modalSuccess);

  const [passState, setPassState] = useState(false);
  const [loading, setLoading] = useState(false);
  const { errors, register, handleSubmit } = useForm();

  // API Google Register
  const registerGoogle = (nombre, correo, id, foto) => {
    const usertemp = { id: id, name: nombre, email: correo, avatar: foto };

    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}/api/auth/social/google/register`,
        usertemp
      )
      .then((res) => {
        if (res.data.status == true) {
          Swal.fire({
            icon: "success",
            title: "Oops...",
            text: t("msg1"),
          });
          props.history.push("/");
        } else {
          Swal.fire({
            icon: "success",
            title: "Oops...",
            text: t("msg2"),
          });
        }
      });
  };

// Save the user in the database
  const registro = async (val) => {
    // console.log(val, "val");
    const usertemp = {
      name: val.name,
      email: val.email,
      password: val.password,
      type:val.type,
      lng: localStorage.getItem('lngselect'),
    };
    axios
      .post(`${process.env.REACT_APP_BASE_URL}/api/auth/register`, usertemp)
      .then((res) => {
        if (res.data.status == true) {
          loadSessionData(res)
          // Token necesary to access other pages
          localStorage.setItem("accessToken", "token");
        } else {
          // Email in use
          Swal.fire({
            icon: "error",
            title: t("lbl_error_register"),
            text: t("msg_error_register"),
          });
        }
        document.getElementById("salirRedes").click();
      }).catch(error => {
        console.log("error "+error)
      });
};

// Load the session data
const loadSessionData = (res) => {
  if ( !((res.data.data.user.type ? res.data.data.user.type.id : 0) > 0) || res.data.data.user.type.id == 4 ) {
    // Clic on the invisible button to open the modal
    document.getElementById("btnmdlchangeusertype").click();
    settokentemp(res.data.data.token)
    setidusertemp(res.data.data.user.id)
  }else{
    switch (res.data.data.user.type.id) {
      case 1:
        console.log("1");
        localStorage.setItem('token',res.data.data.token);
        localStorage.setItem('id',res.data.data.user.id);
        localStorage.setItem('typeuser',res.data.data.user.type.id);
        localStorage.setItem('user',JSON.stringify(res.data.data.user));
        localStorage.setItem('name',res.data.data.user.name);
        localStorage.setItem('email',res.data.data.user.email);
        localStorage.setItem('rol',res.data.data.user.type.description);
        props.history.push("/admin")
      break;

      case 2:
        console.log("2");
        localStorage.setItem('token',res.data.data.token);
        localStorage.setItem('id',res.data.data.user.id);
        localStorage.setItem('typeuser',res.data.data.user.type.id);
        localStorage.setItem('user',JSON.stringify(res.data.data.user));
        localStorage.setItem('name',res.data.data.user.name);
        localStorage.setItem('email',res.data.data.user.email);
        localStorage.setItem('rol',res.data.data.user.type.description);
        axios
        .get(`${process.env.REACT_APP_BASE_URL}/api/has_profile_applicant`,{
          headers: {
            Authorization: `Bearer  ${res.data.data.token}`,
          }
        })
        .then((res) => {
          if(res.data.status===true){
            props.history.push("applicant")
          }else{
            props.history.push("user-profile-regular")
          }
        });
      break;

      case 3:
        console.log("3");
        localStorage.setItem('token',res.data.data.token);
        localStorage.setItem('id',res.data.data.user.id);
        localStorage.setItem('typeuser',res.data.data.user.type.id);
        localStorage.setItem('user',JSON.stringify(res.data.data.user));
        localStorage.setItem('name',res.data.data.user.name);
        localStorage.setItem('email',res.data.data.user.email);
        localStorage.setItem('rol',res.data.data.user.type.description);
        axios
        .get(`${process.env.REACT_APP_BASE_URL}/api/has_companies`, {
          headers: {
            Authorization: `Bearer  ${res.data.data.token}`,
          }
        })
        .then((res) => {
          if(res.data.status===true){
            props.history.push("/company")
          }else{
            props.history.push("/registercompany")
          }
        });
      break;
    }
  }
}

// Necesarry to change the user type after register
const changeTypeUser = (type) => {
  const valuesupdate = {
    userid: idusertemp,
    type: type,
  }
  axios
    .post(
      `${process.env.REACT_APP_BASE_URL}/api/auth/changetypeuser`,
      valuesupdate, {
         headers: {
           Authorization: `Bearer  ${tokentemp}`,
         }
       }
    )
    .then((res) => {
      document.getElementById("btnmdlchangeusertype").click()
      loadSessionData(res)
    })
    .catch((error) => console.log(error));
}

const getTypeUSer = () => {
  axios
  .get(`${process.env.REACT_APP_BASE_URL}/api/user_type`)
  .then((res) => {
    settypeUser(res.data.data.user_type)
  })
  .catch((error) => console.log(error));
}

useEffect(() => {
  document.getElementById("salirRedes").click();
  getTypeUSer()
}, [])

const {
  usertemp,
  signOut,
} = props;

return (
<>
    {usertemp ? (
      <p>
        {registerGoogle(
          usertemp.displayName,
          usertemp.email,
          usertemp.uid,
          usertemp.photoURL,
          "google"
        )}
      </p>
    ) : (
      <p></p>
    )}

    <React.Fragment>
      <Head title="Register" />
      <PageContainer>
        <Block className="nk-block-middle nk-auth-body  wide-xs">
          <div className="brand-logo pb-4 text-center">
            <Link to={`${process.env.PUBLIC_URL}/`} className="logo-link">
              <img className="logo-light logo-img logo-img-lg" src={Logo} alt="logo" />
              <img className="logo-dark logo-img logo-img-lg" src={LogoDark} alt="logo-dark" />
            </Link>
          </div>
          <PreviewCard className="card-bordered" bodyClass="card-inner-lg">
            {/* <BlockHead>
              <BlockContent>
                <BlockTitle tag="h4">Register</BlockTitle>
                <BlockDes>
                  <p>Create New Dashlite Account</p>
                </BlockDes>
              </BlockContent>
            </BlockHead> */}


                  <Formik
                      initialValues={{
                        name: user.name,
                        email: user.email,
                        type: localStorage.getItem('userrol') ? localStorage.getItem('userrol') : "4",
                        password: "",
                        // password2: "",
                        conditions: ""
                      }}
                      validationSchema={Yup.object().shape({
                        conditions: Yup.string().required(t("fr")),
                        name: Yup.string().required(t("fr")),
                        email: Yup.string().required(t("fr")).email(t("inmai")),
                        type: Yup.string(),
                        password: Yup.string().required(t("fr")),
                        // password2: Yup.string().required(t("fr")).oneOf([Yup.ref("password"), null], t("passnoniq")),
                      })}

                      onSubmit={(val, { resetForm }) => {
                        // resetForm();
                            registro(val)
                      }}

                      enableReinitialize
                      render={({ errors, status, touched }) => (
                        <Form className="is-alter">
                    <FormGroup>
                    <label className="form-label" htmlFor="name">
                {t("lbl1")}
                </label>
                <div className="form-control-wrap">
                     <Field
                       id="name"
                       name="name"
                       type="text"
                       className="form-control-lg form-control"
                       placeholder={t("lbl1")}
                     />
                      <ErrorMessage
                          name="name"
                          component="div"
                          className="invalid"
                        />
                   </div>
                   </FormGroup>
                   <FormGroup>
                <div className="form-label-group">
                  <label className="form-label" htmlFor="default-01">
                  {t("lbl2")}
                  </label>
                </div>

                   <div className="form-control-wrap">
                     <Field
                       id="email"
                       name="email"
                       type="email"
                       className="form-control-lg form-control"
                       placeholder={t("lbl2")}
                     />
                      <ErrorMessage
                          name="name"
                          component="div"
                          className="invalid"
                        />
                    </div>
              </FormGroup>
              <div className="form-group mb-4 d-none">
                        <Field
                          as="select"
                          id="type"
                          name="type"
                          className="form-select fondoamarillo imputText"
                          aria-label={t("lbl3")}
                        >
                          <option className="fondo fw-bolder" value="4" selected>
                            {t("lbl4")}
                          </option>

                          {typeUser.map((i) => {

                            if(i.id!=1){

                            return (
                              <option className="fondo fw-bolder" value={i.id}>
                                {i.description}
                              </option>
                            );
                            }
                          })}
                        </Field>

                        <ErrorMessage
                          name="type"
                          component="div"
                          className="invalid"
                        />
                      </div>
                      <FormGroup>
                <div className="form-label-group">
                  <label className="form-label" htmlFor="password">
                  {t("lbl5")}
                  </label>
                </div>
                <div className="form-control-wrap">
                  <a
                    href="#password"
                    onClick={(ev) => {
                      ev.preventDefault();
                      setPassState(!passState);
                    }}
                    className={`form-icon lg form-icon-right passcode-switch ${passState ? "is-hidden" : "is-shown"}`}
                  >
                    <Icon name="eye" className="passcode-icon icon-show"></Icon>

                    <Icon name="eye-off" className="passcode-icon icon-hide"></Icon>
                  </a>
                     <Field
                       id="password"
                       name="password"
                       type= {passState ? "text" : "password"}
                       className={`form-control-lg form-control ${passState ? "is-hidden" : "is-shown"}`}
                       placeholder={t("lbl5")}

                     />
                      <ErrorMessage
                          name="password"
                          component="div"
                          className="invalid"
                        />
                   </div>
                   </FormGroup>
                   <FormGroup>
                <div className="form-label-group">
                  <label className="form-label" htmlFor="default-01">
                  {t("terms_conditions")}: <a style={{color:'blue'}} target="_blank" onClick={()=>window.open(t(`file_personal_data_${i18n.language}`), '_blank')} > {t("lbl22")} </a>
                  </label>
                </div>

                <div className="custom-control custom-control-sm custom-checkbox">
                        <Field
                          id="conditions"
                          name="conditions"
                          className="form-check-input"
                          type="checkbox"
                          placeholder={t("lbl21")}
                        />
                        <label
                          className="color-fuente-gris textbold mx-2"
                          htmlFor="form1Example4"
                        >
                          {" "}
                          {t("lbl21")} {" "}
                        </label>

                        <ErrorMessage
                          name="conditions"
                          component="div"
                          className="invalid"
                        />

                    </div>
                    </FormGroup>
                    <FormGroup>
                <Button type="submit" color="primary" size="lg" className="btn-block">
                  {loading ? <Spinner size="sm" color="light" /> : t("lbl7")}
                </Button>
              </FormGroup>
              </Form>
                       )}
                      />
            {/* End Form */}
            <div className="form-note-s2 text-center pt-4">
              {" "}
              {t("ttl1")}{" "}
              <Link to={`${process.env.PUBLIC_URL}/auth-login`}>
                <strong>{t("ttl2")}</strong>
              </Link>
            </div>
            {/* Social Icons */}
            <div className="text-center pt-4 pb-3">
              <h6 className="overline-title overline-title-sap">
                <span>{t("ttl3")}</span>
              </h6>
            </div>
            <ul className="nav justify-center gx-8">
              <li className="nav-item">
                <a
                  className="nav-link"
                  href={process.env.REACT_APP_BASE_URL+"/auth/google-redirect"}
                >
                  <div>
                    <img
                      className="logo-img-sm"
                      src={icoGoogle}
                    ></img>
                  </div>
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  href={process.env.REACT_APP_URLENCODE_LOGIN_LINKEDIN}
                >
                 <div>
                    <img
                      className="logo-img-sm"
                      src={linkedin}
                    ></img>
                  </div>
                </a>
              </li>
            </ul>
            <button className="invisible" id="salirRedes" onClick={signOut}>
            {t("ttl4")}
            </button>
          {/* Modal to Select Rol */}
            <Button id="btnmdlchangeusertype" onClick={toggleSuccess}> </Button>
            <Modal isOpen={modalSuccess} toggle={toggleSuccess}>
                  <ModalBody className="modal-body-lg text-center">
                    <div className="nk-modal">
                      <Icon className="nk-modal-icon icon-circle icon-circle-xxl ni ni-check bg-success"></Icon>
                      <h4 className="nk-modal-title">{t("congratulations")}!</h4>
                      <div className="nk-modal-text">
                        <span className="sub-text-sm">
                        {t("choose_rol")}{" "}
                        </span>
                        <div className="caption-text">
                        <h5>{t("txt1")}</h5>
                        </div>

                      </div>
                      <div className="nk-modal-action">
                        <Button color="primary" size="lg" className="btn-mw" value="2" onClick={(e)=>changeTypeUser(e.target.value)}>
                        {t("lbl23")}
                        </Button>
                        <br/>
                        {" "}{t("lbl25")} {" "}
                        <br/>
                        <Button color="secondary" size="lg" className="btn-mw" value="3" onClick={(e)=>changeTypeUser(e.target.value)}>
                        {t("lbl24")}
                        </Button>
                      </div>
                    </div>
                  </ModalBody>
                </Modal>

          </PreviewCard>
        </Block>
        <AuthFooter />
      </PageContainer>
    </React.Fragment>
    </>
  )
}
export default Register;

import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import Head from "../../layout/head/Head";
import axios from "axios";
import UserProfile from "../../Login/UserProfile";
import {
    DropdownMenu,
    DropdownToggle,
    FormGroup,
    UncontrolledDropdown,
    Modal,
    ModalBody,
    DropdownItem,
  } from "reactstrap";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Button,
  Row,
  Col,
  PaginationComponent,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableRow,
  DataTableItem,
  RSelect,
  TooltipComponent,
} from "../../components/Component";
// import { useForm } from "react-hook-form";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

const UserProfileSocial = (props) => {

    const [t, i18n] = useTranslation("detailapplap")
    const [ idApplicant, setidApplicant ] = useState(0);
    const [ actividades, setactividades ] = useState([]);
    const [ socialNetwork, setsocialNetwork ] = useState([]);
    const [ socialNetworkRes, setsocialNetworkRes ] = useState([]);

    const addSocialNetwork = (val) => {
        var net = socialNetwork.find( item => item.id == val.network )
        const temp = {
          "network": net,
          "nickname": val.nickname,
          "url": val.url
        }
        setsocialNetworkRes([...socialNetworkRes, temp])
      };

      const deleteSocialNetwork = (val) => {
        setsocialNetworkRes(socialNetworkRes.filter((soc,itm) => itm !== val ))
      };


    const [ educationRes, seteducationRes ] = useState([]);
    const [ levelStudy, setlevelStudy ] = useState([]);
    const [data, setData] = useState("");

    const [sm, updateSm] = useState(false);
    const [tablesm, updateTableSm] = useState(false);
    const [onSearch, setonSearch] = useState(true);
    const [onSearchText, setSearchText] = useState("");
    const [modal, setModal] = useState({
      edit: false,
      add: false,
    });
    const [editId, setEditedId] = useState();
    const [formData, setFormData] = useState({
      name: "",
      email: "",
      balance: "",
      phone: "",
      status: "Active",
    });
    const [currentPage, setCurrentPage] = useState(1);
    const [itemPerPage, setItemPerPage] = useState(10);
    const [sort, setSortState] = useState("");

    const [ pais, setpais ] = useState([]);
    const [ paisLocation, setpaisLocation ] = useState([]);
    const [ departamento, setdepartamento ] = useState([]);
    const [ city, setcity ] = useState([]);
    const [ email, setEmail ] = useState([]);
    const [ optionSelected, setoptionSelected ] = useState(null);
    const [ languageRes, setlanguageRes ] = useState([]);
    const [ languagetipo, setlanguagetipo ] = useState([]);
    const [ nivelLanguage, setnivelLanguage ] = useState([]);

    // Sorting data
  const sortFunc = (params) => {
    let defaultData = data;
    if (params === "asc") {
      let sortedData = defaultData.sort((a, b) => a.name.localeCompare(b.name));
      setData([...sortedData]);
    } else if (params === "dsc") {
      let sortedData = defaultData.sort((a, b) => b.name.localeCompare(a.name));
      setData([...sortedData]);
    }
  };


  // Changing state value when searching name
  useEffect(() => {
    if (onSearchText !== "") {
      const filteredObject = educationRes.filter((item) => {
        return (
          item.name.toLowerCase().includes(onSearchText.toLowerCase()) ||
          item.email.toLowerCase().includes(onSearchText.toLowerCase())
        );
      });
      setData([...filteredObject]);
    } else {
      setData([...data]);
    }
  }, [onSearchText, setData]);

  // onChange function for searching name
  const onFilterChange = (e) => {
    setSearchText(e.target.value);
  };

    // function to reset the form
    const resetForm = () => {
      setFormData({
        name: "",
        email: "",
        type:1,
        password: "",
        status: 1,
      });
    };

    // function to close the form modal
    const onFormCancel = () => {
      setModal({ edit: false, add: false });
      resetForm();
    };

      // function to toggle the search option
  const toggle = () => setonSearch(!onSearch);
    // Get current list, pagination
  const indexOfLastItem = currentPage * itemPerPage;
  const indexOfFirstItem = indexOfLastItem - itemPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  // Change Page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

const getApplicant = async () => {
    var name = "";
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/api/applicants/profile`,
        {
          headers: {
            Authorization: `Bearer  ${UserProfile.getToken()}`,
          },
        }
      )
      .then((res) => {
        if (res.data.error ? res.data.error[0] == 'El Token a Expirado' : false) {props.history.push('/login'); return}
        setidApplicant(res.data.data.id)
        setEmail(res.data.data.email)
        // multiples
        setsocialNetworkRes(res.data.data.applicant_social_networks)
        setData(res.data.data.applicant_social_networks)
        let opactsel = []
        for (const acti of res.data.data.applicant_activities) {
          opactsel.push({"value":acti.activity.id, "label":acti.activity.name})
        }
        setoptionSelected(opactsel)

      })
      .catch((error) => console.log(error));
  };


  const onEditClick = (id) => {
    console.log('clic')

    setModal({ edit: true }, { add: false });
    setEditedId(id);

  };

  useEffect(() => {
    if (navigator.onLine) {
      UserProfile.setToken(localStorage.getItem("token"));
      UserProfile.setId(localStorage.getItem("id"));
      // getEducationInstitution();

      /***************** */
    //   getActividad();
      getApplicant();

    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "msg",
        confirmButtonColor: "#071b30",
        confirmButtonText: "ok",
      });
    }
  }, [])

  return (
    <React.Fragment>
      <Head title="Social, Videos and Docs - Profile"></Head>

      <BlockHead size="lg">
        <BlockBetween>
          <BlockHeadContent>
            {/* HARD Skills */}
            <BlockTitle tag="h4">{t("social_network_title")}</BlockTitle>
            <BlockDes>
              <p>{t("social_network_info")}</p>
            </BlockDes>
          </BlockHeadContent>
          <BlockHeadContent>

            <div className="toggle-wrap nk-block-tools-toggle">
                <Button
                  className={`btn-icon btn-trigger toggle-expand mr-n1 ${sm ? "active" : ""}`}
                  onClick={() => updateSm(!sm)}
                >
                  <Icon name="menu-alt-r"></Icon>
                </Button>
                <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                  <ul className="nk-block-tools g-3">
                    <li className="nk-block-tools-opt">
                      <Button color="primary" className="btn-icon" onClick={() => setModal({ add: true })}>
                        <Icon name="plus"></Icon> {t("ttl4")} &nbsp;
                      </Button>
                    </li>
                  </ul>
                </div>
              </div>
          </BlockHeadContent>
        </BlockBetween>
      </BlockHead>

      <Block>

      <DataTable className="card-stretch">
            <div className="card-inner position-relative card-tools-toggle">
              <div className="card-title-group">
              <div className="card-title">
                  <h5 className="title">{t("your_social_networks")}</h5>
                </div>
              <div className="card-tools">
                  <div className="form-inline flex-nowrap gx-3">
                  </div>
                </div>
                <div className="card-tools mr-n1">
                  <ul className="btn-toolbar gx-1">
                    <li>
                      <a
                        href="#search"
                        onClick={(ev) => {
                          ev.preventDefault();
                          toggle();
                        }}
                        className="btn btn-icon search-toggle toggle-search"
                      >
                        <Icon name="search"></Icon>
                      </a>
                    </li>
                    <li className="btn-toolbar-sep"></li>
                    <li>
                      <div className="toggle-wrap">
                        <Button
                          className={`btn-icon btn-trigger toggle ${tablesm ? "active" : ""}`}
                          onClick={() => updateTableSm(true)}
                        >
                          <Icon name="menu-right"></Icon>
                        </Button>
                        <div className={`toggle-content ${tablesm ? "content-active" : ""}`}>
                          <ul className="btn-toolbar gx-1">
                            <li>
                              <UncontrolledDropdown>
                                <DropdownToggle tag="a" className="btn btn-trigger btn-icon dropdown-toggle">
                                  <Icon name="setting"></Icon>
                                </DropdownToggle>
                                <DropdownMenu right className="dropdown-menu-xs">
                                  <ul className="link-check">
                                    <li>
                                      <span>{t("show", {ns:"global"})}</span>
                                    </li>
                                    <li className={itemPerPage === 10 ? "active" : ""}>
                                      <DropdownItem
                                        tag="a"
                                        href="#dropdownitem"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                          setItemPerPage(10);
                                        }}
                                      >
                                        10
                                      </DropdownItem>
                                    </li>
                                    <li className={itemPerPage === 15 ? "active" : ""}>
                                      <DropdownItem
                                        tag="a"
                                        href="#dropdownitem"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                          setItemPerPage(15);
                                        }}
                                      >
                                        15
                                      </DropdownItem>
                                    </li>
                                  </ul>
                                  <ul className="link-check">
                                    <li>
                                      <span>{t("order", {ns:"global"})}</span>
                                    </li>
                                    <li className={sort === "dsc" ? "active" : ""}>
                                      <DropdownItem
                                        tag="a"
                                        href="#dropdownitem"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                          setSortState("dsc");
                                          sortFunc("dsc");
                                        }}
                                      >
                                        DESC
                                      </DropdownItem>
                                    </li>
                                    <li className={sort === "asc" ? "active" : ""}>
                                      <DropdownItem
                                        tag="a"
                                        href="#dropdownitem"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                          setSortState("asc");
                                          sortFunc("asc");
                                        }}
                                      >
                                        ASC
                                      </DropdownItem>
                                    </li>
                                  </ul>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className={`card-search search-wrap ${!onSearch && "active"}`}>
                <div className="card-body">
                  <div className="search-content">
                    <Button
                      className="search-back btn-icon toggle-search active"
                      onClick={() => {
                        setSearchText("");
                        toggle();
                      }}
                    >
                      <Icon name="arrow-left"></Icon>
                    </Button>
                    <input
                      type="text"
                      className="border-transparent form-focus-none form-control"
                      placeholder={t("search_by",{ns:"global"}) + " " + t("language")}
                      value={onSearchText}
                      onChange={(e) => onFilterChange(e)}
                    />
                    <Button className="search-submit btn-icon">
                      <Icon name="search"></Icon>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <DataTableBody compact>
              <DataTableHead>
                <DataTableRow>
                  <span className="sub-text">{t("Network")}</span>
                </DataTableRow>
                <DataTableRow size="md">
                  <span className="sub-text">{t("nickname")}</span>
                </DataTableRow>
                <DataTableRow size="md">
                  <span className="sub-text">{t("link")}</span>
                </DataTableRow>
                <DataTableRow size="md">
                  <span className="sub-text">{t("options", {ns:"global"})}</span>
                </DataTableRow>
              </DataTableHead>
              {/*Head*/}
              {currentItems.length > 0
                ? currentItems.map((item) => {
                    return (
                      <DataTableItem key={item.id}>
                       <DataTableRow>
                       { item.network.name }
                        </DataTableRow>
                        <DataTableRow>
                        {item.nickname}
                        </DataTableRow>
                        <DataTableRow>
                        <a href={item.url} target="_blank">Link</a>
                        </DataTableRow>
                        <DataTableRow className="nk-tb-col-tools">
                          <ul className="nk-tb-actions gx-1">
                            <li className="nk-tb-action-hidden" onClick={() => onEditClick(item.id)}>
                              <TooltipComponent
                                tag="a"
                                containerClassName="btn btn-trigger btn-icon"
                                id={"edit" + item.id}
                                icon="edit-alt-fill"
                                direction="top"
                                text={t("modify",{ns:"global"})}
                              />
                            </li>
                            <li>
                              <UncontrolledDropdown>
                                <DropdownToggle tag="a" className="dropdown-toggle btn btn-icon btn-trigger">
                                  <Icon name="more-h"></Icon>
                                </DropdownToggle>
                                <DropdownMenu right>
                                  <ul className="link-list-opt no-bdr">
                                    <li onClick={() => onEditClick(item.id)}>
                                      <DropdownItem
                                        tag="a"
                                        href="#edit"
                                        onClick={(ev) => {
                                          ev.preventDefault();
                                        }}
                                      >
                                        <Icon name="edit"></Icon>
                                        <span>{t("modify",{ns:"global"})}</span>
                                      </DropdownItem>
                                    </li>
                                  </ul>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </li>
                          </ul>
                        </DataTableRow>
                      </DataTableItem>
                    );
                  })
                : null}
            </DataTableBody>
            <div className="card-inner">
              {currentItems.length > 0 ? (
                <PaginationComponent
                  itemPerPage={itemPerPage}
                  totalItems={data.length}
                  paginate={paginate}
                  currentPage={currentPage}
                />
              ) : (
                <div className="text-center">
                  <span className="text-silent">No Social Networks found</span>
                </div>
              )}
            </div>
          </DataTable>
      </Block>

      {/* Modal to Add Hard Skill */}

      <Modal isOpen={modal.add} toggle={() => setModal({ add: false })} className="modal-dialog-centered" size="lg">
          <ModalBody>
            <a
              href="#cancel"
              onClick={(ev) => {
                ev.preventDefault();
                onFormCancel();
              }}
              className="close"
            >
              <Icon name="cross-sm"></Icon>
            </a>
            <div className="p-2">
              <h5 className="title"> {t("lbl44")}</h5>
              <div className="mt-4">

              <Formik
                    initialValues={{
                      position: "",
                      company: "",
                      duration_time: "",
                      unit_measurement: "",
                      initial_year: "",
                      functions: "",
                      goals: ""
                    }}

                    validationSchema={Yup.object().shape({
                      position:
                        Yup.string().required(t("fr")),
                      company:
                        Yup.string().required(t("fr")),

                      duration_time: Yup.number()
                        .typeError(t("onum"))
                        .required(t("fr")),

                      unit_measurement: Yup.string().required(t("fr")),

                      initial_year: Yup.string().required(t("fr")),
                      functions: Yup.string().required(t("fr")),
                      goals: Yup.string().required(t("fr")),

                    })}

                    validate = {(values) => {
                      const errors = {};
                      if (values.duration_time < 1) errors.duration_time = t("fr");
                      return errors;
                    }}

                    onSubmit={(val, { resetForm }) => {
                      // addExperience(val);
                      resetForm();
                      onFormCancel();
                    }}
                    render={({ errors, status, touched }) => (

                      <Form className="row gy-4" >

                  <Col md="6">
                    <FormGroup>
                    <label className="form-label">{t("lbl45")}</label>

                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <label className="form-label">{t("lbl41")} </label>
                    </FormGroup>
                  </Col>
                  <Col size="6">
                    <FormGroup>
                      <label className="form-label">{t("lbl42")}</label>
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <label className="form-label">{t("lbl46")}</label>
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <label className="form-label"> {" "}
                                {t("lbl47")} &nbsp;</label>
                    </FormGroup>
                  </Col>
                  <Col md="12">
                    <FormGroup>
                      <label className="form-label">{t("lbl48")}</label>
                    </FormGroup>
                  </Col>
                  <Col md="12">
                  <FormGroup>
                    <label className="form-label">{t("lbl49")}</label>
                  </FormGroup>
                </Col>
                   <Col size="12">
                    <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                      <li>
                        <Button color="primary" size="md" type="submit">
                        {t("lbl33")}
                        </Button>
                      </li>
                      <li>
                        <a
                          href="#cancel"
                          onClick={(ev) => {
                            ev.preventDefault();
                            onFormCancel();
                          }}
                          className="link link-light"
                        >
                          {t("cancel")}
                        </a>
                      </li>
                    </ul>
                  </Col>

                </Form>
                  )}
                  />
              </div>
            </div>
          </ModalBody>
        </Modal>

       {/* Modal to Update Experience */}

       <Modal isOpen={modal.edit} toggle={() => setModal({ edit: false })} className="modal-dialog-centered" size="lg">
          <ModalBody>
            <a
              href="#cancel"
              onClick={(ev) => {
                ev.preventDefault();
                onFormCancel();
              }}
              className="close"
            >
              <Icon name="cross-sm"></Icon>
            </a>
            <div className="p-2">
              <h5 className="title"> {t("lbl44")}</h5>
              <div className="mt-4">

              <Formik
                    initialValues={{
                      position: "",
                      company: "",
                      duration_time: "",
                      unit_measurement: "",
                      initial_year: "",
                      functions: "",
                      goals: ""
                    }}

                    validationSchema={Yup.object().shape({
                      position:
                        Yup.string().required(t("fr")),
                      company:
                        Yup.string().required(t("fr")),

                      duration_time: Yup.number()
                        .typeError(t("onum"))
                        .required(t("fr")),

                      unit_measurement: Yup.string().required(t("fr")),

                      initial_year: Yup.string().required(t("fr")),
                      functions: Yup.string().required(t("fr")),
                      goals: Yup.string().required(t("fr")),

                    })}

                    validate = {(values) => {
                      const errors = {};
                      if (values.duration_time < 1) errors.duration_time = t("fr");
                      return errors;
                    }}

                    onSubmit={(val, { resetForm }) => {
                      // addExperience(val);
                      resetForm();
                      onFormCancel();
                    }}
                    render={({ errors, status, touched }) => (

                      <Form className="row gy-4" >

                  <Col md="6">
                    <FormGroup>
                    <label className="form-label">{t("lbl45")}</label>

                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <label className="form-label">{t("lbl41")} </label>
                      <Field
                                id="duration_time"
                                name="duration_time"
                                type="number"
                                class="form-control"
                                placeholder={t("lbl41")}
                              />

                              <ErrorMessage
                                name="duration_time"
                                component="div"
                                className="invalid"
                              />
                    </FormGroup>
                  </Col>
                  <Col size="6">
                    <FormGroup>
                      <label className="form-label">{t("lbl42")}</label>
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <label className="form-label">{t("lbl46")}</label>
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <label className="form-label"> {" "}
                                {t("lbl47")} &nbsp;</label>
                    </FormGroup>
                  </Col>
                  <Col md="12">
                    <FormGroup>
                      <label className="form-label">{t("lbl48")}</label>
                      <Field
                                id="functions"
                                name="functions"
                                type="textarea"
                                class="form-control"
                                component="textarea"
                                aria-describedby="emailHelp"
                                placeholder={t("lbl48")}
                                rows="3"
                              />

                              <ErrorMessage
                                name="functions"
                                component="div"
                                className="invalid"
                              />
                    </FormGroup>
                  </Col>
                  <Col md="12">
                  <FormGroup>
                    <label className="form-label">{t("lbl49")}</label>
                    <Field
                                id="goals"
                                name="goals"
                                type="textarea"
                                class="form-control"
                                component="textarea"
                                aria-describedby="emailHelp"
                                placeholder={t("lbl49")}
                                rows="3"
                              />

                              <ErrorMessage
                                name="goals"
                                component="div"
                                className="msgerror mx-2"
                              />
                  </FormGroup>
                </Col>
                   <Col size="12">
                    <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                      <li>
                        <Button color="primary" size="md" type="submit">
                        {t("lbl33")}
                        </Button>
                      </li>
                      <li>
                        <a
                          href="#cancel"
                          onClick={(ev) => {
                            ev.preventDefault();
                            onFormCancel();
                          }}
                          className="link link-light"
                        >
                          {t("cancel")}
                        </a>
                      </li>
                    </ul>
                  </Col>

                </Form>
                  )}
                  />
              </div>
            </div>
          </ModalBody>
        </Modal>

    </React.Fragment>

  );
};
export default UserProfileSocial;
